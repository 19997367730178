package global

import androidx.compose.runtime.*
import api.CustomMyListResponse
import api.commonUserPostJsonRequest
import api.firebase.getClientTrainings
import api.firebase.getCommonListFromTrainer
import bdElement.BaseComplex
import bdElement.BaseTraining
import bdElement.CreatiumRequest
import bdElement.MyAnswerCommon
import elements.*
import global.QuickStyleProperty.zIndex
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import mainPage.modalWindows.ModalComplexInfo
import org.jetbrains.compose.web.attributes.Draggable
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import style.First.StyleCommon
import style.First.StyleTrainingRoom
import style.White.StyleCommonElementWhite
import style.common.StyleCustomProperties

object ListComplexes {
    var complexesLoaded by mutableStateOf<MyAnswerCommon<BaseComplex>?>(null)
        private set

    var slider by mutableStateOf<SliderPanel?>(null)

    private fun setComplexesLoaded(load: CustomMyListResponse<BaseComplex>) {
        complexesLoaded = load.getMyAnswerCommon()
        load.totalCount?.let { pagination.totalCount = it }
    }

    val pagination = PageAdapter(12) { limit, skip, silent ->
        if (silent.not()) loadingProcess = true
        getCommonListFromTrainer("spis_complexes", listOf(), limit, skip) {
            setComplexesLoaded(it)

            loadingProcess = false
        }
    }
    private var loadingProcess by mutableStateOf(true)

    private var firstStart: Boolean = true

    @Composable
    fun listenerForWritingCookies() {
//        LaunchedEffect(selectedClient?.selectedTraining) {
//            currentUser.userProfile?.id?.let { id_user ->
//                selectedClient?.selectedTraining?.id?.let { id_training ->
//                    setCookie("${id_user}_selectedClient_selTraining", id_training)
//                } ?: run {
//                    deleteCookie("${id_user}_selectedClient_selTraining")
//                }
//            }
//        }
    }

    private fun getFromCookie() {
//        if (firstStart){
//            currentUser.userProfile?.id?.let { id_user ->
//                getCookie("${id_user}_selectedClient_selTraining")?.let {id_training ->
//                    if (id_training.isNotEmpty()){
//                        complexesLoaded?.rows?.find{ it.id == id_training}?.let {
//                            selectedClient?.setSelectedTraining(it)
//                        }
//                    }
//                }
//            }
//            firstStart = false
//        }
    }


    @Composable
    fun getViewListComplexes(archive: Boolean) {
        if (loadingProcess) {
            CenterDiv({ style { marginTop(40.px) } }) { mainLoader() }
        } else {
            complexesLoaded?.mutList?.filter { if (archive) it.archive == true else it.archive != true }?.let {
                if (it.isEmpty()) {
                    H3(attrs = {
                        classes(styleBlock.commonElement.headerBlock)
                        style {
                            marginTop(20.pt)
                            color(Color.gray)
                        }
                    }) {
                        if (archive) Text("В вашем архиве пока что нет ни одного комплекса.")
                        else Text("Здесь будут отображаться комплексы которые вы создадите.")
                    }
                } else {
                    FlexColumnDivParent(rowGap = 0.px) {
                        it.mapIndexed { index, baseComplex -> Pair(index, baseComplex) }.forEach {
                            if (it.first != 0) Div({ classes(styleBlock.trainerRoom.delimiterCardClient) })
                            CardComplex(it.second) {
                                selectedComplex = it.second
                            }
                        }
                    }
                }
            }
        }
        pagination.paginationElement()
    }

    @Composable
    fun getViewListComplexesForSelect(onClick: (BaseComplex) -> Unit) {
        if (loadingProcess) {
            CenterDiv({ style { marginTop(40.px) } }) { mainLoader() }
        } else {
            complexesLoaded?.mutList?.let {
                FlexColumnDivParent(rowGap = 0.px) {
                    it.mapIndexed { index, baseComplex -> Pair(index, baseComplex) }.forEach {
                        if (it.first != 0) Div({ classes(styleBlock.trainerRoom.delimiterCardClient) })
                        CardComplex(it.second, true) {
                            onClick(it.second)
                        }
                    }
                }
            }
        }
        pagination.paginationElement()
    }

}