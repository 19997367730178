package elements

import androidx.compose.runtime.Composable
import bdElement.UserProfile
import global.*
import global.QuickStyleProperty.borderBox
import global.QuickStyleProperty.fontCommon
import global.QuickStyleProperty.fontHeader
import global.QuickStyleProperty.fontSubheader
import global.QuickStyleProperty.zIndex
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLButtonElement
import style.First.StyleTrainingRoom
import style.common.StyleCustomProperties
import style.common.StyleNavigationCommon

@Composable
private fun buttonGamburger() {
    CenterDiv({
        style {
            marginLeft(6.px)
            width(headerHeight.px)
            height(headerHeight.px)
//                    flexDirection(FlexDirection.Column)
            fontSize(20.pt)
            color(stylePalette.mainTextColor)
            flex("initial")
        }
//        tabIndex(1)
//        id("gamburger")
//        classes(StylePageTitleCommon.gamburger)
        onClick {
            widthSideNavPx = if (widthSideNavPx == widthSide) 0 else widthSide
            headerTop = 0
//            console.log("gamburgerAction()")
            MainScope().launch {
                for (i in 1..5) {
//                    console.log("gamburgerAction($i)")
                    delay(100)
                    onresizeClientComplexSlider()
                }
            }
        }
    }) {
        I({
            classes(
                "fa-solid", "fa-bars",
                StyleTrainingRoom.chevron,
            )
        })
    }
}

@Composable
fun navMenuButton(
    name: String,
    page: PageApplication,
    attrs: AttrBuilderContext<HTMLButtonElement>? = null,
    marker: @Composable () -> Unit = {},
    first: Boolean = false,
    onclick: () -> Unit = {}
) {
    if (first.not()) CenterDiv {
        Div({
            classes(styleBlock.trainerRoom.delimiterCardClient)
            style {
                width(60.pt)
                opacity(0.6)
            }
        })
    }
    Button({
//        classes(styleBlock.navigation.buttonSideNav, StyleNavigationCommon.buttonSideNav)
        attrs?.invoke(this)
        style {
            borderWidth(0.px)
            backgroundColor(Color.white)
            property("margin", "0pt auto")
            width(234.pt)
            padding(10.px)
            textDecoration("none")
            display(DisplayStyle.Block)
            borderRadius(styleConst.borderRadiusCommon)
        }
        onClick {
            onclick()
            currentPage = page
            if (phoneDevice) widthSideNavPx = 0
        }
    }) {
        CenterDiv({
            style {
                position(Position.Relative)
            }
        }) {
            CenterDiv({
                style {
                    fontSubheader(if (currentPage != page) stylePalette.fontCommonColor else stylePalette.fontHeaderColor)
                    width(100.percent)
                    borderBox()
                    columnGap(10.px)
                }
            }) {
                Text(name)
                marker()
            }
            CenterDiv({
                if (currentPage != page) classes(StyleCustomProperties.hoverOpacity)
                style {
                    position(Position.Absolute)
                    width(100.percent)
                    height(100.percent)
                }
            }) {
                CenterDiv {
                    Div({
                        style {
                            borderRadius(50.percent)
                            width(10.pt)
                            height(10.pt)
                            backgroundColor(if (currentPage != page) stylePalette.fontCommonColor else stylePalette.myGreen)
                        }
                    })
                }
                Div({
                    style {
                        flex("auto")
                    }
                })
                CenterDiv {
                    Div({
                        style {
                            borderRadius(50.percent)
                            width(10.pt)
                            height(10.pt)
                            backgroundColor(if (currentPage != page) stylePalette.fontCommonColor else stylePalette.myGreen)
                        }
                    })
                }
            }
        }
    }
}

@Composable
fun buttonLogIn(
    name: String,
    page: PageApplication,
    attrs: AttrBuilderContext<HTMLButtonElement>? = null,
    marker: @Composable () -> Unit = {},
    first: Boolean = false,
    onclick: () -> Unit = {}
) {
    if (first.not()) CenterDiv {
        Div({
            classes(styleBlock.trainerRoom.delimiterCardClient)
            style {
                width(60.pt)
                opacity(0.6)
            }
        })
    }
    Button({
        classes(styleBlock.navigation.buttonSideNav, StyleNavigationCommon.buttonSideNav)
        attrs?.invoke(this)
        style {
            borderWidth(0.px)
            backgroundColor(Color.white)
            property("margin", "0pt auto")
            width(234.pt)
            padding(10.px)
            textDecoration("none")
            display(DisplayStyle.Block)
        }
        onClick {
            onclick()
            currentPage = page
            if (phoneDevice) widthSideNavPx = 0
        }
    }) {
        CenterDiv({
            style {
                position(Position.Relative)
            }
        }) {
            CenterDiv({
                style {
                    fontSubheader(Color.white)
                    width(100.percent)
                    borderBox()
                    columnGap(10.px)
                }
            }) {
                Text(name)
            }
        }
    }
}

@Composable
private fun markerCommon(small: Boolean, color: CSSColorValue, text: String, onclick: () -> Unit) {
    Div(attrs = {
        classes(styleBlock.commonElement.buttonBack)
        style {
            alignItems(AlignItems.Center)
            color(Color.white)
            if (small) {
                fontSize(15.px)
                padding(2.px, 6.px)
            } else {
                fontSize(18.px)
                padding(5.px, 10.px)
            }
            backgroundColor(color)
        }
        onClick {
            onclick()
        }
    }) {
        Text(text)
    }
}

@Composable
private fun markerRequestAndClient(small: Boolean) {
    if (ListRequestsComplexNew.newRequest > 0) markerCommon(
        small,
        stylePalette.myOrange,
        "${ListRequestsComplexNew.newRequest}"
    ) {}
    if (ListClients.newClient > 0) markerCommon(small, stylePalette.myGreen, "${ListClients.newClient}") {}
}

@Composable
private fun avatarBlock(profile: UserProfile){
    CenterDiv({ style { position(Position.Relative) } }) { //if (it.avatar != null && it.avatar != "")
        Div({
            style {
                width(widthSide.px * 0.6)
//                            width(60.percent)
                maxHeight(window.innerHeight * 0.2.px)
                maxWidth(window.innerHeight * 0.2.px)
            }
        }) {

            Img(src = profile.avatar ?: tempAvatarUrl) {
                classes(
                    StyleNavigationCommon.avatarUserProfile,
                    styleBlock.navigation.avatarUserProfile
                )
            }
        }
        CenterDiv({
            style {
                position(Position.Absolute)
                bottom(-3.px)
                padding(3.px, 25.px)
                borderRadius(15.px)
                backgroundColor(if (profile.role?.contains("trainer") == true) stylePalette.myOrange else stylePalette.myBlue)
                fontFamily(styleConst.mainFontFamily)
                color(stylePalette.mainTextColorInv)
                fontSize(18.px)
                fontWeight(600)
                border(3.px, LineStyle.Solid, Color.white)
            }
        }) {
            Text(if (profile.role?.contains("trainer") == true) "Тренер" else "Клиент")
        }
    }
    CenterDiv({style {
        flexDirection(FlexDirection.Column)
        alignItems(AlignItems.Center)
    }}) {
        P({
            style {
                marginTop(styleConst.paddingCommon)
                fontHeader()
            }
        }) {
            Text(profile.name ?: "")
        }
        P({
            style {
                fontCommon()
                margin(styleConst.paddingCommon, 0.px)
            }
        }) {
            Text(profile.email ?: "")
        }
        P({
            style {
                fontCommon()
                marginBottom(styleConst.paddingCommon*3)
            }
        }) {
            Text(profile.phone ?: "")
        }
    }
}

@Composable
fun headerAndSideNav() {
    Div({
        classes(styleBlock.navigation.headerStyle)
        style {
            if (selectableNone) property("user-select", "none")
            zIndex(195)
            overflow("hidden")
            position(Position.Absolute)
            top(headerTop.px)
            width(100.percent)
            height(headerHeight.px)
            alignContent(AlignContent.Center)
        }
    }) {
        FlexRowDivParent(columnGap = 0.px) {
            buttonGamburger() //if (window.innerWidth >= 700)
            CenterDiv({
                style {
                    flex("auto")
                    height(headerHeight.px)
                    flexDirection(FlexDirection.Row)
                    justifyContent(JustifyContent.Left)
                    columnGap(10.px)
                }
            }) {
                H2({
                    classes(styleBlock.navigation.title, StyleNavigationCommon.title)
                }) {
                    Text("MasterGYM")
                }
                markerRequestAndClient(false)
            }
            if (widthSideNavPx == 0 || !phoneDevice) BackButtonStack.buttonBack()
//            if (window.innerWidth < 700) buttonGamburger()
        }
    }



    Div({
        id("mySidenav")
        classes(styleBlock.navigation.sideNav, StyleNavigationCommon.sideNav)
//        addEventListener("wheel"){
//            it.preventDefault()
//        }
//        onScroll {
//            console.log("scroll navigation menu")
//        }
        style {
//            property("pointer-events", "fill")
            zIndex(190)
            if (selectableNone) property("user-select", "none")
            width(widthSideNavPx.px)
            display(DisplayStyle.Flex)
            flexDirection(FlexDirection.Column)
            rowGap(0.px)
        }
    }) {
        Div({
            style {
                flex("auto")
                marginBottom(styleConst.paddingCommon)
                overflowY("auto")
                overflowX("hidden")
            }
        }) {
            Div({
                style {
                    paddingTop(headerHeight.px + headerTop.px)
                    property("transition", "padding-top .3s")
                }
            }) {
                if (currentUser.userProfile?.role?.contains("admin") == true) H4({//if (currentUser.userProfile?.role?.contains("admin") == true)
                    style {
                        marginLeft(50.pt)
                        fontSize(12.px)
                        color(Color.lightgray)
                    }
                }) {
                    Text(versionApplicationStr)
                }
                currentUser.userProfile?.let {
                    avatarBlock(it)
                    navMenuButton("На главную", PageApplication.TitlePage, first = true)
                    if (it.role?.contains("trainer") == true) {
                        navMenuButton("Тренерская", PageApplication.TrainerRoom) {
                            setSelectedTrainingForEdit(null)
                            selectedClient = null
                        }
//                        if (it.role?.contains("admin") == true) {
                        navMenuButton("Мои видео", PageApplication.MyVideo)
                        navMenuButton("Заявки", PageApplication.Sales, marker = { markerRequestAndClient(true) })
//                        }
                    }
                    navMenuButton("Профиль", PageApplication.UserProfile)
                }
                if (currentUser.login) {
//                    if (currentUser.getEmailVerified() == true) {
                    navMenuButton("Тренировки", PageApplication.Trainings) {
                        selectedOpenTraining = null
                    }
//                    }
                    navMenuButton("Как пользоваться", PageApplication.Helps)
                } else {
                    navMenuButton("На главную", PageApplication.TitlePage)
                    navMenuButton("Как пользоваться", PageApplication.Helps)
                    buttonLogIn("Войти", PageApplication.Registration, {
                        classes(styleBlock.navigation.buttEnter, StyleNavigationCommon.buttEnter)
                    })
                }
            }
        }
    }
}