package elements

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import androidx.compose.web.events.SyntheticMouseEvent
import global.QuickStyleProperty.borderBox
import global.QuickStyleProperty.myTestBorder
import global.QuickStyleProperty.zIndex
import global.selectableNone
import kotlinx.browser.document
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.events.SyntheticTouchEvent
import org.w3c.dom.events.Event
import org.w3c.dom.events.MouseEvent
import org.w3c.dom.get
import kotlin.math.abs
import kotlin.math.sqrt

class MyTabs(val name: String, val content: @Composable (Boolean) -> Unit)

class MyToucheEvent(
    val clientX: Int,
    val clientY: Int,
    val pageX: Double,
    val pageY: Double,
    val preventDefault: () -> Unit
) {
    constructor(me: SyntheticMouseEvent) : this(me.clientX, me.clientY, me.pageX, me.pageY, {
        me.preventDefault()
    })

    constructor(me: MouseEvent) : this(me.clientX, me.clientY, me.pageX, me.pageY, {
        me.preventDefault()
    })

    constructor(me: SyntheticTouchEvent) : this(me.touches[0]?.clientX ?: 0, me.touches[0]?.clientY ?: 0,
        me.touches[0]?.pageX?.toDouble() ?: 0.0, me.touches[0]?.pageY?.toDouble() ?: 0.0, {
            me.preventDefault()
        })
}

class SliderPanel(val tabs: List<MyTabs>, funcResize: (()->Unit)->Unit = {}) {

    init {
        funcResize{
            console.log("window.onresize in slider")
            val slideWidthPx: Int = document.getElementById("slider_list")?.clientWidth ?: 150
            translateAnimX = slideWidthPx * slideIndex.toDouble()
        }
    }

    private var dragInit by mutableStateOf(false)
    var moveNowX by mutableStateOf(false)
    private var moveNowY by mutableStateOf(false)
    private var translateAnimX by mutableStateOf(0.0)
    private var translateIndex by mutableStateOf(0.0)

    var slideIndex by mutableStateOf(0)
        private set

    fun next(){
        setSlide(slideIndex + 1)
    }
    fun prev(){
        setSlide(slideIndex - 1)
    }
    fun setSlide(index: Int){
        if (index>=0 && index<tabs.count()){
            val slideWidthPx: Int = document.getElementById("slider_list")?.clientWidth ?: 150
            slideIndex = index
            translateAnimX = slideWidthPx * slideIndex.toDouble()
            paddingStart = translateAnimX
        }
    }

    private var Xi = 0
    private var Yi = 0

    private var Xt = 0
    private var Yt = 0

    private var Xf = 0
    private var Yf = 0

    private var paddingStart = 0.0

    fun dragInitFun(event: MyToucheEvent) {
//        event.preventDefault()
        dragInit = true
        selectableNone = true
        Xi = event.clientX
        Yi = event.clientY
//        translateAnimX = paddingStart
    }

    fun drogEnd() {
        dragInit = false

        if (moveNowX) {
            val slideWidthPx: Int = document.getElementById("slider_list")?.clientWidth ?: 150
            val delta: Int = ((translateAnimX - slideWidthPx * slideIndex)/slideWidthPx).toInt()
            val ostatok: Int = ((translateAnimX - slideWidthPx * slideIndex)%slideWidthPx).toInt()
            if (ostatok > slideWidthPx * 0.35) {
                slideIndex++
            } else if (ostatok < -slideWidthPx * 0.35) {
                slideIndex--
            }
            slideIndex += delta
            if (slideIndex > tabs.count() - 1) slideIndex = tabs.count() - 1
            if (slideIndex < 0) slideIndex = 0

            translateAnimX = slideWidthPx * slideIndex.toDouble()
            translateIndex = 0.0
            paddingStart = translateAnimX
        }

        moveNowX = false
        moveNowY = false
        Xi = 0
        Yi = 0
    }


    fun dragMove(event: MyToucheEvent) {

        val touchX: Double = event.pageX
        val touchY: Double = event.pageY
        Xf = event.clientX
        Yf = event.clientY

        Xt = Xf - Xi
        Yt = Yf - Yi

        if ((Xt > 10 || Xt < -10) && moveNowY.not() && moveNowX.not()) moveNowX = true
        if ((Yt > 10 || Yt < -10) && moveNowY.not() && moveNowX.not()) moveNowY = true

        if (moveNowX) {
            event.preventDefault()
            val slideWidthPx: Int = document.getElementById("slider_list")?.clientWidth ?: 150
            if (Xt > 0 && slideIndex == 0) translateAnimX = paddingStart - sqrt(Xt.toDouble())
            else if (Xt < 0 && slideIndex == tabs.count() - 1) translateAnimX = paddingStart + sqrt(-Xt.toDouble())
            else translateAnimX = paddingStart - Xt
            translateIndex = (translateAnimX - paddingStart)/slideWidthPx.toDouble()
        }
    }

    @OptIn(ExperimentalComposeWebApi::class)
    @Composable
    fun sliderPanel() {
        Div({
            id("slider_list")
            style {
                borderBox()
                width(100.percent)
                height(100.percent)
//                overflow("hidden")
//                height(200.px)
//                myTestBorder()
            }
        }) {
            Div({
                id("slider_track")
                style {
//                    myTestBorder(Color.green)
//                    height(175.px)
                    width(100.percent * tabs.count())
                    height(100.percent)
//                    overflow("hidden")
                    display(DisplayStyle.Flex)
                    columnGap(0.px)
                    position(Position.Relative)
//                    zIndex(if (dragInit || dropDownElementNow == assignment.id.toString()) 150 else 10)
                    transform {
                        translate(-translateAnimX.px, 0.px)
                    }
                    transitions {
                        all {
                            duration(if (dragInit) 0.001.s else 0.2.s)
                            timingFunction(AnimationTimingFunction.EaseInOut)
                        }
                    }
                }
                onMouseDown {
                    dragInitFun(MyToucheEvent(it))
                    document.onmousemove = { event -> dragMove(MyToucheEvent(event)) }
                    document.onmouseup = {
                        selectableNone = false
                        document.onmousemove = null
                        document.onmouseup = null
                        drogEnd()
                        null
                    }
                }
                onTouchStart {
                    dragInitFun(MyToucheEvent(it))
                }
                if (dragInit) onTouchMove { event ->
                    dragMove(MyToucheEvent(event))
                }
                onTouchEnd {
//                    it.preventDefault()
                    selectableNone = false
                    drogEnd()
                }
            }) {
                tabs.mapIndexed{ index, myTabs -> index to myTabs }.forEach {
                    Div({
                        style {
//                        myTestBorder(Color.red)
//                        height(150.px)
                            height(100.percent)
                            overflowY("auto")
//                            overflowX("hidden")
                            opacity((1.0-abs(slideIndex + translateIndex - it.first)/3.0).let { if (it<0.1) 0.1 else it })
                            transform {
                                scale((1.0-abs(slideIndex + translateIndex - it.first)/5.0).let { if (it<0.5) 0.5 else it })
                            }
                            transitions {
                                all {
                                    duration(if (translateIndex == 0.0) 0.2.s else 0.0.s)
                                    timingFunction(AnimationTimingFunction.EaseInOut)
                                }
                            }
                            flexBasis((100.0 / tabs.count().toFloat()).percent)
                        }
                    }) {
                        it.second.content(it.first == slideIndex && translateIndex == 0.0)
                    }
                }
                Div({
                    style {
                        position(Position.Absolute)
                        width(100.percent)
                        height(100.percent)
                        zIndex(if (moveNowX) 250 else -1)
//                        backgroundColor(rgba(0, 255, 255, 0.5))
                    }
                })
            }
        }
    }
}