package mainPage.modalWindows

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import api.bunny.uploadImage
import api.firebase.updateUserProfile
import elements.CenterDiv
import elements.ModalContent
import elements.blankLoaderInOver
import elements.mainLoaderInOver
import global.*
import kotlinx.browser.window
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.accept
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.w3c.fetch.Headers
import org.w3c.fetch.RequestInit
import org.w3c.files.get
import org.w3c.xhr.FormData
import serverAdress
import style.First.StyleUserProfile
import kotlin.js.Date
import kotlin.js.Promise


class ModalChangeAvatar() : ModalContent() {

    private var imgTempAvatar by mutableStateOf<String?>(null)
    private var disabledButtSet by mutableStateOf("temp")

    private var uploadImgStart = mutableStateOf(false)
    private var uploadImgProgress by mutableStateOf(0.percent)

    @Composable
    override fun drawModalContent() {
        Div({
            style {
                padding(20.px)
            }
        }) {
            CenterDiv({ style { position(Position.Relative) } }) {
                Img(
                    src = imgTempAvatar ?: currentUser.userProfile?.avatar ?: tempAvatarUrl,
//                    alt = tempAvatarUrl
                ) {
                    id("imgAvatarPan")
                    classes(styleBlock.userProfile.avatarImg)
                    style {
                        width(170.pt)
//                        borderRadius(50.percent)
//                        border(1.px, LineStyle.Solid, rgb(79, 79, 79))
                    }
                }
                if (uploadImgStart.value) mainLoaderInOver()
            }
            if (uploadImgStart.value) CenterDiv {
                Div({
                    style {
                        margin(5.pt, 0.pt)
                        border(2.px, LineStyle.Solid, stylePalette.myOrange)
                        borderRadius(3.pt)
                        overflow("hidden")
                        height(5.pt)
                        width(170.pt)
                        position(Position.Relative)
                    }
                }) {
                    Div({
                        style {
                            position(Position.Absolute)
                            top(0.px)
                            left(0.px)
                            opacity(0.7)
                            height(100.percent)
                            width(uploadImgProgress)
                            backgroundColor(stylePalette.myOrange)
                        }
                    })
                }
            }
            CenterDiv({
                style {
                    height(40.px)
                    textAlign("center")
                    paddingTop(16.px)
                    position(Position.Relative)
                }
            }) {
                Input(type = InputType.File) {
                    classes("inputFile")
                    style {
                        display(DisplayStyle.None)
                        padding(8.px)
                    }
                    accept("image/*")
                    id("file")
                    onChange { e ->
                        val file = e.target.files?.get(0);
                        console.log("open file")
                        file?.let {
                            console.log("file not")
                            uploadImage(e.target,
                                "avatar_${currentUser.userProfile?.id}_${Date.now()}",
                                600,
                                600,
                                "$serverAdress/upload_image",
                                uploadImgStart,
                                uploadImgProgress = { progress -> uploadImgProgress = (progress * 100).percent },
                                funcRez = { fileName ->
                                    imgTempAvatar = "https://fitconstructorimg.b-cdn.net/avatar/$fileName"
                                }
                            )
                        }
                    }
                }
                Label(forId = "file", attrs = {
                    classes(StyleUserProfile.buttSetAvatar)
                }) {
                    Text("Выбрать файл")
                }
                if (uploadImgStart.value) blankLoaderInOver()
            }
        }
        if (uploadImgStart.value.not()) imgTempAvatar?.let {
            CenterDiv {
                Button({
                    classes(StyleUserProfile.buttSetAvatar)
                    style { width(100.percent) }
                    attr(disabledButtSet, "")
                    onClick {
                        disabledButtSet = "disabled"
                        console.log("buttDisable")
                        setAvatar()
                    }
                }) {
                    Text("Установить аватар")
                }
            }
        }
    }

    fun setAvatar() {

        imgTempAvatar?.let {
            currentUser.getIdToken { token ->
                val headers = Headers()
                headers.append("accessToken", token)
//            headers.append("Content-Type", "application/json")
                headers.append("Accept", "*/*")

                val formData = FormData();

                formData.append("idProfile", currentUser.userProfile?.id ?: "");
                console.log(currentUser.userProfile?.id)
                formData.append("fileLink", it);
                console.log(it)

                val options: RequestInit = RequestInit(
                    method = "POST",
                    headers = headers,
                    body = formData
                )

                Promise.resolve(window.fetch("$serverAdress/set_new_avatar", options).then {
                    updateUserProfile()
                    closeModadWindow()
                    disabledButtSet = "temp"
                })
            }
        }
    }

    /*
        fun uploadImage11(fileInput: HTMLInputElement, maxWidth: Int, maxHeight: Int) {
            val file = fileInput.files?.get(0) ?: return

            val reader = FileReader()

            reader.onloadend = {
                val img = Image()

                img.onload = {
                    val canvas = document.createElement("canvas") as HTMLCanvasElement
                    val width = img.width;
                    val height = img.height;
                    val shouldResize = (width > maxWidth) || (height > maxHeight);

                    var newWidth = 0
                    var newHeight = 0

                    if (!shouldResize) {
                        newWidth = width
                        newHeight = height
                    } else {
                        if (width > height) {
                            newHeight = (height * (maxWidth.toDouble() / width.toDouble())).toInt()
                            newWidth = maxWidth;
                        } else {
                            newWidth = (width * (maxHeight.toDouble() / height.toDouble())).toInt()
                            newHeight = maxHeight;
                        }
                    }
                    canvas.width = newWidth
                    canvas.height = newHeight
                    val ctx = canvas.getContext("2d") as CanvasRenderingContext2D
                    ctx.drawImage(img, 0.0, 0.0, newWidth.toDouble(), newHeight.toDouble())

                    canvas.toBlob(
                        { blob ->
    //                        println("blob canvas: $blob")
                            blob?.let {
                                val formData = FormData()

                                val extension: String = file.name.substring(file.name.lastIndexOf(".") + 1)
                                val fileName = "avatar_${currentUser.userProfile?.id}_${Date.now()}.$extension"

                                formData.append("fileName", fileName)
                                formData.append("file", blob)//resizedImage)

                                uploadImgStart.value = true
                                axiosUploadWithProgress(
                                    url = "$serverAdress/upload_image",
                                    formData = formData,
                                    progressFun = { progress ->
                                        console.log("${(progress * 100).toInt()}%")
                                        uploadImgProgress = (progress * 100).percent
    //            fileCard.progBoxComplete.style.width = String((p.loaded / p.total) * 100) + "%";
                                    },
                                    thenFun = { code ->
                                        console.log("status upload image code: $code")
                                        imgTempAvatar = "https://fitconstructorimg.b-cdn.net/avatar/$fileName"
                                        uploadImgStart.value = false
                                        */
    /*
                                                     if (data.data.statusCode === 200) {
                                                         fileCard.tableCellCont1.style.display = 'none';
                                                         fileCard.progressBox.style.display = 'none';
                                                         fileCard.cardFile.style.background = '#ddfdd2';
                                                         fileCard.cardFile.style.borderColor = '#59ff21';
                                                         fileCard.progBoxComplete.style.background = '#59ff21';
                                                     }
                                         *//*

                                }
                            )
                        }
                    }
                )
            }

            img.src = it.target.asDynamic().result as String
            null//img.src = reader.result as String
        }

        reader.readAsDataURL(file)
    }
*/
}
