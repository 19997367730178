package elements

import androidx.compose.runtime.Composable
import androidx.compose.web.events.SyntheticEvent
import global.ClientTrainings
import global.QuickStyleProperty.marginWidthAuto
import global.QuickStyleProperty.myTextNonSelect
import global.styleBlock
import global.styleConst
import global.stylePalette
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.events.EventTarget
import style.First.StyleTrainingRoom

@Composable
fun buttonIcon(
    iconClass: List<String>,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    fontSize: CSSNumeric = 15.pt,
    minSize: CSSNumeric = 30.pt,
    name: String? = null,
    fontSizeName: CSSNumeric = 13.pt,
    onclick: (SyntheticEvent<EventTarget>) -> Unit
) {
    FlexRowDivParent({
        style {
            fontSize(fontSize)
            minWidth(minSize)
            minHeight(minSize)
            myTextNonSelect()
        }
        attrs?.invoke(this)
/*
        onTouchStart {
            onclick()
            it.stopImmediatePropagation()
        }
        onMouseDown {
            it.stopImmediatePropagation()
        }
*/
        onClick{
            onclick(it)
        }
    }) {
        CenterDiv({
            style {
                minWidth(minSize)
                minHeight(minSize)
                myTextNonSelect()
                property("pointer-events", "none")
            }
        }) {
            I({
                classes(iconClass)
                classes(StyleTrainingRoom.chevron)
            })
        }
        name?.let {
            CenterDiv({
                style {
                    fontSize(fontSizeName)
                    minHeight(minSize)
                    myTextNonSelect()
                }
            }) {
                Text(it)
            }
        }
    }
}
@Composable
fun buttonIconLabel(
    iconClass: List<String>,
    forId: String,
    fontSize: CSSNumeric = 15.pt,
    minSize: CSSNumeric = 30.pt,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
    CenterDiv({
        style {
            fontSize(fontSize)
            minWidth(minSize)
            minHeight(minSize)
            myTextNonSelect()
        }
        attrs?.invoke(this)
    }) {
        Label(forId = forId) {
            I({
                classes(iconClass)
                classes(StyleTrainingRoom.chevron)
            })
        }
    }
}