package bdElement

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import api.firebase.*
import elements.CenterDiv
import elements.CutBorderBox
import elements.DragBaseElement
import global.getCurrentDateTimeUTC
import global.styleBlock
import global.tempComplexCoverUrl
import kotlinx.serialization.EncodeDefault
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.Serializable
import mainPage.modalWindows.modalVideoPreview
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Iframe
import org.jetbrains.compose.web.dom.Img
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLImageElement

/*
@Serializable
data class NewOrderSpis(
    val spis: List<NewOrder>? = null
)
*/

/*
@Serializable
data class NewOrder(
    val idAssignment: String? = null,
    val newOrder: Int? = null
)
*/

@Serializable
data class BaseTraining(
    override val id: String? = null,
    override var order: Int? = null,
    val name: String? = null,
    val description: String? = null,
    val description_title: String? = null,
    val delete_flag: String? = null,
    val client_id: String? = null,
    override val date_create: Long? = getCurrentDateTimeUTC(),
    override val date_update: Long? = null,
    var date_open: Long? = null,
    val count_days_open: Int? = null,
    val date_close: Long? = null,
    val trainer_id: String? = null,
    val muscle_group_tags: List<String>? = null,
    var completed: Long? = null,
    var assignments: List<BaseAssignment> = listOf(),
    val status: String = "",
    val activate: Boolean = false,
    val complex_id: String? = null,
    val complex_date_start: Long? = null,
    val complex_cover: String? = null
) : CommonElement(), DragBaseElement  {
    override val typeElement = TypeElement.Training

    fun addNewTrainingFirestore(
        funcRez: (BaseTraining) -> Unit
    ) {
        addNewCommonElementFirestoreFromTrainer(getBaseElement(this), funcRez)
    }

    fun changeTrainingFirestore(
        funcRez: (String) -> Unit
    ) {
        changeCommonElementFirestoreFromTrainer(
            CommonChangesBaseElement(
                typeElement.label,
                id ?: "",
                mapOf(
                    "name" to (name ?: ""),
                    "description" to (description ?: ""),
                    "date_open" to (date_open ?: 0),
                    "count_days_open" to (count_days_open ?: 0),
                    "date_close" to (date_close ?: 0)
                )
            ), funcRez
        )
    }

    @OptIn(ExperimentalComposeWebApi::class)
    @Composable
    fun getMarkerComplex(onClick: (String) -> Unit = {}) {
        if (complex_id != null) CenterDiv {
/*
            CutBorderBox(10, 2, attrs = {
                style {
                    flex("initial")
                }
                onClick {
                    onClick(complex_id)
                }
            }) {
                Img(src = complex_cover ?: tempComplexCoverUrl) {
                    classes(styleBlock.trainerRoom.ccCoverComplex)
                    style {
                        width(30.pt)
                        height(30.pt)
                    }
                }
            }
*/
            Img(src = complex_cover ?: tempComplexCoverUrl) {
                classes(styleBlock.trainerRoom.ccCoverComplex)
                style {
                    borderRadius(10.pt)
                    width(30.pt)
                    height(30.pt)
                    filter {
                        grayscale(100)
                    }
                }
            }
        }

    }
}

@Serializable
data class BaseAssignment(
    override val id: String? = null,
    var name: String? = null,
    var description: String? = null,
    override var order: Int? = null,
    var video_id: String? = null,
    var muscle_group_tags: List<String>? = null,
    var bunny_library: String? = null,
    var bunny_guid: String? = null
): DragBaseElement {
    @Composable
    fun getPreview(clickable: Boolean = true, attrs: AttrBuilderContext<HTMLImageElement>? = null) {
        getPreview(ForVideoFrame(bunny_library ?: "", bunny_guid ?: ""), clickable, attrs)
        /*
                Div({
        //            attr("data-hystmodal", "#myModal1")
                    style {
                        width(100.percent)
                        height(100.percent)
        //                border(1.px, LineStyle.Solid, rgb(255, 127, 255))
                        boxSizing("border-box")
                    }
                    if (clickable) onClick {
                        modalVideoPreview(ForVideoFrame(bunny_library ?: "", bunny_guid ?: ""))
                    }
                }) {
                    Img(src = "https://vz-d6d857fb-fb1.b-cdn.net/$bunny_guid/thumbnail.jpg") {
                        style {
                            boxSizing("border-box")
                            height(100.percent)
                            width(100.percent)
                            property("object-fit", "cover")

        //                    boxSizing("border-box")
                        }
                        attrs?.invoke(this)
                    }
                }
        */
    }

    @Composable
    fun getFrameVideo(autoplay: Boolean = true) {
        getFrameVideo(ForVideoFrame(bunny_library ?: "", bunny_guid ?: ""), autoplay)
    }
}

data class ForVideoFrame(val library: String, val guid: String)

@Composable
fun getPreview(video: ForVideoFrame, clickable: Boolean = true, attrs: AttrBuilderContext<HTMLImageElement>? = null) {
    Div({
//            attr("data-hystmodal", "#myModal1")
        style {
            width(100.percent)
            height(100.percent)
//                border(1.px, LineStyle.Solid, rgb(255, 127, 255))
            lineHeight("0")
            boxSizing("border-box")
        }
        if (clickable) onClick {
            modalVideoPreview(video)
        }
    }) {
        Img(src = "https://vz-d6d857fb-fb1.b-cdn.net/${video.guid}/thumbnail.jpg") {
            style {
                boxSizing("border-box")
                height(100.percent)
                width(100.percent)
                property("object-fit", "cover")
            }
            attrs?.invoke(this)
        }
    }
}

@Composable
fun getFrameVideo(video: ForVideoFrame, autoplay: Boolean = true) {
    Div({
        style {
            width(100.percent)
            position(Position.Relative)
            paddingTop(178.10760667903526.percent)
        }
    }) {
        Iframe({
            attr(
                "src",
                "https://iframe.mediadelivery.net/embed/${video.library}/${video.guid}?${if (autoplay) "autoplay=true&" else ""}loop=true"
            )
//            attr("loading","lazy")
            if (autoplay) attr("allow", " autoplay; ")
//            attr("allow","accelerometer; gyroscope; autoplay; encrypted-media;")
            attr("allowfullscreen", "true")

            style {
                border {
                    style(LineStyle.None)
                }
                position(Position.Absolute)
                top(0.percent)
                height(100.percent)
                width(100.percent)
            }
        })
    }
}
