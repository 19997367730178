package style.White

import global.CSSTextShadow
import global.QuickStyleProperty.borderBox
import global.QuickStyleProperty.myTextShadow
import global.styleConst
import global.stylePalette
import org.jetbrains.compose.web.css.*
import style.AppStylesheet
import style.SUserProfile

object StyleUserProfileWhite : SUserProfile, StyleSheet(AppStylesheet) {

    override val avatarImg by style {
        property("aspect-ratio", "1")
        property("object-fit", "cover")
        width(100.percent)
        borderRadius(50.percent)
    }
    override val buttonChangeAvatar by style {
        fontFamily(
            styleConst.mainFontFamily
        )
        fontSize(18.pt)
        color(stylePalette.myOrange)
//        border(0.px, LineStyle.Solid, Color.transparent)
        backgroundColor(Color.transparent)
        padding(5.px, 10.pt)
        borderRadius(15.pt)
        border(1.px, LineStyle.Solid, stylePalette.myOrange)
        hover(self) style {
            myTextShadow(CSSTextShadow(2.px, 2.px, 2.px, rgba(0, 0, 0, 0.3)))
            backgroundColor(Color.transparent)
//            myTextShadow(CSSTextShadow(4.px, 4.px, 3.px, rgba(0, 0, 0, 0.5)))
        }
        self + focus style {
            backgroundColor(Color.transparent)
//            myTextShadow(CSSTextShadow(4.px, 4.px, 3.px, rgba(0, 0, 0, 0.5)))
        }

    }
    override val textProperties by style {
        width(100.percent)
        borderBox()
        textAlign("left")
        fontFamily(styleConst.mainFontFamily)
        color(stylePalette.headersTextColor)
    }
    override val buttonIcon by style {
        fontFamily(styleConst.mainFontFamily)
        padding(5.px)
        color(rgb(255, 255, 255))
        borderWidth(0.px)
        borderRadius(10.px)
        backgroundColor(rgb(192, 12, 61))
        myTextShadow(CSSTextShadow(2.px, 2.px, 2.px, rgba(0, 0, 0, 0.3)))
        hover(self) style {
            myTextShadow(CSSTextShadow(4.px, 4.px, 3.px, rgba(0, 0, 0, 0.5)))
        }
        self + focus style {
            myTextShadow(CSSTextShadow(4.px, 4.px, 3.px, rgba(0, 0, 0, 0.5)))
        }
    }
    override val delimiter by style {
        width(100.percent)
        height(1.px)
        backgroundColor(Color.darkgray)
        margin(5.pt, 0.pt)
        borderBox()
    }

}