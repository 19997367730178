package mainPage

import androidx.compose.runtime.*
import api.firebase.*
import elements.CenterDiv
import elements.FlexRowDivParent
import elements.MyContainerInSection
import elements.buttonIcon
import global.*
import global.QuickStyleProperty.borderBox
import global.QuickStyleProperty.zIndex
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.delay
import mainPage.modalWindows.showMessage
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLTextAreaElement
import org.w3c.dom.SMOOTH
import org.w3c.dom.ScrollBehavior
import org.w3c.dom.ScrollToOptions
import style.First.StyleCommon
import style.First.StyleTitlePage
import style.common.StylePageTitleCommon


@Composable
fun signInForm() {
//    renderComposable(rootElementId = "firebaseui-auth-container") {
    Div(
        attrs = {
            id("firebaseui-auth-container")
            style {
//                    width(500.pt)
//                    height(20.pt)
//                    backgroundColor(rgb(0, 0, 0))
            }
        }
    )
    LaunchedEffect(Unit) {
//        while (document.getElementById("firebaseui-auth-container") == null) {
//            console.log("delay 60")
//            delay(60)
//        }
        loadUiGoogleSignIn("firebaseui-auth-container")
    }
//    }
}

private var copyAlarm by mutableStateOf(false)
private var copyAlarm2 by mutableStateOf(false)

@OptIn(ExperimentalComposeWebApi::class)
@Composable
fun pageRegistration() {
    MyContainerInSection {
        if (currentUser.login) {
            if (currentUser.userProfile?.status == "new") {
                CenterDiv({
                    style {
                        marginTop(50.px)
                        width(100.percent)
                        color(stylePalette.mainTextColor)
                        flexDirection(FlexDirection.Column)
                        flexGrow(0)
                        if (window.innerWidth > 600) textAlign("center")
                    }
                }) {
                    H3({
                        style {
                            fontWeight(200)
                        }
                    }) {
                        Text("Вы собираетесь использовать приложение как клиент или как тренер?")
                    }
                    FlexRowDivParent({
                        style {
                            margin(20.px, 5.percent)
                        }
                    }, flexWrap = FlexWrap.Nowrap, rowGap = 10.percent) {
                        Button({
                            classes(StylePageTitleCommon.buttonComplexGirl, styleBlock.pageTitle.buttonComplexGirl)
                            onClick {
                                setStatusUserFirestore("client") {
                                    updateUserProfile()
                                }
                            }
                        }) {
                            Text("Я клиент")
                        }
                        Button({
                            classes(StylePageTitleCommon.buttonComplex, styleBlock.pageTitle.buttonComplex)
                            onClick {
                                setStatusUserFirestore("trainer") {
                                    updateUserProfile() {
                                        openModalWindow(true) {
                                            currentUser.userProfile?.id?.let { id ->
                                                if (currentUser.userProfile?.status == "trainer") {
                                                    FlexRowDivParent {
                                                        CenterDiv({
                                                            style {
                                                                flex("auto")
                                                                flexDirection(FlexDirection.Column)
                                                                textAlign("left")
                                                            }
                                                        }) {
                                                            H3({
                                                                style {
                                                                    borderBox()
                                                                    fontWeight(200)
                                                                    width(100.percent)
                                                                }
                                                            }) {
                                                                Text("Ссылка по которой клиенты смогут отправить вам заявку, чтобы тренироваться у вас.")
                                                            }
                                                            H4({
                                                                style {
                                                                    borderBox()
                                                                    width(100.percent)
                                                                    fontWeight(200)
                                                                    color(stylePalette.myBlue)
                                                                }
                                                            }) {
                                                                Text("mastergym.online?idt=$id")
                                                            }
                                                            H4({
                                                                style {
                                                                    borderBox()
                                                                    fontWeight(200)
                                                                    width(100.percent)
                                                                }
                                                            }) {
                                                                Text("Эту ссылку также можно найти в настройках профиля.")
                                                            }
                                                        }
                                                        CenterDiv({
                                                            style {
                                                                height(100.percent)
                                                            }
                                                        }) {
                                                            LaunchedEffect(copyAlarm) {
                                                                if (copyAlarm) {
                                                                    copyAlarm2 = true
                                                                    delay(1000)
                                                                    copyAlarm2 = false
                                                                    delay(550)
                                                                    copyAlarm = false
                                                                }
                                                            }
                                                            CenterDiv({
                                                                classes(
                                                                    "dropdown-content",
                                                                    StyleCommon.noShow
                                                                ) //if (openDropDown) StyleCommon.show else StyleCommon.noShow,
                                                                style {
                                                                    position(Position.Absolute)
                                                                    top(-30.pt)
                                                                    right(0.px)
                                                                    if (copyAlarm) {
                                                                        display(DisplayStyle.Block)
                                                                        opacity(0)
                                                                    } else {
                                                                        display(DisplayStyle.None)
                                                                        opacity(0)
                                                                    }
                                                                    if (copyAlarm2) {
                                                                        opacity(1)
                                                                    } else {
                                                                        opacity(0)
                                                                    }
                                                                    transitions {
                                                                        all {
                                                                            duration(0.5.s)
                                                                            timingFunction(AnimationTimingFunction.EaseInOut)
                                                                        }
                                                                    }
                                                                    property("z-index", "699")
                                                                }
                                                            }) {
                                                                Div({
                                                                    style {
                                                                        position(Position.Relative)
                                                                        zIndex(799)
                                                                        backgroundColor(stylePalette.myBlue)
                                                                        color(stylePalette.mainTextColorInv)
                                                                        height(100.percent)
                                                                        borderRadius(10.px)
                                                                        padding(5.px, 20.px)
                                                                    }
                                                                }) {
                                                                    Text("Ссылка скопирована")
                                                                }
                                                            }
                                                            buttonIcon(listOf("fa-solid", "fa-copy"), {
                                                                classes(styleBlock.userProfile.buttonIcon)
                                                            }, fontSize = 20.pt) {
                                                                val copyTextarea =
                                                                    document.createElement("textarea") as HTMLTextAreaElement
                                                                copyTextarea.style.position = "fixed";
                                                                copyTextarea.style.opacity = "0";
                                                                copyTextarea.style.height = "0px";
                                                                copyTextarea.style.width = "0px";
                                                                copyTextarea.textContent =
                                                                    "https://mastergym.online?idt=$id"

                                                                document.body?.appendChild(copyTextarea);
                                                                copyTextarea.select();
                                                                document.execCommand("copy")
                                                                copyTextarea.style.display = "none"
                                                                copyAlarm = true
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }) {
                            Text("Я тренер")
                        }
                    }
                    H4({
                        style {
                            fontWeight(200)
                        }
                    }) {
                        Text("В дальнейшем поменять статус можно будет в настройках профиля.")
                    }
                }
            } else {
                Div({
                    style {
                        width(100.percent)
                        height(200.pt)
                        alignContent(AlignContent.Center)
                    }
                }) {
                    Button({
                        style {
                            position(Position.Relative)
                        }
                        onClick {
                            userSignOut { }
                        }
                    }) {
                        Text("Выйти ${currentUser.getDisplayName()}")
                    }
                }
            }
        } else {
            signInForm()
        }
    }
}