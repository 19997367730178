package global

import kotlinx.browser.document
import kotlinx.browser.window
import org.w3c.dom.HTMLElement
import org.w3c.dom.get
import style.First.StyleCommon

fun setListenersWindow() {
//    var prevScrollPos = window.pageYOffset
/*
    window.onscroll = {
        if (modalContent == null) { // !visibleModalWindow.value) {// ancor != -1 && listenScroll) {
            val currentScrollPos = window.pageYOffset
            if (currentScrollPos >= 0) {
                val dy = currentScrollPos - prevScrollPos

//            headerTop = if (prevScrollPos > currentScrollPos) 0.pt else (-40).pt
                var tmpTop = headerTop - dy //if (prevScrollPos > currentScrollPos) headerTop + dy else
                if (tmpTop < -headerHeight) tmpTop = (-headerHeight).toDouble()
                if (tmpTop > 0) tmpTop = 0.0
                headerTop = tmpTop.toInt()
                currentScrollPos.also { prevScrollPos = it }
            }
        } else {
            window.scrollTo(0.0, prevScrollPos)
        }
    }
*/

    window.onresize = { event ->
        console.log("window.onresize")
        onresizeClientComplexSlider()
    }

    window.onclick = { event ->
//        console.log("onCLick ${event.target}")
        val clickObj = event.target as HTMLElement
        if (clickObj.classList.contains("dropbtn").not()) {
            val dropdowns = document.getElementsByClassName("dropdown-content")
            for (i in 0 until dropdowns.length) {
                val openDropdown = dropdowns[i]
                if (openDropdown?.classList?.contains(StyleCommon.show) == true) {
                    openDropdown.classList.remove(StyleCommon.show)
                    openDropdown.classList.add(StyleCommon.noShow)
                }
            }
            dropDownElementNow = ""
        } else {
            val id = clickObj.id
            val dropdowns = document.getElementsByClassName("dropdown-content")
            for (i in 0 until dropdowns.length) {
                val openDropdown = dropdowns[i]
                if ((openDropdown?.classList?.contains(StyleCommon.show) == true) && openDropdown.id != "content-$id") {
                    openDropdown.classList.remove(StyleCommon.show)
                    openDropdown.classList.add(StyleCommon.noShow)
                }
            }
        }
    }
}
