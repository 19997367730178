package mainPage.modalWindows

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import api.firebase.addNewTrainingFirestore
import api.firebase.changeTrainingFirestore
import bdElement.BaseTraining
import elements.*
import global.*
import global.QuickStyleProperty.borderBox
import global.QuickStyleProperty.myFullSize
import global.QuickStyleProperty.myTestBorder
import kotlinx.browser.window
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.jetbrains.compose.web.dom.H3
import style.First.StyleUserProfile
import kotlin.js.Date

class ModalAddEditTraining(val training: BaseTraining? = null, val funcAfter: () -> Unit) : ModalContent() {
    val richTextEdit = RichTextEdit(training?.description ?: "")
    var inputNameState by mutableStateOf(training?.name ?: "")
    var inputDateState by mutableStateOf(training?.date_open?.let { Date(it).getTime() }
        ?: Date(getCurrentDateUTC()).getTime())
    var inputCountDays by mutableStateOf(training?.count_days_open ?: 8)

    override val closableBack = false
    override val fullscreen: Boolean = phoneDevice

    private var disabledButtSet by mutableStateOf("temp")

    @Composable
    override fun drawModalContent() {
        FlexColumnDivParent({
            style {
                myFullSize()
                backgroundColor(stylePalette.bodyModalColor)
                padding(10.px)
                if (fullscreen) height(window.innerHeight.px)
            }
        }) {
            inputBlock()
            buttonBlock()
        }
    }

    @Composable
    private fun inputBlock(){
        FlexColumnDivParent({ style { flex("auto") } }, rowGap = 0.px) {
            H3({
                classes(styleBlock.commonElement.inputTextHeaders)
                style {
                    marginBottom(2.pt)
                }
            }) {
                Text("Название тренировки")
            }
            Input(type = InputType.Text) {

                style {
                    width(100.percent)
                    borderBox()
                }
                classes(styleBlock.commonElement.inputText)
                value(inputNameState)
                onInput { event -> inputNameState = event.value }
            }

            H3({
                classes(styleBlock.commonElement.inputTextHeaders)
                style {
                    marginTop(8.pt)
                    marginBottom(2.pt)
                }
            }) {
                Text("Описание тренировки")
            }
            richTextEdit.RichTextEdit({
                style {
                }
            })
            H3({
                classes(styleBlock.commonElement.inputTextHeaders)
                style {
                    marginTop(8.pt)
                    marginBottom(2.pt)
                }
            }) {
                Text("Дата открытия тренировки")
            }
            Input(type = InputType.Date) {
                style {
                    width(100.percent)
                    borderBox()
                }
                classes(styleBlock.commonElement.inputText)
                value(Date(inputDateState).toISOString().substring(0, 10)) // - Date().getTimezoneOffset() * 60000L
                onInput { event ->
                    inputDateState = Date.parse(event.value) // + Date().getTimezoneOffset() * 60000L
                }
            }
            H3({
                classes(styleBlock.commonElement.inputTextHeaders)
                style {
                    marginTop(8.pt)
                    marginBottom(2.pt)
                }
            }) {
                Text("Количество дней")
            }
            Input(type = InputType.Number) {
                style {
                    width(100.percent)
                    borderBox()
                }
                classes(styleBlock.commonElement.inputText)
                value(inputCountDays)
                onInput { event ->
                    inputCountDays = event.value?.toInt() ?: 0 // as Int
                }
            }
            H4({ classes(styleBlock.commonElement.smallTextInfo) }) {
                Text("(до ${Date(inputDateState + (inputCountDays - 1) * 86400000L).toLocaleDateString()} включительно)")
            }
        }
    }

    @Composable
    private fun buttonBlock(){
        FlexRowDivParent {
            if (fullscreen) Button({
                classes(styleBlock.commonElement.buttonBack)
                style {
                    flex("initial")
                }
                onClick {
                    closeModadWindow()
                }
            }) {
                Text("Назад")
            }
            Button({
                classes(styleBlock.trainerRoom.fmButton)
                style {
                    borderBox()
                    flex("auto")
                }
                attr(disabledButtSet, "")
                onClick {
//                    disabledButtSet = "disabled"
                    selectedClient?.id?.let { id_client ->
                        currentUser.userProfile?.id?.let { id_trainer ->
                            if (training != null) {
                                val newTraining = training.copy(
                                    name = inputNameState,
                                    description = richTextEdit.getText(),
                                    date_open = inputDateState.toLong(),
                                    count_days_open = inputCountDays,
                                    date_close = (inputDateState + inputCountDays * 86400000L).toLong()
                                )
                                changeTrainingFirestore(newTraining) {
                                    ClientTrainings.pagination.update() //.trainingsLoaded?.updateElem(newTraining,{it.date_open}) { it.id == newTraining.id }
                                }
                            } else {
                                val newTraining = BaseTraining(
                                    trainer_id = id_trainer,
                                    client_id = id_client,
                                    name = inputNameState,
                                    description = richTextEdit.getText(),
                                    date_open = inputDateState.toLong(),
                                    count_days_open = inputCountDays,
                                    date_close = (inputDateState + inputCountDays * 86400000L).toLong()
                                )
                                addNewTrainingFirestore(newTraining) {
                                    ClientTrainings.pagination.update() // .trainingsLoaded?.newElem(newTraining) { it.date_open }
                                }
                            }
                        }
                    }
                    closeModadWindow()
                }
            }) {
                Text(if (training?.id != null) "Изменить" else "Добавить")
            }
        }
    }
}
