package elements

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import global.QuickStyleProperty.borderBox
import global.styleBlock
import global.styleConst
import global.stylePalette
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement

@Composable
fun MyCheckbox(
    value: MutableState<Boolean>,
    text: String,
    colorText: CSSColorValue? = null,
    colorBorder: CSSColorValue? = null,
    colorPoint: CSSColorValue? = null,
    fontSize: CSSNumeric = 18.px,
    attrs: AttrBuilderContext<HTMLDivElement>? = null
) {
    FlexRowDivParent({
        attrs?.invoke(this)
        onClick {
            value.value = value.value.not()
        }
    }, columnGap = 5.pt) {
        CenterDiv({
            style {
            }
        }) {
            CenterDiv({
                classes(styleBlock.trainerRoom.fmItemBox_Out)
                style {
                    border(1.px, LineStyle.Solid, colorBorder ?: Color.darkgray)
                }

            }) {
                if (value.value) Div({
                    classes(styleBlock.trainerRoom.fmItemBox_In)
                    style {
                        colorPoint?.let {
                            backgroundColor(it)
                        }
                    }
                })
            }
        }
//        CheckboxInput(value.value) {
//            onChange {
//                value.value = it.value
//            }
//        }
        CenterDiv({
            classes(styleBlock.trainerRoom.fmItemText)
            style {
                colorText?.let {
                    color(it)
                }
//            marginAuto(100.px)
//                marginHeightAuto()
//            property("margin","auto 0")
            }
        }) {
            Text(text)
        }
    }
}

@Composable
fun MySwitch(
    value: MutableState<Boolean>,
    text: String,
    colorText: CSSColorValue = stylePalette.fontCommonColor,
    colorTextTrue: CSSColorValue = stylePalette.myBlue,
    colorBorder: CSSColorValue = stylePalette.fontMiddleColor,
    colorPoint: CSSColorValue = stylePalette.myBlue,
    attrs: AttrBuilderContext<HTMLDivElement>? = null
) {
    FlexRowDivParent({
        attrs?.invoke(this)
        onClick {
            value.value = value.value.not()
        }
    }, columnGap = 5.pt) {
        CenterDiv({
            style {
            }
        }) {
            FlexRowDivParent ({
                style {
                    borderRadius(10.pt)
                    width(30.pt)
                    height(20.pt)
                    padding(3.pt)
                    borderBox()
                    border(styleConst.borderWidthCommon, LineStyle.Solid, colorBorder )
                    justifyContent(if (value.value) JustifyContent.Right else JustifyContent.Left)
                    alignItems(AlignItems.Center)
                }

            }) {
                Div({
                    classes(styleBlock.trainerRoom.fmItemBox_In)
                    style {
                        backgroundColor(if (value.value) colorPoint else colorBorder)
                    }
                })
            }
        }
        CenterDiv({
            classes(styleBlock.trainerRoom.fmItemText)
            style {
                    color(if (value.value) colorTextTrue else colorText)
            }
        }) {
            Text(text)
        }
    }
}