package style.First

import global.styleConst
import org.jetbrains.compose.web.css.*
import style.AppStylesheet

object StyleUserProfile : StyleSheet(AppStylesheet) {
    val richTextEdit by style {
        whiteSpace("normal")
        fontFamily(styleConst.mainFontFamily)
        property("word-wrap", "break-all")
        property("overflow-wrap", "anywhere")
/*
        property("hyphens", "auto")
        "*" style {
//            display(DisplayStyle.Block)
            whiteSpace("normal")
//            myTestBorder()
            property("hyphens", "auto")
            property("word-wrap", "break-all")
            property("overflow-wrap", "anywhere")
        }
*/
    }
    val rowSetting by style {
        display(DisplayStyle.Flex)
        flexWrap(FlexWrap.Wrap)
        border(1.px, LineStyle.Solid, rgb(127, 127, 127))
        borderRadius(10.pt)
        property(
            "box-shadow",
            "3px 3px 1px 0 rgba(0, 0, 0, 0.5), 4px 4px 6px 0 rgba(0, 0, 0, 0.19)"
        )
        padding(10.px)
//        justifyContent(JustifyContent.SpaceBetween)
        columnGap(10.pt)
        rowGap(10.pt)

        marginBottom(10.pt)
        backgroundColor(rgb(230, 230, 230))
    }

    val buttSetAvatar by style {
//        paddingLeft(30.px)
        fontSize(16.px)
        backgroundColor(rgb(47, 47, 47))
        padding(8.px)
        color(rgb(255, 255, 255))
        border(2.px, LineStyle.Solid, rgb(79, 79, 79))
        borderRadius(9.px)
        self + hover style { // self is a selector for `container`
            backgroundColor(rgb(0, 0, 0))
            border(2.px, LineStyle.Solid, rgb(47, 47, 47))
            cursor("pointer")
        }
        self + disabled style {
            opacity(0.3)
        }
    }
    val inputTextProperty by style {
        fontSize(20.px)
        color(rgb(47, 47, 47))
        padding(8.px)
        backgroundColor(rgb(225, 225, 255))
        border(2.px, LineStyle.Solid, rgb(79, 79, 79))
        borderRadius(9.px)
        fontFamily(styleConst.mainFontFamily)
        self + hover style { // self is a selector for `container`
            backgroundColor(rgb(205, 205, 255))
            border(2.px, LineStyle.Solid, rgb(47, 47, 47))
            cursor("text")
        }
        self + focus style { // self is a selector for `container`
            backgroundColor(rgb(205, 205, 255))
            border(2.px, LineStyle.Solid, rgb(47, 47, 47))
            cursor("text")
        }
    }

    val propertyUserProfile by style {
        padding(5.px)
        width(100.percent)
        color(rgb(0, 0, 0))
        fontFamily("Roboto Slab")
        fontSize(18.px)
    }

    val propertyDescription by style {
        padding(5.px)
        width(100.percent)
        color(rgb(0, 0, 0))
        fontFamily("Roboto Slab")
        fontSize(18.px)
    }


}