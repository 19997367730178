package mainPage

import androidx.compose.runtime.*
import api.firebase.sendEmailVerification
import elements.CenterDiv
import elements.MyContainerInSection
import global.currentUser
import global.stylePalette
import kotlinx.browser.window
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import style.First.StyleCommon
import style.First.StyleTitlePage

private var disabledButtSendEmail by mutableStateOf(false)
private var disabledTime by mutableStateOf(-1)

@Composable
fun pageVerification() {
    MyContainerInSection {
        LaunchedEffect(disabledTime) {
            if (disabledTime > 0) {
                delay(999)
                disabledTime--
                if (disabledTime == 0) disabledButtSendEmail = false
            }
        }
        CenterDiv({
            style {
                marginTop(50.px)
                width(100.percent)
                color(stylePalette.mainTextColor)
                flexDirection(FlexDirection.Column)
                flexGrow(0)
                if (window.innerWidth > 600) textAlign("center")
            }
        }) {
            H3({
                style {
                    fontWeight(200)
                }
            }) {
                Text("Для того, чтобы использовать весь функционал MasterGYM, вам необходимо верифицировать почтовый ящик.")
            }
            Button({
                if (disabledButtSendEmail) attr("disabled", "")
                classes(StyleTitlePage.buttonComplex, StyleCommon.elementDisabled)
                style {
                    flex("initial")
                    position(Position.Relative)
                    property("margin-left", "auto")
                    property("margin-right", "auto")
                }
                onClick {
                    disabledButtSendEmail = true
                    disabledTime = 90
                    sendEmailVerification(window.location.href){}
                }
            }) {
                Text("Отправить письмо на ${currentUser.getEmail()}")
            }
            if (disabledTime > -1) {
                H4({
                    style {
                        marginTop(50.px)
                        color(stylePalette.gray)
                        fontWeight(200)
                    }
                }) {
                    Text("Письмо для подтверждения отправлено на ваш почтовый ящик. Проверьте почту и перейдите по ссылке из письма.")
                }
                P({
                    style {
                        marginTop(10.px)
                        color(stylePalette.darkGray)
                        fontSize(14.px)
                    }
                }) {
                    Text("Если письмо не пришло на почту проверьте правильно ли вы указали почтовый ящик и попробуйте отправить письмо еще раз. Если почтовый адрес указан не верно, нажмите на кнопку меню вверху и потом \"Выйти\", чтобы войти по правильному адресу.")
                }
            }
            if (disabledTime > 0) P({
                style {
                    marginTop(10.px)
                    color(stylePalette.gray)
                    fontSize(14.px)
                }
            }) {
                Text("Повторное письмо можно будет отправить через ${disabledTime} с.")
            }
        }

    }
}