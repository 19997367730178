package global

import androidx.compose.runtime.*
import api.firebase.getFilterMyVideoFromFirestore
import bdElement.BaseVideo
import bdElement.MyAnswerCommon
import elements.*
import global.QuickStyleProperty.borderBox
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H3
import org.jetbrains.compose.web.dom.Text

object ListMyVideos {
    var videosLoaded by mutableStateOf<MyAnswerCommon<BaseVideo>?>(null)
        private set

    private var loadingProcess by mutableStateOf(true)

    val pagination = PageAdapter(12) { limit, skip, silent ->
        if (silent.not()) loadingProcess = true
        if (filterMyVideo.isEmpty()) {
            getFilterMyVideoFromFirestore(listOf(),listOf(),listOf(), limit, skip)
        } else {
            getFilterMyVideoFromFirestore(filterMyVideo.sex_id, filterMyVideo.equipment_id, filterMyVideo.muscle_group_id, limit, skip)
        }
    }

    fun setVideosLoaded(load: MyAnswerCommon<BaseVideo>) {
        videosLoaded = load
        load.totalCount?.let { pagination.totalCount = it }
        loadingProcess = false
    }

    @Composable
    fun getViewListMyVideos() {
        LaunchedEffect(findWithoutTags.value){
            pagination.update()
        }
        if (loadingProcess){
            CenterDiv({style { marginTop(40.px) }}) { mainLoader() }
        }   else {
            Div(
                attrs = {
//            classes(StyleVideoBlock.myVideoBlock) //WtRows.wtRow,
                    style {
                        display(DisplayStyle.Flex)
                        flexWrap(FlexWrap.Wrap)

                        columnGap(10.pt)
                        rowGap(10.pt)
                        paddingLeft(1.pt)
                        marginRight(-9.pt)
                        borderBox()

                        paddingBottom(styleConst.paddingCommon)
                    }
                }
            ) {
                videosLoaded?.mutList?.let {
                    if (it.isEmpty()) {
                        H3(attrs = {
                            classes(styleBlock.commonElement.headerBlock)
                            style {
                                color(rgb(255, 150, 150))
                            }
                        }) {
                            Text("Видео подходящих условиям поиска не найдено, если у вас есть загруженные видео попробуйте сменить фильтры.")
                        }
                    } else {
                        for (myVideo in it) cardBaseVideo(myVideo, true,true){
                            pagination.update()
                        }
                    }
                }
            }
        }
        pagination.paginationElement()
    }
}