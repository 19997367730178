package mainPage

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import elements.CenterDiv
import elements.FlexRowDivParent
import elements.MyContainerInSection
import elements.buttonIcon
import global.ListClients
import global.ListRequestsComplexClose
import global.ListRequestsComplexNew
import global.styleBlock
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.Text
import style.First.StyleTrainingRoom

private var openArchive by mutableStateOf(false)
private var openArchiveZayavok by mutableStateOf(false)

@Composable
fun pageSales() {
    MyContainerInSection {
        zayavki()
        FlexRowDivParent(columnGap = 5.pt) {
            CenterDiv {
                buttonIcon(listOf("fa", "fa-box-archive", StyleTrainingRoom.chevron), attrs = {
                    classes(
                        styleBlock.trainerRoom.buttNewTraining,
                        if (openArchive) styleBlock.trainerRoom.buttAccept else styleBlock.trainerRoom.buttResetPasteTrainings
                    )
                }, minSize = 25.pt) {
                    openArchive = openArchive.not()
                }
            }
            H2({
                classes(styleBlock.commonElement.headerBlock)
                style {
                    marginBottom(10.pt)
                }
            }) {
                Text("Запросы комплексов")
            }
        }
        if (openArchive) ListRequestsComplexClose.getViewListReqComplexClose()
        else ListRequestsComplexNew.getViewListComplexes()
    }
}

@Composable
fun zayavki() {
//    if (ListClients.newZayavki()) {
        FlexRowDivParent(columnGap = 5.pt) {
            CenterDiv {
                buttonIcon(listOf("fa", "fa-box-archive", StyleTrainingRoom.chevron), attrs = {
                    classes(
                        styleBlock.trainerRoom.buttNewTraining,
                        if (openArchiveZayavok) styleBlock.trainerRoom.buttAccept else styleBlock.trainerRoom.buttResetPasteTrainings
                    )
                }, minSize = 25.pt) {
                    openArchiveZayavok = openArchiveZayavok.not()
                }
            }
            H2({
                classes(styleBlock.commonElement.headerBlock)
                style {
                    marginBottom(10.pt)
                }
            }) {
                Text("Заявки")
            }
        }
        if (openArchiveZayavok) ListClients.getViewListOldZayavki() else ListClients.getViewListNewZayavki()
//    }
}