package style.common

import global.CSSShadow
import global.CSSTextShadow
import global.QuickStyleProperty.marginWidthAuto
import global.QuickStyleProperty.myBoxShadow
import global.QuickStyleProperty.myTestBorder
import global.QuickStyleProperty.myTextShadow
import global.styleConst
import global.stylePalette
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*
import style.AppStylesheet
import style.STitlePage

object StylePageTitleCommon : StyleSheet(AppStylesheet) {

    val textShadowHover by style {
        fontFamily(styleConst.mainFontFamily)
        color(rgb(255, 255, 255))
//        myTextShadow(CSSTextShadow(2.px, 2.px, 2.px, rgba(0, 0, 0, 0.3)))
        hover(self) style {
            myTextShadow(CSSTextShadow(4.px, 4.px, 3.px, rgba(0, 0, 0, 0.5)))
        }
        self + focus style {
//            myTextShadow(CSSTextShadow(4.px, 4.px, 3.px, rgba(0, 0, 0, 0.5)))
        }
    }

    val gamburger by style {
        myTestBorder(Color.transparent)

        self + focus style {
            myTestBorder()
        }
    }
    val buttonComplex by style {
        flex("auto")
        flexShrink(1)
//        marginLeft(15.percent)
//        marginRight(15.percent)
        marginWidthAuto()
        marginTop(40.px)
        borderRadius(15.px)
        fontSize(15.px)
        fontWeight(700)
        padding(10.px, 20.px)
    }

    val buttonComplexGirl by style {
        flex("auto")
        flexShrink(1)
//        marginLeft(15.percent)
//        marginRight(15.percent)
        marginWidthAuto()
        marginTop(40.px)
        borderRadius(15.px)
        fontSize(15.px)
        fontWeight(700)
        padding(10.px, 20.px)
    }

    @OptIn(ExperimentalComposeWebApi::class)
    val trainingButt by style {
        margin(180.px)
        width(70.px)
        height(70.px)
//                    maxWidth(350.px)
        marginWidthAuto()
        borderRadius(50.percent)
        backgroundColor(rgb(50, 50, 50))
        border(1.px, LineStyle.Solid, rgba(90, 90, 90, 0.4))
        myBoxShadow(
            CSSShadow(-15.px, -15.px, 20.px, color = rgba(242, 110, 66, 0.2)),
            CSSShadow(20.px, 20.px, 35.px, color = rgba(0, 0, 0, 0.3)),
            CSSShadow(-5.px, -5.px, 15.px, color = rgba(242, 110, 66, 0.3)),
            CSSShadow(15.px, 15.px, 20.px, color = rgba(0, 0, 0, 0.4))
        )
        hover(self) style {
            backgroundColor(rgb(30, 30, 30))
            myBoxShadow(
                CSSShadow(-15.px, -15.px, 40.px, color = rgba(242, 110, 66, 0.2)),
                CSSShadow(20.px, 20.px, 55.px, color = rgba(0, 0, 0, 0.3)),
                CSSShadow(-5.px, -5.px, 25.px, color = rgba(242, 110, 66, 0.3)),
                CSSShadow(15.px, 15.px, 40.px, color = rgba(0, 0, 0, 0.4))
            )
            transform {
//                translate(-10.px,-10.px)
            }
        }
        self + active style {
            transform {
//                translate(10.px,10.px)
            }
        }
    }
    val mainHeaders by style {
        fontWeight(600)
        fontSize(26.pt)
        maxWidth(350.px)
        fontFamily(styleConst.mainFontFamily)
        textAlign("center")
    }
    val textInHeaders by style {
//        property("margin-left", "auto")
//        marginRight(0.px)
        margin(0.pt, 20.pt)
        maxWidth(450.px)
        textAlign("left")
        fontSize(18.px)

        fontFamily(styleConst.mainFontFamily)
    }
    val mainText by style {
        marginTop(10.px)
        fontFamily(styleConst.mainFontFamily)
        textAlign("left")
        fontWeight(400)
    }
    val stapsHeaders by style {
    }
    val checkHeader by style {
        fontFamily(styleConst.mainFontFamily)
        textAlign("left")
        fontWeight(600)
    }
    val trainingHeader by style {
        paddingTop(40.px)
        marginWidthAuto()
        textAlign("center")
        fontFamily(styleConst.mainFontFamily)
    }
}