package mainPage.modalWindows

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import api.bunny.uploadImage
import bdElement.BaseComplex
import elements.*
import global.*
import global.QuickStyleProperty.borderBox
import global.QuickStyleProperty.myFullSize
import kotlinx.browser.window
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.accept
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.w3c.files.get
import serverAdress
import style.First.StyleUserProfile
import kotlin.js.Date

class ModalAddEditComplex(val complex: BaseComplex? = null, val funcAfter: () -> Unit) : ModalContent() {
    private var imgTempCover by mutableStateOf<String?>(complex?.cover)

    private val richTextEdit = RichTextEdit(complex?.description ?: "")
    private var inputNameState by mutableStateOf(complex?.name ?: "")
    private var inputDeadline by mutableStateOf(complex?.deadline ?: 8)
    private var inputPrice by mutableStateOf(complex?.price ?: 0.0)

    override val closableBack = false
    override val fullscreen: Boolean = phoneDevice

    private var disabledButtSet by mutableStateOf("temp")

    private var uploadImgStart = mutableStateOf(false)
    private var uploadImgProgress by mutableStateOf(0.percent)


    @Composable
    private fun CoverSelector() {
        FlexColumnDivParent({
            style {
                alignItems(AlignItems.Center)
            }
        }) {
            CutBorderBox(15, 2, attrs = {
                style {
//                                borderBox()
                }
            }) {
                Img(src = imgTempCover ?: tempComplexCoverUrl) {
                    classes(styleBlock.trainerRoom.ccCoverComplex)
                    style {
                        width(210.px)
                        height(280.px)
//                                    borderBox()
                    }
                }
                if (uploadImgStart.value) mainLoaderInOver()
            }

            if (uploadImgStart.value) CenterDiv {
                Div({
                    style {
                        margin(5.pt, 0.pt)
                        border(2.px, LineStyle.Solid, stylePalette.myOrange)
                        borderRadius(3.pt)
                        overflow("hidden")
                        height(5.pt)
                        width(170.pt)
                        position(Position.Relative)
                    }
                }) {
                    Div({
                        style {
                            position(Position.Absolute)
                            top(0.px)
                            left(0.px)
                            opacity(0.7)
                            height(100.percent)
                            width(uploadImgProgress)
                            backgroundColor(stylePalette.myOrange)
                        }
                    })
                }
            }
            CenterDiv({
                style {
                    height(40.px)
                    textAlign("center")
                    paddingTop(10.px)
                    position(Position.Relative)
                }
            }) {
                Input(type = InputType.File) {
                    classes("inputFile")
                    style {
                        display(DisplayStyle.None)
                        padding(8.px)
                    }
                    accept("image/*")
                    id("file")
                    onChange { e ->
                        val file = e.target.files?.get(0);
                        console.log("open file")
                        file?.let {
                            console.log("file not")
                            uploadImage(e.target,
                                "avatar_${currentUser.userProfile?.id}_${Date.now()}",
                                600,
                                600,
                                "$serverAdress/upload_image",
                                uploadImgStart,
                                uploadImgProgress = { progress ->
                                    uploadImgProgress = (progress * 100).percent
                                },
                                funcRez = { fileName ->
                                    imgTempCover = "https://fitconstructorimg.b-cdn.net/avatar/$fileName"
                                }
                            )
                        }
                    }
                }
                Label(forId = "file", attrs = {
                    classes(StyleUserProfile.buttSetAvatar)
                }) {
                    Text("Выбрать обложку")
                }
                if (uploadImgStart.value) blankLoaderInOver()
            }
        }
    }

    @Composable
    override fun drawModalContent() {
        FlexColumnDivParent({
            style {
                myFullSize()
                backgroundColor(stylePalette.bodyModalColor)
                padding(10.px)
                if (fullscreen) height(window.innerHeight.px)
            }
        }) {
            if (window.innerWidth <= 700) CoverSelector()
            FlexRowDivParent {
                if (window.innerWidth > 700) CoverSelector()
                FlexColumnDivParent({
                    style { flex("auto") }
                }, rowGap = 0.px) {
                    H3({
                        classes(styleBlock.commonElement.inputTextHeaders)
                        style {
                            marginBottom(2.pt)
                        }
                    }) {
                        Text("Название комплекса")
                    }
                    Input(type = InputType.Text) {

                        style {
                            width(100.percent)
                            borderBox()
                        }
                        classes(styleBlock.commonElement.inputText)
                        value(inputNameState)
                        onInput { event -> inputNameState = event.value }
                    }

                    H3({
                        classes(styleBlock.commonElement.inputTextHeaders)
                        style {
                            marginTop(8.pt)
                            marginBottom(2.pt)
                        }
                    }) {
                        Text("Описание комплекса")
                    }
                    richTextEdit.RichTextEdit({
                        style {
//                        flex("auto")
                        }
                    })
                    H3({
                        classes(styleBlock.commonElement.inputTextHeaders)
                        style {
                            marginTop(8.pt)
                            marginBottom(2.pt)
                        }
                    }) {
                        Text("Стоимость комплекса")
                    }
                    Input(type = InputType.Number) {
                        style {
                            width(100.percent)
                            borderBox()
                        }
                        classes(styleBlock.commonElement.inputText)
                        value(inputPrice)
                        onInput { event ->
                            inputPrice = event.value?.toDouble() ?: 0.0
                        }
                    }
                    H3({
                        classes(styleBlock.commonElement.inputTextHeaders)
                        style {
                            marginTop(8.pt)
                            marginBottom(2.pt)
                        }
                    }) {
                        Text("Доступен (в днях)")
                    }
                    Input(type = InputType.Number) {
                        style {
                            width(100.percent)
                            borderBox()
                        }
                        classes(styleBlock.commonElement.inputText)
                        value(inputDeadline)
                        onInput { event ->
                            inputDeadline = event.value as Int
                        }
                    }
                    H4({ classes(styleBlock.commonElement.smallTextInfo) }) {
                        Text("(Здесь указывается количество дней, в течение которых весь комплекс будет доступен после того, как клиенту откроется последняя тренировка. Если все тренировки открываются сразу, то все они будут доступны в течение указанного здесь количества дней.)")
                    }
                }
            }
            FlexRowDivParent {
                if (fullscreen) Button({
                    classes(styleBlock.commonElement.buttonBack)
                    style {
                        flex("initial")
                    }
                    onClick {
                        closeModadWindow()
                    }
                }) {
                    Text("Назад")
                }
                Button({
                    classes(styleBlock.trainerRoom.fmButton)
                    style {
                        borderBox()
                        flex("auto")
                    }
                    attr(disabledButtSet, "")
                    onClick {
                        currentUser.userProfile?.id?.let { id_trainer ->
                            if (complex != null) {
                                complex.copy(
                                    cover = imgTempCover,
                                    name = inputNameState,
                                    description = richTextEdit.getText(),
                                    price = inputPrice,
                                    deadline = inputDeadline
                                ).changeComplexFirestore {
                                    ListComplexes.pagination.update()
                                }
                                myGtag("event", "change_complex", "button", "click")
                            } else {
                                BaseComplex(
                                    trainer_id = id_trainer,
                                    cover = imgTempCover,
                                    name = inputNameState,
                                    description = richTextEdit.getText(),
                                    price = inputPrice,
                                    deadline = inputDeadline,
                                    date_create = getCurrentDateTimeUTC()
                                ).addNewComplexFirestore {
                                    ListComplexes.pagination.update()
                                }
                                myGtag("event", "add_complex", "button", "click")
                            }
                        }
                        closeModadWindow()
                    }
                }) {
                    Text(if (complex?.id != null) "Изменить" else "Добавить")
                }
            }
        }
    }
}
