package elements

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import api.firebase.setAssignmentsForTrainingsFirestore
import bdElement.BaseAssignment
import global.QuickStyleProperty.fontSubheader
import global.selectedClient
import global.selectedTrainingForEdit
import global.styleBlock
import global.stylePalette
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import style.Samples.WtTexts

class EditAssignmentsList() {

    var listDragAssignment by mutableStateOf<List<DragAssignment>>(listOf())
    fun setListAssignment(list: List<BaseAssignment>) {
        listDragAssignment = list.sortedBy { it.order }.map { DragAssignment(it) }
    }

    fun fixOrderAssignment() {
        selectedTrainingForEdit?.also { baseTraining ->
            setAssignmentsForTrainingsFirestore(baseTraining,baseTraining.assignments){
//                listDragAssignment = baseTraining.assignments.sortedBy { it.order }.map { DragAssignment(it) } ?: listOf()
            }
        }
    }

    fun addAssignment(assignment: BaseAssignment) {
        selectedTrainingForEdit?.also { baseTraining ->
             val tempListAssignments = baseTraining.assignments.toMutableList().apply {
                add(assignment)
            }
            setAssignmentsForTrainingsFirestore(baseTraining,tempListAssignments){
                baseTraining.assignments = tempListAssignments
                listDragAssignment = baseTraining.assignments.sortedBy { it.order }.map { DragAssignment(it) } ?: listOf()
            }
        }
    }

    fun deleteAssignment(assignment: BaseAssignment) {
        selectedTrainingForEdit?.also { baseTraining ->
            val tempListAssignments = baseTraining.assignments.toMutableList().apply {
                removeAll { it.id == assignment.id }
            }
            setAssignmentsForTrainingsFirestore(baseTraining,tempListAssignments){
                baseTraining.assignments = tempListAssignments
                listDragAssignment = baseTraining.assignments.sortedBy { it.order }.map { DragAssignment(it) } ?: listOf()
            }
        }
    }

    fun changeAsignment(assignment: BaseAssignment, newName: String, newDescription: String) {
        selectedTrainingForEdit?.also { baseTraining ->
            val tempListAssignments = baseTraining.assignments.toMutableList().apply {
                find { it.id == assignment.id }?.apply {
                    name = newName
                    description = newDescription
                }
            }
            setAssignmentsForTrainingsFirestore(baseTraining,tempListAssignments){
                baseTraining.assignments = tempListAssignments
                listDragAssignment = baseTraining.assignments.sortedBy { it.order }.map { DragAssignment(it) } ?: listOf()
            }
        }
    }

    @Composable
    fun getListAssignment() {
        Div({
            style {
                position(Position.Relative)
                property("user-select", "none")
            }
        }) {
            P(attrs = {
                style {
                    fontSubheader()
                    width(100.percent)
                    textAlign("left")
                }
            }) {
                Text("Упражнения")
            }
            if (listDragAssignment.isNotEmpty()) {
                listDragAssignment.forEach {
                    it.getCard(listDragAssignment)
                }
            } else {
                H4(attrs = {
                    style {
                        margin(10.px,0.px)
                        color(stylePalette.gray)
                    }
//                    classes(WtTexts.wtH2ThemeDark)
                }) {
                    Text("В тренировку пока не добавлено ни одного упражнения.")
                }
            }
        }
    }
}
