package style.First

import global.CSSShadow
import global.CSSTextShadow
import global.QuickStyleProperty.defaultShadow
import global.QuickStyleProperty.myBoxShadow
import global.QuickStyleProperty.myTextShadow
import global.styleConst
import global.stylePalette
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*
import style.AppStylesheet
import style.STrainerRoom


object StyleTrainingRoom : STrainerRoom, StyleSheet(AppStylesheet) {

    override val itemCardClientNew by style {}
    override val itemCardClient by style {
        boxSizing("border-box")
        flexGrow(0)
        flexShrink(1)
        flexBasis(33.333.percent - 10.pt)
        media(mediaMaxWidth(1640.px)) {
            self style {
                flexBasis(50.percent - 10.pt)
            }
        }
        media(mediaMaxWidth(740.px)) {
            self style {
                flexBasis(100.percent)
            }
        }
        border(1.px, LineStyle.Solid, rgb(127, 127, 127))
        borderRadius(10.pt)
        property(
            "box-shadow",
            "3px 3px 1px 0 rgba(0, 0, 0, 0.5), 4px 4px 6px 0 rgba(0, 0, 0, 0.19)"
        )
        padding(10.px)
        backgroundColor(rgb(230,230,230))
    }
    override val delimiterCardClient by style {

    }
    override val ccAvatar by style {
        property("aspect-ratio", "1")
        property("object-fit", "cover")
        padding(2.px)
        width(100.percent)
//        width(70.percent)
        border(2.px, LineStyle.Solid, rgb(255, 255, 255))
        borderRadius(15.px)
        width(100.pt)
        border(1.px, LineStyle.Solid, rgb(127, 127, 127))
    }
    override val ccCoverComplex by style {
        property("aspect-ratio", "1")
        property("object-fit", "cover")
        borderRadius(50.percent)
        width(60.pt)
    }

    override val ccName by style {

    }
    override val ccContact by style {}
    override val itemCardTraining by style {
        border(1.px, LineStyle.Solid, rgb(127, 127, 127))
        borderRadius(10.pt)
        myBoxShadow(CSSShadow(3.px,3.px,3.px,0.px))
//        property(
//            "box-shadow",
//            "3px 3px 1px 0 rgba(0, 0, 0, 0.5), 4px 4px 6px 0 rgba(0, 0, 0, 0.19)"
//        )
        padding(10.px)
        marginBottom(10.pt)
        backgroundColor(rgb(230, 230, 230))
    }
    override val ctIconButton by style {}
    override val ctMarkerOut by style {
        myBoxShadow(
            CSSShadow(-3.px, -3.px, 5.px, color = rgb(255, 255, 255)),
            CSSShadow(2.px, 2.px, 5.px, color = rgba(0, 0, 0, 0.3))
        )
    }
    override val ctMarkerIn by style {
        myBoxShadow(
            CSSShadow(-2.px, -2.px, 5.px, color = rgba(255, 255, 255, 0.35), inner = true),
            CSSShadow(2.px, 2.px, 5.px, color = rgba(0, 0, 0, 0.4), inner = true)
        )
    }
    @OptIn(ExperimentalComposeWebApi::class)
    override val ctIconCopyButton by style {
        transitions {
            all {
                duration(0.2.s)
                timingFunction(AnimationTimingFunction.EaseInOut)
            }
        }
        borderRadius(10.px)
        myTextShadow(
            CSSTextShadow(-2.px, -2.px, 4.px, color = rgb(255, 255, 255)),
            CSSTextShadow(2.px, 2.px, 4.px, color = rgba(0, 0, 0, 0.6)),
            CSSTextShadow(0.px, 0.px, 1.px, color = stylePalette.gray)
        )
        self + hover style {
            myTextShadow(
                CSSTextShadow(-2.px, -2.px, 4.px, color = rgb(255, 255, 255)),
                CSSTextShadow(2.px, 2.px, 4.px, color = rgba(0, 0, 0, 0.9)),
                CSSTextShadow(0.px, 0.px, 1.px, color = stylePalette.darkGreen)
            )
        }
        self + active style {
            myTextShadow(
                CSSTextShadow(-2.px, -2.px, 4.px, color = rgb(255, 255, 255)),
                CSSTextShadow(2.px, 2.px, 4.px, color = rgba(0, 0, 0, 0.9)),
                CSSTextShadow(0.px, 0.px, 1.px, color = stylePalette.black)
            )
            transform{
                scale(0.98)
            }
        }
    }
    override val ctName by style {

    }
    override val ctTime by style {

    }
    override val editDragBox by style {
        width(30.px)
        height(75.px)
        border(1.px,  LineStyle.Solid, rgb(121,181,255))
        borderRadius(6.px)
        backgroundColor( rgb(159,255,103))
    }
    override val editItemAssignment by style {
        position(Position.Relative)
        left (0.px)
        top (0.px)
        border(1.px, LineStyle.Solid, stylePalette.gray)
        background( "antiquewhite")
        padding(6.px)
        borderRadius(8.px)
        property(
            "box-shadow",
            "3px 3px 1px 0 rgba(0, 0, 0, 0.5), 4px 4px 6px 0 rgba(0, 0, 0, 0.19)"
        )
    }

    override val editImgAssignment by style {
        width(62.px)
        border(1.px, LineStyle.Solid, stylePalette.gray)
        defaultShadow()
        borderRadius(6.px)
    }

    override val editNameAssignment by style {

    }
    override val editIconButton by style {

    }
    override val bvCard by style {
        boxSizing("border-box")
        flexGrow(0)
        flexShrink(1)
        flexBasis((100 / 6.0).percent - 10.pt)
        media(mediaMaxWidth(1540.px)) {
            self style {
                flexBasis((100 / 5.0).percent - 10.pt)
            }
        }
        media(mediaMaxWidth(1340.px)) {
            self style {
                flexBasis((100 / 4.0).percent - 10.pt)
            }
        }
        media(mediaMaxWidth(1040.px)) {
            self style {
                flexBasis((100 / 3.0).percent - 10.pt)
            }
        }
        media(mediaMaxWidth(740.px)) {
            self style {
                flexBasis((100 / 2.0).percent - 10.pt)
            }
        }

        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Column)
        minHeight(0.px)
        boxSizing("border-box")

        border(1.px, LineStyle.Solid, rgb(127, 127, 127))
        borderRadius(5.pt)
        property(
            "box-shadow",
            "3px 3px 1px 0 rgba(0, 0, 0, 0.5), 4px 4px 6px 0 rgba(0, 0, 0, 0.19)"
        )
        padding(10.px)
        backgroundColor(rgb(230, 230, 230))
        margin(0.pt)
    }
    override val bvImage by style {}
    override val bvIconButton by style {}
    override val bvButtonPanel by style {}
    override val filterPlate by style {
        display(DisplayStyle.Flex)
        flexWrap(FlexWrap.Wrap)
        border(1.px, LineStyle.Solid, rgb(127, 127, 127))
        borderRadius(10.pt)
        property(
            "box-shadow",
            "3px 3px 1px 0 rgba(0, 0, 0, 0.5), 4px 4px 6px 0 rgba(0, 0, 0, 0.19)"
        )
        padding(10.px)
//        justifyContent(JustifyContent.SpaceBetween)
        columnGap(10.pt)
        rowGap(10.pt)

        marginBottom(10.pt)
        backgroundColor(rgb(230, 230, 230))
    }
    override val filterNameText by style {}
    override val filterSelectedText by style {}
    override val filterIconStart by style {}
    override val fmHeaders by style {
        color(stylePalette.white)
        padding(0.px, 8.px)
        borderRadius(8.px)
    }
    override val fmButton by style {
        margin(8.pt)
        fontFamily(styleConst.mainFontFamily)
        padding(8.px, 8.px, 8.px, 8.px)
        textDecoration("none")
        fontSize(15.px)
        property("text-transform", "uppercase");
//        backgroundColor(rgb(71, 71, 71))
        color(rgb(255, 255, 255))
        borderWidth(0.px)
        borderRadius(10.px)
        backgroundColor(rgb(192,12,61))
    }
    override val fmItemText by style {}
    override val fmItemBox_Out by style {}
    override val fmItemBox_In by style {}
    override val buttNewTraining by style {}
    override val buttPasteTrainings by style {}
    override val buttResetPasteTrainings by style {}
    override val buttAccept by style {
        backgroundColor(stylePalette.myGreen)
    }
    override val buttTransparentBack by style {
        backgroundColor(stylePalette.myGreen)
    }
    override val ctBoxMuscleGroup by style {
        padding(6.px)
        borderRadius(6.px)
        fontSize(14.px)
        myBoxShadow(
            CSSShadow(-2.px, -2.px, 3.px, color = rgba(255, 255, 255, 0.3), inner = true),
            CSSShadow(2.px, 2.px, 3.px, color = rgba(0, 0, 0, 0.4), inner = true)
        )
        backgroundColor(stylePalette.gray)
        color(stylePalette.white)
    }
    override val itemVideoPreview by style {
        boxSizing("border-box")
        flexGrow(0)
        flexShrink(1)
        flexBasis((100 / 6.0).percent - 10.pt)
        media(mediaMaxWidth(1540.px)) {
            self style {
                flexBasis((100 / 5.0).percent - 10.pt)
            }
        }
        media(mediaMaxWidth(1340.px)) {
            self style {
                flexBasis((100 / 4.0).percent - 10.pt)
            }
        }
        media(mediaMaxWidth(1040.px)) {
            self style {
                flexBasis((100 / 3.0).percent - 10.pt)
            }
        }
        media(mediaMaxWidth(740.px)) {
            self style {
                flexBasis((100 / 2.0).percent - 10.pt)
            }
        }

        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Column)
        minHeight(0.px)

        border(1.px, LineStyle.Solid, rgb(127, 127, 127))
        borderRadius(5.pt)
        property(
            "box-shadow",
            "3px 3px 1px 0 rgba(0, 0, 0, 0.5), 4px 4px 6px 0 rgba(0, 0, 0, 0.19)"
        )
        padding(10.px)
        backgroundColor(rgb(230, 230, 230))
        margin(0.pt)
        border(0.px, LineStyle.None)
        myBoxShadow(
            CSSShadow(-3.px, -3.px, 5.px, color = rgb(255, 255, 255)),
            CSSShadow(2.px, 2.px, 5.px, color = rgba(0, 0, 0, 0.3))
        )
    }
    override val ctBoxDescription by style {
        padding(5.px)
        borderRadius(10.px)
        myBoxShadow(
            CSSShadow(-3.px, -3.px, 5.px, color = rgb(255, 255, 255)),
            CSSShadow(2.px, 2.px, 5.px, color = rgba(0, 0, 0, 0.3))
        )
    }

    val buttonAdd by style {
        margin(8.pt)
        fontFamily("Roboto Slab")
        padding(12.px)
//        textDecoration("none")
        fontSize(15.px)
        property("text-transform", "uppercase");
        backgroundColor(rgb(192,12,61))
        color(rgb(255, 255, 255))
        borderRadius(8.px)
        display(DisplayStyle.Block)
        border(2.px, LineStyle.Solid,rgb(192,12,61))
        self + hover style {
            border(2.px, LineStyle.Solid, stylePalette.darkRed)
//            myBoxShadow(
//                CSSShadow(-2.px, -2.px, 5.px, color = rgba(0, 0, 0, 0.5), inner = true),
//                CSSShadow(2.px, 2.px, 5.px, color = rgba(255, 255, 255,0.3), inner = true)
//            )
        }
        self + active style {
            border(2.px, LineStyle.Solid, stylePalette.darkRed)
            myBoxShadow(
//                CSSShadow(-2.px, -2.px, 5.px, color = rgba(255, 255, 255,0.3), inner = true),
                CSSShadow(0.px, 0.px, 5.px, color = rgba(0, 0, 0, 0.5), inner = true)
            )
        }
    }

    val buttonPaste by style {
        margin(8.pt)
        fontFamily("Roboto Slab")
        padding(12.px)
//        textDecoration("none")
        fontSize(15.px)
        property("text-transform", "uppercase");
        backgroundColor(rgb(91,42,212))
        color(rgb(255, 255, 255))
        borderRadius(8.px)
        display(DisplayStyle.Block)
        border(2.px, LineStyle.Solid,rgb(91,42,212))
        self + hover style {
            border(2.px, LineStyle.Solid, stylePalette.darkBlue)
//            myBoxShadow(
//                CSSShadow(-2.px, -2.px, 5.px, color = rgba(0, 0, 0, 0.5), inner = true),
//                CSSShadow(2.px, 2.px, 5.px, color = rgba(255, 255, 255,0.3), inner = true)
//            )
        }
        self + active style {
            border(2.px, LineStyle.Solid, stylePalette.darkBlue)
            myBoxShadow(
//                CSSShadow(-2.px, -2.px, 5.px, color = rgba(255, 255, 255,0.3), inner = true),
                CSSShadow(0.px, 0.px, 5.px, color = rgba(0, 0, 0, 0.5), inner = true)
            )
        }
    }

    val cardTrainingHeader by style {
        display(DisplayStyle.Flex)
        flexWrap(FlexWrap.Wrap)
        columnGap(10.pt)
        rowGap(10.pt)
//        justifyContent(JustifyContent.SpaceBetween)
    }
    val cardTrainingDecor by style {
//        border(1.px, LineStyle.Solid, rgb(127, 127, 127))
        borderRadius(10.pt)
//        myBoxShadow(CSSShadow(3.px,3.px,3.px,0.px))
//        property(
//            "box-shadow",
//            "3px 3px 1px 0 rgba(0, 0, 0, 0.5), 4px 4px 6px 0 rgba(0, 0, 0, 0.19)"
//        )
        padding(10.px)
        marginBottom(10.pt)
        backgroundColor(rgb(230, 230, 230))
    }

    val contentSpoiler by style {
        property("transition", "height 0.5s") //ease-in-out // ease-in max-
        overflow("hidden")
    }

    @OptIn(ExperimentalComposeWebApi::class)
    val chevron by style {
//        height(30.pt)
//        width(30.pt)
        transitions {
            all {
                duration(0.2.s)
                timingFunction(AnimationTimingFunction.EaseInOut)
            }
        }
    }

    @OptIn(ExperimentalComposeWebApi::class)
    val disableStyle by style {
//        height(30.pt)
//        width(30.pt)
            opacity(0.3)
    }

    @OptIn(ExperimentalComposeWebApi::class)
    val redButton by style {
        backgroundColor(rgb(255, 210, 210))
        transitions {
            all {
                duration(0.2.s)
                timingFunction(AnimationTimingFunction.EaseInOut)
            }
        }
        self + hover style {
            backgroundColor(rgb(255, 110, 110))
        }
    }

    @OptIn(ExperimentalComposeWebApi::class)
    val chevronButton by style {
//        height(30.pt)
//        width(30.pt)
        transitions {
            all {
                duration(0.2.s)
                timingFunction(AnimationTimingFunction.EaseInOut)
            }
        }
        borderRadius(10.px)
//        self + hover style {
//            backgroundColor(stylePalette.gray)
//        }
/*
        self + active style {
            transform{
                scale(0.98)
            }
            myBoxShadow(CSSShadow(3.px,3.px,3.px,0.px))
//            property("box-shadow"," 3px 2px 22px 1px rgba(0, 0, 0, 0.24)")
            backgroundColor(stylePalette.darkGray)
        }
*/
    }

    @OptIn(ExperimentalComposeWebApi::class)
    val copyButton by style {
//        height(30.pt)
//        width(30.pt)
        transitions {
            all {
                duration(0.2.s)
                timingFunction(AnimationTimingFunction.EaseInOut)
            }
        }
        borderRadius(10.px)
        myTextShadow(
            CSSTextShadow(-2.px, -2.px, 4.px, color = rgb(255, 255, 255)),
            CSSTextShadow(2.px, 2.px, 4.px, color = rgba(0, 0, 0, 0.6)),
            CSSTextShadow(0.px, 0.px, 1.px, color = stylePalette.gray)
        )
        self + hover style {
            myTextShadow(
                CSSTextShadow(-2.px, -2.px, 4.px, color = rgb(255, 255, 255)),
                CSSTextShadow(2.px, 2.px, 4.px, color = rgba(0, 0, 0, 0.9)),
                CSSTextShadow(0.px, 0.px, 1.px, color = stylePalette.darkGreen)
            )
        }
        self + active style {
            myTextShadow(
                CSSTextShadow(-2.px, -2.px, 4.px, color = rgb(255, 255, 255)),
                CSSTextShadow(2.px, 2.px, 4.px, color = rgba(0, 0, 0, 0.9)),
                CSSTextShadow(0.px, 0.px, 1.px, color = stylePalette.black)
            )
            transform{
                scale(0.98)
            }
        }
    }

    @OptIn(ExperimentalComposeWebApi::class)
    val rotatedChevron by style {
        transform {
            rotate(180.deg)
            translateY(3.px)
        }
        marginTop(6.px)
//        property("transition", "all 0.2s ease-in-out") //ease-in-out // ease-in max-
    }

    @OptIn(ExperimentalComposeWebApi::class)
    val noRotatedChevron by style {
    }

    val cardAssignmentParent by style {
        fontSize (14.px)
        position (Position.Relative)
        left (0.px)
        top (0.px)
        padding( 4.px,0.px)
    }

    val cardAssignment by style {
//        fontSize (4.px)
//        lineHeight ("1.0")
        position(Position.Relative)
        left (0.px)
        top (0.px)
        border(1.px, LineStyle.Solid, stylePalette.gray)
        background( "antiquewhite")
        padding(6.px)
        borderRadius(8.px)
        property(
            "box-shadow",
            "3px 3px 1px 0 rgba(0, 0, 0, 0.5), 4px 4px 6px 0 rgba(0, 0, 0, 0.19)"
        )
    }

}