package mainPage.modalWindows

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import bdElement.ForVideoFrame
import bdElement.getFrameVideo
import elements.ModalContent
import global.modalContent
import global.visibleModalWindow
import kotlinx.browser.window
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import kotlin.math.min

val modalVideoOne = ModalVideoPreview()
var videoForModalWindowOne by mutableStateOf<ForVideoFrame?>(null)

fun modalVideoPreview(videoFrame: ForVideoFrame){ //video: @Composable ()->Unit
//    modalContent = null
    videoForModalWindowOne = videoFrame
    modalContent = modalVideoOne //ModalVideoPreview(video)
    visibleModalWindow.value = true
//    myModal.open("#myModal1")
}

class ModalVideoPreview() : ModalContent() { //val video: @Composable ()->Unit

    @Composable
    override fun drawModalContent() {

        Div({
            style {
                width((min(window.innerHeight*0.45, window.innerWidth*0.8)).px)
            }
        }) {
            videoForModalWindowOne?.let { getFrameVideo(it) }
//            video()
        }
    }

}



