package mainPage.modalWindows

import androidx.compose.runtime.*
import api.commonUserPostJsonRequest
import api.firebase.additionFunAfterSignIn
import bdElement.BaseRequestComplex
import bdElement.ComplexInfo
import elements.*
import global.*
import global.QuickStyleProperty.borderBox
import global.QuickStyleProperty.myFullSize
import global.QuickStyleProperty.myTextShadow
import kotlinx.browser.window
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import style.First.StyleCommon

class ModalComplexInfo(val complexId: String, val funcAfter: () -> Unit) : ModalContent() {

    override val closableBack = false
    override val fullscreen: Boolean = phoneDevice

    private var inputPhoneState by mutableStateOf(currentUser.userProfile?.phone ?: "")
    private var inputMessage by mutableStateOf("")

    private var selectedComplex: ComplexInfo? by mutableStateOf(null)

    private var disabledButtSet by mutableStateOf("temp")

    var loadingProgress by mutableStateOf(true)

    var startBuying by mutableStateOf(false)


    @Composable
    private fun buttComm1(str: String) {
        H3({
            classes(StyleCommon.headerBlock)
            style {
                fontSize(17.px)
                color(Color.black)
                minWidth(0.px)
                maxWidth(500.px)
                borderBox()
                textAlign("center")
            }
        }) {
//            Br {}
            Br {}
            Text(str)
        }
    }

    @Composable
    private fun commAlready(str: String) {
        H3({
            classes(StyleCommon.headerBlock)
            style {
                fontSize(20.px)
                color(stylePalette.myGreen)
                minWidth(0.px)
                borderBox()
                maxWidth(500.px)
                myTextShadow(CSSTextShadow(2.px, 2.px, 2.px, rgba(255, 255, 255, 0.3)))
            }
        }) {
            Br {}
            Br {}
            Text(str)
        }
    }

    @Composable
    private fun buttCancel() {
        Button({
            classes(styleBlock.commonElement.buttonBack)
            style {
                flex("initial")
            }
            onClick {
                closeModadWindow()
            }
        }) {
            Text("Назад")
        }
    }

    @Composable
    private fun buttCommon(name: String, onClick: () -> Unit) {
        Button({
            classes(styleBlock.trainerRoom.fmButton)
            style {
                borderBox()
                flex("auto")
            }
            attr(disabledButtSet, "")
            onClick {
                onClick()
            }
        }) {
            Text(name)
        }
    }

    @Composable
    private fun buttEnter() {
        buttCommon("Войти/зарегистрироваться") {
            currentPage = PageApplication.Registration
            closeModadWindow()
            additionFunAfterSignIn = {
                openModalWindow(ModalComplexInfo(complexId, funcAfter))
            }
        }
    }


    @Composable
    private fun buttStart() {
        buttCommon("Начать") {
            closeModadWindow()
            currentUser.userProfile?.id?.let { client_id ->
                statusLoading.trainerProfile?.id?.let { trainer_id ->
                    commonUserPostJsonRequest(
                        path = "add_complex_for_client",
                        headers = { head ->
                            head.append("trainerId", trainer_id)
                            head.append("clientId", client_id)
                            head.append("complexId", complexId)
                            head.append("dateStart", getCurrentDateUTC().toString())
                        }, bodyStr = ""
                    ) {
                        OpenTrainings.pagination.update()
                        showMessage("Комплекс успешно добавлен. Загляните в раздел тренировки.")
                    }
                }
            }
        }
    }

    @Composable
    private fun buttBuy() {
        buttCommon("Купить") {
            startBuying = true
        }
    }

    @Composable
    private fun buttRequest() {
        Button({
            classes(styleBlock.trainerRoom.fmButton)
            style {
                borderBox()
                flex("auto")
                opacity(if (inputPhoneState.length > 10) 1 else 0.3)
            }
            attr(disabledButtSet, "")
            onClick {
                if (inputPhoneState.length > 10) {
                    selectedComplex?.let {info ->
                        currentUser.userProfile?.let {client ->
                            closeModadWindow()
                            BaseRequestComplex(
                                trainer_id = info.complex.trainer_id,
                                complex_id = info.complex.id,
                                complex_cover = info.complex.cover,
                                complex_name = info.complex.name,
                                complex_price = info.complex.price,
                                client_id = client.id,
                                client_name = client.name,
                                client_avatar = client.avatar,
                                client_phone = inputPhoneState,
                                client_message = inputMessage,
                            ).addNewComplexFirestore {
                                showMessage("Ваша заявка успешно отправлена.")
                            }
                        }
                    }
                }
            }
        }) {
            Text("Отправить заявку")
        }
    }

    @Composable
    private fun buttVerify() {
        buttCommon("Верифицировать email") {
            currentPage = PageApplication.Verification
            statusLoading.trainerProfile?.id?.let { id_trainer ->
                setCookie("complex_${id_trainer}", complexId)
            }
            closeModadWindow()
        }
    }


    @Composable
    override fun drawModalContent() {
        LaunchedEffect(Unit) {
            ComplexInfo.getComplexInfo(complexId) {
                selectedComplex = it
                loadingProgress = false
            }
        }

        FlexColumnDivParent({
            style {
                myFullSize()
                backgroundColor(stylePalette.bodyModalColor)
                overflowX("hidden")
                padding(10.px)
                if (fullscreen) height(window.innerHeight.px)
            }
        }) {
            if (loadingProgress) CenterDiv {
                mainLoader()
            } else selectedComplex?.let { info ->
                FlexAutoDiv({
                    style {
                        overflowY("auto")
                        overflowX("hidden")
//                    alignContent(AlignContent.Center)
                    }
                }) {
                    selectedComplex?.let { complex ->
                        CenterDiv {
                            CutBorderBox(15, 2, attrs = {
                                style {
//                                flex("initial")
                                }
                            }) {
                                Img(src = info.complex.cover ?: tempComplexCoverUrl) {
                                    classes(styleBlock.trainerRoom.ccCoverComplex)
                                    style {
                                        width(120.px)
                                        height(160.px)
                                    }
                                }
                            }
                        }
                        CenterDiv({
                            style {
                                color(stylePalette.myOrange)
                                fontWeight(800)
                                textAlign("center")
                                fontFamily(styleConst.titleFontFamily)
                                myTextShadow(CSSTextShadow(2.px, 2.px, 0.px, stylePalette.myBlue))
                                fontSize(28.px)
                                property("overflow-wrap", "break-word")
                            }
                        }) {
                            Text(info.complex.name ?: "")
                        }
                        RichTextView(info.complex.description ?: "", attrs = {
                            classes(styleBlock.commonElement.rtView)
                            style {
                                info.complex.description?.let {
                                    marginTop(20.pt)
                                }
                            }
                        })
                        H3({
                            classes(styleBlock.commonElement.inputTextHeaders)
                            style {
                                marginTop(8.pt)
                                marginBottom(2.pt)
                            }
                        }) {
                            Text("Количество тренировок в комплексе: ${info.count_trainings}")
                        }
                        H3({
                            classes(styleBlock.commonElement.inputTextHeaders)
                            style {
                                marginTop(8.pt)
                                marginBottom(2.pt)
                            }
                        }) {
                            Text(if (info.max_date == 0L) "Тренировки открываются сразу"
                            else "Тренировки открываются в дни:${
                                info.date_opens.foldRight("") { day, rez ->
                                    rez + " ${day + 1}"
                                }
                            }"
                            )
                        }
                        H3({
                            classes(styleBlock.commonElement.inputTextHeaders)
                            style {
                                marginTop(8.pt)
                                marginBottom(2.pt)
                            }
                        }) {
                            Text("Общий срок доступа комплекса: ${info.srok} дней")
                        }
                        H3({
                            classes(StyleCommon.headerBlock)
                            style {
                                fontSize(17.px)
                                color(Color.black)
                                minWidth(0.px)
                            }
                        }) {
                            Br {}
                            Text("Цена: ${(info.complex.price ?: 0.0).let { if (it > 0) it else "Бесплатно!" }}")
                        }

                    }
                    if (info.started.not() && info.request.not()) {
                        if (currentUser.userProfile == null)
                            buttComm1("Для того, чтобы ${(info.complex.price ?: 0.0).let { if (it > 0) "купить" else "начать" }} комплекс, необходимо войти или зарегистрироваться.")
                        else if (info.complex.price != 0.0 && currentUser.getEmailVerified() != true)
                            buttComm1("Для того, чтобы купить комплекс, необходимо подтвердить адрес электронной почты.")
                        if (startBuying) {
                            buttComm1("Для того, чтобы отправить заявку, укажите свой контактный телефон. После этого тренер свяжется с вами по этому номеру и вы договоритесь о форме оплаты. Так же можете добавить комментарий о том как лучше с вами связаться.")
                            CenterDiv({
                                style {
//                    border(1.px, LineStyle.Solid, rgb(47, 47, 47))
                                    paddingTop(8.px)
                                    paddingBottom(8.px)
                                    flexDirection(FlexDirection.Column)
                                }
                            }) {
                                Text("Контактный телефон")
                                Input(type = InputType.Text) {
                                    classes(styleBlock.commonElement.inputText)
                                    style {
                                        width(250.px)
//                                backgroundColor(Color.lightgray)
                                    }
                                    value(inputPhoneState)
                                    onInput { event -> inputPhoneState = event.value }
                                }
//                                backgroundColor(Color.lightgray)
                                Text("Сообщение")
                                TextArea {
                                    classes(styleBlock.commonElement.inputText)
                                    style {
                                        minWidth(300.px)
                                        maxWidth(400.px)
                                        height(100.px)
                                        overflow("auto")
                                        property("resize","none")
                                    }
                                    value(inputMessage)
                                    onInput { event -> inputMessage = event.value }
                                }
                            }
                        }
                    } else {
                        if (info.started) commAlready("Вы уже начали этот комплекс и на данный момент он все еще вам доступен.")
                        else if (info.request) commAlready("Вы уже отправили заявку, подождите пока тренер свяжется с вами.")
                    }
                }

                FlexRowDivParent {
                    if (fullscreen)
                        buttCancel()
                    if (info.started.not() && info.request.not()) {
                        if (currentUser.userProfile == null)
                            buttEnter()
                        else if (info.complex.price == 0.0) {
                            buttStart()
                        } else {
                            if (currentUser.getEmailVerified() == true) {
                                if (startBuying) buttRequest()
                                else buttBuy()
                            } else {
                                buttVerify()
                            }
                        }
                    }
                }
            }
        }
    }
}
