package elements

import androidx.compose.runtime.*
import global.QuickStyleProperty.borderBox
import global.QuickStyleProperty.myTextNonSelect
import global.styleBlock
import kotlinx.browser.document
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLDivElement
import style.First.StyleTrainingRoom
import style.First.StyleUserProfile

class RichTextEdit(initValue: String) {
    private var richTextValue by mutableStateOf(initValue)

    fun setTextValue(newValue: String) {
        richTextValue = newValue
    }

    fun getText(): String = document.getElementById("editText")?.innerHTML ?: ""

    @Composable
    fun RichTextEdit(attrs: AttrBuilderContext<HTMLDivElement>? = null) {

        Div({
//            classes(StyleUserProfile.propertyDescription)
            style {
                position(Position.Relative)
                maxWidth(100.percent)
                borderBox()
            }
            attrs?.invoke(this)
        }) {
            Div({
                classes(styleBlock.commonElement.rtMainBlock)
                id("editText")
                style {
                    borderBox()
//                    border(1.px, LineStyle.Solid, rgb(150, 150, 150))
//                    borderRadius(5.px)
                    padding(10.px)
                    fontSize(16.px)
//                    property("resize", "both") /* Add this line to enable resizing */
                    overflow("auto"); /* Add this line to prevent content from overflowing the editor */
                    minHeight(150.px)
//                    minWidth(400.px)
//                    maxWidth(100.percent)
                    width(100.percent)
                }
                contentEditable(true)
            })
            LaunchedEffect(richTextValue) {
                document.getElementById("editText")?.innerHTML =
                    richTextValue.replace("white-space: pre;", "white-space: normal;")
            }
/*
            Div({
                style {
                    margin(5.px,0.px)
//                    paddingBottom(10.px)
                    display(DisplayStyle.Flex)
                    columnGap(5.px)
                    borderRadius(15.px)
                    border(1.px, LineStyle.Solid,Color.gray)
                }
            }) {
                buttonIcon(listOf("fa-solid","fa-bold"),{
                    classes(styleBlock.commonElement.rtIconButton)
                }){
                    it.preventDefault()
                    document.execCommand("bold", false)
                }
                Button ({

//                    onTouchStart {
//
//                    }
                    onClick {
                        it.preventDefault()
                        document.execCommand("bold", false)
                    }
                }){
                    Text("Bold")
                }
                buttonIcon(listOf("fa-solid","fa-italic"),{
                    classes(styleBlock.commonElement.rtIconButton)
                }){
                    document.execCommand("italic", false)
                    it.preventDefault()
                }
                buttonIcon(listOf("fa-solid","fa-underline"),{
                    classes(styleBlock.commonElement.rtIconButton)
                }){
                    document.execCommand("underline", false)
                }
                buttonIcon(listOf("fa-solid","fa-list-ol"),{
                    classes(styleBlock.commonElement.rtIconButton)
                }){
                    document.execCommand("insertOrderedList", false)
                }
                buttonIcon(listOf("fa-solid","fa-list-ul"),{
                    classes(styleBlock.commonElement.rtIconButton)
                }){
                    document.execCommand("insertUnorderedList", false)
                }

                var colorFont by mutableStateOf("#000")
                Input(type = InputType.Color) {
                    style {
                        width(0.px)
                        height(0.px)
                        padding(0.px)
                        margin(0.px)
                        opacity(0.0)
                    }
                    id("selectTextColor")
                    value(colorFont)
                    onInput { event ->
                        document.execCommand("forecolor", false, event.value)
                        colorFont = event.value
                    }
                }
                buttonIconLabel(listOf("fa-solid","fa-paintbrush"),"selectTextColor"){
                    classes(styleBlock.commonElement.rtIconButton)
                }

                var colorBack by mutableStateOf("#000")
                Input(type = InputType.Color) {
                    style {
                        width(0.px)
                        height(0.px)
                        padding(0.px)
                        margin(0.px)
                        opacity(0.0)
                    }
                    id("selectTextBackgroundColor")
                    value(colorBack)
                    onInput { event ->
                        document.execCommand("hilitecolor", false, event.value)
                        colorBack = event.value
                    }
                }
                buttonIconLabel(listOf("fa-solid","fa-paint-roller"),"selectTextBackgroundColor"){
                    classes(styleBlock.commonElement.rtIconButton)
                }

//                var arr by mutableStateOf(intArrayOf(0,1, 2, 3, 4, 5, 6, 7))
*/
/*
                Select({
                    id("selectFontSize")
                    onChange {
                        if (it.value != "0") {
                            document.execCommand("fontsize", false, "${it.value}.px" ?: "10.px")
                            document.getElementById("selectFontSize").asDynamic().selectedIndex = "0";
                        }
                    }
                }) { //                    intArrayOf(12, 14, 16, 18, 20, 24, 28, 32, 36, 48, 72).forEach {
                    intArrayOf(0, 1, 2, 3, 4, 5, 6, 7).forEach {
                        Option(value = it.toString()) {
                            Text(it.toString())
                        }
                    }
                }
*//*



            }
*/
        }
    }
}

@Composable
fun RichTextView(
    value: String,
    id: String = getUUID(),
    funUpdate: () -> Unit = {},
    attrs: AttrBuilderContext<HTMLDivElement>? = null
) {
    Div({
        attrs?.invoke(this)
        classes(StyleUserProfile.richTextEdit)
        id(id)
    })
    LaunchedEffect(value) {
        document.getElementById(id)?.innerHTML = value.replace("white-space: pre;", "white-space: normal;")
//        document.getElementById("demo").style.color = "red";
        funUpdate()
    }
}
