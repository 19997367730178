package api.firebase

import api.commonUserPostJsonRequest
import bdElement.MyAnswerCommon
import bdElement.CreatiumRequest
import bdElement.MyTags
import global.*
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json

fun getTagsFromFirestore() {
    currentUser.userProfile?.let { profile ->
        commonUserPostJsonRequest(
            path = "get_tags_equipment",
            bodyStr = myJson.encodeToString(CreatiumRequest()),
        ) { userStr -> //                    console.log((Json{ encodeDefaults = true }).encodeToString(CreatiumRequest()))
            tags_equipment.value = Json.decodeFromString<MyAnswerCommon<MyTags>>(userStr)
            println("tags_equipment: ${tags_equipment.value?.totalCount}")
        }
        commonUserPostJsonRequest(
            path = "get_tags_muscle_group",
            bodyStr = myJson.encodeToString(CreatiumRequest()),
        ) { userStr -> //                    console.log((Json{ encodeDefaults = true }).encodeToString(CreatiumRequest()))
            tags_muscle_group.value = Json.decodeFromString<MyAnswerCommon<MyTags>>(userStr)
            println("tags_muscle_group: ${tags_muscle_group.value?.totalCount}")
        }
        commonUserPostJsonRequest(
            path = "get_tags_sex",
            bodyStr = myJson.encodeToString(CreatiumRequest()),
        ) { userStr -> //                    console.log((Json{ encodeDefaults = true }).encodeToString(CreatiumRequest()))
            tags_sex.value = Json.decodeFromString<MyAnswerCommon<MyTags>>(userStr)
            println("tags_sex: ${tags_sex.value?.totalCount}")
        }
    }
}