package mainPage

import androidx.compose.runtime.Composable
import bdElement.ForVideoFrame
import elements.CenterDiv
import elements.FlexColumnDivParent
import elements.MyContainerInSection
import global.QuickStyleProperty.borderBox
import global.openModalWindow
import global.styleBlock
import global.stylePalette
import kotlinx.browser.window
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import style.First.StyleTitlePage
import style.common.StylePageTitleCommon
import kotlin.math.min

val helpers: List<Pair<String, ForVideoFrame>> = listOf(
    "Как зарегистрироваться тренеру?" to ForVideoFrame("94863", "2ed2a7cc-f925-4823-bbe0-c13848a4e77a"),
    "Как пригласить клиента и как клиент будет регистрироваться?" to ForVideoFrame(
        "94863",
        "d24bf4f8-420f-4e27-900c-682f446a2d70"
    ),
    "Как создать первую тренировку?" to ForVideoFrame("94863", "38fef032-abf5-419c-8b88-bcde1a60f9e0"),
)

@Composable
fun pageHelps() {
    MyContainerInSection() {
        FlexColumnDivParent(rowGap = 10.pt) {
            helpers.forEach {
                helpsItem(it.first, it.second)
            }
            CenterDiv({
                style {
                    marginTop(50.px)
                    width(100.percent)
                    color(stylePalette.mainTextColor)
                    flexGrow(0)
                    if (window.innerWidth > 600) textAlign("center")
                }
            }) {

                H3({
                    style {
                        fontWeight(200)
                    }
                }) {

//                    Text("По любым вопросам, а также с отзывами и предложениями можно обращаться на почту fitconstructor2023@gmail.com, а так же в WhatsApp +7(915)305-11-74.")
                    Text("По любым вопросам, а также с отзывами и предложениями можно обращаться на почту ")
                    Span({
                        style {
                            color(stylePalette.myBlue)
                        }
                    }) {
                        Text("fitconstructor2023@gmail.com")
                    }
                    Text(", а так же в ")
                    Span({
                        style {
                            color(stylePalette.myGreen)
                        }
                    }) {
                        Text("WhatsApp")
                    }
                    Span({
                        style {
                            color(stylePalette.myBlue)
                        }
                    }) {
                        Text(" +7(915)305-11-74")
                    }
                    Text(".")
                }
            }
        }

    }
}

@Composable
fun helpsItem(name: String, video: ForVideoFrame, autoplay: Boolean = true) {
    Div({
        classes(StylePageTitleCommon.buttonComplexGirl, styleBlock.pageTitle.buttonComplexGirl)
        style {
            width(100.percent)
            borderBox()
            marginTop(0.px)
        }
        onClick {
            openModalWindow(buttOk = false, closeBack = true) {
                Div({
                    style {
                        width((min(window.innerHeight * 0.4, window.innerWidth * 0.8)).px)
                    }
                }) {
                    Div({
                        style {
                            width(100.percent)
                            position(Position.Relative)
                            paddingTop(218.054.percent)
                        }
                    }) {
                        Iframe({
                            attr(
                                "src",
                                "https://iframe.mediadelivery.net/embed/${video.library}/${video.guid}?${if (autoplay) "autoplay=true&" else ""}loop=true"
                            )
//            attr("loading","lazy")
                            if (autoplay) attr("allow", " autoplay; ")
//            attr("allow","accelerometer; gyroscope; autoplay; encrypted-media;")
                            attr("allowfullscreen", "true")

                            style {
                                border {
                                    style(LineStyle.None)
                                }
                                position(Position.Absolute)
                                top(0.percent)
                                height(100.percent)
                                width(100.percent)
                            }
                        })
                    }
                }
            }
        }
    }) {
        H3 {
            Text(name)
        }
    }

}