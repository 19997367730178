package global

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import api.firebase.getTrainersForThisClient
import bdElement.BaseClient
import bdElement.MyAnswerCommon
import elements.CardClient
import elements.CenterDiv
import elements.mainLoader
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div


object ListTrainers {
    var trainersLoaded by mutableStateOf<MyAnswerCommon<BaseClient>?>(null)
        private set

    private var loadingProcess by mutableStateOf(true)
    val pagination = PageAdapter(10) { limit, skip, silent ->
        if (silent.not()) loadingProcess = true
        getTrainersForThisClient(limit, skip)
    }

    fun setTrainersLoaded(load: MyAnswerCommon<BaseClient>) {
        trainersLoaded = load
        load.totalCount?.let { ListClients.pagination.totalCount = it }
        loadingProcess = false
    }
    fun oldTrainers():Boolean = trainersLoaded?.rows?.filter { it.status != "open" || it.status != "new" }?.isNotEmpty() == true

    @Composable
    fun getViewListTrainers() {
        if (loadingProcess){
            CenterDiv({style { marginTop(40.px) }}) { mainLoader() }
        }   else {
            Div(
                attrs = {
                    style {
//                    display(DisplayStyle.Flex)
//                    flexWrap(FlexWrap.Wrap)
//                    justifyContent(JustifyContent.SpaceBetween)
//                    columnGap(10.pt)
//                    rowGap(10.pt)
//                    alignItems(AlignItems.Stretch)
                    }
                }
            ) {
                trainersLoaded?.rows?.filter { it.status == "open" || it.status == "new" }
                    ?.mapIndexed { index, baseClient -> Pair(index, baseClient) }?.forEach {
                    if (it.first != 0) Div({ classes(styleBlock.trainerRoom.delimiterCardClient) })
                    CardClient(it.second, true) {
//                    statusLoading.loadingTraining = true
//                    selectedClient = it
                    }
                }
            }
        }
        pagination.paginationElement()

    }
    @Composable
    fun getViewListCloseTrainers() {
        if (loadingProcess){
            CenterDiv({style { marginTop(40.px) }}) { mainLoader() }
        }   else {
            Div {
                trainersLoaded?.rows?.filter { it.status != "open" && it.status != "new"  }?.sortedByDescending { it.date_update }
                    ?.mapIndexed { index, baseClient -> Pair(index, baseClient) }?.forEach {
                    if (it.first != 0) Div({ classes(styleBlock.trainerRoom.delimiterCardClient) })
                    CardClient(it.second, true) {
                        statusLoading.loadingTraining = true
                        selectedClient = it.second
                    }
                }
            }
        }
//        pagination.paginationElement()
    }

}