package bdElement

import api.firebase.BaseElement
import api.firebase.TypeElement
import global.getCurrentDateTimeUTC

abstract class CommonElement (){
    abstract val date_create: Long?
    abstract val date_update: Long?
    abstract val typeElement: TypeElement
    protected inline fun <reified T: CommonElement> getBaseElement(obj: T) = BaseElement.createBaseElement(typeElement.label, obj)
}