package mainPage.pageTitleElement

import androidx.compose.runtime.Composable
import bdElement.CommonUser
import elements.CenterDiv
import elements.CutBorderBox
import global.*
import global.QuickStyleProperty.borderBox
import global.QuickStyleProperty.clipCutCorners
import global.QuickStyleProperty.clipCutCornersInBorder
import global.QuickStyleProperty.myBoxShadow
import global.QuickStyleProperty.myTestBorder
import global.QuickStyleProperty.myTextShadow
import global.QuickStyleProperty.zIndex
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*

@Composable
fun trainerCoverBlock(trainer: CommonUser) {
    Div({
        style {
            display(DisplayStyle.Flex)
            marginTop(40.pt)
            marginRight(20.pt)
            marginLeft(20.pt)
            flexDirection(if (phoneDevice) FlexDirection.Column else FlexDirection.Row)
            flexWrap(FlexWrap.Nowrap)
            columnGap(0.pt)
            rowGap(0.pt)
            justifyContent(JustifyContent.Center)
        }
    }) {
        coverTrainerRound(trainer)
        CenterDiv({style {
            if (!phoneDevice) width(460.px)
//            if (!phoneDevice) height(480.px)
        }}) {
            titleTrainer2(trainer)
        }
    }

}

@Composable
private fun coverTrainerRound(trainer: CommonUser) {
    CenterDiv({
        style {
            flex("initial")
            position(Position.Relative)
//            zIndex(15)
        }
    }) {
        Div({style {
//            backgroundColor(stylePalette.myNewRed)
//            backgroundColor(rgba(255,255,255,0.7))
//            padding(if (phoneDevice) 3.px else 5.px)
            borderRadius(15.px)
            myBoxShadow(
                CSSShadow(
                    dx = if (phoneDevice) 4.px else 6.px,
                    dy = if (phoneDevice) 4.px else 6.px,
                    blur = 10.pt, //if (phoneDevice) 15.px else
                    spread = 3.pt,
                    color = rgba(0, 0, 0, 0.2),
//                            inner = true
                )
            )
        }}){
            Div({
                style {
                    padding(0.px)
//                    background("linear-gradient(0deg,#555,#555)")
                    borderRadius(15.px)
                    borderBox()
//                    border(6.px, LineStyle.Solid,stylePalette.myNewRed)
                    border(0.px, LineStyle.Solid,rgba(180,180,180,0.1))
                    overflow("hidden")
                    lineHeight("0")

                }
            }) {
/*
                Div({
                    style {
                        borderBox()
                        lineHeight("0")
                        borderRadius(0.px)
                    }
                }) {
*/
                    Img(src = trainer.avatar ?: tempAvatarUrl) {
                        classes(styleBlock.trainerRoom.ccCoverComplex)
                        style {
                            width(if (phoneDevice) 240.px else 360.px)
                            height(if (phoneDevice) 320.px else 480.px)
                        }
                    }
//                }
            }
        }
    }
}

@Composable
private fun coverTrainerCut(trainer: CommonUser) {
    CenterDiv({
        style {
            flex("initial")
            position(Position.Relative)
            zIndex(15)
        }
    }) {
        Div({
            style {
                position(Position.Absolute)
                margin(8.px)
                width(if (phoneDevice) 180.px else 270.px)
                height(if (phoneDevice) 250.px else 370.px)
                myBoxShadow(
                    CSSShadow(
                        dx = 4.px,
                        dy = 4.px,
                        blur = 20.pt,
                        spread = 10.pt,
                        color = rgba(0, 0, 0, 1)
                    ),
                    CSSShadow(
                        dx = 4.px,
                        dy = 4.px,
                        blur = 20.pt,
                        spread = 4.pt,
                        color = rgba(0, 0, 0, 1)
                    ),
                    CSSShadow(
                        dx = 4.px,
                        dy = 4.px,
                        blur = 20.pt,
                        spread = 0.pt,
                        color = rgba(0, 0, 0, 1)
                    )
                )
            }
        })
        CutBorderBox(15, 3, attrs = {
            style {
            }
            onClick {
//                    onClick()
            }
        }, background = "linear-gradient(0deg,#f1a417,#f1a41700,#f1a417)") {
            CutBorderBox(15, 2, attrs = {
                onClick {
//                    onClick()
                }
            }, background = "linear-gradient(0deg,#555,#555)") {
                Img(src = trainer.avatar ?: tempAvatarUrl) {
                    classes(styleBlock.trainerRoom.ccCoverComplex)
                    style {
                        width(if (phoneDevice) 210.px else 300.px)
                        height(if (phoneDevice) 280.px else 400.px)
                    }
                }
            }
        }
    }
}

@Composable
private fun titleTrainer1(trainer: CommonUser) {
    CenterDiv({
        style {
            flexDirection(FlexDirection.Column)
            margin(20.pt, 0.pt)
            /*
                                fontWeight(800)
                                fontFamily("TT Norms Pro"); //"Paytone One"; //"TT Norms Pro";
                                property("text-transform", "uppercase")
                                transform {
                                    scale(1.0)
                                    rotate(-10.deg)
                                    translateX(0.px)
                                    translateY(0.px)
                                    skewX(-15.deg)
                                    skewY(5.deg)
                                }
                                margin(20.px, 0.px);
                                fontSize(if (phoneDevice) 40.px else 60.px);
                                lineHeight(if (phoneDevice) 50.px else 70.px);
                                color(stylePalette.myOrange); // #f1ebe5;
                                *//*   text-shadow: -2px 0px 1px #fff, 2px 0px 1px #fff; *//*
                    property(
                        "text-shadow",
                        "0px 0px 2px #0071b900, 6px 6px #0071b9cc, 12px 12px #0071b9aa, 18px 18px #0071b988, 24px 24px #0071b966, 30px 30px #0071b944"
                    )
                    property("-webkit-text-stroke", "${if (phoneDevice) 1 else 2}px #fff")
                    *//*-2px 2px 1px #fff, 2px 2px 1px #fff,-2px 2px 1px #fff, -2px -2px 1px #fff,2px -2px 1px #fff, text- \shadow: -1px 1px 1px #aaad, 2px 2px 1px #fff,-2px 2px 1px #fff, -2px -2px 1px #fff,2px -2px 1px #fff;//,0 13.36px 8.896px #000;//#c4b59d; *//*
                    letterSpacing(3.px)
                */
        }
    }) {
        Div({
            style {
                position(Position.Relative)
                if (phoneDevice) marginTop(40.pt)
            }
        }) {
            H1({
                classes(styleBlock.pageTitle.mainHeaders)
                style {
//                                    color(stylePalette.myOrange)
                    color(Color.white)
                    fontWeight(600)
                    fontFamily(styleConst.mainFontFamily)
                    textAlign("center")
                    /*
                                                        property(
                                                            "text-shadow", //2px 2px 2px #00000066,
                                                            "0 0 20px #fefcc9, 10px -10px 30px #feec85, -20px -20px 40px #ffae34, " +
                                                                    "20px -40px 50px #ec760c, -20px -60px 60px #cd4606, " +
                                                                    "0 -80px 70px #973716, 10px -90px 80px #451b0e, " +
                                                                    "0 0 20px #fefcc9, 10px -10px 30px #feec85, -20px -20px 40px #ffae34, " +
                                                                    "20px -40px 50px #ec760c, -20px -60px 60px #cd4606, " +
                                                                    "0 -80px 70px #973716, 10px -90px 80px #451b0e"
                                                        )
                    */
                }
            }) {
//                    Br()
                Text("${trainer.name ?: ""}${trainer.second_name?.let { " $it" } ?: ""}")
            }
            H1({
                classes(styleBlock.pageTitle.mainHeaders)
                style {
                    position(Position.Absolute)
                    top(0.px)
                    left(0.px)
//                                    color(stylePalette.myOrange)
                    color(Color.white)
                    fontWeight(600)
                    fontFamily(styleConst.mainFontFamily)
                    textAlign("center")
                    myTextShadow(CSSTextShadow(3.px, 3.px, 2.px, rgba(0, 0, 0, 0.7)))
                }
            }) {
//                    Br()
                Text("${trainer.name ?: ""}${trainer.second_name?.let { " $it" } ?: ""}")
            }
        }
        H2({
            classes(styleBlock.pageTitle.mainHeaders)
            style {
//                                color(Color.black)
                color(stylePalette.myOrange)
                fontWeight(600)
                fontFamily(styleConst.mainFontFamily)
                textAlign("center")
                myTextShadow(CSSTextShadow(3.px, 3.px, 3.px, rgba(0, 0, 0, 0.7)))
//                                myTextShadow(CSSTextShadow())
                /*
                                                property(
                                                    "text-shadow",
                                                    "0 0 20px #fefcc9, 10px -10px 30px #feec85, -20px -20px 40px #ffae34, " +
                                                            "20px -40px 50px #ec760c, -20px -60px 60px #cd4606, " +
                                                            "0 -80px 70px #973716, 10px -90px 80px #451b0e, " +
                                                            "0 0 20px #fefcc9, 10px -10px 30px #feec85, -20px -20px 40px #ffae34, " +
                                                            "20px -40px 50px #ec760c, -20px -60px 60px #cd4606, " +
                                                            "0 -80px 70px #973716, 10px -90px 80px #451b0e"
                                                )
                */
            }
        }) {
            Text("Тренер-онлайн")
        }
    }
}
@Composable
private fun titleTrainer2(trainer: CommonUser) {
    CenterDiv({
        style {
            flexDirection(FlexDirection.Column)
            margin(96.px, 0.pt)
//            myTestBorder()
        }
    }) {
        Div({
            style {
                position(Position.Relative)
//                if (phoneDevice) marginTop(40.pt)
            }
        }) {
            H1({
                classes(styleBlock.pageTitle.mainHeaders)
                style {
                    color(Color.white)
                    fontWeight(600)
                    fontFamily(styleConst.mainFontFamily)
                    textAlign("center")
                    backgroundColor(stylePalette.myNewRed)
                    padding(2.px,10.px)
                }
            }) {
                Text("${trainer.name ?: ""}${trainer.second_name?.let { " $it" } ?: ""}")
            }

        }
        H2({
            classes(styleBlock.pageTitle.mainHeaders)
            style {
                marginTop(10.px)
                padding(2.px,10.px)
                backgroundColor(Color.white)
                color(stylePalette.myNewRed)
                fontWeight(700)
                fontFamily(styleConst.mainFontFamily)
                textAlign("center")
//                myTextShadow(CSSTextShadow(3.px, 3.px, 3.px, rgba(0, 0, 0, 0.7)))
            }
        }) {
            Text("Тренер-онлайн")
        }
    }
}

