package global

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import api.firebase.getCloseTrainings
import bdElement.BaseTraining
import bdElement.MyAnswerCommon
import elements.CenterDiv
import elements.FlexColumnDivParent
import elements.cardCloseTraining
import elements.mainLoader
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.paddingBottom
import org.jetbrains.compose.web.css.px

object OldTrainings {
    var trainingsLoaded by mutableStateOf<MyAnswerCommon<BaseTraining>?>(null)
        private set

    private var loadingProcess by mutableStateOf(true)

    val pagination = PageAdapter(12) { limit, skip, silent ->
        if (silent.not()) loadingProcess = true
        getCloseTrainings(limit, skip)
    }

    fun setTrainingLoaded(load: MyAnswerCommon<BaseTraining>) {
        trainingsLoaded = load
        load.totalCount?.let { pagination.totalCount = it }
        loadingProcess = false
    }

    @Composable
    fun getViewListTrainings() {
        if (loadingProcess) {
            CenterDiv({ style { marginTop(40.px) } }) { mainLoader() }
        } else {
            FlexColumnDivParent(rowGap = styleConst.paddingCommon, attrs = {style {
                paddingBottom(styleConst.paddingCommon)
            }}) {
                trainingsLoaded?.mutList?.let {
                    for (training in it) cardCloseTraining(training)
                }
            }
        }
        pagination.paginationElement()
    }

}
