package global

import androidx.compose.runtime.*
import api.firebase.CurrentUser
import bdElement.*
import elements.CenterDiv
import elements.EditAssignmentsList
import elements.FilterTagsBlock
import elements.ModalContent
import kotlinx.browser.window
import kotlinx.serialization.json.Json
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnitAbs
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Text
import style.ColorResWhite
import style.First.StyleClientRoom
import style.MyStyleBlock
import style.SMainConst
import style.White.*
import kotlin.js.Date

object myIconURL {
    val rightArrowRound = "https://FitConstructorImg.b-cdn.net/other/Right_round_white.svg"
    val leftArrowRound = "https://FitConstructorImg.b-cdn.net/other/Left_round_white.svg"
    val archiveBlue = "https://FitConstructorImg.b-cdn.net/other/FC-App_-20_archive_blue.svg"
    val archiveGray = "https://FitConstructorImg.b-cdn.net/other/FC-App_-23_archive_gray.svg"
}

enum class PageApplication() {
    UserProfile,
    TrainerRoom,
    Trainings,
    Registration,
    TitlePage,
    MyVideo,
    Sales,
    Verification,
    Helps
}

//external val myModal: dynamic

var canInstallApplication by mutableStateOf(false)
val versionApplicationStr = "v.1.0009s"


val myJson = Json { encodeDefaults = true }
//val mainFontFamily = "Roboto Slab"

val phoneDevice = window.innerWidth < 700

var selectableNone by mutableStateOf(false)

var modalContent by mutableStateOf<ModalContent?>(null)

fun openModalWindow(window: ModalContent) {
    modalContent = window
    visibleModalWindow.value = true
}

fun openModalWindow(buttOk: Boolean = false, closeBack: Boolean = false, fullscreen: Boolean = false, rezCloseFun: ()->Unit = {}, content: @Composable () -> Unit) {

    class tempModalWindow(): ModalContent() {
        override val closableBack = closeBack
        override fun closeFun() { rezCloseFun() }
        override val fullscreen = fullscreen

        @Composable
        override fun drawModalContent() {
            content()
            if (buttOk) CenterDiv{
                Button({
                    classes(StyleClientRoom.buttonDone)
                    style {
                        padding(8.px,30.px)
//                    width(100.px)
                    }
                    onClick {
                        rezCloseFun()
                        closeModadWindow()
                    }
                }) {
                    Text("Ok")
                }
            }
        }
    }
    modalContent = tempModalWindow()
    visibleModalWindow.value = true
}

//private val myFirstStyleBlock = MyStyleBlock(StyleCommon, StyleOpenTraining, StyleClientRoom, StyleTrainingRoom,StyleSideNav,StyleTitlePage)

private val myWhiteStyleBlock = MyStyleBlock(
    commonElement = StyleCommonElementWhite,
    openTraining = StyleOpenTrainingWhite,
    clientRoom = StyleClientRoomWhite,
    trainerRoom = StyleTrainerRoomWhite,
    navigation = StyleNavigationWhite,
    pageTitle = StylePageTitleWhite,
    userProfile = StyleUserProfileWhite
)


val styleBlock: MyStyleBlock = myWhiteStyleBlock

object stylePalette : ColorResWhite()
object styleConst : SMainConst()
//object stylePaletteObj{
//    val cl: SMainPalette = ColorResWhite
//}

fun closeModadWindow() {
    visibleModalWindow.value = false
    modalContent = null
}

fun getCurrentDateUTC(): Long = Date().let { Date(it.getFullYear(), it.getMonth(), it.getDate()).getTime() }.toLong() - Date().getTimezoneOffset() * 60000L
fun getCurrentDateTimeUTC(): Long = Date().getTime().toLong() - Date().getTimezoneOffset() * 60000L
val visibleModalWindow = mutableStateOf(false)

val statusLoading = StatusLoading()


var headerTop by mutableStateOf(0)
val headerHeight = 45
val widthSide = if (phoneDevice) window.innerWidth else 360
var widthSideNavPx: Int by mutableStateOf(if (phoneDevice) 0 else widthSide)

var freeGirlTrainings by mutableStateOf<ArrayList<BaseTraining>?>(null)
var freeBoyTrainings by mutableStateOf<ArrayList<BaseTraining>?>(null)


/** Списки тегов которые получаются из базы. По ним создаются массивы тегов во всех остальных элементах, которые их используют. */
var tags_equipment: MutableState<MyAnswerCommon<MyTags>?> = mutableStateOf(null)
var tags_muscle_group: MutableState<MyAnswerCommon<MyTags>?> = mutableStateOf(null)
var tags_sex: MutableState<MyAnswerCommon<MyTags>?> = mutableStateOf(null)

val filterVideo = TagsVideo()
val filterMyVideo = TagsVideo()

val filterBlock = FilterTagsBlock(filterVideo)
val filterBlockMyVideo = FilterTagsBlock(filterMyVideo)
val tagsBlock = FilterTagsBlock()

//var curentEditListAssignment by mutableStateOf<List<BaseAssignment>?>(null)

var dropDownElementNow by mutableStateOf("")
var onresizeClientComplexSlider: ()->Unit = {}

var selectedClient by mutableStateOf<BaseClient?>(null)
var selectedComplex by mutableStateOf<BaseComplex?>(null)
var copyTrainingsBuffer by mutableStateOf<List<BaseTraining>>(listOf())

var selectedTrainingForEdit by mutableStateOf<BaseTraining?>(null)
    private set
var editListAssignment = EditAssignmentsList()
fun setSelectedTrainingForEdit(training: BaseTraining?){
    selectedTrainingForEdit = training
    selectedTrainingForEdit?.let { train ->
        train.assignments.let { editListAssignment.setListAssignment(it) }
    }
}

var selectedOpenTraining by mutableStateOf<BaseTraining?>(null)
var selectedFreeTraining = mutableStateOf<BaseTraining?>(null)
//var selectedFreeTraining by mutableStateOf<BaseTraining?>(null)
var saveScrollState by mutableStateOf<Double?>(null)

var currentPage by mutableStateOf(PageApplication.TitlePage)

val currentUser = CurrentUser()

val tempAvatarUrl = "https://FitConstructorImg.b-cdn.net/avatar/82d5cb6e-ce2b-4080-8d11-94af81410b81.png"
val tempComplexCoverUrl = "https://FitConstructorImg.b-cdn.net/other/gantelya.jpg"



