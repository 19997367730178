package api.bunny

import androidx.compose.runtime.MutableState
import kotlinx.browser.document
import mainPage.axiosUploadWithProgress
import org.w3c.dom.CanvasRenderingContext2D
import org.w3c.dom.HTMLCanvasElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.Image
import org.w3c.files.FileReader
import org.w3c.files.get
import org.w3c.xhr.FormData

fun uploadImage(
    fileInput: HTMLInputElement,
    fileName: String,
    maxWidth: Int,
    maxHeight: Int,
    pathBackend: String,
    uploadImgStart: MutableState<Boolean>,
    uploadImgProgress: (Double)->Unit,
    funcRez: (String)->Unit
) {
    val file = fileInput.files?.get(0) ?: return

    val reader = FileReader()

    reader.onloadend = {
        val img = Image()

        img.onload = {
            val canvas = document.createElement("canvas") as HTMLCanvasElement
            val width = img.width;
            val height = img.height;
            val shouldResize = (width > maxWidth) || (height > maxHeight);

            var newWidth = 0
            var newHeight = 0

            if (!shouldResize) {
                newWidth = width
                newHeight = height
            } else {
                if (width > height) {
                    newHeight = (height * (maxWidth.toDouble() / width.toDouble())).toInt()
                    newWidth = maxWidth;
                } else {
                    newWidth = (width * (maxHeight.toDouble() / height.toDouble())).toInt()
                    newHeight = maxHeight;
                }
            }
            canvas.width = newWidth
            canvas.height = newHeight
            val ctx = canvas.getContext("2d") as CanvasRenderingContext2D
            ctx.drawImage(img, 0.0, 0.0, newWidth.toDouble(), newHeight.toDouble())

            canvas.toBlob(
                { blob ->
//                        println("blob canvas: $blob")
                    blob?.let {
                        val formData = FormData()

                        val extension: String = file.name.substring(file.name.lastIndexOf(".") + 1)
                        val fileNameF = "$fileName.$extension"

                        formData.append("fileName", fileNameF)
                        formData.append("file", blob)

                        uploadImgStart.value = true
                        axiosUploadWithProgress(
                            url = pathBackend,
                            formData = formData,
                            progressFun = { progress ->
                                console.log("${(progress * 100).toInt()}%")
                                uploadImgProgress (progress)
                            },
                            thenFun = { code ->
//                                console.log("status upload image code: $code")
                                funcRez(fileNameF)
                                uploadImgStart.value = false
                            }
                        )
                    }
                }
            )
        }

        img.src = it.target.asDynamic().result as String
        null
    }

    reader.readAsDataURL(file)
}
