package mainPage.modalWindows

import androidx.compose.runtime.Composable
import elements.CenterDiv
import elements.ModalContent
import global.closeModadWindow
import global.openModalWindow
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.H3
import org.jetbrains.compose.web.dom.Text
import style.First.StyleClientRoom

class ModalShowMessage(val textMessage: String, closeBack: Boolean = false, val rezFun: ()->Unit = {}) : ModalContent() {

    override val closableBack = closeBack
    override fun closeFun() { rezFun() }

    @Composable
    override fun drawModalContent() {
        H3({style { marginBottom(10.px) }}) {
            Text(textMessage)
        }
        CenterDiv{
            Button({
                classes(StyleClientRoom.buttonDone)
                style {
                    padding(8.px,30.px)
//                    width(100.px)
                }
                onClick {
                    rezFun()
                    closeModadWindow()
                }
            }) {
                Text("Ok")
            }

        }

    }
}

fun showMessage(message: String, closeBack: Boolean = false, rezFun: ()->Unit = {}) {
    openModalWindow(ModalShowMessage(message,closeBack,rezFun))
}