package style

import global.stylePalette
import org.jetbrains.compose.web.css.*

object AppStylesheet : StyleSheet() {

    init {


        "html" style  {
            overflowX ("hidden")
//            overflow("hidden")
        }
        "body" style  {
            backgroundColor(stylePalette.bodyColor)//Color("#323236"))//stylePalette.bodyColor)// stylePalette.bodyColor)
//            overflow("hidden")
        }
        "label, a, button" style {
//            property(
//                "font-family",
//                "system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto Slab,Roboto,Oxygen,Ubuntu,Cantarell,Droid Sans,Helvetica Neue,Arial,sans-serif"
//            )
        }

        universal style {
//            AppCSSVariables.wtColorGreyLight(Color("#f4f4f4"))
//            AppCSSVariables.wtColorGreyDark(Color("#323236"))
            margin(0.px)
        }
    }
}
