package elements

import androidx.compose.runtime.Composable
import global.QuickStyleProperty.borderBox
import global.QuickStyleProperty.clipCutCorners
import global.QuickStyleProperty.clipCutCornersInBorder
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement

@Composable
fun CutBorderBox(
    cutSizePx: Int,
    borderWidthPx: Int,
    background: String = "linear-gradient(45deg,red,#ff000058,transparent)",
    lineHeightZero: Boolean = true,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    content: @Composable () -> Unit
) {
    Div({
        style {
//            position(Position.Relative)
            padding(borderWidthPx.px)
            background(background)
            clipCutCorners(cutSizePx)
            borderBox()
        }
        attrs?.invoke(this)
    }) {
        Div({
            style {
                borderBox()
//            backgroundColor(Color.yellow)
//            margin(0.px)
//            padding(0.px)
                if (lineHeightZero) lineHeight("0")
                clipCutCornersInBorder(cutSizePx, borderWidthPx)
            }
        }) {
            content()
        }
    }
}