package style.White

import global.CSSShadow
import global.QuickStyleProperty.borderBox
import global.QuickStyleProperty.myBackgroundGradient
import global.QuickStyleProperty.myBoxShadow
import global.styleConst
import global.stylePalette
import org.jetbrains.compose.web.css.*
import style.AppStylesheet
import style.SOpenTraining


object StyleOpenTrainingWhite: SOpenTraining, StyleSheet(AppStylesheet) {
    override val nameTraining by style {
        padding(5.px)
        width(100.percent)
        textAlign("center")
        color(Color.darkgray)
        fontFamily(styleConst.mainFontFamily)
        fontSize(23.px)
    }
    override val descriptionTraining by style {
        fontFamily(styleConst.mainFontFamily)
        padding(10.px)
    }

    override val nameAssignment by style {
        fontFamily(styleConst.mainFontFamily)
        paddingBottom(5.px)
        color(stylePalette.myGreen)
    }
    override val descriptionAssignment by style {
        fontFamily(styleConst.mainFontFamily)
        paddingBottom(10.px)
    }
    override val delimiter by style {
        width(100.percent)
        height(1.px)
        backgroundColor(Color.darkgray)
        margin(8.pt,0.pt)
        borderBox()
    }
    override val itemAssignment by style {
    }

    override val buttonDone by style {
        flex("initial")
        fontFamily(styleConst.mainFontFamily)
        fontWeight(700)
        fontSize(15.pt)
        borderRadius(10.px)
        myBackgroundGradient(rgb(236,156,24),rgb(237,123,26))
        color(Color.white)
        border(0.px, LineStyle.Solid, Color.transparent)
        hover(self) style {
            myBackgroundGradient(rgb(216,136,20),rgb(217,103,18))
        }
    }


}