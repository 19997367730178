package elements

import androidx.compose.runtime.Composable
import global.QuickStyleProperty.zIndex
import global.dropDownElementNow
import kotlinx.browser.document
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement
import style.First.StyleCommon

@Composable
fun DropDown(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    button: @Composable () -> Unit,
    attrsContent: AttrBuilderContext<HTMLDivElement> = {
        classes(StyleCommon.dropDownContentBoxDecor, StyleCommon.dropDownContentBox)
    },
    right: Boolean = false,
    elementId: String = "",
    marginSide: CSSNumeric = 0.px,
    content: @Composable () -> Unit
) {
    val id = getUUID()
    Div({
        style {
//            zIndex(999)
            display(DisplayStyle.InlineBlock)
            position(Position.Relative)
        }
    }) {
        Div({
            id(id)
            classes("dropbtn")
            onClick {
                val elem = document.getElementById("content-$id")
//                console.log("elem: $elem")
                if (elem?.classList?.contains(StyleCommon.show) == true) {
//                    console.log("elem: false")
                    dropDownElementNow = ""
                    elem.classList.remove(StyleCommon.show);
                    elem.classList.add(StyleCommon.noShow);
                } else {
//                    console.log("elem: true")
                    dropDownElementNow = elementId
                    elem?.classList?.add(StyleCommon.show);
                    elem?.classList?.remove(StyleCommon.noShow);
                }
//                console.log("click bars")
//                console.log("click bars $dropDownElementNow")
//                openDropDown = openDropDown.not()
            }
            attrs?.invoke(this)
        }) {
            Div({
                style {
                    property("pointer-events", "none")
                    display(DisplayStyle.Flex)
                    alignItems(AlignItems.Center)
                    justifyContent(JustifyContent.Center)
                }
            }) {
                button()
            }
        }
        Div({
            id("content-$id")
            classes(
                "dropdown-content",
                StyleCommon.noShow
            ) //if (openDropDown) StyleCommon.show else StyleCommon.noShow,
            style {
//                display(DisplayStyle.None)
                position(Position.Absolute)
                overflow("auto")
                if (right) right(0.px)
                if (right) marginRight(marginSide) else marginLeft(marginSide)
                property("z-index", "699")
            }
            attrsContent()
        }) {
            Div({
                style {
                    position(Position.Relative)
                    zIndex(799)
                }
            }){
                content()
            }
        }
    }
}