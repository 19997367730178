package global

import androidx.compose.runtime.*
import api.firebase.getTrainerProfileFromEmailOrId
import bdElement.BaseClient
import kotlinx.browser.window
import org.jetbrains.compose.web.css.px

external fun decodeURI(str: String): String
external fun decodeURIComponent(str: String): String

class StatusLoading {

    private var firstRequestCurrentUser by mutableStateOf(false)

    /** Считывает из адресной строки параметры еслит таковые есть. **/

    val params1 = window.location.search.replace("?", "")
    val params = window.location.search.replace("?", "")
        .split("&")
        .associate { decodeURIComponent(it.substringBefore("=")) to decodeURIComponent(it.substringAfter("=")) }

    val emailInHeader = params["et"] ?: ""// window.location.search.replace("?et=", "")
    val idTrainerInHeader = params["idt"] ?: ""// window.location.search.replace("?et=", "")

    /** Указывает был ли параметр в адресной строке из которого надо проверить наличие заявки */
    val haveZayavka = emailInHeader != "" || idTrainerInHeader != ""

    /** Ключевая переменная которая указывает на то, была ли осуществлена проверка заявки если таковая имелась */
    var checkZayavka by mutableStateOf(haveZayavka.not())

    fun checkTitleTrainer() = statusLoading.trainerProfile != null || currentUser.userProfile?.role?.contains("trainer") == true

    /** После того как пользователь залогинился или зарегистрировался нужно понять нужно ли задавать ему вопрос о добавлении тренера,
     * в случае если заявка уже отправлялась или пользователь уже ответил на вопрос, повторно его задавать уже не нужно. */
    var haveQuestionZayavka by mutableStateOf(haveZayavka)

    /** Указывает был ли получен с сервера список тренеров привязанных к клиенту */
    var loadedTrainersList by mutableStateOf(false)

    /** Указывает произошел поиск профиля тренера по параметру из адресной стоки или нет. */
    private var testedTrainerProfile by mutableStateOf(emailInHeader == "" && idTrainerInHeader == "")

    var trainerProfile by mutableStateOf<BaseClient?>(null)
    var complex_trainerProfile by mutableStateOf<String?>(null)
    var new_client_trainerProfile by mutableStateOf<String?>(null)

    /** Отвечает за отображение лоадера при открытии списка тренировок конкретного клиента */
    var loadingTraining by mutableStateOf(true)

    /** Определяет загрузилось ли все необходимое чтобы можно было скрыть лоадер и скомпоновать первый экран */
    var startComplete by mutableStateOf(false)


    @Composable
    fun listenerForWritingCookies() {
        LaunchedEffect(widthSideNavPx) {
            currentUser.userProfile?.id?.let { id ->
                setCookie("${id}_widthSideNavPx", widthSideNavPx.toString())
            }
        }
        LaunchedEffect(currentPage) {
            currentUser.userProfile?.id?.let { id ->
                setCookie("${id}_currentPage", currentPage.name)
            }
        }
        OpenTrainings.listenerForWritingCookies()
        ListClients.listenerForWritingCookiesAndFilter()
        ListComplexes.listenerForWritingCookies()
        ClientTrainings.listenerForWritingCookies()
        ComplexTrainings.listenerForWritingCookies()
    }


    fun getCookiesOnStart(){
        currentUser.userProfile?.id?.let { id_user->
            PageApplication.values().filter { it.name ==  getCookie("${id_user}_currentPage")}.firstOrNull()?.let {
                currentPage = it
            }
            getCookie("${id_user}_widthSideNavPx")?.let {w ->
                if (w.isNotEmpty()) w.toDoubleOrNull()?.let {
                    if(it.toInt() == widthSide || it == 0.0) widthSideNavPx = it.toInt()
                }
            }
        }
    }

    private fun testStartComplete(funChange: () -> Unit) {
        funChange()
        startComplete =
            firstRequestCurrentUser && (haveZayavka.not() || (testedTrainerProfile && loadedTrainersList) || checkZayavka)
    }


    fun loadTrainingProfile() {
        console.log("idTrainerInHeader = $idTrainerInHeader")
        getTrainerProfileFromEmailOrId(emailInHeader, idTrainerInHeader, falseFun = {
            testStartComplete {
                testedTrainerProfile = true
                checkZayavka = true
//                window.location.search = ""
            }
        }) { trPr ->
            testStartComplete {
//                console.log("getTrainerProfileFromEmail")
                /*
                                it?.id?.let { idTrainer ->
                                    currentUser.userProfile?.id?.let { client_id ->
                                        console.log("getTrainerProfileFromEmail  22")
                                        addNewClient(clientId = client_id, idTrainer = idTrainer)
                                    }
                                }
                */
                trainerProfile = trPr
                trPr?.id?.let { id_trainer ->
                    getCookie("new_client_for_${id_trainer}")?.let {
                        new_client_trainerProfile = it
                        deleteCookie("new_client_for_${id_trainer}")
                    }
                    getCookie("complex_${id_trainer}")?.let {
                        complex_trainerProfile = it
                        deleteCookie("complex_${id_trainer}")
                    }
                }
                currentUser.userProfile?.let {
                    //todo здесь надо бы сделать не проверку по списку, а запрос на сервер, на случай если тренеров будет больше, чем загрузится в запросе.
                    if (currentUser.getEmailVerified() == true) {
                        if (ListTrainers.trainersLoaded?.rows?.filter { it.status == "open" }?.map { it.id }
                                ?.contains(trPr?.id) == true && haveQuestionZayavka) {
//                        showMessage("Тренеру ${trPr?.name ?: ""} уже была отправлена заявка.") {
                            checkZayavka = true
                            haveQuestionZayavka = false
//                            window.location.search = ""
//                        }
                        } else {
                            loadedTrainersList = true
                        }
                    }   else   loadedTrainersList = true
                } ?: run {
                    loadedTrainersList = true
                }
                testedTrainerProfile = true
            }
        }
    }


    private fun checkTrainerConnect() {
        currentUser.userProfile?.let { id_client ->
            trainerProfile?.let { id_trainer ->
                if (loadedTrainersList) ListTrainers.trainersLoaded?.rows?.let {
                    if (it.contains(trainerProfile)) {
                    }
                }
            }
        }
    }

    fun finishLoadSpisTrainersFirebase() = testStartComplete {
        loadedTrainersList = true
        if (emailInHeader != "" || idTrainerInHeader != "") loadTrainingProfile()// ListTrainers.trainersLoaded?.rows?.contains(trainerProfile))
    }

    fun startNoUserFirebase() = testStartComplete {
        loadedTrainersList = true
        firstRequestCurrentUser = true
        if (emailInHeader != "" || idTrainerInHeader != "") loadTrainingProfile()// ListTrainers.trainersLoaded?.rows?.contains(trainerProfile))
    }

    fun startFirebase() = testStartComplete {
        getCookiesOnStart()
        firstRequestCurrentUser = true
        ListTrainers.pagination.update()
        ListRequestsComplexNewForClient.pagination.update()
        ListRequestsComplexCloseForClient.pagination.update()
    }

    //    fun getCheckZayavka(): MutableState<Boolean> = checkZayavka
    fun checkZayavkaDone() = testStartComplete {
        checkZayavka = true
    }

}