package global

import androidx.compose.runtime.*
import api.CustomMyListResponse
import api.commonPostJsonRequest
import api.parseMyListResponse
import bdElement.BaseComplex
import bdElement.CreatiumRequest
import bdElement.MyAnswerCommon
import elements.*
import global.QuickStyleProperty.borderBox
import global.QuickStyleProperty.myBoxShadow
import global.QuickStyleProperty.myTextNonSelect
import global.QuickStyleProperty.zIndex
import kotlinx.serialization.encodeToString
import mainPage.modalWindows.ModalComplexInfo
import mainPage.modalWindows.ModalRequestNewClient
import org.jetbrains.compose.web.attributes.Draggable
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import style.First.StyleCommon
import style.First.StyleTrainingRoom
import style.White.StyleCommonElementWhite
import style.common.StyleCustomProperties
import style.common.StylePageTitleCommon

object ListComplexesForSale {
    var complexesLoaded by mutableStateOf<MyAnswerCommon<BaseComplex>?>(null)
        private set

    var slider by mutableStateOf<SliderPanel?>(null)

    private fun setComplexesLoaded(load: CustomMyListResponse<BaseComplex>) {
        complexesLoaded = load.getMyAnswerCommon()
        load.totalCount?.let { pagination.totalCount = it }
        complexesLoaded?.mutList?.map { complex ->
            MyTabs(complex.name ?: "") { selected ->
                tabComplexElement(complex, selected)
            }
        }?.let {
            slider = SliderPanel(it)
        }
//        getFromCookie()
    }

    val pagination = PageAdapter(12) { limit, skip, silent ->
        if (silent.not()) loadingProcess = true
        (statusLoading.trainerProfile ?: currentUser.userProfile?.let { profile ->
            if (profile.role?.contains("trainer") == true) profile else null
        })?.id?.let { id_trainer ->
            commonPostJsonRequest(
                path = "spis_complexes_for_sale",
                body = myJson.encodeToString(CreatiumRequest(limit = limit, skip = skip)),
                headers = { headersIn ->
                    headersIn.append("trainerId", id_trainer)
                }) { userStr ->
                parseMyListResponse<BaseComplex>(userStr).checkStatusOK {
                    setComplexesLoaded(it)
                    loadingProcess = false
                }
            }
        }

    }
    private var loadingProcess by mutableStateOf(false)

    private var firstStart: Boolean = true

    @Composable
    fun listenerForWritingCookies() {
//        LaunchedEffect(selectedClient?.selectedTraining) {
//            currentUser.userProfile?.id?.let { id_user ->
//                selectedClient?.selectedTraining?.id?.let { id_training ->
//                    setCookie("${id_user}_selectedClient_selTraining", id_training)
//                } ?: run {
//                    deleteCookie("${id_user}_selectedClient_selTraining")
//                }
//            }
//        }
    }

    private fun getFromCookie() {
//        if (firstStart){
//            currentUser.userProfile?.id?.let { id_user ->
//                getCookie("${id_user}_selectedClient_selTraining")?.let {id_training ->
//                    if (id_training.isNotEmpty()){
//                        complexesLoaded?.rows?.find{ it.id == id_training}?.let {
//                            selectedClient?.setSelectedTraining(it)
//                        }
//                    }
//                }
//            }
//            firstStart = false
//        }
    }


    @Composable
    fun getViewListComplexes() {
        if (loadingProcess) {
            CenterDiv({ style { marginTop(40.px) } }) { mainLoader() }
        } else {
            complexesLoaded?.mutList?.let {
                FlexColumnDivParent(rowGap = 0.px) {
                    it.mapIndexed { index, baseComplex -> Pair(index, baseComplex) }.forEach {
                        if (it.first != 0) Div({ classes(styleBlock.trainerRoom.delimiterCardClient) })
                        CardComplex(it.second) {
                            selectedComplex = it.second
                        }
                    }
                }
            }
        }
        pagination.paginationElement()
    }

    @Composable
    fun getViewListComplexesForSelect(onClick: (BaseComplex) -> Unit) {
        if (loadingProcess) {
            CenterDiv({ style { marginTop(40.px) } }) { mainLoader() }
        } else {
            complexesLoaded?.mutList?.let {
                FlexColumnDivParent(rowGap = 0.px) {
                    it.mapIndexed { index, baseComplex -> Pair(index, baseComplex) }.forEach {
                        if (it.first != 0) Div({ classes(styleBlock.trainerRoom.delimiterCardClient) })
                        CardComplex(it.second, true) {
                            onClick(it.second)
                        }
                    }
                }
            }
        }
        pagination.paginationElement()
    }

    @Composable
    private fun tabComplexElement(complex: BaseComplex, selected: Boolean) {
        CenterDiv({
            style {
                flexDirection(FlexDirection.Column)
                overflowX("hidden")
            }
        }) {
            CenterDiv({
                style {
                    position(Position.Relative)
                }
            }) {
/*
                CutBorderBox(15, 2, attrs = {
                    style {
//                        flex("initial")
                    }
                    onClick {
//                        onClick()
                    }
                }) {
*/
                Div({
                    style {
                        margin(15.px)
                        myBoxShadow(
                            CSSShadow(
                                dx = 4.px,
                                dy = 4.px,
                                blur = 7.pt, //if (phoneDevice) 15.px else
                                spread = 3.pt,
                                color = rgba(0, 0, 0, 0.2),
//                            inner = true
                            )
                        )
                        borderRadius(15.px)
                        borderBox()
                        border(1.px, LineStyle.Solid, rgba(180, 180, 180, 0.3))
                        overflow("hidden")
                        lineHeight("0")

                    }
                    onClick {
                        if (selected) openModalWindow(ModalComplexInfo(complex.id ?: "") {})
                    }
                }) {
                    Img(src = complex.cover ?: tempComplexCoverUrl) {
                        classes(styleBlock.trainerRoom.ccCoverComplex)
                        draggable(Draggable.False)
                        style {
                            width(210.px)
                            height(280.px)
                            property("pointer-events", "none")
                        }
                    }
                }
            }
        }

    }

    @Composable
    fun getViewSlider(onClick: (BaseComplex) -> Unit) {
        if (loadingProcess) {
            CenterDiv({ style { marginBottom(96.px) } }) { mainLoader() }
        } else {
            if ((complexesLoaded?.mutList?.count() ?: 0) > 0) {
                CenterDiv {
                    H1({
                        classes(StyleCommonElementWhite.headerBlock)
                        style {
                            backgroundColor(rgba(255, 255, 255, 0.9))
                            color(stylePalette.myNewRed)
                            padding(2.px, 10.px)
                            borderRadius(15.px)
                            fontSize(30.px)
                            maxWidth(820.px)
//                        marginTop(60.pt)
                            marginBottom(20.pt)
                        }
                    }) {
                        Text("Готовые комплексы тренировок")
                    }
                }
                slider?.let { sli ->
                    CenterDiv({
                        style {
                            marginBottom(15.pt)
                            maxWidth(100.percent)
                            justifyContent(JustifyContent.SpaceBetween)
                            overflow("hidden")
                        }
                    }) {
                        CenterDiv({
                            style {
                                flex("initial")
                                fontSize(20.pt)
                                minWidth(30.pt)
                                zIndex(10)
//                                height(280.px)
                                color(Color.red)
//                            background("linear-gradient(90deg,#999,transparent)")
//                            borderRadius(0.px, 100.px, 100.px, 0.px)
                            }
                            onClick {
                                slider?.prev()
                            }
                        }) {
                            Img(src = myIconURL.leftArrowRound) {
//                            Img(src = "svgIcon/Left_round_white.svg") {
                                classes(styleBlock.trainerRoom.ccCoverComplex)
                                style {
                                    width(50.px)
                                    height(50.px)
                                    opacity(if (sli.slideIndex > 0) 1.0 else 0.0)
                                }
                            }
//                        I({
//                            classes(
//                                "fa", "fa-chevron-left",
//                                StyleTrainingRoom.chevron,
//                            )
//                        })
                        }

                        Div({
                            style {
                                maxWidth(250.px)
//                    overflowX("hidden")
                            }
                        }) {
                            sli.sliderPanel()
                        }
                        CenterDiv({
                            style {
                                flex("initial")
                                fontSize(20.pt)
                                minWidth(30.pt)
                                zIndex(10)
//                                height(280.px)
                                color(Color.white)
//                            background("linear-gradient(270deg,#999,transparent)")
//                            borderRadius(100.px, 0.px, 0.px, 100.px)
                            }
                            onClick {
                                sli.next()
                            }
                        }) {
                            Img(src = myIconURL.rightArrowRound) {
//                            Img(src = "svgIcon/Right_round_white.svg") {
                                classes(styleBlock.trainerRoom.ccCoverComplex)
                                style {
                                    width(50.px)
                                    height(50.px)
                                    opacity(if (sli.slideIndex < sli.tabs.count() - 1) 1.0 else 0.0)
                                }
                            }
                            /*
                                                    I({
                                                        classes(
                                                            "fa", "fa-chevron-right",
                                                            StyleTrainingRoom.chevron,
                                                        )
                                                    })
                            */
                        }
                    }
                    complexesLoaded?.mutList?.get(sli.slideIndex)?.let { complex ->
                        CenterDiv({style {
                            if (phoneDevice) marginBottom(84.px)
                        }}) {
                            CenterDiv({
                                style {
                                    opacity(if (sli.moveNowX.not()) 1.0 else 0)
                                    flexDirection(FlexDirection.Column)
                                    maxWidth(410.px)
                                    borderRadius(15.px)
                                    backgroundColor(rgba(255, 255, 255, 0.8))
                                    padding(10.px, 20.px)
                                    width(100.percent)
                                }
                            }) {
                                H2({
                                    classes(StyleCommon.headerBlock)
                                    style {
                                        flex("auto")
//                                        backgroundColor(Color.white)
                                        textAlign("left")
                                        color(Color.black)
                                        padding(2.px, 10.px)
                                        width(100.percent)
                                    }
                                }) {
                                    Text(complex.name ?: "")
                                }
                                H3({
                                    classes(StyleCommon.headerBlock)
                                    style {
                                        textAlign("left")
                                        fontSize(17.px)
                                        color(stylePalette.myNewRed)
                                        width(100.percent)
                                    }
                                }) {
                                    Text("Цена: ${(complex.price ?: 0.0).let { if (it > 0) it else "Бесплатно!" }}")
                                }
                                Div({
                                    classes(StylePageTitleCommon.textShadowHover)
                                    style {
                                        marginTop(5.px)
                                        fontWeight(600)
                                        borderBox()
                                        textAlign("center")
                                        fontSize(20.px)
                                        backgroundColor(stylePalette.myGreen)
                                        color(Color.white)
                                        padding(4.pt, 10.pt)
                                        lineHeight("1")
                                        borderRadius(15.px)
                                        myTextNonSelect()
/*
                                        myBoxShadow(
                                            CSSShadow(
                                                dx = if (phoneDevice) 4.px else 6.px,
                                                dy = if (phoneDevice) 4.px else 6.px,
                                                blur = 10.pt, //if (phoneDevice) 15.px else
                                                spread = 5.pt,
                                                color = rgba(0, 0, 0, 0.15),
//                            inner = true
                                            )
                                        )
*/
                                    }
                                    onClick {
                                        openModalWindow(ModalComplexInfo(complex.id ?: "") {})
                                    }
                                }) {
                                    Text("Подробнее")
                                }
/*
                                buttonIcon(listOf("fa-solid", "fa-magnifying-glass"), {
                                    classes(styleBlock.trainerRoom.buttNewTraining, StyleCustomProperties.allAnimation)
                                    style {
                                        backgroundColor(stylePalette.myGreen)
                                    }
                                }, fontSize = 25.pt) {
                                    openModalWindow(ModalComplexInfo(complex.id ?: "") {})
                                }
*/
                            }
                        }
                    }
                }
            }
        }
        LaunchedEffect(Unit, statusLoading.trainerProfile, currentUser.userProfile) {
            pagination.update(true)
        }
//        pagination.paginationElement()
    }

}