package api.firebase

import api.commonUserPostFDRequest
import api.commonUserPostJsonRequest
import api.parseMyListResponse
import api.parseMyResponse
import bdElement.BaseVideo
import bdElement.MyAnswerCommon
import bdElement.CreatiumRequest
import elements.findInCommon
import elements.findInMy
import elements.findWithoutTags
import global.*
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json



fun setNewTagsVideoFirestore(
    tagsVideo: TagsVideo,
    baseVideo: BaseVideo,
    rezFun: ()->Unit = {}
) {
    requestFromTrainer {
        console.log("baseVideo.id: ${baseVideo.id}")
        commonUserPostFDRequest(
            path = "set_new_tags_video",
            formData = { formData ->
                formData.append("tagsVideo", myJson.encodeToString(tagsVideo))
                formData.append("videoId", baseVideo.id ?: "")
            },
        ) { userStr ->
            rezFun()
        }
    }
}

fun getFilterBaseVideoFromFirestore(
    sex: List<String>? = null,
    equipment: List<String>? = null,
    muscle_group: List<String>? = null,
    limit: Int = 10, skip: Int = 0
) {
    currentUser.userProfile?.let { profile ->
        commonUserPostFDRequest(
            path = "get_filter_base_video",
            formData = { formData ->
                formData.append("commonVideo", findInCommon.value.toString())
                formData.append("myVideo", findInMy.value.toString())
                formData.append("sex", sex.toString().let { it.substring(1, it.length - 1) })
                formData.append("equipment", equipment.toString().let { it.substring(1, it.length - 1) })
                formData.append("muscle_group", muscle_group.toString().let { it.substring(1, it.length - 1) })
                formData.append("creatiumRequest", myJson.encodeToString(CreatiumRequest(limit = limit, skip = skip)))
            },
        ) { userStr ->
            parseMyListResponse<BaseVideo>(userStr).checkStatusOK { resp ->
                resp.getMyAnswerCommon()?.let {
                    ListVideoForAdd.setVideosLoaded(it)
                }
            }
        }
    }
}

fun getFilterMyVideoFromFirestore(
    sex: List<String>? = null,
    equipment: List<String>? = null,
    muscle_group: List<String>? = null,
    limit: Int = 10, skip: Int = 0
) {
    currentUser.userProfile?.let { profile ->
        commonUserPostFDRequest(
            path = "get_filter_my_video",
            formData = { formData ->
                formData.append("user_id", profile.id ?: "-1")
                formData.append("withoutTags", findWithoutTags.value.toString())
                formData.append("sex", sex.toString().let { it.substring(1, it.length - 1) })
                formData.append("equipment", equipment.toString().let { it.substring(1, it.length - 1) })
                formData.append("muscle_group", muscle_group.toString().let { it.substring(1, it.length - 1) })
                formData.append("creatiumRequest", myJson.encodeToString(CreatiumRequest(limit = limit, skip = skip)))
            },
        ) { userStr ->
            parseMyListResponse<BaseVideo>(userStr).checkStatusOK { resp ->
                resp.getMyAnswerCommon()?.let {
                    ListMyVideos.setVideosLoaded(it)
                }
            }
        }
    }
}

fun deleteMyVideoFromFirestore(
    video: BaseVideo,
    funcRez: () -> Unit
) {
    commonUserPostFDRequest(
        path = "delete_my_video",
        formData = { formData ->
            formData.append("videoId", video.id ?: "")
            formData.append("videoLibrary", video.bunny_library)
            formData.append("videoGUID", video.bunny_guid)
        },
    ) { userStr ->
        val response = parseMyResponse<String>(userStr)
        response.status?.let { status ->
            if (status == "OK") {
                funcRez()
            }
        }
    }
}

