package style

class MyStyleBlock(
    val commonElement: SCommonElement,
    val openTraining: SOpenTraining,
    val clientRoom: SClientRoom,
    val trainerRoom: STrainerRoom,
    val navigation: SNavigation,
    val pageTitle: STitlePage,
    val userProfile: SUserProfile
)

interface SOpenTraining {
    val nameTraining: String
    val descriptionTraining: String
    val nameAssignment: String
    val descriptionAssignment: String
    val delimiter: String
    val itemAssignment: String
    val buttonDone: String
}

interface STrainerRoom {
    val itemCardClient: String
    val itemCardClientNew: String
    val delimiterCardClient: String
    val ccAvatar: String
    val ccCoverComplex: String
    val ccName: String
    val ccContact: String

    val itemCardTraining: String
    val ctIconButton: String
    val ctIconCopyButton: String
    val ctMarkerOut: String
    val ctMarkerIn: String
    val ctName: String
    val ctTime: String
    val ctBoxMuscleGroup: String
    val ctBoxDescription: String
    val itemVideoPreview: String

    val editDragBox: String
    val editItemAssignment: String
    val editImgAssignment: String
    val editNameAssignment: String
    val editIconButton: String

    val bvCard: String
    val bvImage: String
    val bvIconButton: String
    val bvButtonPanel: String

    val filterPlate: String
    val filterNameText: String
    val filterSelectedText: String
    val filterIconStart: String

    val fmHeaders: String
    val fmButton: String
    val fmItemText: String
    val fmItemBox_Out: String
    val fmItemBox_In: String

    val buttNewTraining: String
    val buttPasteTrainings: String
    val buttResetPasteTrainings: String
    val buttAccept: String
    val buttTransparentBack: String
}

interface SClientRoom {

    val itemCardTraining: String
    val ctIconButton: String
    val ctIconDoneButton: String
    val ctMarkerOut: String
    val ctMarkerIn: String
    val ctName: String
    val ctTime: String
    val ctBoxMuscleGroup: String
    val buttonStart: String

}

interface SCommonElement {
    val buttonBack: String
    val headerBlock: String
    val pagination: String
    val paginationPoint: String
    val paginationActiv: String

    val inputText: String
    val inputTextHeaders: String
    val rtIconButton: String
    val rtMainBlock: String
    val rtView: String
    val smallTextInfo: String
}

interface SNavigation {
    val headerStyle: String
    val buttEnter: String
    val closebtn: String
    val buttonSideNav: String
    val textUserContact: String
    val textUserProfile: String
    val avatarUserProfile: String
    val lineMenuButton: String
    val sideNav: String
    val title: String
}

interface SUserProfile {
    val avatarImg: String
    val buttonChangeAvatar: String
    val textProperties: String
    val buttonIcon: String
    val delimiter: String
}

interface STitlePage {
    val buttonComplex: String
    val buttonComplexGirl: String
    val trainingButt: String
    val mainHeaders: String
    val textInHeaders: String
    val mainText: String
    val stapsHeaders: String
    val checkHeader_1: String
    val checkHeader_2: String
    val checkHeader_3: String
    val trainingHeader: String
}

