package style.White

import global.*
import global.QuickStyleProperty.aspectRatio
import global.QuickStyleProperty.borderBox
import global.QuickStyleProperty.defaultShadow
import global.QuickStyleProperty.fontCommon
import global.QuickStyleProperty.myBoxShadow
import global.QuickStyleProperty.myTextShadow
import global.QuickStyleProperty.overflowHidden
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*
import style.AppStylesheet
import style.STrainerRoom

object StyleTrainerRoomWhite : STrainerRoom, StyleSheet(AppStylesheet) {
    override val itemCardClient by style {
        padding(5.px, 0.px)
    }
    override val itemCardClientNew by style {
        border(2.px, LineStyle.Solid, stylePalette.myOrange)
        borderRadius(15.pt)
        padding(8.px)
    }
    override val delimiterCardClient by style {
        width(100.percent)
        height(1.px)
        backgroundColor(Color.lightgray)
        margin(2.pt, 0.pt)
        borderBox()
    }
    override val ccAvatar by style {
        property("aspect-ratio", "1")
        property("object-fit", "cover")
        borderRadius(50.percent)
        width(60.pt)
    }
    override val ccCoverComplex by style {
//        property("aspect-ratio", "0.75")
        property("object-fit", "cover")
//        borderRadius(50.percent)
//        position(Position.Relative)
//        width(60.pt)
//        height(80.pt)
//        before {
//            position(Position.Absolute)
/*
            background("linear-gradient(45deg,red,blue)")
            val b = "4px"
            val s = "20px"
            val g1 = "#000 $b,#0000 0 calc(100% - $b),#000 0"
            val g2 = "#0000   calc(0.707*$s)," +
                    "#000  0 calc(0.707*$s + $b)," +
                    "#0000 0 calc(100% - 0.707*$s - $b)," +
                    "#000  0 calc(100% - 0.707*$s)," +
                    "#0000 0"
            property(
                "-webkit-mask", //
                " linear-gradient(45deg ,$g2)," +
                        " linear-gradient(-45deg,$g2)," +
                        " linear-gradient(90deg ,$g1) 50%/100% calc(100% - 2*$s) no-repeat," +
                        " linear-gradient(180deg,$g1) 50%/calc(100% - 2*$s) 100% no-repeat"
            )
*/
//        }
    }
    override val ccName by style {
        paddingLeft(5.px)
        paddingBottom(5.px)
        color(rgb(30, 30, 30))
        fontWeight(700)
        fontFamily(styleConst.mainFontFamily)
        fontSize(18.px)
    }
    override val ccContact by style {
        paddingLeft(5.px)
        color(Color.gray)
        fontFamily(styleConst.mainFontFamily)
        fontWeight(300)
        fontSize(16.px)
    }


    override val itemCardTraining by style {
//        border(1.px, LineStyle.Solid, rgb(127, 127, 127))
        borderRadius(10.pt)
        padding(10.px)
        marginBottom(10.pt)
        backgroundColor(stylePalette.myGreen)
    }
    override val ctIconButton by style {
        fontFamily(styleConst.mainFontFamily)
        color(Color.white)
    }
    override val ctMarkerOut by style {
        myBoxShadow(
            CSSShadow(-3.px, -3.px, 5.px, color = rgba(255, 255, 255, 0.35)),
            CSSShadow(2.px, 2.px, 5.px, color = rgba(0, 0, 0, 0.3))
        )
        backgroundColor(Color.lightgray)
    }
    override val ctMarkerIn by style {
        myBoxShadow(
            CSSShadow(-2.px, -2.px, 5.px, color = rgba(255, 255, 255, 0.35), inner = true),
            CSSShadow(2.px, 2.px, 5.px, color = rgba(0, 0, 0, 0.4), inner = true)
        )
    }

    @OptIn(ExperimentalComposeWebApi::class)
    override val ctIconCopyButton by style {
        transitions {
            all {
                duration(0.2.s)
                timingFunction(AnimationTimingFunction.EaseInOut)
            }
        }
        borderRadius(10.px)
        myTextShadow(
            CSSTextShadow(-2.px, -2.px, 4.px, color = rgba(255, 255, 255, 0.35)),
            CSSTextShadow(2.px, 2.px, 4.px, color = rgba(0, 0, 0, 0.6)),
            CSSTextShadow(0.px, 0.px, 1.px, color = stylePalette.gray)
        )
        self + hover style {
            myTextShadow(
                CSSTextShadow(-2.px, -2.px, 4.px, color = rgba(255, 255, 255, 0.35)),
                CSSTextShadow(2.px, 2.px, 4.px, color = rgba(0, 0, 0, 0.9)),
                CSSTextShadow(0.px, 0.px, 1.px, color = stylePalette.darkGreen)
            )
        }
        self + active style {
            myTextShadow(
                CSSTextShadow(-2.px, -2.px, 4.px, color = rgba(255, 255, 255, 0.35)),
                CSSTextShadow(2.px, 2.px, 4.px, color = rgba(0, 0, 0, 0.9)),
                CSSTextShadow(0.px, 0.px, 1.px, color = stylePalette.black)
            )
            transform {
                scale(0.98)
            }
        }
    }
    override val ctName by style {
        fontFamily(styleConst.mainFontFamily)
        color(Color.white)
    }
    override val ctTime by style {
        fontFamily(styleConst.mainFontFamily)
        color(Color.yellow)
//        color(rgb(235,235,235))
    }
    override val ctBoxMuscleGroup by style {
        fontFamily(styleConst.mainFontFamily)
        padding(6.px)
        borderRadius(6.px)
        fontSize(14.px)
        backgroundColor(rgba(255, 255, 255, 0.3))
        color(stylePalette.white)
    }
    override val ctBoxDescription by style {
//        padding(5.px)
//        borderRadius(10.px)
//        myBoxShadow(
//            CSSShadow(-3.px, -3.px, 5.px, color = rgb(255, 255, 255)),
//            CSSShadow(2.px, 2.px, 5.px, color = rgba(0, 0, 0, 0.3))
//        )
    }
    override val itemVideoPreview by style {
        boxSizing("border-box")
        flexGrow(0)
        flexShrink(1)
        borderRadius(10.px)
        overflow("hidden")
//        border(1.px, LineStyle.Solid, Color.gray)
    }

    override val editDragBox by style {
        width(30.px)
        height(60.px)
        borderRadius(10.px)
        backgroundColor(stylePalette.fontUnactiveColor)
    }
    override val editItemAssignment by style {
        position(Position.Relative)
        border(1.px, LineStyle.Solid, stylePalette.lightGray)
        backgroundColor(Color.white)
        padding(0.px)
        borderRadius(10.px)
    }
    override val editImgAssignment by style {
        property("aspect-ratio", "3/4")
        property("object-fit", "cover")
    }
    override val editNameAssignment by style {
        fontFamily(styleConst.mainFontFamily)
        color(rgb(75, 75, 75))
    }
    override val editIconButton by style {

    }
    override val bvCard by style {
        boxSizing("border-box")
        flexGrow(0)
        flexShrink(1)
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Column)
        borderBox()
//        border(1.px, LineStyle.Solid, rgb(127, 127, 127))
        borderRadius(10.pt)
        aspectRatio(3, 4)
        position(Position.Relative)
    }
    override val bvImage by style {
//        aspectRatio(3, 4)
//        property("object-fit", "cover")
    }
    override val bvIconButton by style {
        myTextShadow(
            CSSTextShadow(0.px, 0.px, 3.px, rgba(255, 255, 255, 1)),
            CSSTextShadow(0.px, 0.px, 10.px, rgba(255, 255, 255, 1)),
        )
    }
    override val bvButtonPanel by style {
        position(Position.Absolute)
        backgroundColor(rgba(255, 255, 255, 0.6))
        bottom(0.px)
        width(100.percent)
    }
    override val filterPlate by style {
        backgroundColor(stylePalette.myOrange)
        borderRadius(15.px)
        padding(10.px)
    }
    override val filterNameText by style {
        fontFamily(styleConst.mainFontFamily)
        color(rgb(240, 240, 240))
        myTextShadow(CSSTextShadow(2.px, 2.px, 2.px, rgba(0, 0, 0, 0.3)))
    }
    override val filterSelectedText by style {
        fontFamily(styleConst.mainFontFamily)
        color(Color.white)
    }
    override val filterIconStart by style {
        color(Color.white)
        myTextShadow(CSSTextShadow(2.px, 2.px, 3.px, rgba(0, 0, 0, 0.4)))
    }
    override val fmHeaders by style {
        fontFamily(styleConst.mainFontFamily)
        color(stylePalette.white)
        padding(6.px, 10.px)
        margin(8.px, 0.px)
        borderRadius(10.px)
        backgroundColor(Color.darkgray)
        myTextShadow(CSSTextShadow(2.px, 2.px, 3.px, rgba(0, 0, 0, 0.4)))
    }
    override val fmButton by style {
        fontFamily(styleConst.mainFontFamily)
        padding(8.px, 8.px, 8.px, 8.px)
        textDecoration("none")
        fontSize(16.px)
        fontWeight(600)
        color(rgb(255, 255, 255))
        borderWidth(0.px)
        borderRadius(10.px)
        backgroundColor(stylePalette.myRed)// rgb(192, 12, 61))
    }
    override val fmItemText by style {
        fontFamily(styleConst.mainFontFamily)
    }
    override val fmItemBox_Out by style {
        borderRadius(50.percent)
        width(20.pt)
        height(20.pt)
        border(1.px, LineStyle.Solid, Color.darkgray)
    }
    override val fmItemBox_In by style {
        borderRadius(50.percent)
        width(14.pt)
        height(14.pt)
        backgroundColor(stylePalette.myBlue)
    }

    override val buttNewTraining by style {
        fontFamily(styleConst.mainFontFamily)
        padding(5.px)
        color(rgb(255, 255, 255))
        borderWidth(0.px)
        borderRadius(styleConst.borderRadiusCommon)
        backgroundColor(rgb(192, 12, 61))
        myTextShadow(CSSTextShadow(2.px, 2.px, 2.px, rgba(0, 0, 0, 0.3)))
        hover(self) style {
            myTextShadow(CSSTextShadow(4.px, 4.px, 3.px, rgba(0, 0, 0, 0.5)))
        }
        self + focus style {
            myTextShadow(CSSTextShadow(4.px, 4.px, 3.px, rgba(0, 0, 0, 0.5)))
        }
    }
    override val buttPasteTrainings by style {
        backgroundColor(stylePalette.myBlue)
        padding(5.px, 10.px)
    }
    override val buttResetPasteTrainings by style {
        backgroundColor(stylePalette.gray)
    }
    override val buttAccept by style {
        backgroundColor(stylePalette.myGreen)
    }

    override val buttTransparentBack by style {
        backgroundColor(Color.transparent)
        color(stylePalette.mainTextColor)
    }


}