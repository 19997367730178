package mainPage.modalWindows

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import api.firebase.addNewClient
import api.firebase.additionFunAfterSignIn
import api.firebase.updateUserProfile
import bdElement.BaseClient
import elements.*
import global.*
import global.QuickStyleProperty.borderBox
import global.QuickStyleProperty.myFullSize
import kotlinx.browser.window
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import style.First.StyleClientRoom
import style.First.StyleCommon

class ModalRequestNewClient(val trainer: BaseClient, val funcAfter: () -> Unit = {}) :
    ModalContent() {
    private var inputPhoneState by mutableStateOf(currentUser.userProfile?.phone ?: "")

    override val closableBack = false
    override val fullscreen: Boolean = phoneDevice

    private var disabledButtSet by mutableStateOf("temp")


    @Composable
    override fun drawModalContent() {
        FlexColumnDivParent({
            style {
                myFullSize()
                backgroundColor(stylePalette.bodyModalColor)
                overflowX("hidden")
                padding(10.px)
                if (fullscreen) height(window.innerHeight.px)
            }
        }) {
            if (trainer.avatar?.isNotEmpty() == true) CenterDiv({
                style {
                    marginTop(20.pt)
//                                flex("initial")
                }
            }) {
                Img(src = trainer.avatar ?: tempAvatarUrl) {
                    classes(styleBlock.trainerRoom.ccAvatar)
                    style {
                        width(80.pt)
                    }
                }
            }
            if (currentUser.userProfile == null) H3({
                classes(StyleCommon.headerBlock)
                style {
                    fontSize(17.px)
                    color(Color.black)
                    minWidth(0.px)
                }
            }) {
                Br {}
                Br {}
                Text("Для того, чтобы записаться на персональные онлайн-тренировки, необходимо войти или зарегистрироваться и подтвердить адрес электронной почты.")
            } else if (currentUser.getEmailVerified() != true) H3({
                classes(StyleCommon.headerBlock)
                style {
                    fontSize(17.px)
                    color(Color.black)
                    minWidth(0.px)
                }
            }) {
                Br {}
                Br {}
                Text("Для того, чтобы записаться на персональные онлайн-тренировки, вам осталось ещё подтвердить адрес электронной почты.")
            } else {
                H3({
                    classes(StyleCommon.headerBlock)
                    style {
                        fontSize(17.px)
                        color(Color.black)
                        minWidth(0.px)
                    }
                }) {
                    Br {}
                    Br {}
                    Text("Для того, чтобы записаться на персональные онлайн-тренировки, укажите свой контактный телефон и отправьте заявку.")
                }
                CenterDiv({
                    style {
//                    border(1.px, LineStyle.Solid, rgb(47, 47, 47))
                        paddingTop(8.px)
                        paddingBottom(8.px)
                    }
                }) {

                    Input(type = InputType.Text) {
                        classes(styleBlock.commonElement.inputText)
                        style {
                            width(250.px)
//                                backgroundColor(Color.lightgray)
                        }
                        value(inputPhoneState)
                        onInput { event -> inputPhoneState = event.value }
                    }
                }
            }

            FlexRowDivParent {
                if (fullscreen) Button({
                    classes(styleBlock.commonElement.buttonBack)
                    style {
                        flex("initial")
                    }
                    onClick {
                        closeModadWindow()
                    }
                }) {
                    Text("Назад")
                }
                if (currentUser.userProfile == null) Button({
                    classes(styleBlock.trainerRoom.fmButton)
                    style {
                        borderBox()
                        flex("auto")
                    }
                    attr(disabledButtSet, "")
                    onClick {
                        currentPage = PageApplication.Registration
                        closeModadWindow()
                        additionFunAfterSignIn = {
                            currentPage = PageApplication.Verification
                            statusLoading.trainerProfile?.id?.let { id_trainer ->
                                currentUser.userProfile?.id?.let { id_client ->
                                    setCookie("new_client_for_${id_trainer}", id_client)
                                }
                            }
                        }
                    }
                }) {
                    Text("Войти/зарегистрироваться")
                } else if (currentUser.getEmailVerified() == true) {
                    Button({
                        style {
                            opacity(if (inputPhoneState.length > 10) 1 else 0.3)
                            width(100.percent)
                        }
                        classes(StyleClientRoom.buttonDone)
                        attr(disabledButtSet, "")
                        onClick {
                            if (inputPhoneState.length > 10) {
                                disabledButtSet = "disabled"
                                console.log("buttDisable")
                                currentUser.userProfile?.id?.let { userId ->
                                    addNewClient(
                                        clientId = userId,
                                        idTrainer = trainer.id ?: "",
                                        inputPhoneState
                                    ) {
                                        if (currentUser.userProfile?.status == "new") updateUserProfile {
                                            showMessage("Заявка тренеру ${trainer.name ?: ""} отправлена.") {
                                                statusLoading.checkZayavka = true
                                                statusLoading.haveQuestionZayavka = false
                                            }
                                        } else {
                                            showMessage("Заявка тренеру ${trainer.name ?: ""} отправлена.") {
                                                statusLoading.checkZayavka = true
                                                statusLoading.haveQuestionZayavka = false
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }) {
                        Text("Отправить заявку")
                    }
                } else {
                    Button({
                        classes(styleBlock.trainerRoom.fmButton)
                        style {
                            borderBox()
                            flex("auto")
                        }
                        attr(disabledButtSet, "")
                        onClick {
                            currentPage = PageApplication.Verification
                            statusLoading.trainerProfile?.id?.let { id_trainer ->
                                currentUser.userProfile?.id?.let { id_client ->
                                    setCookie("new_client_for_${id_trainer}", id_client)
                                }
                            }
                            closeModadWindow()
                        }
                    }) {
                        Text("Верифицировать email")
                    }
                }
            }
        }
    }
}
