package global

import androidx.compose.runtime.*
import bdElement.MyTags
import elements.CenterDiv
import global.QuickStyleProperty.zIndex
import kotlinx.browser.window
import kotlinx.serialization.Serializable
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.columnGap
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text


class PageAdapter(sizePage: Int = 10, private val onChange: (Int, Int, Boolean) -> Unit) {
    /*
        private var currentPrivPage = 0
            get
            set(value) {
                if (value<totalPage){
                    field = value
                    skip = pageSize * value
                    currentPage = value
                }
            }
    */
    fun update(silent: Boolean = false) = onChange(limit,skip, silent)

    fun setCurrentPage(value: Int) {
        if (value < totalPage) {
//            field = value
            skip = pageSize * value
            currentPage = value
        }
//        currentPrivPage = value
    }

    var totalCount = 0
        get
        set(value) {
            field = value
            totalPage = value / pageSize + if (value % pageSize > 0) 1 else 0
            if (totalPage <= currentPage) setCurrentPage(0)
        }
    var totalPage by mutableStateOf(0)
        get
        private set
    var pageSize = sizePage
        get
        set(value) {
            if (value != 0) {
                if(value != field) {
                    field = value
                    totalPage = totalCount / value + if (totalCount % value > 0) 1 else 0
                    if (totalPage <= currentPage) setCurrentPage(0)
                    limit = value
                }
            }   else    {
                field = value
                limit = 0
                if (totalPage <= currentPage) setCurrentPage(0)
                totalPage = 1
            }
        }

    var limit by mutableStateOf(pageSize)
    var skip by mutableStateOf(0)
    var currentPage by mutableStateOf(0)
        private set

    @Composable
    private fun massivePage(start: Int, end: Int) {
        for (i in start..end) {
            CenterDiv(attrs = {
                classes(if (i == currentPage + 1) styleBlock.commonElement.paginationActiv else styleBlock.commonElement.pagination)
                style {
                    property("user-select", "none")
                    zIndex(13)
                }
                onClick {
                    setCurrentPage(i - 1)
                }
            }) {
                Text("$i")
            }
        }
    }


    @Composable
    private fun spacePoint() {
        CenterDiv(attrs = {
            style { property("user-select", "none") }
            classes(styleBlock.commonElement.paginationPoint)
        }) {
            Text("...")
        }
    }

    val sosed = if (window.innerWidth<700) 1 else 2

    @Composable
    private fun mainElem(page: Int) {
        Div({
            style {
                property("user-select", "none")
                display(DisplayStyle.Flex)
                columnGap(styleConst.paddingSmall)
            }
        }) {
/*
            if (currentPage > 0) A(attrs = {
                classes(styleBlock.commonElement.pagination)
                style { property("user-select", "none") }
                onClick {
                    setCurrentPage(currentPage - 1)
                }
            }) {
                Text("«")
            }
*/
            if (totalPage < sosed*2 + 5) massivePage(1, totalPage) else when (currentPage+1) {
                in 1..sosed + 3 -> {
                    massivePage(1, currentPage + sosed + 1)
                    spacePoint()
                    massivePage(totalPage, totalPage)
                }
                in sosed + 4..totalPage - (sosed + 3) -> {
                    massivePage(1, 1)
                    spacePoint()
                    massivePage(currentPage - (sosed - 1), currentPage + sosed + 1)
                    spacePoint()
                    massivePage(totalPage, totalPage)
                }
                in totalPage - (sosed + 3)..totalPage -> {
                    massivePage(1, 1)
                    spacePoint()
                    massivePage(currentPage - (sosed - 1), totalPage)
                }
            }
/*
            if (currentPage < totalPage - 1) A(attrs = {
                classes(styleBlock.commonElement.pagination)
                style { property("user-select", "none") }
                onClick {
                    setCurrentPage(currentPage + 1)
                }
            }) {
                Text("»")
            }
*/
        }
    }

    @Composable
    fun paginationElement() {
        LaunchedEffect(limit, skip) {
            onChange(limit, skip, false)
        }
        if (totalPage>1) mainElem(currentPage)
    }
}