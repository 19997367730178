package elements

import androidx.compose.runtime.Composable
import bdElement.BaseClient
import global.*
import global.QuickStyleProperty.borderBox
import global.QuickStyleProperty.zIndex
import mainPage.modalWindows.ModalCancelObject
import mainPage.modalWindows.ModalConfirmObject
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import style.First.StyleTrainingRoom
import kotlin.js.Date

@Composable
fun CardClient(client: BaseClient, cardTrainer: Boolean = false, cardZayvka: Boolean = false, onClick: () -> Unit = {}) {
    FlexColumnDivParent (
        attrs = {
            classes(styleBlock.trainerRoom.itemCardClient)
            if (client.status == "new") classes(styleBlock.trainerRoom.itemCardClientNew)
            style {
                zIndex(if (dropDownElementNow == client.id.toString()) 150 else 10)
                borderBox()
                width(100.percent)
            }
        }
    ) {
        FlexRowDivParent {
            FlexAutoDiv (
                attrs = {
                    style {
                        display(DisplayStyle.Flex)
                        flexWrap(FlexWrap.Nowrap)
                    }
                    onClick {
                        onClick()
                    }
                }
            ) {
                CenterDiv({
                    style {
                        flex("initial")
                    }
                }) {
                    Img(src = client.avatar ?: tempAvatarUrl) {
                        classes(styleBlock.trainerRoom.ccAvatar)
                    }
                }
                CenterDiv({
                    style {
                        marginLeft(10.pt)
//                    flex("auto")
                        fontFamily(styleConst.mainFontFamily)
                        height(100.percent)
                        property("word-wrap", "break-word")
                    }
                }) {
                    Div {

                        P({ classes(styleBlock.trainerRoom.ccName) }) {
                            Text("${client.name ?: ""} ${client.second_name ?: ""}")
                        }
                        client.email?.let {
                            if (it.isNotEmpty()) P({ classes(styleBlock.trainerRoom.ccContact) }) {
                                Text(it)
                            }
                        }
                        if ((client.phone?.isNotEmpty() == true) || (client.zayavka_phone?.isNotEmpty() == true && cardTrainer.not())){
                            P({
                                classes(styleBlock.trainerRoom.ccContact)
                                style {
                                    paddingTop(5.px)
                                }
                            }) {
                                client.phone?.let {
                                    if (it.isNotEmpty())
                                        Text(it)
                                    if (it != client.zayavka_phone && (client.zayavka_phone?.isNotEmpty() == true && cardTrainer.not())){
                                        Text(", ")
                                    }
                                }
                                if(cardTrainer.not()) client.zayavka_phone?.let {
                                    if (it.isNotEmpty()){
                                        Text(it)
                                    }
                                }
                            }

                        }
                        if (cardTrainer || cardZayvka) {
                            if (client.status != "open" || cardZayvka)P({
                                style {
                                    backgroundColor(if(client.status == "new") stylePalette.myBlue
                                    else if(client.status == "rejected" || client.status == "canceled" || client.status == "close")  Color.gray
                                    else stylePalette.myGreen)
                                    color(stylePalette.mainTextColorInv)
                                    borderRadius(15.px)
                                    padding(3.px, 15.px)
                                }
                            }) {
                                Text(if(client.status == "new") "Заявка на рассмотрении"
                                else if(client.status == "rejected")  "Заявка отклонена"
                                else if(client.status == "canceled")  "Заявка отменена"
                                else if(client.status == "close")  "Клиент в архиве"
                                else "Заявка принята")
                                if (client.status != "new") {
                                    Br{}
                                    Text(Date((client.date_update ?: 0) - Date().getTimezoneOffset()).toLocaleDateString())
                                }
                            }
                        }
                        if (client.status == "new") client.date_create?.let {
                            P({
                                classes(styleBlock.trainerRoom.ccContact)
                                style {
                                    paddingTop(5.px)
                                }
                            }) {
                                Text(Date((it) - Date().getTimezoneOffset()).toLocaleDateString())
                            }
                        }
                    }
                }
                FlexAutoDiv {}
            }
            if (cardTrainer.not() && cardZayvka.not() ) FlexInitialDiv {
                if (client.status == "open" || client.status == "close" ) {
                    CenterDiv({style {
                        height(100.percent)
                    }}) {
                        DropDown({
                            classes(StyleTrainingRoom.chevronButton)
                        }, {
                            Div({
                                style {
                                    fontSize(20.pt)
                                    minWidth(40.px)
                                    minHeight(40.px)
                                    display(DisplayStyle.Flex)
                                    alignItems(AlignItems.Center)
                                    justifyContent(JustifyContent.Center)
                                }
                            }) { //, minHeight
                                I({
                                    classes(
                                        "fa-solid", "fa-bars",
                                        StyleTrainingRoom.chevron,
                                    )
                                })
                            }
                        }, right = true, elementId = client.id.toString(), marginSide = 23.px) {
                            if (client.status == "open") buttonWithIcon("В архив", listOf("fa", "fa-box-archive", StyleTrainingRoom.chevron), {
                                style {
                                    property("white-space","nowrap")
                                    //                                marginBottom(6.px)
                                }
                            }) {
                                client.close {
                                    ListClients.pagination.update()
                                }
//                                setStatusClientFirestore(client,"close"){
//                                    ListClients.pagination.update()
//                                }
                            }
                            if (client.status == "close") buttonWithIcon("Вернуть из архива", listOf("fa", "fa-check", StyleTrainingRoom.chevron), {
                                style {
                                    property("white-space","nowrap")
                                }
                            }) {
                                client.open {
                                    ListClients.pagination.update()
                                }
//                                setStatusClientFirestore(client,"open"){
//                                    ListClients.pagination.update()
//                                }
                            }
                        }
                    }
                    /*
                                        buttonIcon(listOf("fa-solid", "fa-bars"), {
                                            classes(styleBlock.trainerRoom.buttNewTraining, styleBlock.trainerRoom.buttTransparentBack)
                                        }) {
                                        }
                    */
//                }
                }
/*
                if (client.status == "new") {
                    FlexColumnDivParent(rowGap = 5.px){
                        buttonIcon(listOf("fa-solid", "fa-box-archive"), {
                            classes(
                                styleBlock.trainerRoom.buttNewTraining,
                                styleBlock.trainerRoom.buttResetPasteTrainings
                            )
                        }, minSize = 20.pt) {
                            setStatusClientFirestore(client,"close"){
                                ListClients.pagination.update()
                            }
                        }
//                    FlexAutoDiv { }
                        buttonIcon(listOf("fa-solid", "fa-check"), {
                            classes(styleBlock.trainerRoom.buttNewTraining, styleBlock.trainerRoom.buttAccept)
                        }, minSize = 20.pt) {
                            setStatusClientFirestore(client,"open"){
                                ListClients.pagination.update()
                            }
                        }
                    }
                }
*/
            }
        }
        if (client.status == "new") FlexRowDivParent({style {
            justifyContent(JustifyContent.SpaceBetween)
        }}) {
            if (cardTrainer.not()) Button({
                classes(styleBlock.trainerRoom.fmButton)
                style {
                    borderBox()
//                    flex("auto")
                    flexBasis(40.percent)
                }
                onClick {
                    openModalWindow(ModalCancelObject("Вы действительно хотите отклонить эту заявку?","Отклонить") {
                        client.reject { ListClients.pagination.update() }
                    })
                }
            }) {
                Text("Отклонить")
            }   else    Button({
                classes(styleBlock.trainerRoom.fmButton)
                style {
                    borderBox()
//                    flex("auto")
                    flexBasis(40.percent)
                }
                onClick {
                    openModalWindow(ModalCancelObject("Вы действительно хотите отменить эту заявку?","Отменить") {
                        client.cancel { ListTrainers.pagination.update() }
                    })
                }
            }) {
                Text("Отменить")
            }
            if (cardTrainer.not()) Button({
                classes(styleBlock.trainerRoom.fmButton)
                style {
                    borderBox()
//                    flex("auto")
                    flexBasis(40.percent)
                    backgroundColor(stylePalette.myGreen)
                }
                onClick {
                    openModalWindow(ModalConfirmObject("Вы действительно хотите принять эту заявку?","Принять") {
                        client.open { ListClients.pagination.update() }
                    })
                }
            }) {
                Text("Принять")
            }

        }
    }

}