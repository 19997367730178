package global

import kotlinx.browser.window
import org.jetbrains.compose.web.css.*

object QuickStyleProperty {
    fun StyleScope.fontHeader(color: CSSColorValue = stylePalette.fontHeaderColor) {
        fontFamily(styleConst.mainFontFamily)
        fontSize(styleConst.fontHeaderSize)
        fontWeight(styleConst.fontHeaderWeight)
        color(color)
    }
    fun StyleScope.fontCommon(color: CSSColorValue = stylePalette.fontCommonColor) {
        fontFamily(styleConst.mainFontFamily)
        fontSize(styleConst.fontCommonSize)
        fontWeight(styleConst.fontCommonWeight)
        color(color)
    }
    fun StyleScope.fontSmall(color: CSSColorValue = stylePalette.fontCommonColor) {
        fontFamily(styleConst.mainFontFamily)
        fontSize(styleConst.fontSmallSize)
        fontWeight(styleConst.fontSmallWeight)
        color(color)
    }
    fun StyleScope.fontSubheader(color: CSSColorValue = stylePalette.myGreen) {
        fontFamily(styleConst.mainFontFamily)
        fontSize(styleConst.fontSubheaderSize)
        fontWeight(styleConst.fontSubheaderWeight)
        color(color)
    }
    fun StyleScope.myTestBorder(color: CSSColorValue = stylePalette.blue) {
        border(1.px, LineStyle.Solid, color)
    }

    fun StyleScope.myTextNonSelect() {
        property("user-select", "none")
        property("webkit-user-select", "none")
        property("moz-user-select", "none")
        property("ms-user-select", "none")
    }

    fun StyleScope.myNonClick() {
        property("pointer-events", "none")
    }
    fun StyleScope.myFullSize(windowHeight: Boolean = false) {
        borderBox()
        width(100.percent)
        overflowY("auto")
        if (windowHeight) height(window.innerHeight.px) else height(100.percent)
    }
    fun StyleScope.defaultShadow() {
        property(
            "box-shadow",
            "3px 3px 1px 0 rgba(0, 0, 0, 0.5), 4px 4px 6px 0 rgba(0, 0, 0, 0.19)"
        )
    }

    fun StyleScope.zIndex(value: Int) {
        property("z-index", value.toString())
    }

    /** Не работает если указан не в контейнере, т.е. например в Button. Также у Div должна быть указана ширина, чтобы работало это свойство. */
    fun StyleScope.marginWidthAuto(width: CSSNumeric? = null) {
        width?.let { minWidth(width) }
        property("margin", "0 auto")
    }

    fun StyleScope.marginHeightAuto() {
        property("margin", "auto 0")
    }

    fun StyleScope.borderBox() {
        boxSizing("border-box")
    }
    fun StyleScope.clipCutCorners(sizeCorners: Int){
        clipPath("${sizeCorners}px 0, calc(100% - ${sizeCorners}px) 0px, " +
                "100% ${sizeCorners}px, 100% calc(100% - ${sizeCorners}px), " +
                "calc(100% - ${sizeCorners}px) 100%,${sizeCorners}px 100%, " +
                "0 calc(100% - ${sizeCorners}px), 0 ${sizeCorners}px")
    }
    fun StyleScope.clipCutCornersInBorder(sizeCorners: Int,borderWidth: Int){
        clipPath("calc(${sizeCorners}px - 0.58578*${borderWidth}px) 0, calc(100% - ${sizeCorners}px + 0.58578*${borderWidth}px) 0px, " +
                "100% calc(${sizeCorners}px - 0.58578*${borderWidth}px), 100% calc(100% - ${sizeCorners}px + 0.58578*${borderWidth}px), " +
                "calc(100% - ${sizeCorners}px + 0.58578*${borderWidth}px) 100%,calc(${sizeCorners}px - 0.58578*${borderWidth}px) 100%, " +
                "0 calc(100% - ${sizeCorners}px + 0.58578*${borderWidth}px), 0 calc(${sizeCorners}px - 0.58578*${borderWidth}px)")
    }
    fun StyleScope.clipPath(poligon: String){
        property("-webkit-clip-path","polygon($poligon)")
        property("clip-path","polygon($poligon)")
    }

    fun StyleScope.overflowHidden() {
        overflow("hidden")
    }

    fun StyleScope.aspectRatio(width: Int, height: Int) {
        property("aspect-ratio", "$width/$height")
    }

    fun StyleScope.myBoxShadow(vararg shadows: CSSShadow) {
        var value = ""
        shadows.forEach {
            if (value != "") value += ","
            value += "${if (it.inner) " inset " else " "}${it.dx} ${it.dy} ${it.blur} ${it.spread} ${it.color}"
        }
        property("box-shadow", value)
    }

    fun StyleScope.myTextShadow(vararg shadows: CSSTextShadow) {
        var value = ""
        shadows.forEach {
            if (value != "") value += ","
            value += " ${it.dx} ${it.dy} ${it.blur} ${it.color}"
        }
        property("text-shadow", value)
    }

    fun StyleScope.myBackgroundGradient(vararg color: CSSColorValue) {
        var value = "linear-gradient(to right"
        color.forEach {
            value += ", $it"
        }
        value += ") border-box"
        background(value)
    }

    fun StyleScope.myBorderGradient(width: CSSLengthValue, background: CSSColorValue, vararg color: CSSColorValue) {
        var value = "linear-gradient($background, $background) padding-box,linear-gradient(to right"
        color.forEach {
            value += ", $it"
        }
        value += ") border-box"
        background(value)
        border(width, LineStyle.Solid, Color.transparent)
    }

}

data class CSSShadow(
    val dx: CSSNumeric = 2.px,
    val dy: CSSNumeric = 2.px,
    val blur: CSSNumeric = 2.px,
    val spread: CSSNumeric = 2.px,
    val color: CSSColorValue = rgba(0, 0, 0, 0.4),
    val inner: Boolean = false
)

data class CSSTextShadow(
    val dx: CSSNumeric = 2.px,
    val dy: CSSNumeric = 2.px,
    val blur: CSSNumeric = 2.px,
    val color: CSSColorValue = rgba(0, 0, 0, 0.4)
)