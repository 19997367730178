package api

import bdElement.MyAnswerCommon
import global.myJson
import kotlinx.serialization.Serializable
import kotlinx.serialization.decodeFromString

/*
class SimpleResponse(jsonStr: String) : MyResponse<String>(jsonStr)

open class MyResponse<T>(jsonStr: String) {
    private val response = myJson.decodeFromString<CustomMyResponse<T>>(jsonStr)
    val status = response.status ?: "Error"
    val obj = response.objectResponse
    val totalCount = response.totalCount
    val skip = response.skip

//    fun <T: List> getMyAnswerCommon(): CreatiumAnswerCommon<R : List>? = obj?.let { CreatiumAnswerCommon(it,totalCount) }
}

open class MyListResponse<R, T : List<R>>(jsonStr: String) {
    private val response = myJson.decodeFromString<CustomMyResponse<T>>(jsonStr)
    val status = response.status ?: "Error"
    val obj = response.objectResponse
    val totalCount = response.totalCount
    val skip = response.skip

    fun getMyAnswerCommon(): MyAnswerCommon<R>? = obj?.let { MyAnswerCommon(it, totalCount) }
}
*/

@Serializable
data class CustomMyResponse<T>(
    val status: String? = null,
    val objectResponse: T? = null,
    val totalCount: Int? = null,
    val skip: Int? = null,
    val message: String? = null
) {
    fun checkStatusOK(
        falseFun: (String) -> Unit = {},
        rezFun: CustomMyResponse<T>.() -> Unit
    ) {
        if (status == "OK") this.rezFun() else falseFun(status ?: "Status is null")
    }
}

@Serializable
data class CustomMyListResponse<R>(
    val status: String? = null,
    val objectResponse: List<R>? = null,
    val totalCount: Int? = null,
    val skip: Int? = null,
    val message: String? = null
) {
    fun checkStatusOK(rezFun: (CustomMyListResponse<R>) -> Unit) {
        if (status == "OK") rezFun(this)
    }

    fun getMyAnswerCommon(): MyAnswerCommon<R>? = objectResponse?.let { MyAnswerCommon(it, totalCount) }
}

/*
interface  MyResponce<R> {
    val status: String?
    val objectResponse: R?
    val totalCount: Int?
    val skip: Int?
    fun checkStatusOK(rezFun: (MyResponce<R>)->Unit) {
        if (status == "OK") rezFun(this)
    }
    fun getMyAnswerCommon(): CreatiumAnswerCommon<R>? = objectResponse?.let { CreatiumAnswerCommon(it,totalCount) }
}
*/


inline fun <reified T> parseMyResponse(json: String): CustomMyResponse<T> {
    return myJson.decodeFromString<CustomMyResponse<T>>(json)
}

inline fun <reified R> parseMyListResponse(json: String): CustomMyListResponse<R> {

    return myJson.decodeFromString<CustomMyListResponse<R>>(json)
}

