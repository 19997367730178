package style.White

import global.*
import global.QuickStyleProperty.borderBox
import global.QuickStyleProperty.myTestBorder
import global.QuickStyleProperty.myTextShadow
import org.jetbrains.compose.web.css.*
import style.AppStylesheet
import style.SCommonElement

object StyleCommonElementWhite : SCommonElement, StyleSheet(AppStylesheet) {
    override val headerBlock by style {
        padding(5.px)
        width(100.percent)
        borderBox()
        textAlign("center")
        color(stylePalette.myBlue)
        fontFamily(styleConst.mainFontFamily)
        fontSize(21.px)
    }
    override val buttonBack by style {
        flex("initial")
        fontFamily(styleConst.mainFontFamily)
        padding(8.px, 8.px, 8.px, 8.px)
        fontSize(15.px)
        fontWeight(700)
        backgroundColor(stylePalette.myBlue)
        color(Color.white)
        borderWidth(0.px)
//        border(0.px, LineStyle.Solid, Color.lightgray)
        borderRadius(10.px)
        self + hover style {
            opacity(0.9)
//            backgroundColor(Color.lightgray)
        }
    }
    override val pagination by style {
        color(Color.darkgray)
        fontFamily(styleConst.mainFontFamily)
        fontWeight(500)
        property("float", "left")
        padding(8.px, 16.px)
        textDecoration("none")
        borderRadius(styleConst.borderRadiusCommon)
//            borderRadius(8.px)
        border(1.px, LineStyle.Solid, stylePalette.fontUnactiveColor )//rgba(70,70,70,0.5))
        self + hover style {
            backgroundColor(rgb(208, 208, 208))
//            color(rgb(0, 0, 0))
        }
    }
    override val paginationPoint by style {
        color(Color.darkgray)
//        color(rgb(255, 255, 255))
        property("float", "left")
        padding(8.px, 16.px)
        textDecoration("none")
    }
    override val paginationActiv by style {
        property("float", "left")
        fontFamily(styleConst.mainFontFamily)
        fontWeight(500)
        padding(8.px, 16.px)
        textDecoration("none")
        borderRadius(styleConst.borderRadiusCommon)
        backgroundColor(stylePalette.myGreen)
        color(rgb(255, 255, 255))
    }


    override val inputText by style {
        color(stylePalette.mainTextColor)
        fontFamily(styleConst.mainFontFamily)
        backgroundColor(stylePalette.backgroundInputText)
        fontSize(20.px)
        padding(8.px)
        borderRadius(10.pt)
        border(1.px, LineStyle.Solid, Color.transparent)
        self + hover style {
            cursor("text")
        }
        self + focus style {
            border(1.px, LineStyle.Solid, Color.gray)
            cursor("text")
        }
    }
    override val inputTextHeaders by style {
        color(stylePalette.headersTextColor)
        fontFamily(styleConst.mainFontFamily)
    }
    override val rtIconButton by style {
        color(stylePalette.headersTextColor)
        myTextShadow(CSSTextShadow(2.px, 2.px, 2.px, rgba(0, 0, 0, 0.3)))
        hover(self) style {
            myTextShadow(CSSTextShadow(4.px, 4.px, 3.px, rgba(0, 0, 0, 0.5)))
        }
//        self + focus style {
//            myTextShadow(CSSTextShadow(4.px, 4.px, 3.px, rgba(0, 0, 0, 0.5)))
//        }
    }
    override val rtView by style {
        whiteSpace("normal")
        property("word-wrap", "break-all")
        property("overflow-wrap", "anywhere")

        color(stylePalette.mainTextColor)
        fontFamily(styleConst.mainFontFamily)
        fontSize(18.px)
    }
    override val rtMainBlock by style {
        whiteSpace("normal")
        property("word-wrap", "break-all")
        property("overflow-wrap", "anywhere")

        color(stylePalette.mainTextColor)
        fontFamily(styleConst.mainFontFamily)
        backgroundColor(stylePalette.backgroundInputText)
        fontSize(20.px)
        padding(8.px)
        borderRadius(10.pt)
        border(1.px, LineStyle.Solid, Color.transparent)
        self + hover style {
            cursor("text")
        }
        self + focus style {
            border(1.px, LineStyle.Solid, Color.gray)
            cursor("text")
        }
    }
    override val smallTextInfo by style {
        color(stylePalette.textSmallInfo)
        fontFamily(styleConst.mainFontFamily)
    }

}