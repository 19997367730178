package api

import global.currentUser
import kotlinx.browser.window
import org.w3c.fetch.Headers
import org.w3c.fetch.RequestInit
import org.w3c.xhr.FormData
import serverAdress
import kotlin.js.Promise

fun commonUserPostJsonRequest(
    path: String,
    bodyStr: String,
    headers: ((Headers) -> Unit)? = null,
    callback: (String) -> Unit
) {
    commonUserPostRequest(
        path = path,
        body = bodyStr,
        headers = { head ->
            head.append("Content-Type", "application/json")
            headers?.invoke(head)
        },
        callback = callback
    )
}

fun commonUserPostFDRequest(
    path: String,
    formData: (FormData) -> Unit,
    headers: ((Headers) -> Unit)? = null,
    callback: (String) -> Unit
) {
    val formDataInner = FormData();

    formDataInner.append("idProfile", currentUser.userProfile?.id ?: "");
    formData(formDataInner)

    commonUserPostRequest(
        path = path,
        body = formDataInner,
        headers = headers,
        callback = callback
    )
}

fun commonUserPostRequest(
    path: String,
    body: dynamic,
    headers: ((Headers) -> Unit)? = null,
    callback: (String) -> Unit
) {
    currentUser.getIdToken { token ->
        val headersInner = Headers()
        headersInner.append("accessToken", token)
//            headers.append("Content-Type", "application/json")
        headersInner.append("Accept", "*/*")
        headers?.invoke(headersInner)

        val options: RequestInit = RequestInit(
            method = "POST",
            headers = headersInner,
            body = body
        )

        Promise.resolve(window.fetch("$serverAdress/$path", options).then { response ->
            response.json()
        }.then {
            val jsonStr = JSON.stringify(it)
            callback(jsonStr)
        })
    }
}

fun commonUserGetJsonRequest(
    path: String,
    headers: ((Headers) -> Unit)? = null,
    callback: (String) -> Unit
) {
    currentUser.getIdToken { token ->
        val headersInner = Headers()
        headersInner.append("accessToken", token)
        headersInner.append("Content-Type", "application/json")
        headersInner.append("Accept", "*/*")
        headers?.invoke(headersInner)


        val options: RequestInit = RequestInit(
            method = "GET",
            headers = headersInner
        )

        Promise.resolve(window.fetch("$serverAdress/$path", options).then { response ->
            response.json()
        }.then {
            val jsonStr = JSON.stringify(it)
            callback(jsonStr)
        })
    }
}


fun commonPostJsonRequest(
    path: String,
    body: String,
    headers: ((Headers) -> Unit)? = null,
    callback: (String) -> Unit
) {
        val headersInner = Headers()
    headersInner.append("Content-Type", "application/json")
    headersInner.append("Accept", "*/*")
        headers?.invoke(headersInner)

        val options: RequestInit = RequestInit(
            method = "POST",
            headers = headersInner,
            body = body
        )

        Promise.resolve(window.fetch("$serverAdress/$path", options).then { response ->
            response.json()
        }.then {
            val jsonStr = JSON.stringify(it)
            callback(jsonStr)
        })
}