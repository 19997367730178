package elements

import androidx.compose.runtime.Composable
import bdElement.BaseAssignment
import global.QuickStyleProperty.marginHeightAuto
import global.editListAssignment
import global.openModalWindow
import global.selectedTrainingForEdit
import global.styleBlock
import mainPage.modalWindows.ModalDeleteAssignment
import mainPage.modalWindows.ModalEditAssignment
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H4
import org.jetbrains.compose.web.dom.I
import org.jetbrains.compose.web.dom.Text
import style.First.StyleTrainingRoom


class DragAssignment(val assignment: BaseAssignment): DragCommon<BaseAssignment>(assignment) {
    override val directionAsk: Boolean = true

    override val view: @Composable (BaseAssignment, @Composable () -> Unit) -> Unit = @Composable {assign, dragEl ->
        FlexRowDivParent({
            style {
                position(Position.Relative)
            }
        }, columnGap = 5.px) {
            FlexRowDivParent({
                classes(styleBlock.trainerRoom.editItemAssignment)
                style {
                    flex("auto")
                    position(Position.Relative)
                }
            }) {
                Div({
                    style {
                        flex("initial")
                        flexGrow(0)
                        flexShrink(0)
                        marginHeightAuto()
                        overflow("hidden")
                        width(60.px)
                        height(80.px)
//                                    border(1.px, LineStyle.Solid, stylePalette.gray)
                        borderRadius(9.px)
                    }
                }) { //const imageBox = document . createElement ("div");
                    assignment.getPreview() {
                        classes(styleBlock.trainerRoom.editImgAssignment)
                    }
                }
                Div({
                    style {
                        flex("auto")
                        marginHeightAuto()
                        fontSize(18.px)
                        textAlign("left")
                        width(100.percent)
                        display(DisplayStyle.Flex)
                        flexDirection(FlexDirection.Column)
                    }
                }) {
                    H4({
                        classes(styleBlock.trainerRoom.editNameAssignment)
                        style {
                            textAlign("left")
                            width(100.percent)
                        }
                    }) {
                        Text(assignment.name ?: "")
                    }
                    /*
                                                    RichTextView(assignment.description ?: "", funUpdate = {
                                                        minHeight.value = 0
                                                    }, attrs = {
                                                        classes(StyleUserProfile.propertyDescription)
                                                    })
                    */

                    /*
                                                    P({ style { width(100.percent) } }) {
                                                        Text("Новый №: $numberNew, Старый №:$numberCurr")
                                                    }
                    */
                }
                Div({
                    style {
                        flex("initial")
                        marginHeightAuto()
                    }
                })  {
                    DropDown({
                        classes(StyleTrainingRoom.chevronButton)
                    }, {
                        Div({//const buttSett = document . createElement ("button"); //CenterHeight
//                                        classes(StyleTrainingRoom.chevronButton)
                            style {
//                                            flex("initial")
                                fontSize(20.pt)
//                                            marginHeightAuto()//Auto()
                                minWidth(40.px)
                                minHeight(40.px)
                                display(DisplayStyle.Flex)
                                alignItems(AlignItems.Center)
                                justifyContent(JustifyContent.Center)
                            }
                        }) { //, minHeight
                            I({
                                classes(
                                    "fa", "fa-bars",
                                    StyleTrainingRoom.chevron,
                                )
                            })
                        }
                    }, right = true, elementId = assignment.id.toString()) {
                        buttonWithIcon("Редактировать", listOf("fa", "fa-gears", StyleTrainingRoom.chevron), {
                            style {
                                marginBottom(6.px)
                            }
                        }) {
                            selectedTrainingForEdit?.let { training ->
                                openModalWindow(ModalEditAssignment(assignment) { newName, newDescription ->
                                    editListAssignment.changeAsignment(assignment, newName, newDescription)
                                })
                            }
                        }
                        buttonWithIcon(
                            "Удалить",
                            listOf("fa-solid", "fa-trash-can", StyleTrainingRoom.chevron),
                            {
                                classes(StyleTrainingRoom.redButton)
                            }) {
                            openModalWindow(ModalDeleteAssignment {
                                editListAssignment.deleteAssignment(assignment)
                            })
                        }
                    }
                }
            }
            dragEl()
        }
    }
    override val fixOrderInElement: (DragCommon<BaseAssignment>) -> Unit = { elem ->
        selectedTrainingForEdit?.assignments?.find { it.id == elem.dragBaseElement.id }?.let { findELem ->
            findELem.order = elem.numberNew
        }

    }
    override val fixOrdersInBaseAndView: () -> Unit = {
        editListAssignment.fixOrderAssignment()
    }
}
/*
class DragAssignment_old(val assignment: BaseAssignment) {

    var numberCurr = assignment.order ?: 0

    val paddingStart = 0.0

    var myRect: MyRect? = null

    var Xi = 0
    var Yi = 0

    var Xt = 0
    var Yt = 0

    var Xf = 0
    var Yf = 0

    var dragInit by mutableStateOf(false)

    fun dragInitFun(assignments: List<DragAssignment>) {
        dragInit = true
        translateAnimX = paddingStart
        assignments.forEach {
            document.getElementById("assignment-${it.assignment.id}")?.getBoundingClientRect()?.let { domRect ->
                it.myRect = MyRect(domRect)
//                console.log("DOMRect")
//                console.log(it)
            }
        }
    }

    fun drogEnd(assignments: List<DragAssignment>) {
        dragInit = false;

        val newOrderSpis = mutableListOf<NewOrder>()

        assignments.forEach { elem ->
            elem.translateCurr = elem.translateNew
            elem.numberCurr = elem.numberNew
            selectedTrainingForEdit?.assignments?.find { it.id == elem.assignment.id }?.let { findELem ->
                findELem.order = elem.numberNew
            }
//            assignment.order = elem.numberNew
//                elem.numberText.textContent = elem.numberCurr;
            // elem.elem.style.transform = 'translate(0px, ' + elem.translateCurr + 'px)';
            elem.translateAnimX = 0.0
            elem.translateAnimY = elem.translateCurr
            newOrderSpis.add(NewOrder(elem.assignment.id ?: "", elem.numberNew))
        }
        editListAssignment.fixOrderAssignment()
//        selectedClient?.selectedTraining?.id?.let { idTraining ->
//            setNewOrderAssignments(idTraining, newOrderSpis)
//        }

        Xi = 0
        Yi = 0
        Xt = 0
        Yt = 0
        Xf = 0
        Yf = 0
    }


    fun dragMoveMouse(event: MouseEvent, assignments: List<DragAssignment>) {
        event.preventDefault()
        Xf = event.clientX
        Yf = event.clientY
        dragMove(assignments, event.pageX, event.pageY)
    }

    fun dragMoveTouch(event: SyntheticTouchEvent, assignments: List<DragAssignment>) {
        event.preventDefault()
        Xf = event.touches[0]?.clientX ?: 0
        Yf = event.touches[0]?.clientY ?: 0
        dragMove(assignments, (event.touches[0]?.pageX ?: 0).toDouble(), (event.touches[0]?.pageY ?: 0).toDouble())
    }

    fun dragMove(assignments: List<DragAssignment>, touchX: Double, touchY: Double) {

        Xt = Xf - Xi
        Yt = Yf - Yi


        var elementUnder: DragAssignment? = null
        assignments.forEach { elem ->
//            if ( MyRect(elem.cardParent.getBoundingClientRect()).contains(touchX, touchY - elem.translateCurr)) {
            if (elem.myRect?.contains((elem.myRect?.x ?: 0.0) + 10.0, touchY - elem.translateCurr) == true) {
                elementUnder = elem;
            }
        }

        elementUnder?.let { elemUnder ->
            val heightMoving = myRect?.height ?: 0.0
            val heightUnder = elemUnder.myRect?.height ?: 0.0
//            console.log("heightMoving: $heightMoving");
//            console.log("heightUnder: $heightUnder");
            assignments.forEach { elem ->
                if (elemUnder.getCurrY() < getCurrY()) {
                    if (elem.getCurrY() < getCurrY() && elem.getCurrY() >= elemUnder.getCurrY() && elem !== this) {
                        elem.translateNew = elem.translateCurr + heightMoving;
                        elem.numberNew = elem.numberCurr + 1;
                    } else {
                        elem.translateNew = elem.translateCurr;
                        elem.numberNew = elem.numberCurr;
                    }
                    elem.translateAnimY = elem.translateNew;
                } else {
                    if (elem.getCurrY() > getCurrY() && elem.getCurrY() <= elemUnder.getCurrY() && elem !== this) {
                        elem.translateNew = elem.translateCurr - heightMoving;
                        elem.numberNew = elem.numberCurr - 1;
                    } else {
                        elem.translateNew = elem.translateCurr;
                        elem.numberNew = elem.numberCurr;
                    }
                    elem.translateAnimY = elem.translateNew;
                }
                if (elemUnder != this) {
                    val delta1 = elemUnder.getCurrY() - getCurrY();
                    var delta2 = heightUnder - heightMoving;
                    if (delta1 < 0) delta2 = 0.0;
                    translateNew =
                        translateCurr + delta1 + delta2;//(elemUnder.getCurrY() - elemMoving.getCurrY() + heightUnder - heightMoving);
                    numberNew = elemUnder.numberCurr;
                } else {
                    translateNew = translateCurr;
                    numberNew = numberCurr;
                }
            }

        }



        translateAnimX = paddingStart + Xt
        translateAnimY = translateCurr + Yt
    }


    val minHeight = mutableStateOf(0)

    var updateTrigger by mutableStateOf(0)

    @OptIn(ExperimentalComposeWebApi::class)
    @Composable
    fun getCard(listAssignment: List<DragAssignment>) {
        updateTrigger.let {
            Div({
                id("assignment-${assignment.id}")
                style {
                    position(Position.Relative)
                }
            }) {
                Div({
                    id("card-${assignment.id}")
                    classes(StyleTrainingRoom.cardAssignmentParent)
                    style {
                        position(Position.Relative)
                        zIndex(if (dragInit || dropDownElementNow == assignment.id.toString()) 150 else 10)
                        transform {
                            translate(translateAnimX.px, translateAnimY.px)
                        }
                        transitions {
                            all {
                                duration(if (dragInit) 0.001.s else 0.2.s)
                                timingFunction(AnimationTimingFunction.EaseInOut)
                            }
                        }
                    }
                    onDrag {
                        console.log("it.y:${it.y}")
                        console.log("it.x:${it.x}")
                    }
                }) {
                    FlexRowDivParent({
                        style {
                            position(Position.Relative)
                        }
                    }, columnGap = 5.px) {
                        FlexRowDivParent({
                            classes(styleBlock.trainerRoom.editItemAssignment)
                            style {
                                flex("auto")
                                position(Position.Relative)
                            }
                        }) {
                            Div({
                                style {
                                    flex("initial")
                                    flexGrow(0)
                                    flexShrink(0)
                                    marginHeightAuto()
                                    overflow("hidden")
                                    width(60.px)
                                    height(80.px)
//                                    border(1.px, LineStyle.Solid, stylePalette.gray)
                                    borderRadius(9.px)
                                }
                            }) { //const imageBox = document . createElement ("div");
                                assignment.getPreview() {
                                    classes(styleBlock.trainerRoom.editImgAssignment)
                                }
                            }
                            Div({
                                style {
                                    flex("auto")
                                    marginHeightAuto()
                                    fontSize(18.px)
                                    textAlign("left")
                                    width(100.percent)
                                    display(DisplayStyle.Flex)
                                    flexDirection(FlexDirection.Column)
                                }
                            }) {
                                H4({
                                    classes(styleBlock.trainerRoom.editNameAssignment)
                                    style {
                                        textAlign("left")
                                        width(100.percent)
                                    }
                                }) {
                                    Text(assignment.name ?: "")
                                }
*/
/*
                                RichTextView(assignment.description ?: "", funUpdate = {
                                    minHeight.value = 0
                                }, attrs = {
                                    classes(StyleUserProfile.propertyDescription)
                                })
*//*


*/
/*
                                P({ style { width(100.percent) } }) {
                                    Text("Новый №: $numberNew, Старый №:$numberCurr")
                                }
*//*

                            }
                            Div({
                                style {
                                    flex("initial")
                                    marginHeightAuto()
                                }
                            })  {
                                DropDown({
                                classes(StyleTrainingRoom.chevronButton)
                                }, {
                                    Div({//const buttSett = document . createElement ("button"); //CenterHeight
//                                        classes(StyleTrainingRoom.chevronButton)
                                        style {
//                                            flex("initial")
                                            fontSize(20.pt)
//                                            marginHeightAuto()//Auto()
                                            minWidth(40.px)
                                            minHeight(40.px)
                                            display(DisplayStyle.Flex)
                                            alignItems(AlignItems.Center)
                                            justifyContent(JustifyContent.Center)
                                        }
                                    }) { //, minHeight
                                        I({
                                            classes(
                                                "fa", "fa-bars",
                                                StyleTrainingRoom.chevron,
                                            )
                                        })
                                    }
                                }, right = true, elementId = assignment.id.toString()) {
                                    buttonWithIcon("Редактировать", listOf("fa", "fa-gears", StyleTrainingRoom.chevron), {
                                        style {
                                            marginBottom(6.px)
                                        }
                                    }) {
                                        selectedTrainingForEdit?.let { training ->
                                            openModalWindow(ModalEditAssignment(assignment) { newName, newDescription ->
                                                editListAssignment.changeAsignment(assignment, newName, newDescription)
                                            })
                                        }
                                    }
                                    buttonWithIcon(
                                        "Удалить",
                                        listOf("fa-solid", "fa-trash-can", StyleTrainingRoom.chevron),
                                        {
                                            classes(StyleTrainingRoom.redButton)
                                        }) {
                                        openModalWindow(ModalDeleteAssignment {
                                            editListAssignment.deleteAssignment(assignment)
                                        })
                                    }
                                }
                            }
                        }
                        Div({
                            style {
                                flex("initial")
                                minWidth(30.px)
                                marginHeightAuto()
                            }
                            classes(styleBlock.trainerRoom.editDragBox)
//                                attr("data-drog", "")
                            onMouseDown {
                                it.preventDefault()
                                selectableNone = true
                                Xi = it.clientX
                                Yi = it.clientY
                                dragInitFun(listAssignment)
                                document.onmousemove = { event -> dragMoveMouse(event, listAssignment) }
                                document.onmouseup = {
                                    selectableNone = false
                                    document.onmousemove = null
                                    document.onmouseup = null
                                    drogEnd(listAssignment)
                                    null
                                }
                            }
                            onTouchStart {
                                it.preventDefault()
                                selectableNone = true
                                Xi = it.touches[0]?.clientX ?: 0
                                Yi = it.touches[0]?.clientY ?: 0
                                dragInitFun(listAssignment)
                            }
                            if (dragInit) onTouchMove { event ->
                                dragMoveTouch(event, listAssignment)
                            }
                            onTouchEnd {
                                selectableNone = false
                                drogEnd(listAssignment)
                            }
                        }) { //const dragBox = document . createElement ("div");
                        }
                    }
                }
            }
            LaunchedEffect(Unit, minHeight.value) {
                document.getElementById("assignment-${assignment.id}")?.getBoundingClientRect()?.let {
                    myRect = MyRect(it)
                }
            }
        }

    }

    var translateCurr by mutableStateOf(0.0)
    var translateNew by mutableStateOf(0.0)
    var translateAnimX by mutableStateOf(0.0)
    var translateAnimY by mutableStateOf(0.0)
    var numberNew by mutableStateOf(assignment.order ?: 0)


    fun getCurrRect(): MyRect? = document.getElementById("assignment-${assignment.id}")?.getBoundingClientRect()?.let {
        MyRect(it)
    }

    fun getCurrY() = (this.myRect?.y ?: 0.0) + this.translateCurr

}
*/



