package elements

import androidx.compose.runtime.*
import global.*
import global.QuickStyleProperty.fontCommon
import global.QuickStyleProperty.fontSmall
import global.QuickStyleProperty.marginWidthAuto
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLDivElement
import style.First.StyleTrainingRoom

var findInCommon = mutableStateOf(true)
var findInMy = mutableStateOf(true)
var findWithoutTags = mutableStateOf(false)

@Composable
fun filterSelectedBlock(
    filter: FilterTagsBlock,
    updateListFun: () -> Unit,
    myVideo: Boolean,
    attrs: AttrBuilderContext<HTMLDivElement>? = null
) {
    Div(attrs) {
        Div({
            style {
//                backgroundColor(stylePalette.myOrange)
                border(styleConst.borderWidthCommon, LineStyle.Solid, stylePalette.fontMiddleColor)
                borderRadius(styleConst.borderRadiusCommon)
                padding(styleConst.paddingCommon)
            }
        }
        ) {
            if (myVideo && findWithoutTags.value) FlexRowDivParent({style {
                justifyContent(JustifyContent.Right)
            }}) {
                MySwitch(findWithoutTags, "только без тегов") {
                    if (findWithoutTags.value.not()) style { marginBottom(5.px) }
                }
            }
            if (myVideo && findWithoutTags.value.not() || myVideo.not()) {
                FlexRowDivParent(attrs = {
                    style {
                        alignItems(AlignItems.Center)
                    }
                }, columnGap = styleConst.paddingCommon) {
                    Div({
                        style {
                            flex("auto")
                        }
                    }) {
                        P({
                            style {
                                fontSmall(if (filter.getSex_IsNotEmpty()) stylePalette.myBlue else stylePalette.fontCommonColor)
                            }
                        }) {
                            Text("Пол:")
                        }
                        P({
                            style {
                                fontCommon(stylePalette.fontHeaderColor)
                            }
                        }) {
                            Text(filter.getSexStr_Name())
                        }
                    }
                    if (myVideo.not()) {
                        MySwitch(findInCommon, "общие")
                        MySwitch(findInMy, "мои")
                    }
                    if (myVideo) MySwitch(findWithoutTags, "только без тегов") {
                        if (findWithoutTags.value.not()) style { marginBottom(5.px) }
                    }
                }
                FlexRowDivParent {
                    Div({
                        style {
                            marginTop(styleConst.paddingSmall)
                            flex("auto")
                        }
                    }) {
                        P({
                            style {
                                fontSmall(if (filter.getMuscleGroup_IsNotEmpty()) stylePalette.myBlue else stylePalette.fontCommonColor)
                            }
                        }) {
                            Text("Группы мышц:")
                        }
                        P({
                            style {
                                fontCommon(stylePalette.fontHeaderColor)
                            }
                        }) {
                            Text(filter.getMuscleGroupStr_Name())
                        }
                        P({
                            style {
                                marginTop(styleConst.paddingSmall)
                                fontSmall(if (filter.getEquipment_IsNotEmpty()) stylePalette.myBlue else stylePalette.fontCommonColor)
                            }
                        }) {
                            Text("Тип оборудования:")
                        }
                        P({
                            style {
                                fontCommon(stylePalette.fontHeaderColor)
                            }
                        }) {
                            Text(filter.getEquipmentStr_Name())
                        }
                    }
                    CenterDiv({
                        classes(StyleTrainingRoom.chevronButton, styleBlock.trainerRoom.filterIconStart)
                        style {
                            flex("initial")
                            fontSize(20.pt)
                            marginWidthAuto()
                            minWidth(30.pt)
                            minHeight(30.pt)
                            color(stylePalette.myOrange)
                        }
                        onClick {
                            openModalWindow(fullscreen = phoneDevice, rezCloseFun = {
                                filter.tagsVideo.sex_tags_state.forEach {
                                    it.second.value = filter.tagsVideo?.sex_id?.contains(it.first.id) ?: false
                                }
                                filter.tagsVideo.equipment_tags_state.forEach {
                                    it.second.value = filter.tagsVideo?.equipment_id?.contains(it.first.id) ?: false
                                }
                                filter.tagsVideo.muscle_group_tags_state.forEach {
                                    it.second.value = filter.tagsVideo?.muscle_group_id?.contains(it.first.id) ?: false
                                }
                            }) {
                                filter.getBlock(funUpdate = {
                                    updateListFun()
                                }) {
                                    visibleModalWindow.value = false
                                    modalContent = null
                                }
                            }
                        }
                    }) {
                        I({
                            classes(
                                "fa-solid", "fa-filter",
                                StyleTrainingRoom.chevron,
                            )
                        })
                    }
                }
            }
        }
    }
}
