package elements

import androidx.compose.runtime.Composable
import api.firebase.deleteTrainingFirestore
import api.firebase.setActivateTrainingFirestore
import api.firebase.setStatusTrainingFirestore
import bdElement.BaseTraining
import global.*
import global.QuickStyleProperty.borderBox
import global.QuickStyleProperty.fontCommon
import global.QuickStyleProperty.fontSmall
import global.QuickStyleProperty.fontSubheader
import global.QuickStyleProperty.marginWidthAuto
import global.QuickStyleProperty.overflowHidden
import global.QuickStyleProperty.zIndex
import kotlinx.browser.window
import mainPage.modalWindows.ModalAddEditTraining
import mainPage.modalWindows.ModalAddEditTrainingForComplex
import mainPage.modalWindows.ModalDeleteObject
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import style.First.StyleTrainingRoom
import style.common.StyleCustomProperties
import kotlin.js.Date
import kotlin.math.round

@Composable
fun cardTrainingEditor(training: BaseTraining, complexFlag: Boolean, dragEl: @Composable () -> Unit = {}) {
    Spoiler(
        attrs = {
            style {
                backgroundColor(Color.white)
                borderRadius(styleConst.borderRadiusCommon)
                border(styleConst.borderWidthCommon, LineStyle.Solid, stylePalette.fontMiddleColor)
                position(Position.Relative)
                lineHeight("0")
                zIndex(if (dropDownElementNow == training.id.toString()) 150 else 10)
            }
        },
        attrsChevron = {
            style {
                color(stylePalette.fontMiddleColor)
                paddingRight(styleConst.paddingCommon)
            }
        },
        attrsContentDiv = {
            style {
                overflowHidden()
                borderRadius(styleConst.borderRadiusCommon - styleConst.borderWidthCommon * 2)
            }
        },
        spoilerEnable = training.assignments?.isNotEmpty() == true, {
            assignmentBox(training)
        },
        contentSecondLevel = {
            if (phoneDevice) descriptionBox(training, complexFlag, dragEl)
        }
    ) {
        FlexRowDivParent({ style { position(Position.Relative) } }, flexWrap = FlexWrap.Nowrap) {
            buttonBox(training, complexFlag)
            if (phoneDevice.not()) descriptionBox(training, complexFlag, dragEl)
        }
    }
}

@Composable
fun cardTrainingEditorOld(training: BaseTraining, complexFlag: Boolean, dragEl: @Composable () -> Unit = {}) {
    Spoiler(
        attrs = {
            classes(styleBlock.trainerRoom.itemCardTraining)
            style {
                position(Position.Relative)
                zIndex(if (dropDownElementNow == training.id.toString()) 150 else 10)
            }
        },
        attrsChevron = {
            classes(styleBlock.trainerRoom.ctIconButton)
        },
        attrsContentDiv = {},
        spoilerEnable = training.assignments?.isNotEmpty() == true, {
            assignmentBoxOld(training)
        },
        contentSecondLevel = {
            if (window.innerWidth < 800) descriptionBoxOld(training, complexFlag, dragEl)
        }
    ) {
        FlexRowDivParent({ style { position(Position.Relative) } }, flexWrap = FlexWrap.Nowrap) {
            buttonBoxOld(training, complexFlag)
            if (window.innerWidth >= 800) descriptionBoxOld(training, complexFlag, dragEl)
        }
    }
}

@Composable
fun descriptionBox(training: BaseTraining, complexFlag: Boolean, dragEl: @Composable () -> Unit) {
    if (training.activate || complexFlag)
        descriptionBoxCommon(
            training,
            smallC = stylePalette.fontUnactiveColor,
            nameC = stylePalette.fontHeaderColorInv,
            opisC = stylePalette.fontUnactiveColor,
            backC = if (complexFlag) stylePalette.myBlue else stylePalette.myGreen,
            complexFlag = complexFlag,
            dragEl = dragEl
        ) {
//            saveScrollState = mainScrollTop()// window.scrollY
//            selectedOpenTraining = training
//            mainScrollTo(0.0)
        }
    else
        descriptionBoxCommon(
            training,
            smallC = stylePalette.fontCommonColor,
            nameC = stylePalette.fontHeaderColor,
            opisC = stylePalette.fontCommonColor,
            backC = stylePalette.fontUnactiveColor,
            complexFlag = complexFlag,
            dragEl = dragEl
        ) {
//            saveScrollState = mainScrollTop()// window.scrollY
//            selectedOpenTraining = training
//            mainScrollTo(0.0)
        }
}

@Composable
fun descriptionBoxCommon(
    training: BaseTraining,
    smallC: CSSColorValue,
    nameC: CSSColorValue,
    opisC: CSSColorValue,
    backC: CSSColorValue,
    borderC: CSSColorValue? = null,
    complexFlag: Boolean,
    dragEl: @Composable () -> Unit,
    onClick: () -> Unit = {}
) {
    Div({
        style {
            lineHeight("1")
            flex("auto")
            if (phoneDevice)
                borderRadius(styleConst.borderRadiusCommon, styleConst.borderRadiusCommon, 0.px, 0.px)
            else
                borderRadius(styleConst.borderRadiusCommon)
            padding(styleConst.paddingCommon)
            position(Position.Relative)
            backgroundColor(backC)
            borderC?.let {
                border(styleConst.borderWidthCommon, LineStyle.Solid, it)
            }
        }
        onClick {
            onClick()
        }
    }) {
        FlexRowDivParent({
            style {
                position(Position.Relative)
                justifyContent(JustifyContent.SpaceBetween)
                paddingBottom(styleConst.paddingSmall)
            }
        }, columnGap = 3.pt) {
            P({
                style {
                    flex("auto")
                    fontSmall(smallC)
                }
            }) {
                Text(
                    if (complexFlag.not()) "${Date((training.date_open ?: 0) - Date().getTimezoneOffset()).toLocaleDateString()} " +
                            "- ${Date((training.date_close ?: 0) - Date().getTimezoneOffset() - 86400000.0).toLocaleDateString()}" +
                            (round(((training.date_close ?: 0) - getCurrentDateTimeUTC()) / 8640000.0) / 10).let {
                                if (it < 0) "" else " (Ост: $it д.)"
                            }
                    else if (training.date_open == 0L) "Откроется сразу" else "Откроется на ${(training.date_open ?: 0) / 86400000L} день"

                )
            }
            training.completed?.let { compl ->
                if (compl > 10000000) {
                    CenterDiv({
                        style {
                            position(Position.Relative)
                            minWidth(18.px)
                            lineHeight("0")
                        }
                    }) {
                        P({
                            style {
                                position(Position.Absolute)
                                fontSmall(stylePalette.myOrange)
                                fontSize(styleConst.fontCommonSize)
//                                padding(2.pt)
//                                margin(-1.px)
//                                left(-15.px)
                                lineHeight("0")
                                backgroundColor(Color.white)
                                borderRadius(50.percent)
                            }
                        }) {
                            I({
                                classes("fa-solid", "fa-circle-check")
                            })
                        }
                    }
                    P({
                        style {
                            fontSmall(smallC)
                        }
                    }) {
                        Text("${Date(compl - Date().getTimezoneOffset()).toLocaleDateString()}")
                    }
                }
            }
        }
        /*
                training.completed?.let { compl ->
                    CenterDiv({style {
                        position(Position.Absolute)
                        borderBox()
                        backgroundColor(Color.white)
                        top(-8.px)
                        right(-8.px)
                        width(25.px)
                        height(25.px)
                        borderRadius(50.percent)
                        fontSmall(stylePalette.myOrange)
                        border(styleConst.borderWidthCommon, LineStyle.Solid, stylePalette.fontCommonColor)
                        opacity(if (compl > 0) 1.0 else 0.0)
                    }}) {
                        I({
                            classes("fa-solid", "fa-circle-check")
                        })
                    }
        */
        /*
                    Img(src = "svgIcon/Check_orange_16.svg") {
                        style {
                            position(Position.Absolute)
                            top(-8.px)
                            right(-8.px)
                            width(26.px)
                            height(26.px)
                            opacity(if (compl > 0) 1.0 else 0.0)
                        }
                    }
        *//*

        }
*/
        FlexRowDivParent({
            style {
                position(Position.Relative)
                justifyContent(JustifyContent.SpaceBetween)
                alignContent(AlignContent.End)
                alignItems(AlignItems.End)
            }
        }) {
            Div({
                style {
                    flex("auto")
                    borderBox()
                }
            }) {
                P({
                    style {
                        fontSubheader(nameC)
                        paddingBottom(styleConst.paddingCommon)
                    }
                }) {
                    Text(training.name ?: "")
                }
                P({
                    style {
                        fontCommon(opisC)
                    }
                }) {
                    Text(
                        "[ ${training.assignments.count()} ] ${
                            training.assignments?.fold(mutableListOf<String>()) { str, assign ->
                                assign.muscle_group_tags?.forEach {
                                    if (it != "") str.add(it)
                                }
                                str
                            }?.distinct()?.toString()?.let { it.substring(1,it.length-1) } ?: " --- "
                        }"
                    )
                }
            }
            if (complexFlag) dragEl()
            else training.getMarkerComplex()
        }

    }
}


@Composable
private fun assignmentBox(training: BaseTraining) {
    Div(
        attrs = {
//            classes(StyleVideoBlock.myVideoBlock)//,StyleTrainingRoom.contentCard, if (closeCard) StyleTrainingRoom.closeCard else StyleTrainingRoom.openCard) //WtRows.wtRow,
            style {
                display(DisplayStyle.Flex)
                flexWrap(FlexWrap.Wrap)

                columnGap(5.pt)
                rowGap(5.pt)
                margin(0.pt)

                boxSizing("border-box")
                backgroundColor(stylePalette.fontUnactiveColor)

                marginRight(-5.pt)
                padding(styleConst.paddingCommon)
                lineHeight("0")
//                paddingLeft(styleConst.paddingCommon)
//                paddingRight(styleConst.paddingCommon)
//                paddingBottom(styleConst.paddingCommon)
//                paddingTop(styleConst.paddingCommon)

//                borderRadius(0.px, 0.px, styleConst.borderRadiusCommon, styleConst.borderRadiusCommon)
            }
        }
    ) {
        training.assignments.sortedBy { it.order }.forEach {
            Div({
                classes(StyleCustomProperties.flexBasis_3_8_5pt, styleBlock.trainerRoom.itemVideoPreview)
            }) {
                it.getPreview() {
                    style {
                    }
                }
            }
        }
    }
}

@Composable
private fun assignmentBoxOld(training: BaseTraining) {
    Div(
        attrs = {
//            classes(StyleVideoBlock.myVideoBlock)//,StyleTrainingRoom.contentCard, if (closeCard) StyleTrainingRoom.closeCard else StyleTrainingRoom.openCard) //WtRows.wtRow,
            style {
                display(DisplayStyle.Flex)
                flexWrap(FlexWrap.Wrap)

                columnGap(5.pt)
                rowGap(5.pt)
                margin(0.pt)

                boxSizing("border-box")

                paddingLeft(1.pt)
                marginRight(-4.pt)
                paddingBottom(1.px)
                paddingTop(10.px)
            }
        }
    ) {
        training.assignments.sortedBy { it.order }.forEach {
            Div({
                classes(StyleCustomProperties.flexBasis_3_8_5pt, styleBlock.trainerRoom.itemVideoPreview)
            }) {
                it.getPreview() {
                    style {
                    }
                }
            }
        }
    }
}

@OptIn(ExperimentalComposeWebApi::class)
@Composable
private fun buttonBox(training: BaseTraining, complexFlag: Boolean) {
    FlexRowDivParent({
        style {
//            color(stylePalette.fontUnactiveColor)
            lineHeight("1")
            position(Position.Relative)
        }
    }) {
        CenterDiv({
            style {
                flex("initial")
//                    marginAuto()
//                    minWidth(70.pt)
//                    minHeight(30.pt)
            }
        }) {
            DropDown({
                classes(StyleTrainingRoom.chevronButton, styleBlock.trainerRoom.ctIconButton)
            }, {
                CenterDiv({
                    style {
                        fontSize(20.pt)
//                            marginAuto()
                        color(stylePalette.fontMiddleColor)
                        marginLeft(5.px)
                        width(45.px)
                        height(50.px)
                    }
                }) {
                    I({
                        style {
//                                fontSize(20.pt)
//                                marginAuto()
                        }
                        classes(
                            "fa", "fa-bars",
                            StyleTrainingRoom.chevron,
                        )
                    })
                }
            }, elementId = training.id ?: "") {
                if (complexFlag.not()) {
                    if (training.activate) buttonWithIcon(
                        "Деактивировать",
                        listOf("fa-solid", "fa-toggle-off", StyleTrainingRoom.chevron),
                        {
                            style {
                                marginBottom(6.px)
                            }
                        }) {
                        setActivateTrainingFirestore(training, false) {
                            ClientTrainings.trainingsLoaded?.updateElem(training.copy(activate = false),
                                { it.date_open }) { it.id == training.id }
                        }
                    } else buttonWithIcon(
                        "Активировать",
                        listOf("fa-solid", "fa-toggle-on", StyleTrainingRoom.chevron),
                        {
                            style {
                                marginBottom(6.px)
                            }
                        }) {
                        setActivateTrainingFirestore(training, true) {
                            ClientTrainings.trainingsLoaded?.updateElem(training.copy(activate = true),
                                { it.date_open }) { it.id == training.id }
                        }
                    }
                }
                buttonWithIcon("Редактировать", listOf("fa", "fa-pen-to-square", StyleTrainingRoom.chevron), {
                    style {
                        marginBottom(6.px)
                    }
                }) {
                    if (complexFlag.not()) openModalWindow(ModalAddEditTraining(training) {})
                    else openModalWindow(ModalAddEditTrainingForComplex(training) {})
                }
                buttonWithIcon("Удалить", listOf("fa-solid", "fa-trash-can", StyleTrainingRoom.chevron), {
                    classes(StyleTrainingRoom.redButton)
                }) {
                    openModalWindow(ModalDeleteObject("эту тренировку") {
                        deleteTrainingFirestore(training) {
                            if (complexFlag.not()) ClientTrainings.trainingsLoaded?.deleteElem(training)
                            else ComplexTrainings.pagination.update()
                        }
//                        editListAssignment.deleteAsignment(assignment)
                    })
                }
            }
        }
        if (complexFlag.not()) CenterDiv({
            style {
                flex("initial")
                marginWidthAuto()
                minWidth(30.pt)
                minHeight(30.pt)
            }
            onClick {
                val dateNow = (Date().getTime() + Date().getTimezoneOffset()).toLong()
                training.date_open?.let { open ->
                    training.date_close?.let { close ->
                        if (dateNow in open until close && training.activate) {
                            if (training.status == "second") setStatusTrainingFirestore(training, "") {
                                ClientTrainings.trainingsLoaded?.updateElem(training.copy(status = ""),
                                    { it.date_open }) { it.id == training.id }
                            }
                            else setStatusTrainingFirestore(training, "second") {
                                ClientTrainings.trainingsLoaded?.updateElem(training.copy(status = "second"),
                                    { it.date_open }) { it.id == training.id }
                            }
                        }
                    }
                }
            }
        }) {
            markerRound2(if (training.activate) {
                val dateNow = getCurrentDateTimeUTC() //Date().getTime() + Date().getTimezoneOffset()).toLong()
                training.date_open?.let { open ->
                    training.date_close?.let { close ->
                        if (dateNow in open until close) {
                            if (training.status == "second") stylePalette.myOrange else stylePalette.myGreen
                        } else stylePalette.myRed
                    } ?: stylePalette.myRed
                } ?: stylePalette.myRed
            } else stylePalette.fontMiddleColor, 20.pt)
        }
        CenterDiv({
            style {
                flex("initial")
                fontSize(20.pt)
//                    marginAuto()
            }
            onClick {
                filterVideo.clear()
                ListVideoForAdd.pagination.setCurrentPage(0)
                setSelectedTrainingForEdit(training)
//                selectedClient?.selectedTraining = training
                mainScrollTo(0.0)
//                window.scrollTo(0.0, 0.0)
            }
        }) {
            CenterDiv({
                classes(StyleTrainingRoom.chevronButton, styleBlock.trainerRoom.ctIconButton)
                style {
                    color(stylePalette.fontMiddleColor)
                    width(50.px)
                    height(50.px)
                }
            }) {
                I({
                    classes(
                        "fa", "fa-gears",
                        StyleTrainingRoom.chevron,
                    )
                })
            }
        }
        CenterDiv({
//            classes(styleBlock.trainerRoom.ctIconCopyButton) //StyleTrainingRoom.copyButton,
            style {
//                transitions {
//                    all {
//                        duration(0.2.s)
//                        timingFunction(AnimationTimingFunction.EaseInOut)
//                    }
//                }
                flex("initial")
                fontSize(20.pt)
                marginWidthAuto()
                minWidth(30.pt)
                minHeight(30.pt)
                color(if (copyTrainingsBuffer.contains(training)) stylePalette.myBlue else stylePalette.fontMiddleColor)
            }
            onClick {
                copyTrainingsBuffer = copyTrainingsBuffer.toMutableList().apply {
                    if (contains(training)) remove(training)
                    else add(training)
                }
            }
        }) {
            I({
                classes(
                    "fa", "fa-copy",
                    StyleTrainingRoom.chevron,
                )
            })
        }
    }
}

@Composable
private fun buttonBoxOld(training: BaseTraining, complexFlag: Boolean) {
    FlexRowDivParent({ style { position(Position.Relative) } }) {
        CenterDiv({
            style {
                flex("initial")
//                    marginAuto()
//                    minWidth(70.pt)
//                    minHeight(30.pt)
            }
        }) {
            DropDown({
                classes(StyleTrainingRoom.chevronButton, styleBlock.trainerRoom.ctIconButton)
            }, {
                CenterDiv({
                    style {
                        fontSize(20.pt)
//                            marginAuto()
                        width(50.px)
                        height(50.px)
                    }
                }) {
                    I({
                        style {
//                                fontSize(20.pt)
//                                marginAuto()
                        }
                        classes(
                            "fa", "fa-bars",
                            StyleTrainingRoom.chevron,
                        )
                    })
                }
            }, elementId = training.id ?: "") {
                if (complexFlag.not()) {
                    if (training.activate) buttonWithIcon(
                        "Деактивировать",
                        listOf("fa-solid", "fa-toggle-off", StyleTrainingRoom.chevron),
                        {
                            style {
                                marginBottom(6.px)
                            }
                        }) {
                        setActivateTrainingFirestore(training, false) {
                            ClientTrainings.trainingsLoaded?.updateElem(training.copy(activate = false),
                                { it.date_open }) { it.id == training.id }
                        }
                    } else buttonWithIcon(
                        "Активировать",
                        listOf("fa-solid", "fa-toggle-on", StyleTrainingRoom.chevron),
                        {
                            style {
                                marginBottom(6.px)
                            }
                        }) {
                        setActivateTrainingFirestore(training, true) {
                            ClientTrainings.trainingsLoaded?.updateElem(training.copy(activate = true),
                                { it.date_open }) { it.id == training.id }
                        }
                    }
                }
                buttonWithIcon("Редактировать", listOf("fa", "fa-pen-to-square", StyleTrainingRoom.chevron), {
                    style {
                        marginBottom(6.px)
                    }
                }) {
                    if (complexFlag.not()) openModalWindow(ModalAddEditTraining(training) {})
                    else openModalWindow(ModalAddEditTrainingForComplex(training) {})
                }
                buttonWithIcon("Удалить", listOf("fa-solid", "fa-trash-can", StyleTrainingRoom.chevron), {
                    classes(StyleTrainingRoom.redButton)
                }) {
                    openModalWindow(ModalDeleteObject("эту тренировку") {
                        deleteTrainingFirestore(training) {
                            if (complexFlag.not()) ClientTrainings.trainingsLoaded?.deleteElem(training)
                            else ComplexTrainings.pagination.update()
                        }
//                        editListAssignment.deleteAsignment(assignment)
                    })
                }
            }
        }
        if (complexFlag.not()) CenterDiv({
            style {
                flex("initial")
                marginWidthAuto()
                minWidth(30.pt)
                minHeight(30.pt)
            }
            onClick {
                val dateNow = (Date().getTime() + Date().getTimezoneOffset()).toLong()
                training.date_open?.let { open ->
                    training.date_close?.let { close ->
                        if (dateNow in open until close && training.activate) {
                            if (training.status == "second") setStatusTrainingFirestore(training, "") {
                                ClientTrainings.trainingsLoaded?.updateElem(training.copy(status = ""),
                                    { it.date_open }) { it.id == training.id }
                            }
                            else setStatusTrainingFirestore(training, "second") {
                                ClientTrainings.trainingsLoaded?.updateElem(training.copy(status = "second"),
                                    { it.date_open }) { it.id == training.id }
                            }
                        }
                    }
                }
            }
        }) {
            markerRound(if (training.activate) {
                val dateNow = getCurrentDateTimeUTC() //Date().getTime() + Date().getTimezoneOffset()).toLong()
                training.date_open?.let { open ->
                    training.date_close?.let { close ->
                        if (dateNow in open until close) {
                            if (training.status == "second") stylePalette.markerYellow else stylePalette.markerGreen
                        } else stylePalette.markerRed
                    } ?: stylePalette.markerRed
                } ?: stylePalette.markerRed
            } else stylePalette.markerGray, 20.pt)
        }
        CenterDiv({
            style {
                flex("initial")
                fontSize(20.pt)
//                    marginAuto()
            }
            onClick {
                filterVideo.clear()
                ListVideoForAdd.pagination.setCurrentPage(0)
                setSelectedTrainingForEdit(training)
//                selectedClient?.selectedTraining = training
                mainScrollTo(0.0)
//                window.scrollTo(0.0, 0.0)
            }
        }) {
            CenterDiv({
                classes(StyleTrainingRoom.chevronButton, styleBlock.trainerRoom.ctIconButton)
                style {
                    width(50.px)
                    height(50.px)
                }
            }) {
                I({
                    classes(
                        "fa", "fa-gears",
                        StyleTrainingRoom.chevron,
                    )
                })
            }
        }
        CenterDiv({
            classes(styleBlock.trainerRoom.ctIconCopyButton) //StyleTrainingRoom.copyButton,
            style {
                flex("initial")
                fontSize(20.pt)
                marginWidthAuto()
                minWidth(30.pt)
                minHeight(30.pt)
                color(if (copyTrainingsBuffer.contains(training)) stylePalette.myOrange else stylePalette.lightGray)
            }
            onClick {
                copyTrainingsBuffer = copyTrainingsBuffer.toMutableList().apply {
                    if (contains(training)) remove(training)
                    else add(training)
                }
            }
        }) {
            I({
                classes(
                    "fa", "fa-copy",
                    StyleTrainingRoom.chevron,
                )
            })
        }
    }
}

@Composable
private fun descriptionBoxOld(training: BaseTraining, complexFlag: Boolean, dragEl: @Composable () -> Unit) {
    FlexRowDivParent({
        classes(styleBlock.trainerRoom.ctBoxDescription)
        style {
            flex("auto")
        }
    }) {
        Div({
            style {
                flex("auto")
            }
        }) {
            FlexRowDivParent {
                Div({
                    style {
                        paddingLeft(5.px)
                        paddingBottom(5.px)
                        flex("auto")
                    }
                }) {
                    H3({
                        classes(styleBlock.trainerRoom.ctName)
                    }) {
                        if (currentUser.userProfile?.role?.contains("admin") == true) Text("${training.order ?: "null"}: ")
                        Text(training.name ?: "")
                    }
                    P({
                        classes(styleBlock.trainerRoom.ctTime)
                        style {
//                    color(stylePalette.gray)
                            fontSize(14.px)
                        }
                    }) {
                        if (complexFlag.not()) Text("${Date((training.date_open ?: 0) - Date().getTimezoneOffset()).toLocaleDateString()} " +
                                "- ${Date((training.date_close ?: 0) - Date().getTimezoneOffset() - 86400000.0).toLocaleDateString()}" +
                                " (Ост.: ${
                                    (round(((training.date_close ?: 0) - getCurrentDateTimeUTC()) / 8640000.0) / 10).let {
                                        if (it < 0) 0 else it
                                    }
                                } д.)"
                        )
                        else Text(if (training.date_open == 0L) "Откроется сразу" else "Откроется на ${(training.date_open ?: 0) / 86400000L} день")
                        /*
                                        Text("Доступ с ${Date((training.date_open ?: 0) - Date().getTimezoneOffset()).toLocaleDateString()} " +
                                                "по ${Date((training.date_close ?: 0) - Date().getTimezoneOffset() - 86400000.0).toLocaleDateString()}" +
                                                " Осталось: ${
                                                    (round(((training.date_close ?: 0) - (Date().getTime() + Date().getTimezoneOffset())) / 8640000.0) / 10).let {
                                                        if (it < 0) 0 else it
                                                    }
                                                } д."
                                        )
                        */
                    }
                }
                if (complexFlag.not()) {
                    Div({
                        style {
//                        paddingLeft(5.px)
                            display(DisplayStyle.Flex)
                            flexWrap(FlexWrap.Nowrap)
                            paddingBottom(5.px)
                            flex("initial")
                        }
                    }) {
                        training.getMarkerComplex()
                        CenterDiv({
                            classes(styleBlock.clientRoom.ctIconDoneButton)
                            style {
                                fontSize(23.pt)
                                marginWidthAuto()
                                minWidth(30.pt)
                                minHeight(30.pt)
                                color(
                                    if ((training.completed ?: 0) > 0) stylePalette.myOrange else stylePalette.lightGray
                                )
//                    color(ColorRes.darkGreen)
//                    myTextShadow(
//                        CSSTextShadow(-2.px, -2.px, 8.px, color = rgb(255, 255, 255)),
//                        CSSTextShadow(2.px, 2.px, 8.px, color = rgba(0, 0, 0, 0.6)),
//                        CSSTextShadow(0.px, 0.px, 2.px, color = rgba(0, 0, 0, 0.99))
//                    )
                            }
                        }) {
                            I({
                                classes(
                                    "fa-solid", "fa-circle-check",
                                    StyleTrainingRoom.chevron,
                                )
                            })
                        }
                    }
                }
            }
            Div({
                classes(styleBlock.trainerRoom.ctBoxMuscleGroup)
                style {
                    flex("auto")
                }
            }) {
                P {
                    Text(
                        "Упражнений: ${training.assignments.count()}. Группы мышц: ${
                            training.assignments?.fold(mutableListOf<String>()) { str, assign ->
                                assign.muscle_group_tags?.forEach {
                                    if (it != "") str.add(it)
                                }
                                str
                            }?.distinct() ?: " --- "
                        }"
                    )
                }
            }
        }
        dragEl()
    }
}

