package style.common

import global.QuickStyleProperty.borderBox
import global.QuickStyleProperty.marginWidthAuto
import global.QuickStyleProperty.myBackgroundGradient
import global.QuickStyleProperty.myBorderGradient
import global.styleConst
import global.stylePalette
import org.jetbrains.compose.web.css.*
import style.AppStylesheet
import style.SNavigation

object StyleNavigationCommon:  StyleSheet(AppStylesheet) {

    val title by style {
        borderBox()
//        width(100.percent)
        fontWeight(800)
        marginLeft(8.pt)
        fontSize(18.pt)
        fontFamily(styleConst.titleFontFamily)
        textAlign("left")
//        flex("auto")
    }


    val lineMenuButton by style {
        width(28.px)
        height(3.px)
        display(DisplayStyle.Block)
        margin(6.px)
    }

    val sideNav by style {
        height(100.percent)//window.innerHeight.px)
        position(Position.Fixed)
        top(0.px)
        left(0.px)
        overflowX("hidden")
        property("transition", "0.3s")
    }

    val avatarUserProfile by style {
        property("aspect-ratio", "1")
        property("object-fit", "cover")
        width(100.percent)
    }

    val textUserProfile by style {
        boxSizing("border-box")
        paddingTop(15.px)
        paddingBottom(10.px)
        width(100.percent)
        textAlign("center")
        fontSize(22.px)
    }

    val textUserContact by style {
        boxSizing("border-box")
        padding(5.px)
        width(100.percent)
        textAlign("center")
        fontSize(18.px)
    }

    val buttonSideNav by style {
        property("margin", "8pt auto")
        width(234.pt)
        fontFamily(styleConst.mainFontFamily)
//        padding(8.px, 8.px, 8.px, 8.px)
        padding(10.px)
        textDecoration("none")
        fontWeight(700)
        fontSize(15.pt)
//        property("text-transform", "uppercase");
        display(DisplayStyle.Block)
    }

    val buttEnter by style {
        marginBottom(20.px)
    }
    val closebtn by style {
        position(Position.Absolute)
        width(100.percent)
        bottom(20.px)
    }
}