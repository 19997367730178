package global

import androidx.compose.runtime.*
import api.firebase.getClientTrainings
import bdElement.BaseTraining
import bdElement.MyAnswerCommon
import elements.CenterDiv
import elements.FlexColumnDivParent
import elements.cardTrainingEditor
import elements.mainLoader
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.paddingBottom
import org.jetbrains.compose.web.css.px

object ClientTrainings {
    var trainingsLoaded by mutableStateOf<MyAnswerCommon<BaseTraining>?>(null)
        private set

    val pagination = PageAdapter(12) { limit, skip, silent ->
        if (silent.not()) statusLoading.loadingTraining = true
        selectedClient?.id?.let { getClientTrainings(it, limit, skip) }
    }

    private var firstStart: Boolean = true

    @Composable
    fun listenerForWritingCookies() {
        LaunchedEffect(selectedTrainingForEdit) {
            currentUser.userProfile?.id?.let { id_user ->
                selectedTrainingForEdit?.id?.let { id_training ->
                    setCookie("${id_user}_selectedClient_selTraining", id_training)
                } ?: run {
                    deleteCookie("${id_user}_selectedClient_selTraining")
                }
            }
        }
    }

    private fun getFromCookie(){
        if (firstStart){
            currentUser.userProfile?.id?.let { id_user ->
                getCookie("${id_user}_selectedClient_selTraining")?.let {id_training ->
                    if (id_training.isNotEmpty()){
                        trainingsLoaded?.rows?.find{ it.id == id_training}?.let {
                            setSelectedTrainingForEdit(it)
                        }
                    }
                }
            }
            firstStart = false
        }
    }

    fun setTrainingLoaded(load: MyAnswerCommon<BaseTraining>){
        trainingsLoaded = load
        load.totalCount?.let { pagination.totalCount = it }
        getFromCookie()
    }

    @Composable
    fun getViewListTrainings(){
        if (statusLoading.loadingTraining){
            CenterDiv({style { marginTop(40.px) }}) { mainLoader() }
        }   else {
            trainingsLoaded?.mutList?.let {
                FlexColumnDivParent(rowGap = styleConst.paddingCommon, attrs = {style {
                    paddingBottom(styleConst.paddingCommon)
                }}) {
                    for (training in it)
                        if (training.client_id == selectedClient?.id) {
                            cardTrainingEditor(training, false)
                        }
                }
            }
        }
        pagination.paginationElement()
    }

}