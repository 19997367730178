package global

import androidx.compose.runtime.*
import elements.CenterDiv
import elements.FlexRowDivParent
import elements.mainScrollTo
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.I
import org.jetbrains.compose.web.dom.Text
import style.First.StyleTrainingRoom

object BackButtonStack {

    /*
        private enum class ActionBack(val func: () -> Unit) {
            backToClientsTrainigns({
                selectedClient?.setSelectedTraining(null)
            }),
            backToClientsSpis({
                selectedClient = null
            })
        }

        private val stack = mutableListOf<ActionBack>()
    */

    fun preventiveGamburger() {
        gamburgerAction()
//        gamburgerAction()
        console.log("preventiveGamburger()")
        window.history.pushState(null, "null", window.location.href);
    }

    fun pressButtonBack() {
        modalContent?.let {
            it.closeFun()
            closeModadWindow()
        } ?: run {
            when (currentPage) {
                PageApplication.UserProfile -> gamburgerAction()
                PageApplication.TrainerRoom -> {
                    if (selectedTrainingForEdit != null) setSelectedTrainingForEdit(null)
                    else if (selectedClient != null) selectedClient = null
                    else if (selectedComplex != null) selectedComplex = null
                    else gamburgerAction()
                }

                PageApplication.Trainings -> {
                    if (selectedOpenTraining != null) {
                        selectedOpenTraining = null
                        saveScrollState?.let {
                            mainScrollTo(it)
//                    window.scrollTo(x = 0.0, y = it)
                        }
                        saveScrollState = null
                    } else gamburgerAction()
                }

                PageApplication.Registration -> gamburgerAction()
                PageApplication.Verification -> gamburgerAction()
                PageApplication.TitlePage -> {
                    if (selectedFreeTraining.value != null) {
                        selectedFreeTraining.value = null
                        saveScrollState?.let {
                            mainScrollTo(it)
//                    window.scrollTo(x = 0.0, y = it)
                        }
                        saveScrollState = null
                    } else gamburgerAction()
                }

                PageApplication.MyVideo -> gamburgerAction()
                PageApplication.Sales -> gamburgerAction()
                PageApplication.Helps -> gamburgerAction()
            }
        }
    }

    private var enableButtBack by mutableStateOf(false)

    @Composable
    fun buttonBack() {
        LaunchedEffect(
            currentPage,
            selectedFreeTraining.value,
            selectedOpenTraining,
            selectedClient,
            selectedComplex,
            selectedTrainingForEdit
        ) {
            enableButtBack = when (currentPage) {
                PageApplication.TrainerRoom -> selectedClient != null || selectedComplex != null || selectedTrainingForEdit != null
                PageApplication.Trainings -> selectedOpenTraining != null
                PageApplication.TitlePage -> selectedFreeTraining.value != null
                else -> false
            }
        }
        if (enableButtBack) CenterDiv({
            style {
                marginRight(10.pt)
            }
        }) {
            Button(attrs = {
                classes(styleBlock.commonElement.buttonBack)
                onClick {
                    pressButtonBack()
                }
            }) {
                FlexRowDivParent({
                    style {
                        alignItems(AlignItems.Center)
                        fontSize(18.px)
                        color(Color.white)
                        padding(0.px, 5.px)
                    }
                }) {

                    I({
                        classes(
                            "fa-solid", "fa-arrow-left",
                            StyleTrainingRoom.chevron,
                        )
                    })
                    if (window.innerWidth > 700) Text("назад")
                }

            }
        }
    }


    /*
        fun addSelectedTraining() {
            stack.add(ActionBack.backToClientsTrainigns)
        }

        fun addSelectedClient() {
            stack.add(ActionBack.backToClientsSpis)
        }

        fun removeSelectedTraining() {
            stack.remove(ActionBack.backToClientsTrainigns)
        }

        fun removeSelectedClient() {
            stack.remove(ActionBack.backToClientsSpis)
        }
    */

    private fun gamburgerAction() {
        widthSideNavPx = if (widthSideNavPx == widthSide) 0 else widthSide
        headerTop = 0
//        console.log("gamburgerAction()")
        MainScope().launch {
            for (i in 1..5) {
//                console.log("gamburgerAction($i)")
                delay(100)
                onresizeClientComplexSlider()
            }
        }
//        console.log("gamburgerAction")
    }

}