package elements

import androidx.compose.runtime.*
import api.firebase.setNewTagsVideoFirestore
import bdElement.BaseVideo
import bdElement.MyTags
import global.*
import kotlinx.browser.window
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import style.common.StyleCustomProperties

class FilterTagsBlock(val tagsVideo: TagsVideo = TagsVideo()) {


    private fun getArrayThisToStr(array: ArrayList<Pair<MyTags, MutableState<Boolean>>>): String {
        var rez = ""
        array.forEach {
            if (it.second.value) {
                if (rez != "") rez += ", "
                rez += it.first.name
            }
        }
        if (rez == "") rez = " - "
        return rez
    }

    private fun getIsEmpty(array: ArrayList<Pair<MyTags, MutableState<Boolean>>>): Boolean {
        var rez = false
        array.forEach {
            if (it.second.value) {
                rez = true
            }
        }
        return rez
    }

    fun getSexStr_Name() = getArrayThisToStr(tagsVideo.sex_tags_state)
    fun getEquipmentStr_Name() = getArrayThisToStr(tagsVideo.equipment_tags_state)
    fun getMuscleGroupStr_Name() = getArrayThisToStr(tagsVideo.muscle_group_tags_state)
    fun getSex_IsNotEmpty() = getIsEmpty(tagsVideo.sex_tags_state)
    fun getEquipment_IsNotEmpty() = getIsEmpty(tagsVideo.equipment_tags_state)
    fun getMuscleGroup_IsNotEmpty() = getIsEmpty(tagsVideo.muscle_group_tags_state)

    @Composable
    fun setListener() {
        LaunchedEffect(tags_equipment.value) {//,tagsVideo.equipment_tags_state
            tagsVideo.equipment_tags_state = arrayListOf<Pair<MyTags, MutableState<Boolean>>>().apply {
                tags_equipment.value?.rows?.forEach {
                    this.add(Pair(it, mutableStateOf(false)))
                }
            }
        }
        LaunchedEffect(tags_muscle_group.value) {//,tagsVideo.muscle_group_tags_state
            tagsVideo.muscle_group_tags_state = arrayListOf<Pair<MyTags, MutableState<Boolean>>>().apply {
                tags_muscle_group.value?.rows?.forEach {
                    this.add(Pair(it, mutableStateOf(false)))
                }
            }
        }
        LaunchedEffect(tags_sex.value) {//,tagsVideo.sex_tags_state
            tagsVideo.sex_tags_state = arrayListOf<Pair<MyTags, MutableState<Boolean>>>().apply {
                tags_sex.value?.rows?.forEach {
                    this.add(Pair(it, mutableStateOf(false)))
                }
            }
        }
    }

    @Composable
    fun getBlock(video: BaseVideo? = null, funUpdate: () -> Unit, funClose: () -> Unit) {
        video?.let { vid ->
            LaunchedEffect(tags_sex.value, tagsVideo.equipment_tags_state) {
                tagsVideo.sex_tags_state.forEach {
                    it.second.value = vid.sex_id?.contains(it.first.id) ?: false
                }
            }
            LaunchedEffect(tags_equipment.value, tagsVideo.muscle_group_tags_state) {
                tagsVideo.equipment_tags_state.forEach {
                    it.second.value = vid.equipment_type_tags_id?.contains(it.first.id) ?: false
                }
            }
            LaunchedEffect(tags_muscle_group.value, tagsVideo.sex_tags_state) {
                tagsVideo.muscle_group_tags_state.forEach {
                    it.second.value = vid.muscle_group_tags_id?.contains(it.first.id) ?: false
                }
            }
        }
        FlexColumnDivParent({
            style {
                position(Position.Relative)
                paddingTop(5.px)
                paddingBottom(5.px)
                borderRadius(if (window.innerWidth < 700) 0.px else 8.px)
                backgroundColor(stylePalette.lightGray)
                if (phoneDevice) height(window.innerHeight.px - 10.px) else maxHeight(window.innerHeight.px * 0.8)
            }
        }, rowGap = 5.px) {
            Div({
                style {
//                    myTestBorder(Color.red)
                    flex("auto")
//                    height(400.pt)
                    overflow("auto")
                }
            }) {
                for (tp in arrayListOf(
                    Pair(
                        "Пол",
                        tagsVideo.sex_tags_state.filter { it.first.name != "--- повтор ---" }), //if (video != null) sex else
                    Pair("Группы мышц", tagsVideo.muscle_group_tags_state),
                    Pair("Тип оборудования", tagsVideo.equipment_tags_state)
                )) {
                    Spoiler(
                        attrs = {
                            style {
                                padding(0.px, 10.px)
                            }
                        },
                        attrsChevron = null,
                        attrsContentDiv = null,
                        spoilerEnable = true,
                        spoilerContent = {
                            Div({
                                style {
                                    display(DisplayStyle.Flex)
                                    flexWrap(FlexWrap.Wrap)
                                    columnGap(5.pt)
                                    marginRight(-5.pt)
                                    rowGap(10.px)
                                }
                            }) {
                                tp.second.sortedBy { it.first.name }.forEach {
                                    MyCheckbox(it.second, it.first.name, stylePalette.black) {
                                        classes(StyleCustomProperties.flexBasis_2_6_5pt)
                                    }
                                }
                            }
                        },
                        startCloseCard = false
                    ) {
                        CenterDiv({
                            classes(styleBlock.trainerRoom.fmHeaders)
                            style {
                            }
                        }) {
                            P(attrs = {
                                style {
                                    width(100.percent)
                                    boxSizing("border-box")
                                }
                            }) {
                                Text(tp.first)
                            }
                        }
                    }
                }
            }
            CenterDiv({
                style {
                    flex("initial")
                    width(100.percent)
                    columnGap(20.pt)
                }
            }) {
                Button({
                    classes(styleBlock.trainerRoom.fmButton)
                    style {
                        backgroundColor(stylePalette.fontCommonColor)
//                        position(Position.Relative)
                        margin(5.px, 0.px)
                        padding(5.px, 15.px)
//                        width(250.px)
                    }
                    onClick {
                        if (video != null) funClose() else {
                            tagsVideo.sex_tags_state.forEach {
                                it.second.value = tagsVideo.sex_id.contains(it.first.id) ?: false
                            }
                            tagsVideo.equipment_tags_state.forEach {
                                it.second.value = tagsVideo.equipment_id.contains(it.first.id) ?: false
                            }
                            tagsVideo.muscle_group_tags_state.forEach {
                                it.second.value = tagsVideo.muscle_group_id.contains(it.first.id) ?: false
                            }
                            closeModadWindow()
                        }
                    }
                }) {
                    Text("Отмена")
                }
                if (video == null) Button({
                    classes(styleBlock.trainerRoom.fmButton)
                    style {
                        backgroundColor(stylePalette.myBlue)
//                        position(Position.Relative)
                        margin(5.px, 0.px)
                        padding(5.px, 15.px)
//                        width(250.px)
                    }
                    onClick {
                        tagsVideo.clearBlock()
                        /*
                                                tagsVideo.equipment_tags_state.forEach {
                                                    it.second.value = false
                                                }
                                                tagsVideo.muscle_group_tags_state.forEach {
                                                    it.second.value = false
                                                }
                                                tagsVideo.sex_tags_state.forEach {
                                                    it.second.value = false
                                                }
                        */
                    }
                }) {
                    Text("Очистить")
                }
                /*
                                if (video != null) Button({
                                    classes(styleBlock.trainerRoom.fmButton)
                                    style {
                                        backgroundColor(stylePalette.myBlue)
                //                        position(Position.Relative)
                                        margin(5.px, 0.px)
                                        padding(5.px, 15.px)
                //                        width(250.px)
                                    }
                                    onClick {
                                        funClose()
                                    }
                                }) {
                                    Text("Отмена")
                                }
                */
                Button({
                    classes(styleBlock.trainerRoom.fmButton)
                    style {
//                        position(Position.Relative)
                        margin(5.px, 0.px)
                        padding(5.px, 15.px)
//                        padding(5.px,)
//                        width(250.px)
                    }
                    onClick {
                        val equipment_id = arrayListOf<String>().apply {
                            tagsVideo.equipment_tags_state.forEach {
                                if (it.second.value) this.add(it.first.id ?: "")
                            }
                        }
                        val muscle_group_id = arrayListOf<String>().apply {
                            tagsVideo.muscle_group_tags_state.forEach {
                                if (it.second.value) this.add(it.first.id ?: "")
                            }
                        }
                        val sex_id = arrayListOf<String>().apply {
                            tagsVideo.sex_tags_state.forEach {
                                if (it.second.value) this.add(it.first.id ?: "")
                            }
                        }
                        if (video != null) {
                            setNewTagsVideoFirestore(
                                TagsVideo(
                                    sex_id = sex_id,
                                    equipment_id = equipment_id,
                                    muscle_group_id = muscle_group_id
                                ), video
                            ) {
                                video.sex_id = sex_id
                                video.equipment_type_tags_id = equipment_id
                                video.muscle_group_tags_id = muscle_group_id
                                funUpdate()
                            }
                        } else {
                            tagsVideo?.sex_id = sex_id
                            tagsVideo?.equipment_id = equipment_id
                            tagsVideo?.muscle_group_id = muscle_group_id
                            funUpdate()
                        }
                        funClose()
//                    getFilterBaseVideoFromFirestore(sex, equipment, muscle_group)
                    }
                }) {
                    Text(if (video != null) "Изменить" else "Применить")
                }
            }
        }

    }
}