package elements

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement
import style.First.StyleLoaders

@Composable
fun miniLoader(){
    Div({ classes(StyleLoaders.loading) })
}

@Composable
fun mainLoaderInOver(color: CSSColorValue = rgba(255,255,255,0.7),attrs: AttrBuilderContext<HTMLDivElement>? = null){
    CenterDiv({
        style {
            position(Position.Absolute)
            height(100.percent)
            width(100.percent)
            backgroundColor(color)
        }
        attrs?.invoke(this)
    }) {
        mainLoader()
    }
}

@Composable
fun miniLoaderInOver(color: CSSColorValue = rgba(255,255,255,0.7),attrs: AttrBuilderContext<HTMLDivElement>? = null){
    CenterDiv({
        style {
            position(Position.Absolute)
            height(100.percent)
            width(100.percent)
            backgroundColor(color)
        }
        attrs?.invoke(this)
    }) {
        miniLoader()
    }
}

@Composable
fun blankLoaderInOver(color: CSSColorValue = rgba(255,255,255,0.7),attrs: AttrBuilderContext<HTMLDivElement>? = null){
    CenterDiv({
        style {
            position(Position.Absolute)
            height(100.percent)
            width(100.percent)
            backgroundColor(color)
        }
        attrs?.invoke(this)
    }) {
//        miniLoader()
    }
}

@Composable
fun mainLoader(){
    Div({ classes(StyleLoaders.spinner) }) {
        Div({ classes(StyleLoaders.pipParent, StyleLoaders.pip1) }) {
            Div({ classes(StyleLoaders.pipBefore, StyleLoaders.pip1Before) })
        }
        Div({ classes(StyleLoaders.pipParent, StyleLoaders.pip2) }) {
            Div({ classes(StyleLoaders.pipBefore, StyleLoaders.pip2Before) })
        }
        Div({ classes(StyleLoaders.pipParent, StyleLoaders.pip3) }) {
            Div({ classes(StyleLoaders.pipBefore, StyleLoaders.pip3Before) })
        }
        Div({ classes(StyleLoaders.pipParent, StyleLoaders.pip4) }) {
            Div({ classes(StyleLoaders.pipBefore, StyleLoaders.pip4Before) })
        }
        Div({ classes(StyleLoaders.pipParent, StyleLoaders.pip5) }) {
            Div({ classes(StyleLoaders.pipBefore, StyleLoaders.pip5Before) })
        }
        Div({ classes(StyleLoaders.pipParent, StyleLoaders.pip6) }) {
            Div({ classes(StyleLoaders.pipBefore, StyleLoaders.pip6Before) })
        }
        Div({ classes(StyleLoaders.pipParent, StyleLoaders.pip7) }) {
            Div({ classes(StyleLoaders.pipBefore, StyleLoaders.pip7Before) })
        }
    }
}