package elements.helpers

import kotlinx.browser.window
import org.w3c.dom.DOMRect

class MyRect(rect: DOMRect) {

    val x = window.scrollX + rect.left
    //    val y = mainScrollTop() + rect.top; // window.scrollY
    val y = window.scrollY + rect.top
    val width = rect.width;
    val height = rect.height;

    fun contains(x: Double, y: Double) = this.x <= x && x <= this.x + this.width &&
            this.y <= y && y <= this.y + this.height;


}