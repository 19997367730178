package mainPage

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import elements.CenterDiv
import elements.FlexRowDivParent
import elements.buttonIcon
import global.*
import global.QuickStyleProperty.fontSubheader
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import style.First.StyleTrainingRoom


private var openArchive by mutableStateOf(false)
private var openArchiveReqComplex by mutableStateOf(false)
private var openArchiveTrainings by mutableStateOf(false)

@Composable
fun pageSpisOpenTrainings() {
    FlexRowDivParent({
        style {
            width(100.percent)
            alignItems(AlignItems.Center)
            marginBottom(styleConst.paddingCommon)
        }
    }) {
        P({
            style {
                flex("auto")
                fontSubheader()
                textAlign("left")
                width(100.percent)
            }
        }) {
            Text(if (openArchive) "Архив" else if ((ListTrainers.trainersLoaded?.totalCount ?: 0) > 1) "Ваши тренеры" else "Ваш тренер")
        }

        Img(src = if (openArchive) myIconURL.archiveBlue else myIconURL.archiveGray) {
            style {
                width(26.px)
                height(26.px)
            }
            onClick {
                openArchive = openArchive.not()
            }
        }
    }
/*
    CenterDiv({
        style {
            position(Position.Relative)
            width(100.percent)
            height(30.pt)
//            position(Position.Relative)
        }
    }) {
        H2({ classes(styleBlock.commonElement.headerBlock) }) {
            if (openArchive) Text("Архив") else {
                if ((ListTrainers.trainersLoaded?.totalCount ?: 0) > 1) Text("Ваши тренеры") else Text("Ваш тренер")
            }
        }
        if (ListTrainers.oldTrainers()) Div({
            style {
                position(Position.Absolute)
                top(0.px)
//            height(100.percent)
                right(0.px)
            }
        }) {
            CenterDiv {
                buttonIcon(listOf("fa", "fa-box-archive", StyleTrainingRoom.chevron), attrs = {
                    classes(
                        styleBlock.trainerRoom.buttNewTraining,
                        if (openArchive) styleBlock.trainerRoom.buttAccept else styleBlock.trainerRoom.buttResetPasteTrainings
                    )
                }, minSize = 20.pt) {
                    openArchive = openArchive.not()
                }
            }
        }
    }
*/
    if (openArchive) ListTrainers.getViewListCloseTrainers() else ListTrainers.getViewListTrainers()
    if (ListRequestsComplexNewForClient.newRequest != 0 || ListRequestsComplexCloseForClient.closeRequest != 0) FlexRowDivParent(
        columnGap = 5.pt
    ) {
        CenterDiv {
            buttonIcon(listOf("fa", "fa-box-archive", StyleTrainingRoom.chevron), attrs = {
                classes(
                    styleBlock.trainerRoom.buttNewTraining,
                    if (openArchiveReqComplex) styleBlock.trainerRoom.buttAccept else styleBlock.trainerRoom.buttResetPasteTrainings
                )
            }, minSize = 25.pt) {
                openArchiveReqComplex = openArchiveReqComplex.not()
            }
        }
        H2({
            classes(styleBlock.commonElement.headerBlock)
            style {
                marginBottom(10.pt)
            }
        }) {
            Text("Запросы комплексов")
        }
    }
    if (openArchiveReqComplex) ListRequestsComplexCloseForClient.getViewListRequestsComplex()
    else ListRequestsComplexNewForClient.getViewListRequestsComplex()
    FlexRowDivParent({
        style {
            width(100.percent)
            alignItems(AlignItems.Center)
            margin(styleConst.paddingCommon,0.px)
        }
    }) {
        P({
            style {
                flex("auto")
                fontSubheader()
                textAlign("left")
                width(100.percent)
            }
        }) {
            Text(if (openArchiveTrainings) "Прошедшие тренировки" else "Открытые тренировки")
        }

        Img(src = if (openArchiveTrainings) myIconURL.archiveBlue else myIconURL.archiveGray) {
            style {
                width(26.px)
                height(26.px)
            }
            onClick {
                openArchiveTrainings = openArchiveTrainings.not()
            }
        }
    }
    if (openArchiveTrainings)
        OldTrainings.getViewListTrainings()
    else
        OpenTrainings.getViewListTrainings()
/*
    if (OldTrainings.trainingsLoaded?.mutList?.isNotEmpty() == true) {
        Div({
            style {
                width(100.percent)
//                height(40.pt)
//                position(Position.Relative)
            }
        }) {
            P({
                style {
                    fontSubheader()
                    textAlign("left")
                    width(100.percent)
                }
            }) {
                Text("Прошедшие тренировки")
            }
        }
    }
*/

}