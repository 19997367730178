package elements

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import global.modalContent
import global.stylePalette
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.I
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLElement
import style.First.StyleModalWindow
import style.First.StyleTrainingRoom

@OptIn(ExperimentalComposeWebApi::class)
@Composable
fun modalWindow(modal: ModalContent, visible: MutableState<Boolean>) {
    Div({
        classes(StyleModalWindow.hystmodal)
        classes(if (visible.value) StyleModalWindow.hystmodalActive else StyleModalWindow.hystmodalDeactive)
        style {
            fontFamily("Roboto Slab")
        }
    }) {
        Div({
            classes(StyleModalWindow.hystmodal__wrap)
            onClick {
                val clickObj = it.target as HTMLElement
                if (clickObj.classList.contains(StyleModalWindow.hystmodal__wrap).not()) return@onClick
                if (modal.closableBack){
                    modalContent?.closeFun()
                    visible.value = false
                    modalContent = null
                }
            }
        }) {
            Div({
                classes(StyleModalWindow.hystmodal__window)
                classes(if (visible.value) StyleModalWindow.hystmodalActive else StyleModalWindow.hystmodalDeactive)
//                attr("role", "dialog")
//                attr("aria-modal", "true")
//                onClick {
//                    console.log("div Window")
//                }
                style {
                    if (modal.fullscreen) {
                        maxWidth(100.percent)
                        width(100.percent)
                        height(100.percent)
                        borderRadius(0.px)
                        padding(0.px)
                        margin(0.px)
                    }
                }
            }) {
                if (modal.fullscreen.not()) Button({
                    classes(StyleModalWindow.hystmodalClose)
//                    attr("data-hystclose", "")
                    onClick {
                        console.log("Butt Close")
                        modalContent?.closeFun()
                        modalContent = null
                        visible.value = false
                    }
                }) {
                    I({
                        classes(
                            "fa", "fa-xmark", //"fa-beat",
                            StyleTrainingRoom.chevron,
                        )
                        style {
                            position(Position.Absolute)
                            fontSize(30.px)
                            transform {
                                translate((-50).percent, (-50).percent)
                            }
                            color(stylePalette.red)
                        }
                    })
                }
                Div({
//                    classes("contentModal")
//                    id("contentModal")
                }) {
                    modal.drawModalContent()
                }
            }
        }
    }
//    }
}

@Composable
fun modalWindowHyst(modal: ModalContent) {
    Div({
        classes("hystmodal")
        id("myModal1")
        attr("aria-hidden", "true")
    }) {
        Div({
            classes("hystmodal__wrap")
        }) {
            Div({
                classes("hystmodal__window")
                attr("role", "dialog")
                attr("aria-modal", "true")
            }) {
                Button({
                    classes("hystmodal__close")
                    attr("data-hystclose", "")
                    onClick {
                    }
                }) {
                    Text("Закрыть")
                }
                Div({
                    classes("contentModal")
                    id("contentModal")
                }) {
                    modal.drawModalContent()
                }
            }
        }
    }
//    }
}

abstract class ModalContent() {

    open val fullscreen = false
    open val closableBack = true

    open fun closeFun() {}

    @Composable
    abstract fun drawModalContent()
}