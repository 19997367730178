package elements

import androidx.compose.runtime.Composable
import bdElement.BaseTraining
import global.*
import global.QuickStyleProperty.borderBox
import global.QuickStyleProperty.fontCommon
import global.QuickStyleProperty.fontSmall
import global.QuickStyleProperty.fontSubheader
import global.QuickStyleProperty.marginWidthAuto
import global.QuickStyleProperty.myBoxShadow
import global.QuickStyleProperty.myTextShadow
import global.QuickStyleProperty.zIndex
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import style.First.StyleTrainingRoom
import kotlin.js.Date

@Composable
fun cardCloseTraining(training: BaseTraining) {
    cardCommonClientTraining(
        training,
        smallC = stylePalette.fontCommonColor,
        nameC = stylePalette.fontCommonColor,
        opisC = stylePalette.fontCommonColor,
        backC = stylePalette.fontUnactiveColor,
    )
}

@Composable
fun cardCloseTrainingOld(training: BaseTraining) {
    Div({
        classes(StyleTrainingRoom.cardTrainingDecor)
        style {
            padding(5.px)
            position(Position.Relative)
            zIndex(if (dropDownElementNow == training.id.toString()) 150 else 10)
        }
    }) {
        FlexRowDivParent({ style { position(Position.Relative) } }) {
            CenterDiv({
                style {
                    flex("initial")
                    marginWidthAuto()
                    minWidth(30.pt)
                    minHeight(30.pt)
                }
            }) {
                markerRound(stylePalette.lightGray, 20.pt)
            }
            Div({
                style {
                    flex("auto")
//                    padding(5.px)
                    borderRadius(10.px)
//                    myBoxShadow(
//                        CSSShadow(-3.px, -3.px, 5.px, color = rgb(255, 255, 255)),
//                        CSSShadow(2.px, 2.px, 5.px, color = rgba(0, 0, 0, 0.3))
//                    )

                }
            }) {
                Div({
                    style {
//                        paddingLeft(5.px)
//                        paddingBottom(5.px)
                        fontFamily(styleConst.mainFontFamily)
                    }
                }) {
                    H3 {
                        Text(training.name ?: "")
                    }
                    P({
                        style {
                            color(stylePalette.gray)
                            fontFamily(styleConst.mainFontFamily)
                            fontSize(14.px)
                        }
                    }) {
                        Text(
                            "${Date((training.date_open ?: 0) - Date().getTimezoneOffset()).toLocaleDateString()} " +
                                    "- ${Date((training.date_close ?: 0) - Date().getTimezoneOffset() - 86400000.0).toLocaleDateString()}"
                        )
                        /*
                                                Text("Доступ был с ${Date((training.date_open ?: 0) - Date().getTimezoneOffset()).toLocaleDateString()} " +
                                                        "по ${Date((training.date_close ?: 0) - Date().getTimezoneOffset()-86400000.0).toLocaleDateString()}")
                        */
                    }
                }
            }
            training.getMarkerComplex()
            CenterDiv({
                classes(StyleTrainingRoom.copyButton)
                style {
                    flex("initial")
                    fontSize(23.pt)
                    marginWidthAuto()
                    minWidth(30.pt)
                    minHeight(30.pt)
                    color(if ((training.completed ?: 0) > 0) stylePalette.myOrange else stylePalette.lightGray)
                    myTextShadow(
                        CSSTextShadow(-2.px, -2.px, 8.px, color = rgb(255, 255, 255)),
                        CSSTextShadow(2.px, 2.px, 8.px, color = rgba(0, 0, 0, 0.6)),
                        CSSTextShadow(0.px, 0.px, 2.px, color = rgba(0, 0, 0, 0.99))
                    )
                }
            }) {
                I({
                    classes(
                        "fa-solid", "fa-circle-check",
                        StyleTrainingRoom.chevron,
                    )
                })
            }
        }
        Div({
            style {
//                flex("auto")
                width(100.percent)
                padding(5.px)
                boxSizing("border-box")
                borderRadius(6.px)
                fontSize(14.px)
//                marginBottom(5.px)
                marginTop(5.px)
                /*
                                myBoxShadow(
                                    CSSShadow(-2.px, -2.px, 3.px, color = rgba(255, 255, 255, 0.3), inner = true),
                                    CSSShadow(2.px, 2.px, 3.px, color = rgba(0, 0, 0, 0.4), inner = true)
                                )
                */
                backgroundColor(stylePalette.gray)
                color(stylePalette.white)
                fontFamily(styleConst.mainFontFamily)
            }
        }) {
            P {
                Text(
                    "Упражнений: ${training.assignments.count()}. Группы мышц: ${
                        training.assignments?.fold(mutableListOf<String>()) { str, assign ->
                            assign.muscle_group_tags?.forEach {
                                if (it != "") str.add(it)
                            }
                            str
                        }?.distinct() ?: " --- "
                    }"
                )
            }
        }
    }
}
