package mainPage

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import bdElement.BaseTraining
import elements.*
import global.*
import global.QuickStyleProperty.borderBox
import global.QuickStyleProperty.fontCommon
import global.QuickStyleProperty.fontHeader
import global.QuickStyleProperty.fontSubheader
import global.QuickStyleProperty.marginWidthAuto
import global.QuickStyleProperty.myBoxShadow
import global.QuickStyleProperty.myNonClick
import global.QuickStyleProperty.myTextShadow
import global.QuickStyleProperty.zIndex
import kotlinx.browser.document
import kotlinx.browser.window
import mainPage.modalWindows.ModalComplexInfo
import mainPage.modalWindows.ModalRequestNewClient
import mainPage.pageTitleElement.trainerCoverBlock
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.*
import style.White.StyleCommonElementWhite
import style.common.StyleNavigationCommon
import style.common.StylePageTitleCommon


@Composable
fun pageTitle() {
    selectedFreeTraining.value?.let {
        MyContainerInSection {
            pageClientSelectedTraining(it) {
                selectedFreeTraining.value = null
                saveScrollState?.let {
                    mainScrollTo(it)
//                    window.scrollTo(x = 0.0, y = it)
                }
                saveScrollState = null
            }
        }
    } ?: run {
        Img(src = "https://FitConstructorImg.b-cdn.net/other/metal_GYM.jpg") {
//        Img(src = "https://FitConstructorImg.b-cdn.net/other/zaklep_2.jpg"){
            style {
                position(Position.Fixed)
                top(0.px)
                left(0.px)
                property("object-fit", "cover")
                zIndex(-1)
                overflowY("visible")
                myNonClick()
                width(100.percent)
                height(window.outerHeight.px*1.10)
            }
        }
        blockMainTitle()
        (statusLoading.trainerProfile
            ?: if (currentUser.userProfile?.role?.contains("trainer") == true) currentUser.userProfile else null).let { trainer ->
            blockPluses(trainer == null)
            blockSteps(trainer == null)
        }
        if (statusLoading.checkTitleTrainer().not()) blockTrainings(
            "Комплекс тренировок для девушек",
            freeGirlTrainings,
            "radial-gradient(circle at 0% 50%,rgba(50, 50, 50, 0.0),rgba(20, 20, 20, 0.8))",
            "girlsBlock"
        )
        if (statusLoading.checkTitleTrainer().not()) blockTrainings(
            "Комплекс тренировок для парней",
            freeBoyTrainings,
            "radial-gradient(circle at 100% 50%,rgba(50, 50, 50, 0.0),rgba(20, 20, 20, 0.8))",
            "boysBlock"
        )
        blockButton()
    }
}

@OptIn(ExperimentalComposeWebApi::class)
@Composable
fun blockMainTitle() {
    /*
        CenterDiv({style {
            position(Position.Fixed)
            width(100.percent)
            height(100.percent)
            top(0.percent)
            left(0.percent)
        }}) {
            Div({
                classes("shadow")
                style {
                    width(100.pt)
                    height(100.pt)
                }
            }){

            }
        }
    */
    (statusLoading.trainerProfile
        ?: if (currentUser.userProfile?.role?.contains("trainer") == true) currentUser.userProfile else null)?.let { trainer ->
        Div({
            style {
                position(Position.Relative)
//                backgroundImage("url(\"metal_3.jpg\")")
//                backgroundImage("url(\"zaklep_3.jpg\")")

//                backgroundImage("url(\"https://FitConstructorImg.b-cdn.net/other/zaklep_2.jpg\")")
//                backgroundAttachment("fixed")
//                minHeight(100.pt)

                //                backgroundImage("url(\"zaklep_2.jpg\")")
//                backgroundImage("url(\"spir_1.jpg\")")
//                backgroundImage("url(\"zaklep_1_1.jpg\")") //backgroundImage = "url(\"metal_1.jpg\")"

//                backgroundRepeat("no-repeat")
//                backgroundAttachment("fixed")
//                backgroundSize("cover")
//                backgroundPosition("center")

                //                    backgroundBlendMode()
            }
        }) {
            MyContainerInSection() {
                LaunchedEffect(Unit, statusLoading.complex_trainerProfile) {
                    statusLoading.complex_trainerProfile?.let {
                        openModalWindow(ModalComplexInfo(it) {})
                        statusLoading.complex_trainerProfile = null
                    }
                }
                LaunchedEffect(Unit, statusLoading.new_client_trainerProfile) {
                    statusLoading.new_client_trainerProfile?.let {
                        statusLoading.trainerProfile?.let { trainer ->
                            openModalWindow(ModalRequestNewClient(trainer) {})
                        }
                        statusLoading.new_client_trainerProfile = null
                    }
                }
                trainerCoverBlock(trainer)
                /*
                                CutBorderBox(40, 2, attrs = {
                                    style {
                                        if (phoneDevice.not()) marginTop(96.px)
                                        marginBottom(96.px)
                                        if (phoneDevice.not() && window.innerWidth > 800){
                                            marginLeft(100.pt)
                                            marginRight(100.pt)
                                        }
                                        flex("initial")
                                    }
                                    onClick {
                //                    onClick()
                                    }
                                }, background = "linear-gradient(0deg,#ffffffdd,#ffffffdd)", lineHeightZero = false) {
                */
                CenterDiv({
                    style {
                        flex("initial")
                    }
                }) {
                    CenterDiv({
                        style {
                            backgroundColor(rgba(255, 255, 255, 0.9))
                            if (phoneDevice.not()) marginTop(96.px)
                            marginBottom(96.px)
                            if (phoneDevice.not() && window.innerWidth > 820) {
                                width(820.px)
//                            marginLeft(130.pt)
//                            marginRight(130.pt)
                            }
                            borderRadius(15.px)
                        }
                    }) {
                        RichTextView(trainer.description ?: "", attrs = {
                            classes(styleBlock.commonElement.rtView)
                            style {
                                trainer.description?.let {
                                    margin(30.pt, 20.pt)
                                }
//                        myTestBorder()
//                        maxWidth(400.px)

                            }
                        })
                    }
                }
//                }
                if (statusLoading.checkZayavka.not()) CenterDiv({
                    style {
                        flex("initial")
                    }
                }) {
                    Div({
                        classes(StylePageTitleCommon.textShadowHover)
                        style {
//                            fontFamily(styleConst.titleFontFamily)
                            fontWeight(600)
                            width(100.percent)
                            borderBox()
                            textAlign("center")
                            fontSize(25.px)

                            marginBottom(96.px)
                            flexDirection(FlexDirection.Column)
                            backgroundColor(stylePalette.myGreen)
                            color(Color.white)
                            padding(20.pt, 20.pt)
                            lineHeight("1")
                            borderRadius(15.px)
                            maxWidth(820.px)
                            myBoxShadow(
                                CSSShadow(
                                    dx = if (phoneDevice) 4.px else 6.px,
                                    dy = if (phoneDevice) 4.px else 6.px,
                                    blur = 10.pt, //if (phoneDevice) 15.px else
                                    spread = 5.pt,
                                    color = rgba(0, 0, 0, 0.15),
//                            inner = true
                                )
                            )
                        }
                        onClick {
                            statusLoading.trainerProfile?.let { trainer ->
                                openModalWindow(ModalRequestNewClient(trainer))
                            }
                        }
                    }) {
                        Text("Записаться на персональные")
                        Br {}
                        Text("онлайн-тренировки")
                    }
                    /*
                                        CutBorderBox(15, 3, attrs = {
                                            onClick {
                    //                    onClick()
                                            }
                                        }, background = "linear-gradient(90deg,#0071b9,#f1a41700,#0071b9)") {
                                            CutBorderBox(15, 2, attrs = {
                                                onClick {
                                                    statusLoading.trainerProfile?.let { trainer ->
                                                        openModalWindow(ModalRequestNewClient(trainer))
                                                    }
                                                }
                                            }, background = "linear-gradient(0deg,#fff,#fff)") {
                                                CenterDiv({
                                                    classes(StylePageTitleCommon.textShadowHover, StyleCommonElementWhite.headerBlock)
                                                    style {
                                                        flexDirection(FlexDirection.Column)
                                                        backgroundColor(stylePalette.myGreen)
                                                        color(Color.white)
                                                        padding(20.pt, 50.pt)
                                                        lineHeight("1")
                                                    }
                                                }) {
                    //                        Div {
                                                    Text("Записаться на персональные")
                                                    Br {}
                    //                        }
                    //                        Div({style {
                    //                            marginTop(10.px)
                    //                        }}) {
                                                    Text("онлайн-тренировки")
                    //                        }
                                                }
                                            }
                                        }
                    */
                }
                ListComplexesForSale.getViewSlider { }
            }
        }
    }
    if (statusLoading.checkTitleTrainer().not()) CenterDiv({
        style {
//            https://FitConstructorImg.b-cdn.net/other/Blins_001.jpg
//            backgroundImage("linear-gradient(rgba(20, 20, 20, 0.7),rgba(50, 50, 50, 0.5)), url('https://FitConstructorImg.b-cdn.net/other/samuel_girven_fqmu99l8sqo_unsplash.jpg')")
            width(100.percent)
//            backgroundSize("cover")
//            backgroundPosition("center")
            height(window.innerHeight.px - headerHeight.px)
//            display(DisplayStyle.Flex)
//            alignItems(AlignItems.Center)
        }
    }) {
        MyContainerInSection {
            CenterDiv({
                style {
                    flexDirection(FlexDirection.Column)
                    margin(46.px, 0.pt)
                }
            }) {
                P({
                    style {
                        fontHeader(stylePalette.myNewRed)
                        padding(2.px, 10.px)
                        backgroundColor(Color.white)
                        textAlign("center")
                        marginBottom(styleConst.paddingSmall)
                    }
                }) {
                    Text("Фитнес онлайн")
                }
                /*               CenterDiv {
                                   P({
                                       style {
                                           fontHeader(stylePalette.myNewRed)
                                           padding(2.px,10.px)
                                           backgroundColor(Color.white)
                                           textAlign("center")
                                       }
                                   }) {
                                       Text("вместе с")
                                   }
               */                    P({
                style {
                    fontHeader(Color.white)
                    fontSize(28.pt)
//                            fontWeight(600)
//                            fontFamily(styleConst.mainFontFamily)
                    textAlign("center")
                    backgroundColor(stylePalette.myNewRed)
                    padding(2.px, 10.px)
                }
            }) {
                Text("MasterGYM")
            }

//                }
            }

            /*
                        CenterDiv({ style { width(100.percent) } }) {
                            Img(src = "https://FitConstructorImg.b-cdn.net/other/Blins_001.jpg") {
                                style {
                                    maxWidth(window.innerWidth * 0.95.px)
                                    maxHeight(window.innerHeight * 0.4.px)
                                    property("object-fit", "fit")
                                }
            //                    classes(StyleNavigationCommon.avatarUserProfile, styleBlock.navigation.avatarUserProfile)
                            }
                        }
            */
            FlexRowDivParent({
                style {
                    margin(20.px, 0.px)
                }
            }, flexWrap = FlexWrap.Wrap) {
/*                CenterDiv({
                    style {
                        marginTop(25.pt)
                        marginBottom(10.pt)
                        width(100.percent)
                    }
                }) {
                    H2({
                        classes(StylePageTitleCommon.mainHeaders, styleBlock.pageTitle.mainHeaders)
                    }) {
//                    Text("Личный тренер в твоем смартфоне")
                        Text("Личный тренер")
//                        Text("Personal trainer")
                        Br()
                        Text("в твоем смартфоне")
//                        Text("on your smartphone")

                    }
                }*/
                CenterDiv({
                    style {
                        width(100.percent)
                        marginBottom(10.pt)
                    }
                }) {
                    P({
//                        classes(StylePageTitleCommon.textInHeaders, styleBlock.pageTitle.textInHeaders)
                        style {
                            margin(0.pt, 20.pt)
                            maxWidth(450.px)
                            textAlign("left")
                            fontSize(18.px)
                            borderRadius(styleConst.borderRadiusCommon)
                            padding(styleConst.paddingCommon)
                            backgroundColor(rgba(0, 0, 0, 0.7))
                            fontCommon(stylePalette.fontUnactiveColor)
                        }
                    }) {
/*
                        Text(
                            "Work out in the gym with video work- " +
                                    "outs, which are compiled by profes- " +
                                    "sionals specifically for your goals"
                        )
*/
                        Text("Откройте новые горизонты онлайн-тренировок: привлекайте, вдохновляйте и развивайте клиентов с нашим приложением")
                    }
                }
            }
            FlexRowDivParent({
                style {
                    margin(20.px, 5.percent)
                }
            }, flexWrap = FlexWrap.Nowrap, rowGap = 10.percent) {
                Button({
                    classes(StylePageTitleCommon.buttonComplexGirl)//, styleBlock.pageTitle.buttonComplexGirl
                    style {
//                        color(stylePalette.fontCommonColor)
                        borderRadius(styleConst.borderRadiusCommon)
                        border(0.px, LineStyle.Solid, Color.white)
                        fontCommon()
                    }
                    onClick {
                        document.getElementById("girlsBlock")?.getBoundingClientRect()?.y?.let {
                            val me = document.getElementById("mainElement")
                            me?.scrollTo(
                                ScrollToOptions(
                                    top = it + me.scrollTop,
                                    behavior = ScrollBehavior.SMOOTH
                                )
                            )
                        }
                    }
                }) {
                    Text("Примеры тренировок")
                }
                Button({
                    classes(StylePageTitleCommon.buttonComplexGirl)//, styleBlock.pageTitle.buttonComplexGirl
                    style {
//                        color(stylePalette.fontCommonColor)
                        borderRadius(styleConst.borderRadiusCommon)
                        border(0.px, LineStyle.Solid, Color.white)
                        fontCommon()
                    }
                    onClick {
                        currentPage = PageApplication.Helps
                        if (phoneDevice) widthSideNavPx = 0
                    }
                }) {
                    Text("Как пользоваться")
                }
//                navMenuButton("Как пользоваться", PageApplication.Helps)

                /*
                                Button({
                                    classes(StylePageTitleCommon.buttonComplex, styleBlock.pageTitle.buttonComplex)
                                    onClick {
                                        document.getElementById("boysBlock")?.getBoundingClientRect()?.y?.let {
                                            val me = document.getElementById("mainElement")
                                            me?.scrollTo(
                                                ScrollToOptions(
                                                    top = it + me.scrollTop,
                                                    behavior = ScrollBehavior.SMOOTH
                                                )
                                            )
                                        }
                                    }
                                }) {
                                    Text("Пробник для парней")
                                }
                */
            }
        }
    }
}

val imgSize: CSSNumeric = 50.px

@Composable
fun blockPluses(forTrainers: Boolean) {
    MyContainerInSection(backgroundColor = Color.white) {
        FlexRowDivParent({
            style {
                margin(40.px, 0.px)
            }
        }, flexWrap = FlexWrap.Wrap) {
            FlexRowDivParent({
                style {
                    margin(10.pt, 10.pt)
                }
            }, columnGap = 20.pt) {
                Div({
                    style {
//                    margin(0.pt,10.pt)
//                    width(70.px)
//                    height(70.px)
                    }
                }) {
                    Img("https://FitConstructorImg.b-cdn.net/other/Icon_Title_1.jpg", attrs = {
                        style {
                            property("object-fit", "scale-down")
                            width(imgSize)
                            height(imgSize)
                        }
                    })
                }
                Div({
                    style {
                        margin(90.px, 0.px)
                        maxWidth(350.px)
                        marginWidthAuto()
                    }
                }) {

                    H2({
                        classes(styleBlock.pageTitle.checkHeader_1, StylePageTitleCommon.checkHeader)
                    }) {
                        if (forTrainers)
                            Text("Максимизируй свой тренерский потенциал")
                        else
                            Text("Занимайся по плану")
                    }
                    H4({
                        classes(styleBlock.pageTitle.mainText, StylePageTitleCommon.mainText)
                    }) {
                        if (forTrainers)
                            Text("Подними свои онлайн-тренировки на новый уровень. Ускорь процесс составления тренировок. Составление программы тренировок никогда не было таким простым!")
                        else
                            Text("Выбирай тренировки по своим задачам. Следуй рекомендациям и достигай результата по своей личной программе.")
                    }
                }
            }
            FlexRowDivParent({
                style {
                    margin(10.pt, 10.pt)
                }
            }, columnGap = 20.pt) {
                Div({
                    style {
//                        margin(0.pt, 10.pt)
//                        width(70.px)
//                        height(70.px)
                    }
                }) {
                    Img("https://FitConstructorImg.b-cdn.net/other/Icon_Title_2.jpg", attrs = {
                        style {
                            property("object-fit", "scale-down")
                            width(imgSize)
                            height(imgSize)
                        }
                    })
                }
                Div({
                    style {
                        margin(90.px, 0.px)
                        maxWidth(350.px)
                        marginWidthAuto()
                    }
                }) {

                    H2({
                        classes(styleBlock.pageTitle.checkHeader_2, StylePageTitleCommon.checkHeader)
                    }) {
//                        Text("Work out at any time")
                        if (forTrainers)
                            Text("Из оффлайна в онлайн")
                        else
                            Text("В любое время")
                    }
                    H4({
                        classes(styleBlock.pageTitle.mainText, StylePageTitleCommon.mainText)
                    }) {
                        if (forTrainers)
                            Text("Сохраните своих клиентов в любых обстоятельствах! Переведите их на онлайн-тренировки с помощью MasterGYM! Тренируйте клиентов в любой точке мира!")
                        else
                            Text("Нет привязки к расписанию тренера: выбирай любое время и любой зал.")
                    }
                }
            }
            FlexRowDivParent({
                style {
                    margin(10.pt, 10.pt)
                }
            }, columnGap = 20.pt) {
                Div({
                    style {
//                        margin(0.pt, 10.pt)
//                        width(70.px)
//                        height(70.px)
                    }
                }) {
                    Img("https://FitConstructorImg.b-cdn.net/other/Icon_Title_3.jpg", attrs = {
                        style {
                            property("object-fit", "scale-down")
                            width(imgSize)
                            height(imgSize)
                        }
                    })
                }
                Div({
                    style {
                        margin(90.px, 0.px)
                        maxWidth(350.px)
                        marginWidthAuto()
                    }
                }) {

                    H2({
                        classes(styleBlock.pageTitle.checkHeader_3, StylePageTitleCommon.checkHeader)
                    }) {
                        Text("Попробуй бесплатно")
//                        Text("Первые три тренировки из курса - в подарок")
                    }
                    H4({
                        classes(styleBlock.pageTitle.mainText, StylePageTitleCommon.mainText)
                    }) {
                        if (forTrainers)
                            Text("Ведение одного клиента всегда бесплатно. Попробуй с одним и приведи еще.")
                        else
                            Text("Можешь попробовать и оценить качество и удобство.")
                    }
                }
            }
        }
    }
}

@Composable
fun blockSteps(forTrainers: Boolean) {
    MyContainerInSection({
        style {
            backgroundColor(stylePalette.black)
        }
    }) {
        Div({
            style {
                marginWidthAuto()
                padding(22.px)
//                paddingTop(22.px)
//                paddingBottom(22.px)
            }
        }) {
            H2({
                style {
                    color(stylePalette.lightGray)
                    fontFamily(styleConst.mainFontFamily)
                    fontSize(26.pt)
//                    fontFamily("TT Norms Pro")
                    textAlign("left")
                    marginBottom(10.px)
                }
            }) {
                Text("Как использовать приложение")
            }
            for (step in arrayListOf<Pair<String, String>>(
                Pair(
                    "Шаг 1:",
                    if (forTrainers)
                        "зарегистрируйся и заполни профиль"
                    else
                        "выбирай готовый комплекс тренировок или записывайся на персональный тренировки"
                ),
                Pair(
                    "Шаг 2:",
                    if (forTrainers)
                        "отправь свою ссылку из профиля текущим и потенциальным клиентам"
                    else
                        "иди в ближайший фитнес-зал, в который у тебя есть доступ"
                ),
                Pair(
                    "Шаг 3:",
                    if (forTrainers)
                        "составляй для своих клиентов комплексы и персональные тренировки"
                    else
                        "включай полученную тренировку на экране смартфона, смотри как тренер выполняет упражнение, повторяй за ним"
                ),
                Pair(
                    "Шаг 4:",
                    "пиши нам в форме обратной связи: что было сложно, что не получилось, чего не хватило"
                ),
            )) {
                H2({
                    classes(styleBlock.pageTitle.stapsHeaders)
                    style {
//                        fontWeight(600)
//                        fontSize(40.px)
//                        color(ColorRes.white)
                        marginTop(30.pt)
//                        color(rgb(232, 90, 36))
//                        fontFamily("TT Norms Pro")
                        textAlign("left")
                    }
                }) {
                    Text(step.first)
                }
                H4({
                    classes(styleBlock.pageTitle.mainText, StylePageTitleCommon.mainText)
                    style {
                        marginTop(10.px)
//                        color(stylePalette.gray)
//                        fontWeight(400)
//                        fontFamily("TT Norms Pro")
//                        fontSize(20.px)
//                        textAlign("left")
                    }
                }) {
                    Text(step.second)
                }
            }
        }
    }
}

@Composable
fun blockTrainings(name: String, trainings: ArrayList<BaseTraining>?, background: String, idSection: String) {
    MyContainerInSection(
        {
            id(idSection)
            style {
//                background("linear-gradient(97deg,rgba(50, 50, 50, 0.5),rgba(20, 20, 20, 0.7))")
//                background(background)
            }
        }
    ) {
        H2({
            classes(styleBlock.pageTitle.trainingHeader, StylePageTitleCommon.trainingHeader)
            style {
                color(Color.white)
            }
        }) {
            Text(name)
        }
        FlexRowDivParent({
            style {
                margin(30.px, 30.px)
                paddingBottom(20.px)
//                margin(60.px, 100.px)
//                paddingBottom(40.px)
            }
        }, rowGap = 30.px, columnGap = 30.px, flexWrap = FlexWrap.Wrap) {
            trainings?.forEach { train ->
                CenterDiv({
                    classes(styleBlock.pageTitle.trainingButt)
                    onClick {
                        saveScrollState = mainScrollTop() // window.scrollY
                        selectedFreeTraining.value = train
                        mainScrollTo(0.0)
//                        window.scrollTo(x = 0.0, y = 0.0)
                    }
                }) {

                    H2({
//                        classes(styleBlock.pageTitle.trainingHeader, StylePageTitleCommon.trainingHeader)
                        style {
                            property("user-select", "none")
                            color(rgb(242, 110, 66))
                            fontFamily("TT Norms Pro")
                            textAlign("left")
                        }
                    }) {
                        Text(train.name ?: "")
                    }
                }
            }
        }
    }
}

@Composable
fun blockButton() {
//    if (currentUser.login.not())
    Div({
        style {
            margin(140.px, 0.px)
        }
    }) {
        if (currentUser.login) {
            if (currentUser.getEmailVerified() == true) {
                if (canInstallApplication) {
                    Button({
                        classes(styleBlock.navigation.buttonSideNav, StyleNavigationCommon.buttonSideNav)
                        id("addToHomeScreenButton")
//                    style {
//                        display(DisplayStyle.None)
//                    }
                        onClick {
                            installPWA()
                        }
                    }) {
                        CenterDiv {
                            Text("Добавить на рабочий экран")
                        }
                    }
                    Div({ style { height(40.pt) } })
                }
                navMenuButton("Как пользоваться", PageApplication.Helps)
                Div({ style { height(40.pt) } })
                if (currentUser.userProfile?.role?.contains("trainer") == true)
                    navMenuButton("Перейти в тренерскую", PageApplication.TrainerRoom)
                else
                    navMenuButton("Перейти к персональным тренировкам", PageApplication.Trainings)
            }
        } else {
            navMenuButton("Как пользоваться", PageApplication.Helps)
            Div({ style { height(40.pt) } })
            buttonLogIn("Войти или зарегистрироваться", PageApplication.Registration, {
                classes(styleBlock.navigation.buttEnter, StyleNavigationCommon.buttEnter)
            })
            /*
                        Button({
                            classes(StyleSideNav.buttonSideNav, StyleSideNav.buttEnter)
                            onClick {
                                currentPage = pageApplication.registration
                                if (window.innerWidth < 700.pt.value) widthSideNavPx = 0.pt
            //                loadUiGoogleSignIn("firebaseui-auth-container")
                            }
                            style {
                                marginWidthAuto()
                            }
                        }) {
                            Text("Войти")
                        }
            */
        }
    }
}
