package elements

import androidx.compose.runtime.Composable
import bdElement.BaseTraining
import global.*
import global.QuickStyleProperty.borderBox
import global.QuickStyleProperty.marginWidthAuto
import global.QuickStyleProperty.myBoxShadow
import global.QuickStyleProperty.myTextShadow
import global.QuickStyleProperty.zIndex
import kotlinx.browser.window
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import style.First.StyleClientRoom
import style.First.StyleTrainingRoom
import kotlin.js.Date
import kotlin.math.round

@Composable
fun cardOpenTraining(training: BaseTraining) {
    if (training.status == "second")
        cardCommonClientTraining(
            training,
            smallC = stylePalette.fontCommonColor,
            nameC = stylePalette.fontHeaderColor,
            opisC = stylePalette.fontCommonColor,
            backC = stylePalette.fontUnactiveColor,
        ) {
            saveScrollState = mainScrollTop()// window.scrollY
            selectedOpenTraining = training
            mainScrollTo(0.0)
        }
    else
        cardCommonClientTraining(
            training,
            smallC = stylePalette.fontUnactiveColor,
            nameC = stylePalette.fontHeaderColorInv,
            opisC = stylePalette.fontUnactiveColor,
            backC = stylePalette.myGreen,
        ) {
            saveScrollState = mainScrollTop()// window.scrollY
            selectedOpenTraining = training
            mainScrollTo(0.0)
        }
}

@Composable
fun cardOpenTrainingOld(training: BaseTraining) {
    FlexColumnDivParent({
        classes(styleBlock.clientRoom.itemCardTraining)
        style {
            padding(5.px)
            position(Position.Relative)
            zIndex(if (dropDownElementNow == training.id.toString()) 150 else 10)
        }
    }, rowGap = 5.px) {
        FlexRowDivParent({ style { position(Position.Relative) } }, columnGap = 5.pt) {
            CenterDiv({
                style {
                    flex("initial")
                    marginWidthAuto()
                    minWidth(30.pt)
                    minHeight(30.pt)
                }
            }) {
                markerRound(
                    if (training.status == "second") stylePalette.markerYellow else stylePalette.markerGreen,
                    20.pt
                )
            }
            Div({
                style {
                    flex("auto")
                }
            }) {
                Div({
                    style {
//                        paddingLeft(5.px)
//                        paddingBottom(5.px)
                    }
                }) {
                    H3({ classes(styleBlock.clientRoom.ctName) }) {
                        Text(training.name ?: "")
                    }
                    P({
                        classes(styleBlock.clientRoom.ctTime)
                        style {
//                            color(stylePalette.gray)
                            fontSize(14.px)
                        }
                    }) {
                        Text("${Date((training.date_open ?: 0) - Date().getTimezoneOffset()).toLocaleDateString()} " +
                                "- ${Date((training.date_close ?: 0) - Date().getTimezoneOffset() - 86400000.0).toLocaleDateString()}" +
                                " (Ост: ${
                                    (round(((training.date_close ?: 0) - getCurrentDateTimeUTC()) / 8640000.0) / 10).let {
                                        if (it < 0) 0 else it
                                    }
                                } д.)"
                        )
                        /*
                                                Text("Доступ с ${Date((training.date_open ?: 0) - Date().getTimezoneOffset()).toLocaleDateString()} " +
                                                        "по ${Date((training.date_close ?: 0) - Date().getTimezoneOffset()-86400000.0).toLocaleDateString()}" +
                                                        " Осталось: ${(round(((training.date_close ?: 0)-(Date().getTime() + Date().getTimezoneOffset()))/8640000.0)/10).let {
                                                            if (it<0) 0 else it
                                                        }} д.")
                        */
                    }
                }
            }
            training.getMarkerComplex()
            CenterDiv({
                classes(styleBlock.clientRoom.ctIconDoneButton)
                style {
                    flex("initial")
                    fontSize(23.pt)
                    marginWidthAuto()
                    minWidth(30.pt)
                    minHeight(30.pt)
                    color(if ((training.completed ?: 0) > 0) stylePalette.myOrange else stylePalette.lightGray)
//                    color(ColorRes.darkGreen)
                    myTextShadow(
                        CSSTextShadow(-2.px, -2.px, 8.px, color = rgb(255, 255, 255)),
                        CSSTextShadow(2.px, 2.px, 8.px, color = rgba(0, 0, 0, 0.6)),
                        CSSTextShadow(0.px, 0.px, 2.px, color = rgba(0, 0, 0, 0.99))
                    )
                }
            }) {
                I({
                    classes(
                        "fa-solid", "fa-circle-check",
                        StyleTrainingRoom.chevron,
                    )
                })
            }
        }
        Div({
            classes(styleBlock.clientRoom.ctBoxMuscleGroup)
            style {
                flex("auto")
            }
        }) {
            P {
                Text(
                    "Упражнений: ${training.assignments.count()}. Группы мышц: ${
                        training.assignments?.fold(mutableListOf<String>()) { str, assign ->
                            assign.muscle_group_tags?.forEach {
                                if (it != "") str.add(it)
                            }
                            str
                        }?.distinct() ?: " --- "
                    }"
                )
            }
        }
        Button({
            classes(styleBlock.clientRoom.buttonStart)
            style {
                borderBox()
                width(100.percent)
//                            marginAuto()
//                            marginTop(16.px)
//                            marginBottom(16.px)
            }

            onClick {
                saveScrollState = mainScrollTop()// window.scrollY
                selectedOpenTraining = training
                mainScrollTo(0.0)
//                window.scrollTo(x = 0.0, y = 0.0)
//                openModalWindow(ModalAddEditTraining() {
//
//                })
            }
        }) {
            Text("Начать тренировку")
        }
    }
}
