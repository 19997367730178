package bdElement

import androidx.compose.runtime.Composable
import kotlinx.serialization.Serializable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.HTMLImageElement


@Serializable
data class BunnyApiKey(
    var signature: String? = null,
    val expire: Long? = null,
    val guid: String? = null,
    val library: Long? = null
)

@Serializable
data class BaseVideo(
    var id: String? = null,
    val common: Boolean? = null,
    val author_id: String? = null,
    val date_upload: Long? = null,
    var muscle_group_tags_id: List<String>? = null,
    var equipment_type_tags_id: List<String>? = null,
    var sex_id: List<String>? = null,
    val bunny_library: String = "",
    val name: String = "",
    val bunny_guid: String = ""
) {
    @Composable
    fun getPreview(clickable: Boolean = true, attrs: AttrBuilderContext<HTMLImageElement>? = null) {
        getPreview(ForVideoFrame(bunny_library ?: "", bunny_guid ?: ""),clickable, attrs)
    }

    @Composable
    fun getFrameVideo(){
        getFrameVideo(ForVideoFrame(bunny_library ?: "",bunny_guid ?: ""))
    }
}

