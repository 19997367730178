package global

import androidx.compose.runtime.*
import api.firebase.getOpenTrainings
import bdElement.BaseTraining
import bdElement.MyAnswerCommon
import elements.CenterDiv
import elements.FlexColumnDivParent
import elements.cardOpenTraining
import elements.mainLoader
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.H4
import org.jetbrains.compose.web.dom.Text

object OpenTrainings {
    var trainingsLoaded by mutableStateOf<MyAnswerCommon<BaseTraining>?>(null)
        private set

    private var loadingProcess by mutableStateOf(true)

    val pagination = PageAdapter(6) { limit, skip, silent ->
        if (silent.not()) loadingProcess = true
        getOpenTrainings(limit, skip)
    }

    private var firstStart: Boolean = true

    @Composable
    fun listenerForWritingCookies() {
        LaunchedEffect(selectedOpenTraining) {
            currentUser.userProfile?.id?.let { id_user ->
                selectedOpenTraining?.id?.let { id_training ->
                    setCookie("${id_user}_selectedOpenTraining", id_training)
                } ?: run {
                    deleteCookie("${id_user}_selectedOpenTraining")
                }
            }
        }
    }

    fun setTrainingLoaded(load: MyAnswerCommon<BaseTraining>) {
        trainingsLoaded = load
        load.totalCount?.let { pagination.totalCount = it }
        if (firstStart) {
            currentUser.userProfile?.id?.let { id_user ->
                getCookie("${id_user}_selectedOpenTraining")?.let { id_train ->
                    if (id_train.isNotEmpty()) {
                        trainingsLoaded?.rows?.find { it.id == id_train }?.let {
                            selectedOpenTraining = it
                        }
                    }
                }
            }
            firstStart = false
        }
        loadingProcess = false
    }

    @Composable
    fun getViewListTrainings() {
        if (loadingProcess) {
            CenterDiv({ style { marginTop(40.px) } }) { mainLoader() }
        } else {
            FlexColumnDivParent(rowGap = styleConst.paddingCommon, attrs = {style {
                paddingBottom(styleConst.paddingCommon)
            }}) {
                trainingsLoaded?.mutList?.let {
                    for (training in it) cardOpenTraining(training)
                }
                if (trainingsLoaded?.mutList?.isEmpty() ?: true) H4({
                    style {
                        margin(10.px, 0.px)
                        color(stylePalette.gray)
                    }
                }) {
                    Text("На данный момент нет ни одной доступной тренировки.")
                }
            }
        }
        pagination.paginationElement()
    }
}
