package mainPage.modalWindows

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import api.commonUserPostFDRequest
import api.firebase.updateUserProfile
import elements.CenterDiv
import elements.FlexColumnDivParent
import elements.ModalContent
import global.QuickStyleProperty.borderBox
import global.QuickStyleProperty.myFullSize
import global.closeModadWindow
import global.styleBlock
import global.stylePalette
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.Text
import style.First.StyleUserProfile

private var disabledButtSet by mutableStateOf("temp")

class ModalChangePhone(currentName: String) : ModalContent() {
    var inputPhoneState by mutableStateOf(currentName)

    @Composable
    override fun drawModalContent() {
        FlexColumnDivParent({
            style {
                myFullSize()
                backgroundColor(stylePalette.bodyModalColor)
                borderRadius(10.px)
                padding(10.px)
            }
        }) {
            Input(type = InputType.Text) {

                style {
                    width(100.percent)
                    borderBox()
                }
                classes(styleBlock.commonElement.inputText)
                value(inputPhoneState)
                onInput { event -> inputPhoneState = event.value }
            }
            Button({
                classes(styleBlock.trainerRoom.fmButton)
                style {
                    borderBox()
                    flex("auto")
                }
                attr(disabledButtSet, "")
                onClick {
                    disabledButtSet = "disabled"
                    console.log("buttDisable")
                    setNewPhone()
                }
            }){
                Text("Изменить телефон")
            }
        }
    }

    fun setNewPhone() {
        if (inputPhoneState != "") {
            commonUserPostFDRequest("set_user_field",{ formData ->
                formData.append("fieldName","phone")
                formData.append("newValue",inputPhoneState)
            }){
                updateUserProfile()
                closeModadWindow()
                disabledButtSet = "temp"
            }
        }
    }

}

