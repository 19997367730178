package elements

import androidx.compose.runtime.Composable
import api.firebase.deleteTrainingFirestore
import bdElement.BaseComplex
import global.*
import global.QuickStyleProperty.borderBox
import global.QuickStyleProperty.fontCommon
import global.QuickStyleProperty.fontSubheader
import global.QuickStyleProperty.myTextShadow
import global.QuickStyleProperty.zIndex
import mainPage.modalWindows.ModalAddEditComplex
import mainPage.modalWindows.ModalDeleteObject
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.I
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text
import style.First.StyleTrainingRoom

@Composable
fun CardComplex(complex: BaseComplex, forSelect: Boolean = false, onClick: () -> Unit = {}) {
    FlexRowDivParent({
        style {
            zIndex(if (dropDownElementNow == complex.id.toString()) 150 else 10)
//            maxWidth(100.percent)
//            borderBox()
//            myTestBorder()
        }
    }, columnGap = styleConst.paddingCommon) {
//        CutBorderBox(15, 2, attrs = {
        CenterDiv {
            Div({
                style {
                    flex("initial")
                    flexShrink(0)
                    position(Position.Relative)
                    borderRadius(styleConst.borderRadiusCommon)
                    borderBox()
                    border(1.px, LineStyle.Solid, rgba(180, 180, 180, 0.3))
                    overflow("hidden")
                    lineHeight("0")
                }
                onClick {
                    onClick()
                }
            }) {
                Img(src = complex.cover ?: tempComplexCoverUrl) {
                    classes(styleBlock.trainerRoom.ccCoverComplex)
                    style {
                        width(120.px)
                        height(160.px)
                    }
                }
                if (complex.archive != true) CenterDiv({
                    style {
                        minWidth(30.pt)
                        minHeight(30.pt)
                        position(Position.Absolute)
                        bottom(5.px)
                        left(5.px)
                    }
                }) {
                    markerRound2(if (complex.activate == true) stylePalette.myGreen  else stylePalette.myRed, 20.pt)
                }

            }
        }
        CenterDiv({
            style {
                flex("auto")
                flexShrink(1)
                overflowX("hidden")
                flexGrow(1)
            }
            onClick {
                onClick()
            }
        }) {
            Div({
                style {
                    textAlign("left")
                    width(100.percent)
                    borderBox()
                    fontCommon(stylePalette.fontHeaderColor)

                    property("vertical-align", "middle")
                    property("overflow-wrap", "break-word")
                }
            }) {
                Text(complex.name ?: "")
            }
        }
        if (forSelect.not()) FlexInitialDiv {
            CenterDiv({
                style {
                    height(100.percent)
                }
            }) {
                DropDown({
                    classes(StyleTrainingRoom.chevronButton)
                }, {
                    Div({
                        style {
                            fontSize(20.pt)
                            minWidth(40.px)
                            minHeight(40.px)
                            display(DisplayStyle.Flex)
                            alignItems(AlignItems.Center)
                            justifyContent(JustifyContent.Center)
                        }
                    }) { //, minHeight
                        I({
                            classes(
                                "fa-solid", "fa-bars",
                                StyleTrainingRoom.chevron,
                            )
                        })
                    }
                }, right = true, elementId = complex.id.toString(), marginSide = 23.px) {
                    if (complex.archive != true) if (complex.activate == true) buttonWithIcon(
                        "Деактивировать",
                        listOf("fa-solid", "fa-toggle-off", StyleTrainingRoom.chevron),
                        {
                            style {
                                marginBottom(6.px)
                            }
                        }) {
                        complex.setActivateComplexFirestore(false) {
                            ListComplexes.pagination.update(true)
                            ListComplexesForSale.pagination.update(true)
                        }
                    } else buttonWithIcon(
                        "Активировать",
                        listOf("fa-solid", "fa-toggle-on", StyleTrainingRoom.chevron),
                        {
                            style {
                                marginBottom(6.px)
                            }
                        }) {
                        complex.setActivateComplexFirestore(true) {
                            ListComplexes.pagination.update(true)
                            ListComplexesForSale.pagination.update(true)
                        }
                    }
                    buttonWithIcon("Редактировать", listOf("fa", "fa-pen-to-square", StyleTrainingRoom.chevron), {
                        style {
                            marginBottom(6.px)
                        }
                    }) {
                       openModalWindow(ModalAddEditComplex(complex) {})
                    }
                    if (complex.archive != true) buttonWithIcon("В архив", listOf("fa", "fa-box-archive", StyleTrainingRoom.chevron), {
                        style {
                            property("white-space", "nowrap")
                        }
                    }) {
                        complex.setArchiveComplexFirestore(true) {
                            ListComplexes.pagination.update(true)
                            ListComplexesForSale.pagination.update(true)
                        }
                    }
                    else buttonWithIcon("Вернуть из архива", listOf("fa", "fa-check", StyleTrainingRoom.chevron), {
                        style {
                            property("white-space", "nowrap")
                            //                                marginBottom(6.px)
                        }
                    }) {
                        complex.setArchiveComplexFirestore(false) {
                            ListComplexes.pagination.update(true)
                            ListComplexesForSale.pagination.update(true)
                        }
                    }
                    buttonWithIcon("Удалить", listOf("fa-solid", "fa-trash-can", StyleTrainingRoom.chevron), {
                        classes(StyleTrainingRoom.redButton)
                    }) {
                        openModalWindow(ModalDeleteObject("этот комплекс") {
                            complex.deleteComplexFirestore {
                                ListComplexes.pagination.update()
                                ListComplexesForSale.pagination.update()
                            }
                        })
                    }
                }
            }
        }
    }

}