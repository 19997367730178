package mainPage

import androidx.compose.runtime.Composable
import api.firebase.pasteTrainingsFirestore
import bdElement.BaseClient
import elements.*
import elements.miniElement.clientHeader
import global.*
import global.QuickStyleProperty.borderBox
import global.QuickStyleProperty.fontSubheader
import global.QuickStyleProperty.marginWidthAuto
import global.QuickStyleProperty.myTestBorder
import global.QuickStyleProperty.myTextShadow
import global.QuickStyleProperty.zIndex
import mainPage.modalWindows.ModalAddComplexForClient
import mainPage.modalWindows.ModalAddEditTraining
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import style.First.StyleCommon
import style.First.StyleSideNav
import style.First.StyleTrainingRoom
import kotlin.js.Date

@Composable
fun pageOpenClient(client: BaseClient) {
    Div({style {
        position(Position.Relative)
//        height(100.percent)
        borderBox()
//        myTestBorder(stylePalette.myGreen)

    }}){
            clientHeader(client)
            if (phoneDevice.not()) buttonAddPasteBlock(client)
            ClientTrainings.getViewListTrainings()
        if (phoneDevice) Div({
            style {
//        position(Position.Relative)
//                borderBox()
//                height(100.percent)
//                overflowY("auto")
                height(45.pt)
            }
        }) {
        }
    }
    if (phoneDevice) Div({
        style {
            borderBox()
            width(100.percent - styleConst.paddingCommon*2)
            zIndex(13)
            backgroundColor(Color.white)
            paddingTop(styleConst.paddingSmall)
            position(Position.Fixed)
            bottom(0.px)
//            myTestBorder()
        }
    }) {
        buttonAddPasteBlock(client)
    }
}

@Composable
private fun buttonAddPasteBlock(client: BaseClient) {
    FlexRowDivParent({
        style {
            borderBox()
            width(100.percent)
            paddingBottom(styleConst.paddingCommon)
//            padding(5.pt, 0.pt)
        }
    }) {
/*
        CenterDiv({
            style {
                flex("auto")
                padding(0.pt, 0.pt)
            }
        }) {
            CutBorderBox(10, 2, attrs = {
                style {
                    flex("initial")
                }
                onClick {
                    openModalWindow(ModalAddComplexForClient(client) {})
                }
            }, background = "linear-gradient(40deg,#e41c24,#f1a417)") { //,transparent
                CenterDiv({
                    style {
                        backgroundColor(Color.white)
                        width(30.pt - 4.px)
                        height(30.pt - 4.px)
                        fontSize(25.pt)
                    }
                    classes(styleBlock.trainerRoom.buttNewTraining)

                }) {
                    I({
                        classes("fa", "fa-plus")
                        classes(StyleTrainingRoom.chevron)
                        style {
                            color(stylePalette.myRed)
//                            width(30.pt)
//                            height(30.pt)
//                            textAlign("center")
                            borderWidth(0.px)
                            borderRadius(0.px)
                            backgroundColor(Color.white)
                            */
/*
                                                        myTextShadow(CSSTextShadow(2.px, 2.px, 2.px, rgba(0, 0, 0, 0.3)))
                                                        hover(self) style {
                                                            myTextShadow(CSSTextShadow(4.px, 4.px, 3.px, rgba(0, 0, 0, 0.5)))
                                                        }
                                                        self + focus style {
                                                            myTextShadow(CSSTextShadow(4.px, 4.px, 3.px, rgba(0, 0, 0, 0.5)))
                                                        }
                            *//*

                        }
                    })
                }
            }
        }
*/
        CenterDiv({
            style {
                flex("auto")
                padding(0.pt, 0.pt)
            }
        }) {
            buttonIcon(listOf("fa", "fa-plus"), {
                classes(styleBlock.trainerRoom.buttNewTraining)
            }, fontSize = 25.pt) {
                myGtag("event", "add_training", "button", "click")
                openModalWindow(ModalAddEditTraining() {})
            }
        }
        if (copyTrainingsBuffer.isNotEmpty()) {
            CenterDiv({
                style {
                    flex("auto")
                    padding(0.pt, 0.pt)
                }
            }) {
                buttonIcon(listOf("fa-solid", "fa-paste"), {
                    classes(styleBlock.trainerRoom.buttNewTraining, styleBlock.trainerRoom.buttPasteTrainings)
                }, fontSize = 25.pt, name = "${copyTrainingsBuffer.count()} шт.", fontSizeName = 15.pt) {
                    selectedClient?.id?.let { id_client ->
                        pasteTrainingsFirestore(copyTrainingsBuffer.map { tr ->
                            val dateO =
                                Date().let { Date(it.getFullYear(), it.getMonth(), it.getUTCDate()).getTime() }
                                    .toLong()
                            val countD = 8
                            tr.copy(
                                client_id = id_client,
                                complex_id = null,
                                activate = false,
                                status = "",
                                count_days_open = countD,
                                completed = 0,
                                date_open = dateO,
                                date_close = dateO + countD * 86400000L
                            )
                        }) { list ->
                            list.forEach { newE ->
                                ClientTrainings.pagination.update() // .trainingsLoaded?.newElem(newE) { it.date_open }
                            }
                            copyTrainingsBuffer = listOf()
                        }
                    }
                }
            }
            CenterDiv({
                style {
                    flex("auto")
                    padding(0.pt, 0.pt)
                }
            }) {
                buttonIcon(listOf("fa-solid", "fa-ban"), {
                    classes(styleBlock.trainerRoom.buttNewTraining, styleBlock.trainerRoom.buttResetPasteTrainings)
                }, fontSize = 25.pt) {
                    copyTrainingsBuffer = listOf()
                }
            }
        }
    }
}