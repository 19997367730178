package bdElement

import kotlinx.serialization.EncodeDefault
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class SortObj(
    @SerialName("column")
    val column: String = "",
    val direction: String = "" // "desc" or "asc"
)


@Serializable
data class CreatiumRequest @OptIn(ExperimentalSerializationApi::class) constructor(
    @EncodeDefault
//    @SerialName("properties")
    val properties: List<Map<String, String>> = listOf(),
    @EncodeDefault
//    @SerialName("sort")
    val sort: List<SortObj> = listOf(),
    @EncodeDefault
//    @SerialName("limit")
    val limit: Int = 0,
    @EncodeDefault
//    @SerialName("skip")
    val skip: Int = 0
)

