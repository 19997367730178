package mainPage

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import elements.*
import style.Samples.WtSections
import global.*
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import style.First.StyleVideoBlock

@Composable
fun pageTrainerRoom() {
    LaunchedEffect(selectedClient) {
        selectedClient?.id?.let {
            if (ClientTrainings.pagination.currentPage != 0) ClientTrainings.pagination.setCurrentPage(0)
            else ClientTrainings.pagination.update()
        }
    }

    MyContainerInSection(heightFull = selectedTrainingForEdit == null && selectedClient == null && selectedComplex == null) {
//        SliderButton().sliderButton()
        selectedTrainingForEdit?.let { training ->
            pageOpenEditTraining(training)
        } ?: run {
            selectedClient?.let { client ->
                pageOpenClient(client)
            } ?: run {
                selectedComplex?.let { complex ->
                    pageOpenComplex(complex)
                } ?: run {
                    pageSpisClients()
                }
            }
        }
    }
}