package style.First

import global.QuickStyleProperty.zIndex
import global.stylePalette
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*
import style.AppStylesheet

object StyleModalWindow : StyleSheet(AppStylesheet) {
    val hystmodal__opened by style {
        position(Position.Fixed)
        right(0.px)
        left(0.px)
        overflow("hidden")
    }

    @OptIn(ExperimentalComposeWebApi::class)
    val hystmodalShadow by style {
        position(Position.Fixed)
        border(null, null, null)
        display(DisplayStyle.Block)
        width(100.percent)
        top(0.px)
        bottom(0.px)
        right(0.px)
        left(0.px)
        overflow("hidden")
//    pointer(events: none)
        zIndex(98)
        opacity(0)
        transitions {
            "opacity" {
                duration(0.15.s)
                timingFunction(AnimationTimingFunction.Ease)
            }
        }
        backgroundColor(stylePalette.black)
    }
    val hystmodalShadowShow by style {
//    pointer-events: auto;
        opacity(0.6)
    }
    val hystmodal by style {
        position(Position.Fixed)
        top(0.px)
        bottom(0.px)
        right(0.px)
        left(0.px)
        overflow("hidden")
        overflowY("auto")
        backgroundColor(rgba(0,0,0,0.5))
//    -webkit-overflow-scrolling (touch)
        opacity(1)
//    pointer-events (none)
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Column)
        flexWrap(FlexWrap.Nowrap)
        justifyContent(JustifyContent.FlexStart)
        zIndex(200)

//     visibility (hidden)
    }
    val hystmodalActive by style{
//        zIndex(200)
        opacity(1)
    }

    val hystmodalDeactive by style{
        opacity(0)
    }

//    val hystmodalMoved by style,
//    val hystmodalActive by style {
//        pointer - events: auto;
//        visibility: visible;
//    }

    val hystmodal__wrap by style {
        flexShrink(0)
        flexGrow(0)
        width (100.percent)
        minHeight (100.percent)
        property("margin", "auto")
        display (DisplayStyle.Flex)
        flexFlow(FlexDirection.Column, FlexWrap.Nowrap)
        alignItems(AlignItems.Center)
        justifyContent(JustifyContent.Center)
    }
    @OptIn(ExperimentalComposeWebApi::class)
    val hystmodal__window by style {
        position (Position.Relative)
        zIndex( 250)
        margin (50.px, 0.px)
        boxSizing( "border-box")
        flexShrink( 0)
        flexGrow( 0)
        backgroundColor(stylePalette.white)
        minWidth( 200.px)
        maxWidth( 95.percent)
        overflow ("visible")
//        property("transition", "transform 0.2s ease 0s, opacity 0.2s ease 0s")
        /*transform: scale(0.9);*/
//        opacity (0)
        alignItems( AlignItems.Center)
        padding( 10.px)
        borderRadius( 10.px)
        self + "hystmodalActive" style {
            transform{
                scale(1)
            }
            opacity (1)
        }
    }
/*
    @OptIn(ExperimentalComposeWebApi::class)
    val hystmodalActive by style { //, hystmodalWindow {
        transform{
            scale(1)
        }
        opacity (1)
    }
*/

    @OptIn(ExperimentalComposeWebApi::class)
    val hystmodalClose by style {
        position (Position.Absolute)
        zIndex (10)
        top(0.px)
        right(0.px)
        display(DisplayStyle.Block)
        width (40.px)
        height (40.px)
        transform {
            translate(50.percent,(-50).percent)
        }
        backgroundColor( stylePalette.white)
        border (3.px, LineStyle.Solid, stylePalette.black)
        padding(2.px)
        borderRadius(50.percent)
        cursor( "pointer" )
        property("box-shadow"," 3px 2px 12px 1px rgba(0, 0, 0, 0.24)")
        self + active style {
            transform{
                translate(50.percent,(-50).percent)
                scale(0.8)
            }
            property("box-shadow"," 3px 2px 22px 1px rgba(0, 0, 0, 0.24)")
            backgroundColor(stylePalette.gray)
        }
    }
}
