package elements

import androidx.compose.runtime.*
import elements.helpers.MyRect
import global.*
import global.QuickStyleProperty.borderBox
import global.QuickStyleProperty.marginHeightAuto
import global.QuickStyleProperty.zIndex
import kotlinx.browser.document
import kotlinx.coroutines.*
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.events.SyntheticTouchEvent
import org.w3c.dom.events.MouseEvent
import org.w3c.dom.get
import style.First.StyleTrainingRoom
import kotlin.coroutines.CoroutineContext

interface DragBaseElement {
    val id: String?
    var order: Int?
}

abstract class DragCommon<T : DragBaseElement>(dragBaseElementP: T) { //, val view: @Composable (@Composable (T)-> Unit)->Unit

    companion object {
        var translationAnimationOff by mutableStateOf(true)
    }


    var dragBaseElement by mutableStateOf<T>(dragBaseElementP)
    abstract val view: @Composable (T, @Composable () -> Unit) -> Unit
    abstract val fixOrderInElement: (DragCommon<T>) -> Unit
    abstract val fixOrdersInBaseAndView: () -> Unit
    abstract val directionAsk: Boolean

    var numberCurr = dragBaseElement.order ?: 0

    val paddingStart = 0.0

    var myRect: MyRect? = null

    var Xi = 0
    var Yi = 0

    var Xt = 0
    var Yt = 0

    var Xf = 0
    var Yf = 0

    var dragInit by mutableStateOf(false)

    fun dragInitFun(assignments: List<DragCommon<T>>) {
        dragInit = true
        translationAnimationOff = false
        translateAnimX = paddingStart
        assignments.forEach {
            document.getElementById("assignment-${it.dragBaseElement.id}")?.getBoundingClientRect()?.let { domRect ->
                it.myRect = MyRect(domRect)
            }
        }
    }

    fun drogEnd(assignments: List<DragCommon<T>>) {
        dragInit = false;

        assignments.forEach { elem ->
            elem.translateCurr = elem.translateNew
            elem.numberCurr = elem.numberNew
            fixOrderInElement(elem)
            elem.translateAnimX = 0.0
            elem.translateAnimY = elem.translateCurr
//            newOrderSpis.add(NewOrder(elem.dragBaseElement.id ?: "", elem.numberNew))
        }
        MainScope().launch {
            delay(200)
            translationAnimationOff = true
            fixOrdersInBaseAndView()
//            translationAnimationOff = false
        }

        Xi = 0
        Yi = 0
        Xt = 0
        Yt = 0
        Xf = 0
        Yf = 0
    }


    fun dragMoveMouse(event: MouseEvent, assignments: List<DragCommon<T>>) {
        event.preventDefault()
        Xf = event.clientX
        Yf = event.clientY
        dragMove(assignments, event.pageX, event.pageY)
    }

    fun dragMoveTouch(event: SyntheticTouchEvent, assignments: List<DragCommon<T>>) {
        event.preventDefault()
        Xf = event.touches[0]?.clientX ?: 0
        Yf = event.touches[0]?.clientY ?: 0
        dragMove(assignments, (event.touches[0]?.pageX ?: 0).toDouble(), (event.touches[0]?.pageY ?: 0).toDouble())
    }

    fun dragMove(assignments: List<DragCommon<T>>, touchX: Double, touchY: Double) {

        Xt = Xf - Xi
        Yt = Yf - Yi


        var elementUnder: DragCommon<T>? = null
        assignments.forEach { elem ->
//            if ( MyRect(elem.cardParent.getBoundingClientRect()).contains(touchX, touchY - elem.translateCurr)) {
            if (elem.myRect?.contains((elem.myRect?.x ?: 0.0) + 10.0, touchY - elem.translateCurr) == true) {
                elementUnder = elem;
            }
        }

        elementUnder?.let { elemUnder ->
            val heightMoving = myRect?.height ?: 0.0
            val heightUnder = elemUnder.myRect?.height ?: 0.0
//            console.log("heightMoving: $heightMoving");
//            console.log("heightUnder: $heightUnder");
            assignments.forEach { elem ->
                if (elemUnder.getCurrY() < getCurrY()) {
                    if (elem.getCurrY() < getCurrY() && elem.getCurrY() >= elemUnder.getCurrY() && elem !== this) {
                        elem.translateNew = elem.translateCurr + heightMoving;
                        elem.numberNew = elem.numberCurr + if (directionAsk) 1 else -1;
                    } else {
                        elem.translateNew = elem.translateCurr;
                        elem.numberNew = elem.numberCurr;
                    }
                    elem.translateAnimY = elem.translateNew;
                } else {
                    if (elem.getCurrY() > getCurrY() && elem.getCurrY() <= elemUnder.getCurrY() && elem !== this) {
                        elem.translateNew = elem.translateCurr - heightMoving;
                        elem.numberNew = elem.numberCurr - if (directionAsk) 1 else -1;
                    } else {
                        elem.translateNew = elem.translateCurr;
                        elem.numberNew = elem.numberCurr;
                    }
                    elem.translateAnimY = elem.translateNew;
                }
                if (elemUnder != this) {
                    val delta1 = elemUnder.getCurrY() - getCurrY();
                    var delta2 = heightUnder - heightMoving;
                    if (delta1 < 0) delta2 = 0.0;
                    translateNew =
                        translateCurr + delta1 + delta2;//(elemUnder.getCurrY() - elemMoving.getCurrY() + heightUnder - heightMoving);
                    numberNew = elemUnder.numberCurr;
                } else {
                    translateNew = translateCurr;
                    numberNew = numberCurr;
                }
            }

        }



        translateAnimX = paddingStart + Xt
        translateAnimY = translateCurr + Yt
    }


    val minHeight = mutableStateOf(0)

    var updateTrigger by mutableStateOf(0)

    @OptIn(ExperimentalComposeWebApi::class)
    @Composable
    fun getCard(listAssignment: List<DragCommon<T>>) {
        updateTrigger.let {
            Div({
                id("assignment-${dragBaseElement.id}")
                style {
                    position(Position.Relative)
                }
            }) {
                Div({
                    id("card-${dragBaseElement.id}")
                    classes(StyleTrainingRoom.cardAssignmentParent)
                    style {
                        position(Position.Relative)
                        zIndex(if (dragInit || dropDownElementNow == dragBaseElement.id.toString()) 150 else 10)
                        transform {
                            translate(translateAnimX.px, translateAnimY.px)
                        }
                        if (!translationAnimationOff) transitions {
                            all {
                                duration(if (dragInit) 0.0.s else 0.2.s)
                                timingFunction(AnimationTimingFunction.EaseInOut)
                            }
                        }
                    }
                    onDrag {
                        console.log("it.y:${it.y}")
                        console.log("it.x:${it.x}")
                    }
                }) {
                    view(dragBaseElement) {
                        Div({
                            style {
                                flex("initial")
                                minWidth(30.px)
                                marginHeightAuto()
                            }
                            classes(styleBlock.trainerRoom.editDragBox)
//                                attr("data-drog", "")
                            onMouseDown {
                                it.preventDefault()
                                selectableNone = true
                                Xi = it.clientX
                                Yi = it.clientY
                                dragInitFun(listAssignment)
                                document.onmousemove = { event -> dragMoveMouse(event, listAssignment) }
                                document.onmouseup = {
                                    selectableNone = false
                                    document.onmousemove = null
                                    document.onmouseup = null
                                    drogEnd(listAssignment)
                                    null
                                }
                            }
                            onTouchStart {
                                it.preventDefault()
                                selectableNone = true
                                Xi = it.touches[0]?.clientX ?: 0
                                Yi = it.touches[0]?.clientY ?: 0
                                dragInitFun(listAssignment)
                            }
                            if (dragInit) onTouchMove { event ->
                                dragMoveTouch(event, listAssignment)
                            }
                            onTouchEnd {
                                selectableNone = false
                                drogEnd(listAssignment)
                            }
                        }) { //const dragBox = document . createElement ("div");
                            CenterDiv({style {
                                height(100.percent)
                                width(100.percent)
                                borderBox()
                            }}) {
                                Div{
                                    for (i in 0..3)
                                        FlexRowDivParent(columnGap = 0.px) {
                                            pointDrag()
                                            pointDrag()
                                        }
                                }
                            }
                        }
                    }
                }
            }
            LaunchedEffect(Unit, minHeight.value) {
                document.getElementById("assignment-${dragBaseElement.id}")?.getBoundingClientRect()?.let {
                    myRect = MyRect(it)
                }
            }
        }

    }

    var translateCurr by mutableStateOf(0.0)
    var translateNew by mutableStateOf(0.0)
    var translateAnimX by mutableStateOf(0.0)
    var translateAnimY by mutableStateOf(0.0)
    var numberNew by mutableStateOf(dragBaseElement.order ?: 0)


    fun getCurrRect(): MyRect? =
        document.getElementById("assignment-${dragBaseElement.id}")?.getBoundingClientRect()?.let {
            MyRect(it)
        }

    fun getCurrY() = (this.myRect?.y ?: 0.0) + this.translateCurr

}

@Composable
private fun pointDrag(){
    Div ({style {
        height(3.px)
        width(3.px)
        borderRadius(50.percent)
        backgroundColor(stylePalette.fontCommonColor)
        margin(3.5.px)
    }})
}
