package bdElement

import api.commonUserPostJsonRequest
import api.firebase.*
import global.*
import kotlinx.serialization.Serializable
import mainPage.modalWindows.showMessage

@Serializable
data class BaseRequestComplex(
    var id: String? = null,
    var trainer_id: String? = null,
    var complex_id: String? = null,
    var complex_cover: String? = null,
    var complex_name: String? = null,
    var complex_price: Double? = null,
    var client_id: String? = null,
    var client_name: String? = null,
    var client_avatar: String? = null,
    var client_phone: String? = null,
    var client_message: String? = null,
    var status: String? = "new",
    override var date_create: Long? = getCurrentDateTimeUTC(),
    override var date_update: Long? = null,
) : CommonElement() {

    override val typeElement = TypeElement.RequestComplex

    fun addNewComplexFirestore(
        funcRez: (BaseRequestComplex) -> Unit
    ) {
        addNewCommonElementFirestoreFromClient(getBaseElement(this), funcRez)
    }


    fun confirm(
        funcRez: (String) -> Unit
    ) {
        requestFromTrainer { trainer ->
            commonUserPostJsonRequest(
                path = "add_complex_for_client",
                headers = { head ->
                    head.append("trainerId", trainer.id ?: "")
                    head.append("clientId", client_id ?: "")
                    head.append("complexId", complex_id ?: "")
                    head.append("dateStart", getCurrentDateUTC().toString())
                    head.append("requestComplex", id ?: "")
                }, bodyStr = ""
            ) {
                funcRez(it)
            }
        }
    }

    fun reject(
        funcRez: (String) -> Unit
    ) {
        changeCommonElementFirestoreFromTrainer(
            CommonChangesBaseElement(
                TypeElement.RequestComplex.label,
                id ?: "",
                mapOf(
                    "status" to "rejected"
                )
            ), funcRez
        )
    }

    fun cancel(
        funcRez: (String) -> Unit
    ) {
        changeCommonElementFirestoreFromClient(
            CommonChangesBaseElement(
                TypeElement.RequestComplex.label,
                id ?: "",
                mapOf(
                    "status" to "canceled"
                )
            ), funcRez
        )
    }

}
