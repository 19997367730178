package mainPage

import androidx.compose.runtime.Composable
import api.firebase.pasteTrainingsFirestore
import bdElement.BaseComplex
import elements.CenterDiv
import elements.CutBorderBox
import elements.FlexRowDivParent
import elements.buttonIcon
import elements.miniElement.complexHeader
import global.*
import global.QuickStyleProperty.borderBox
import global.QuickStyleProperty.fontSubheader
import global.QuickStyleProperty.overflowHidden
import global.QuickStyleProperty.zIndex
import mainPage.modalWindows.ModalAddEditTrainingForComplex
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import kotlin.js.Date

@Composable
fun pageOpenComplex(complex: BaseComplex) {
    complexHeader(complex)
    Div({ style {
        position(Position.Relative)
        if (phoneDevice) paddingBottom(2*styleConst.paddingCommon + 30.pt)
    } }) {
        if (phoneDevice.not()) CenterDiv ({style {
            paddingTop(styleConst.paddingCommon)
            paddingBottom(styleConst.paddingCommon)
        }}){
            buttonBlock(complex)
        }
        ComplexTrainings.getViewListTrainings()
    }
   if (phoneDevice) Div({style {
       paddingTop(styleConst.paddingCommon)
       position(Position.Fixed)
       bottom(0.px)
       paddingBottom(styleConst.paddingCommon)
       backgroundColor(Color.white)
       width(100.percent-2*styleConst.paddingCommon)
       borderBox()
       zIndex(13)
   }}){
       buttonBlock(complex)
   }
}

@Composable
private fun buttonBlock(complex: BaseComplex){
    FlexRowDivParent({
        style {
        }
    })  {
        CenterDiv({
            style {
                flex("auto")
                padding(0.pt,10.pt)
            }
        }) {
            buttonIcon(listOf("fa", "fa-plus"), {
                classes(styleBlock.trainerRoom.buttNewTraining)
            }, fontSize = 25.pt) {
                myGtag("event","add_training_for_complex","button","click")
                openModalWindow(ModalAddEditTrainingForComplex() {})
            }
        }
        if (copyTrainingsBuffer.isNotEmpty()) {
            CenterDiv({
                style {
                    flex("auto")
                    padding(0.pt,10.pt)
                }
            }) {
                buttonIcon(listOf("fa-solid", "fa-paste"), {
                    classes(styleBlock.trainerRoom.buttNewTraining, styleBlock.trainerRoom.buttPasteTrainings)
                }, fontSize = 25.pt, name = "${copyTrainingsBuffer.count()} шт.", fontSizeName = 15.pt) {
                    selectedComplex?.id?.let { id_complex ->
                        val startInd = ComplexTrainings.trainingsLoaded?.mutList?.maxOf { it.order ?: 0 } ?: 0
                        val dateMax = ComplexTrainings.trainingsLoaded?.mutList?.maxOf { it.date_open ?: 0L } ?: 0L
                        pasteTrainingsFirestore(copyTrainingsBuffer.mapIndexed() { ind, tr ->
                            val countD = 8
                            tr.copy(
                                order = startInd + ind + 1,
                                client_id = null,
                                complex_id = id_complex,
                                activate = false,
                                status = "",
                                count_days_open = countD,
                                completed = 0,
                                date_open = dateMax,
                                date_close = countD * 86400000L
                            )
                        }) { list ->
//                                list.forEach { newE ->
                            ComplexTrainings.pagination.update() // .trainingsLoaded?.newElem(newE) { it.date_open }
//                                }
                            copyTrainingsBuffer = listOf()
                        }
                    }
                }
            }
            CenterDiv({
                style {
                    flex("auto")
                    padding(0.pt,10.pt)
                }
            }) {
                buttonIcon(listOf("fa-solid", "fa-ban"), {
                    classes(styleBlock.trainerRoom.buttNewTraining, styleBlock.trainerRoom.buttResetPasteTrainings)
                }, fontSize = 25.pt) {
                    copyTrainingsBuffer = listOf()
                }
            }
        }
    }
}

