package bdElement

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import api.firebase.CommonChangesBaseElement
import api.firebase.TypeElement
import api.firebase.changeCommonElementFirestoreFromClient
import api.firebase.changeCommonElementFirestoreFromTrainer
import global.BackButtonStack
import global.editListAssignment
import global.getCurrentDateTimeUTC
import kotlinx.serialization.Serializable

@Serializable
data class BaseClient(
    override var id: String? = null,
    override var email: String? = null,
    override var name: String? = null,
    override var second_name: String? = null,
    override var phone: String? = null,
    override var avatar: String? = null,
    override var description: String? = null,
    override var status: String? = null,
    override var date_create: Long? = getCurrentDateTimeUTC(),
    override var date_update: Long? = null,
    val date_open: Long? = null,
    val date_close: Long? = null,
    var zayavka_phone: String? = null,
    var zayavka_id: String? = null,
) : CommonUser() {
    private fun commonElStatusChange(
        value: String
    ): CommonChangesBaseElement = CommonChangesBaseElement(
        TypeElement.Client.label,
        zayavka_id ?: "",
        mapOf(
            "status" to value
        )
    )

    fun open(
        funcRez: (String) -> Unit
    ) {
        changeCommonElementFirestoreFromTrainer(commonElStatusChange("open"), funcRez)
    }

    fun close(
        funcRez: (String) -> Unit
    ) {
        changeCommonElementFirestoreFromTrainer(commonElStatusChange("close"), funcRez)
    }

    fun reject(
        funcRez: (String) -> Unit
    ) {
        changeCommonElementFirestoreFromTrainer(commonElStatusChange("rejected"), funcRez)
    }

    fun cancel(
        funcRez: (String) -> Unit
    ) {
        changeCommonElementFirestoreFromClient(commonElStatusChange("canceled"), funcRez)
    }
}
