import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import api.firebase.firebaseStart
import api.firebase.getFreeTrainings
import style.AppStylesheet
import elements.*
import global.*
import global.QuickStyleProperty.myNonClick
import global.QuickStyleProperty.zIndex
import kotlinx.browser.document
import kotlinx.browser.window
import mainPage.*
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.renderComposable

//import io.ktor.application.*
//import io.ktor.http.*
//import io.ktor.response.*
//import io.ktor.routing.*
//import io.ktor.server.engine.*
//import io.ktor.server.netty.*

/*
@Serializable
data class Project(val name: String, val language: List<String>)
*/


fun main() {

    /**
     * Нужно для работы в формате PWA приложения. canInstallApplication определяет возможна ли установка
     * приложение через кнопку в браузере. Это определяется в событии beforeinstallprompt. Оно срабатывает
     * если такая установка в принципе возможно и при этом приложение еще не установлено.
     * */
    window.addEventListener("beforeinstallprompt", { event ->
        saveBeforeInstallPromptEvent(event) {
            canInstallApplication = true
        }
    })

    document.addEventListener("DOMContentLoaded", {
        window.history.pushState(null, "null", window.location.href)
        window.onpopstate = {
            BackButtonStack.pressButtonBack()
            console.log("onpopstate")
            window.history.pushState(null, "null", window.location.href);
        }
    })

    firebaseStart()
    setListenersWindow()
    getFreeTrainings()

    statusLoading.getCookiesOnStart()


    renderComposable(rootElementId = "root") {
        Style(AppStylesheet)

        statusLoading.listenerForWritingCookies()

        filterBlock.setListener()
        filterBlockMyVideo.setListener()
        tagsBlock.setListener()

        modalContent?.let {
            modalWindow(it, visibleModalWindow)
        }

        if (statusLoading.startComplete) {
            MainScreenApp()
        } else {
            StartScreenLoader()
        }
    }
}

@Composable
private fun MainScreenApp() {
//    if (currentPage == PageApplication.TitlePage)
/*
        Div({
        style {
            position(Position.Absolute)
            top(0.px)
            left(0.px)
            width(100.percent)
            height(50.percent)

//                overflow: clip;
//                marginBottom(-500.px)
//                property("overflow-clip-margin", "500px")
            zIndex(-1)
            myNonClick()
        }
    }) {
*/
//    }
    headerAndSideNav()
    MainContentLayout {
        if (currentUser.login) {
            if (currentUser.userProfile?.status == "new")
                pageRegistration()
            else RegisteredUserScreen()
        } else UnregisteredUserScreen()
    }
}

@Composable
private fun RegisteredUserScreen() {
    when (currentPage) {
        PageApplication.UserProfile -> pageUserProfile()
        PageApplication.TrainerRoom -> if (currentUser.getEmailVerified() == false) pageVerification() else pageTrainerRoom()
        PageApplication.Trainings -> pageClientTrainings()
        PageApplication.TitlePage -> pageTitle()
        PageApplication.MyVideo -> if (currentUser.getEmailVerified() == false) pageVerification() else pageMyVideo()
        PageApplication.Sales -> if (currentUser.getEmailVerified() == false) pageVerification() else pageSales()
        PageApplication.Verification -> if (currentUser.getEmailVerified() == false) pageVerification() else pageTitle()
        PageApplication.Helps -> pageHelps()
        else -> pageTitle()
    }
}

@Composable
private fun UnregisteredUserScreen() {
    when (currentPage) {
        PageApplication.Registration -> pageRegistration()
        PageApplication.Helps -> pageHelps()
        PageApplication.TitlePage -> pageTitle()
        else -> pageTitle()
    }
}

@Composable
private fun StartScreenLoader() {
    CenterDiv({
        style {
            width(100.percent)
            height(window.innerHeight.px) // - headerHeight.px
        }
    }) {
        mainLoader()
    }
}