package elements

import androidx.compose.runtime.Composable
import bdElement.BaseTraining
import global.QuickStyleProperty.borderBox
import global.QuickStyleProperty.fontCommon
import global.QuickStyleProperty.fontSmall
import global.QuickStyleProperty.fontSubheader
import global.getCurrentDateTimeUTC
import global.styleConst
import global.stylePalette
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.I
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import kotlin.js.Date
import kotlin.math.round

@Composable
fun cardCommonClientTraining(
    training: BaseTraining,
    smallC: CSSColorValue,
    nameC: CSSColorValue,
    opisC: CSSColorValue,
    backC: CSSColorValue,
    borderC: CSSColorValue? = null,
    onClick: ()->Unit = {}
) {
    Div({
        style {
            borderRadius(styleConst.borderRadiusCommon)
            padding(styleConst.paddingCommon)
            position(Position.Relative)
            backgroundColor(backC)
            borderC?.let {
                border(styleConst.borderWidthCommon, LineStyle.Solid, it)
            }
        }
        onClick{
            onClick()
        }
    }) {
        FlexRowDivParent({
            style {
                position(Position.Relative)
                justifyContent(JustifyContent.SpaceBetween)
                paddingBottom(styleConst.paddingSmall)
            }
        }, columnGap = 3.pt) {
            P({
                style {
                    flex("auto")
                    fontSmall(smallC)
                }
            }) {
                Text(
                    "${Date((training.date_open ?: 0) - Date().getTimezoneOffset()).toLocaleDateString()} " +
                            "- ${Date((training.date_close ?: 0) - Date().getTimezoneOffset() - 86400000.0).toLocaleDateString()}"+
                            (round(((training.date_close ?: 0) - getCurrentDateTimeUTC()) / 8640000.0) / 10).let {
                                if (it < 0) "" else " (Ост: $it д.)"
                            }

                )
            }
            training.completed?.let { compl ->
                if (compl > 10000000) {
                    CenterDiv ({style {
                        position(Position.Relative)
                        minWidth(22.px)
                        lineHeight("0")
                    }}) {
                        P({
                            style {
                                position(Position.Absolute)
                                fontSmall(stylePalette.myOrange)
                                fontSize(styleConst.fontCommonSize)
//                                padding(3.pt)
//                                left(-15.px)
                                lineHeight("0")
                                backgroundColor(Color.white)
                                borderRadius(50.percent)
                            }
                        }) {
                            I({
                                classes("fa-solid", "fa-circle-check")
                            })
                        }
                    }
                    P({
                        style {
                            fontSmall(smallC)
                        }
                    }) {
                        Text("${Date(compl - Date().getTimezoneOffset()).toLocaleDateString()}")
                    }
                }
            }
        }
        /*
                training.completed?.let { compl ->
                    CenterDiv({style {
                        position(Position.Absolute)
                        borderBox()
                        backgroundColor(Color.white)
                        top(-8.px)
                        right(-8.px)
                        width(25.px)
                        height(25.px)
                        borderRadius(50.percent)
                        fontSmall(stylePalette.myOrange)
                        border(styleConst.borderWidthCommon, LineStyle.Solid, stylePalette.fontCommonColor)
                        opacity(if (compl > 0) 1.0 else 0.0)
                    }}) {
                        I({
                            classes("fa-solid", "fa-circle-check")
                        })
                    }
        */
        /*
                    Img(src = "svgIcon/Check_orange_16.svg") {
                        style {
                            position(Position.Absolute)
                            top(-8.px)
                            right(-8.px)
                            width(26.px)
                            height(26.px)
                            opacity(if (compl > 0) 1.0 else 0.0)
                        }
                    }
        *//*

        }
*/
        FlexRowDivParent({
            style {
                position(Position.Relative)
                justifyContent(JustifyContent.SpaceBetween)
                alignContent(AlignContent.End)
                alignItems(AlignItems.End)
            }
        }) {
            Div({
                style {
                    flex("auto")
                    borderBox()
                }
            }) {
                P({
                    style {
                        fontSubheader(nameC)
                        paddingBottom(styleConst.paddingCommon)
                    }
                }) {
                    Text(training.name ?: "")
                }
                P({
                    style {
                        fontCommon(opisC)
                    }
                }) {
                    (training.assignments?.fold(mutableListOf<String>()) { str, assign ->
                        assign.muscle_group_tags?.forEach {
                            if (it != "") str.add(it)
                        }
                        str
                    }?.distinct()?.toString()?.let { it.substring(1,it.length-1) } ?: " --- ").let {
                         Text(if (it == "") " --- " else it)
                    }
                }
            }
            training.getMarkerComplex()
        }

    }
}
