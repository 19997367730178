package style.First

import global.CSSShadow
import global.QuickStyleProperty.myBoxShadow
import global.QuickStyleProperty.myTestBorder
import global.stylePalette
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*
import style.AppStylesheet

object StyleLoaders : StyleSheet(AppStylesheet) {
    val base_line_height = 24.px
    val white = rgb(255, 255, 255)
    val off_white = rgba(255, 255, 255, 0.2);
    val spin_duration = 1.s
    val pulse_duration = 750.ms

    @OptIn(ExperimentalComposeWebApi::class)
    val spin by keyframes {
        0.percent {
            transform {
                rotate(0.deg);
            }
        }
        100.percent {
            transform {
                rotate(360.deg);
            }
        }

    }

    val pulse by keyframes {
        50.percent {
            backgroundColor(white)
        }
    }

    val loading by style {
        borderRadius(50.percent)
        width(base_line_height)
        height(base_line_height)
        border(5.px, LineStyle.Solid, off_white)
        property("border-top-color", "rgb(255,255,255)")
        animation(spin) {
            duration(spin_duration)
//            this.fillMode(AnimationFillMode.Both)
//            this.playState(AnimationPlayState.Inherit)
            this.iterationCount(null) //infinite
            this.timingFunction(AnimationTimingFunction.Linear)
        }
    }

    /*    .loading-pulse
        {
            position: relative;
            width: ($base-line-height / 4);
            height: $base-line-height;
            background: $off-white;
            animation: pulse $pulse-duration infinite;
            animation - delay: ($pulse-duration / 3);
            &:before, &:after {
            content: '';
            position: absolute;
            display: block;
            height: ($base-line-height / 1.5);
            width: ($base-line-height / 4);
            background: $off-white;
            top: 50%;
            transform: translateY(-50%);
            animation: pulse $pulse-duration infinite;
        }
            &:before {
            left:-($base-line-height / 2);
        }
            &:after {
            left: ($base-line-height / 2);
            animation - delay: ($pulse-duration / 1.5);
        }
        }*/

    val colorBallShadow = rgb(232, 90, 36)
    val colorBall = Color.white // rgb(224,224,224)

    val bounce by keyframes {
        0.percent {
            top(15.px)
            myBoxShadow(CSSShadow(0.px, 0.px, 0.px, 1.px, colorBallShadow))
        }
        15.percent {
            top(15.px)
            myBoxShadow(CSSShadow(0.px, 0.px, 0.px, 1.px, colorBallShadow))
        }
        45.percent { //55
            top(0.px)
            myBoxShadow(CSSShadow(0.px, 0.px, 0.px, 8.4.px, colorBallShadow))
        }
        55.percent { //55
            top(0.px)
            myBoxShadow(CSSShadow(0.px, 0.px, 0.px, 8.4.px, colorBallShadow))
        }
        85.percent {
            top(15.px)
            myBoxShadow(CSSShadow(0.px, 0.px, 0.px, 1.px, colorBallShadow))
        }
        100.percent {
            top(15.px)
            myBoxShadow(CSSShadow(0.px, 0.px, 0.px, 1.px, colorBallShadow))
        }
    }

    val spinner by style {
        position(Position.Relative)
        width(100.px)
        height(100.px)
//        opacity(0.5)
    }

    val pipParent by style {
        position(Position.Absolute)
        width(20.px)
        height(100.px)
        top(0.px)
        left(40.px)
    }
    val pipBefore by style {  // Selector + String,  Combine + Selector -> ".parent:hover .child"
        position(Position.Absolute)
        top(15.px)
        left(0.px)
        width(20.px)
        height(20.px)
        myBoxShadow(CSSShadow(0.px, 0.px, 0.px, 1.px, colorBallShadow))
        borderRadius(50.percent)
        backgroundColor(colorBall) //ColorRes.red) //
        animation(bounce) {
            duration(1.2.s)
            this.iterationCount(null) //infinite
            this.timingFunction(AnimationTimingFunction.Linear)
        }
        before {
            myTestBorder(stylePalette.red)
        }

    }

    @OptIn(ExperimentalComposeWebApi::class)
    val pip1 by style {
        transform { rotateZ(17.1428571429.deg) }
    }
    val pip1Before by style {
        property("animation-delay", "0s")
    }

    @OptIn(ExperimentalComposeWebApi::class)
    val pip2 by style {
        transform { rotateZ(68.5714285714.deg) }
    }
    val pip2Before by style {
        property("animation-delay", "0.1714285714s")
    }

    @OptIn(ExperimentalComposeWebApi::class)
    val pip3 by style {
        transform { rotateZ(120.deg) }
    }
    val pip3Before by style {
        property("animation-delay", "0.3428571429s")
    }

    @OptIn(ExperimentalComposeWebApi::class)
    val pip4 by style {
        transform { rotateZ(171.4285714286.deg) }
    }
    val pip4Before by style {
        property("animation-delay", "0.5142857143s")
    }

    @OptIn(ExperimentalComposeWebApi::class)
    val pip5 by style {
        transform { rotateZ(222.8571428571.deg) }
    }
    val pip5Before by style {
        property("animation-delay", "0.6857142857s")
    }

    @OptIn(ExperimentalComposeWebApi::class)
    val pip6 by style {
        transform { rotateZ(274.2857142857.deg) }
    }
    val pip6Before by style {
        property("animation-delay", "0.8571428571s")
    }

    @OptIn(ExperimentalComposeWebApi::class)
    val pip7 by style {
        transform { rotateZ(325.7142857143.deg) }
    }
    val pip7Before by style {
        property("animation-delay", "1.0285714286s")
    }
}

