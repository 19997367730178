package global

import androidx.compose.runtime.*
import api.CustomMyListResponse
import api.commonUserPostFDRequest
import api.firebase.TypeElement
import api.firebase.changeCommonElementFirestoreFromTrainer
import api.firebase.getCommonListFromTrainer
import api.firebase.requestFromTrainer
import api.parseMyResponse
import bdElement.BaseAssignment
import bdElement.BaseTraining
import bdElement.MyAnswerCommon
import elements.*
import extension.AnySerializer
import kotlinx.serialization.builtins.MapSerializer
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.encodeToString
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.H4
import org.jetbrains.compose.web.dom.Text

object ComplexTrainings {
    var trainingsLoaded by mutableStateOf<MyAnswerCommon<BaseTraining>?>(null)
        private set

    var listDragTraining by mutableStateOf<List<DragTraining>>(listOf())

    private fun setTrainingsLoaded(load: CustomMyListResponse<BaseTraining>) {
        trainingsLoaded = load.getMyAnswerCommon()
        load.totalCount?.let { ListComplexes.pagination.totalCount = it }
        trainingsLoaded?.mutList?.sortedByDescending { it.order }?.map { DragTraining(it) }?.let {
            listDragTraining = it
        }
        getFromCookie()
    }

    fun fixOrderInBase(
        mapChanges: Map<String, Pair<Int, Long>> = (trainingsLoaded?.mutList //: List<Pair<String, Pair<Int, Long>>>
        ?.sortedByDescending { it.order }
        ?.mapIndexed { ind, item ->
            (item.id ?: "") to ((item.order ?: 0) to (item.date_open ?: 0L))
        } ?: listOf()).toMap(),
        funcRez: (String)->Unit = {}
    ) {  //
//        val dates = trainingsLoaded?.mutList
//            ?.sortedByDescending { it.date_open }
//            ?.mapIndexed { ind, item -> ind to item.date_open }?.toMap() ?: mapOf()
//        val mapChanges
//        mapChanges.map{ }
        requestFromTrainer {
            commonUserPostFDRequest(
                path = "change_order_trainings",
                formData = { formData ->
                    formData.append("mapChanges", myJson.encodeToString(mapChanges))
                },
            ) { userStr ->
                val response = parseMyResponse<String>(userStr)
                response.checkStatusOK(falseFun = { error ->
                    console.log(error)
                }) {
                    objectResponse?.let { timeUpdate ->
                        funcRez(timeUpdate)
                    }
                }
            }
        }
    }

    val pagination = PageAdapter(0) { limit, skip, silent ->
        if (silent.not()) loadingProcess = true
        selectedComplex?.id?.let { id_complex ->
//            getCommonListFromTrainer("trainings_complex", listOf("complexId" to id_complex), limit, skip) {
            val mapAny = mapOf(
                "complex_id" to id_complex,
                "client_id" to null,
            )
            getCommonListFromTrainer(
                "get_common_spis_from_trainer", listOf(
                    "typeElement" to TypeElement.Training.label,
                    "mapAny" to myJson.encodeToString(MapSerializer(String.serializer(), AnySerializer), mapAny)
                ), limit, skip
            ) {
                setTrainingsLoaded(it)
                loadingProcess = false
            }
        }
    }

    private var loadingProcess by mutableStateOf(true)

    private var firstStart: Boolean = true

    @Composable
    fun listenerForWritingCookies() {
//        LaunchedEffect(selectedClient?.selectedTraining) {
//            currentUser.userProfile?.id?.let { id_user ->
//                selectedClient?.selectedTraining?.id?.let { id_training ->
//                    setCookie("${id_user}_selectedClient_selTraining", id_training)
//                } ?: run {
//                    deleteCookie("${id_user}_selectedClient_selTraining")
//                }
//            }
//        }
    }

    private fun getFromCookie() {
//        if (firstStart) {
//            currentUser.userProfile?.id?.let { id_user ->
//                getCookie("${id_user}_selectedClient_selTraining")?.let { id_training ->
//                    if (id_training.isNotEmpty()) {
//                        trainingsLoaded?.rows?.find { it.id == id_training }?.let {
//                            selectedClient?.setSelectedTraining(it)
//                        }
//                    }
//                }
//            }
//            firstStart = false
//        }
    }

    @Composable
    fun getViewListTrainings() {
        if (loadingProcess) {
            CenterDiv({ style { marginTop(40.px) } }) { mainLoader() }
        } else {
            if (listDragTraining.isNotEmpty()) {
                FlexColumnDivParent(rowGap = styleConst.paddingCommon, attrs = {style {
                    paddingBottom(styleConst.paddingCommon)
                }}) {
                    listDragTraining.forEach { //.sortedByDescending { it.dragBaseElement.order }
                        it.getCard(listDragTraining)
                    }
                }
            } else {
                H4(attrs = {
                    style {
                        margin(10.px, 0.px)
                        color(stylePalette.gray)
                    }
//                    classes(WtTexts.wtH2ThemeDark)
                }) {
                    Text("В комплекс пока не добавлено ни одной тренировки.")
                }
            }
            /*
                        trainingsLoaded?.mutList?.let {
                            for (training in it)
            //                    if (training.client_id == selectedClient?.id) {
                                cardTrainingEditor(training, true)
            //                    }
                        }
            */
        }
        pagination.paginationElement()
    }

}