package elements

import androidx.compose.runtime.*
import global.QuickStyleProperty.marginWidthAuto
import global.QuickStyleProperty.myTestBorder
import global.QuickStyleProperty.overflowHidden
import global.QuickStyleProperty.zIndex
import global.styleConst
import kotlinx.browser.document
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLDivElement
import style.First.StyleTrainingRoom

external val crypto: dynamic
fun getUUID(): String = crypto.randomUUID() as String

@Composable
fun Spoiler(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    attrsChevron: AttrBuilderContext<HTMLDivElement>? = null,
    attrsContentDiv: AttrBuilderContext<HTMLDivElement>? = null,
    spoilerEnable: Boolean,
    spoilerContent: @Composable () -> Unit,
    startCloseCard: Boolean = true,
    contentSecondLevel: @Composable () -> Unit = {},
    content: @Composable () -> Unit
) {
    val name = getUUID()
    var closeCard by mutableStateOf(startCloseCard)
    var heightCard by mutableStateOf(0.px)

    Div(
        attrs = {
            style {
//                myTestBorder()
            }
            attrs?.invoke(this)
        }
    ) {
        Div(
            attrs = {
//                classes(StyleTrainingRoom.cardTrainingHeader)
                style {
//                    myTestBorder(Color.green)
                    position(Position.Relative)
                    zIndex(50)
                    display(DisplayStyle.Flex)
//                    flexWrap(FlexWrap.Wrap)
                    columnGap(10.pt)
                    rowGap(10.pt)
                }
            }
        ) {
            Div({
                style {
                    flex("auto")
                }
            }) {
                content()
            }
            if (spoilerEnable) {
                CenterDiv({
                    style {
                        flex("initial")
//                        myTestBorder(Color.green)
                        fontSize(20.pt)
                        marginWidthAuto()
                        minWidth(30.px)
//                    color(ColorRes.black)
                    }
                    attrsChevron?.invoke(this)
                    onClick {
                        closeCard = closeCard.not()
                        heightCard =
                            if (closeCard) 0.px else document.getElementById("content-$name")?.clientHeight?.px
                                ?: 0.px
                    }
                }) {
                    I({
                        classes(
                            "fa", "fa-chevron-down",
                            StyleTrainingRoom.chevron,
                            if (closeCard) StyleTrainingRoom.noRotatedChevron else StyleTrainingRoom.rotatedChevron
                        )
                    })
                }
            } else Div({ style { width(30.px + styleConst.paddingCommon) } })
        }
        Div({
            attrsContentDiv?.invoke(this)
        }) {

            contentSecondLevel()
            Div({
                classes(StyleTrainingRoom.contentSpoiler)
                id("content-parent-$name")
                attr("onresize", "console.log('resizeeee')")
                style {
                    position(Position.Relative)
                    zIndex(40)
                    height(heightCard)
                }
            }) {

                Div(
                    attrs = {
                        id("content-$name")
                        style {
//                            lineHeight("0")
                        }
                    }
                ) {
                    spoilerContent()
                }
            }
        }

    }
    LaunchedEffect(Unit) {
        heightCard =
            if (closeCard) 0.px else document.getElementById("content-$name")?.clientHeight?.px
                ?: 0.px
    }

}