package mainPage

import androidx.compose.runtime.Composable
import style.Samples.WtSections
import elements.ContainerInSection
import elements.FlexRowDivParent
import elements.MyContainerInSection
import elements.mainScrollTo
import global.saveScrollState
import global.selectedOpenTraining
import global.styleBlock
import kotlinx.browser.window
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Text

@Composable
fun pageClientTrainings() {
    MyContainerInSection() {
        selectedOpenTraining?.let { training ->
            selectedOpenTraining?.let { pageClientSelectedTraining(it) {
                selectedOpenTraining = null
                saveScrollState?.let  {
                    mainScrollTo(it)
//                    window.scrollTo(x = 0.0, y = it)
                }
                saveScrollState = null
            } }
        } ?: run {
            pageSpisOpenTrainings()
        }
    }
}