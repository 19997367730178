package mainPage.modalWindows

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import api.firebase.addNewTrainingFirestore
import api.firebase.changeTrainingFirestore
import bdElement.BaseTraining
import elements.FlexColumnDivParent
import elements.FlexRowDivParent
import elements.ModalContent
import elements.RichTextEdit
import global.*
import global.QuickStyleProperty.borderBox
import global.QuickStyleProperty.myFullSize
import kotlinx.browser.window
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*

class ModalAddEditTrainingForComplex(val training: BaseTraining? = null, val funcAfter: () -> Unit) : ModalContent() {
    val richTextEdit = RichTextEdit(training?.description ?: "")
    var inputNameState by mutableStateOf(training?.name ?: "")
    private val maxStartDay =
        ComplexTrainings.trainingsLoaded?.mutList?.let {
            if (it.isNotEmpty()) it.maxOf { (it.date_open ?: 0) / 86400000 }.toInt()
            else 0
        } ?: 0
    var inputStartDay by mutableStateOf(
        training?.date_open?.let { it / 86400000 }?.toInt()
            ?: maxStartDay
    )
    var inputCountDays by mutableStateOf(training?.count_days_open ?: 8)

    private fun checkOrderAndUpdateView(newTr: BaseTraining){
        if (inputStartDay < maxStartDay) {
            ComplexTrainings.trainingsLoaded?.mutList?.sortedBy { it.order }?.run {
                find {
                    (it.date_open ?: 0L) > inputStartDay.toLong() * 86400000L
                }?.order?.let { newOrder ->
                    val listChanges = mapIndexed { ind, item ->
                        if ((item.order ?: 0) < newOrder)
                            (item.id ?: "") to ((item.order ?: 0) to (item.date_open ?: 0L))
                        else
                            (item.id ?: "") to (((item.order ?: 0) + 1) to (item.date_open ?: 0L))
                    }.toMutableList()
                    listChanges.add( (newTr.id ?: "") to (newOrder to (newTr.date_open ?: 0L)) )
                    ComplexTrainings.fixOrderInBase(listChanges.toMap()){
                        ComplexTrainings.pagination.update()
                    }
                }
            }
        } else ComplexTrainings.pagination.update()
    }

    override val closableBack = false
    override val fullscreen: Boolean = phoneDevice

    private var disabledButtSet by mutableStateOf("temp")

    @Composable
    override fun drawModalContent() {
        FlexColumnDivParent({
            style {
                myFullSize()
                backgroundColor(stylePalette.bodyModalColor)
                padding(10.px)
                if (fullscreen) height(window.innerHeight.px)
            }
        }) {
            FlexColumnDivParent({ style { flex("auto") } }, rowGap = 0.px) {
                H3({
                    classes(styleBlock.commonElement.inputTextHeaders)
                    style {
                        marginBottom(2.pt)
                    }
                }) {
                    Text("Название тренировки")
                }
                Input(type = InputType.Text) {

                    style {
                        width(100.percent)
                        borderBox()
                    }
                    classes(styleBlock.commonElement.inputText)
                    value(inputNameState)
                    onInput { event -> inputNameState = event.value }
                }

                H3({
                    classes(styleBlock.commonElement.inputTextHeaders)
                    style {
                        marginTop(8.pt)
                        marginBottom(2.pt)
                    }
                }) {
                    Text("Описание тренировки")
                }
                richTextEdit.RichTextEdit({
                    style {
//                        flex("auto")
                    }
                })

                H3({
                    classes(styleBlock.commonElement.inputTextHeaders)
                    style {
                        marginTop(8.pt)
                        marginBottom(2.pt)
                    }
                }) {
                    Text("Откроется через:")
                }
                Input(type = InputType.Number) {
                    style {
                        width(100.percent)
                        borderBox()
                    }
                    classes(styleBlock.commonElement.inputText)
                    value(inputStartDay)
                    onInput { event ->
                        inputStartDay = event.value as Int
                    }
                }
                H4({ classes(styleBlock.commonElement.smallTextInfo) }) {
                    Text("(Здесь указывается количество дней, через которое тренировка будет доступна после старта комплекса. Если указать 0, то тренировка будет доступна сразу.)")
                }
/*
                H3({
                    classes(styleBlock.commonElement.inputTextHeaders)
                    style {
                        marginTop(8.pt)
                        marginBottom(2.pt)
                    }
                }) {
                    Text("Количество дней в доступе")
                }
                Input(type = InputType.Number) {
                    style {
                        width(100.percent)
                        borderBox()
                    }
                    classes(styleBlock.commonElement.inputText)
                    value(inputCountDays)
                    onInput { event ->
                        inputCountDays = event.value as Int
                    }
                }
                H4({ classes(styleBlock.commonElement.smallTextInfo) }) {
                    Text("(откроется на $inputCountDays дней через $inputStartDay дней после старта комплекса)")
                }
*/
            }
            FlexRowDivParent {
                if (fullscreen) Button({
                    classes(styleBlock.commonElement.buttonBack)
                    style {
                        flex("initial")
                    }
                    onClick {
                        closeModadWindow()
                    }
                }) {
                    Text("Назад")
                }
                Button({
                    classes(styleBlock.trainerRoom.fmButton)
                    style {
                        borderBox()
                        flex("auto")
                    }
                    attr(disabledButtSet, "")
                    onClick {
//                    disabledButtSet = "disabled"
                        selectedComplex?.id?.let { id_complex ->
                            currentUser.userProfile?.id?.let { id_trainer ->
                                if (training != null) {
                                    val updatedTraining = training.copy(
                                        name = inputNameState,
                                        description = richTextEdit.getText(),
                                        date_open = inputStartDay * 86400000L,
                                        count_days_open = inputCountDays,
                                        date_close = (inputStartDay + inputCountDays) * 86400000L
                                    )
                                    changeTrainingFirestore(updatedTraining) {
                                        checkOrderAndUpdateView(updatedTraining)
                                    }
                                } else {
                                    val maxOrder = ComplexTrainings.trainingsLoaded?.mutList?.let {
                                        if (it.isNotEmpty()) it.maxOf { it.order ?: 0 }.toInt()
                                        else 0
                                    } ?: 0
                                    val newTraining = BaseTraining(
                                        order = maxOrder + 1,
                                        trainer_id = id_trainer,
                                        complex_id = id_complex,
                                        name = inputNameState,
                                        description = richTextEdit.getText(),
                                        date_open = inputStartDay.toLong() * 86400000L,
                                        count_days_open = inputCountDays,
                                        date_close = (inputStartDay + inputCountDays * 86400000L).toLong()
                                    )
                                    addNewTrainingFirestore(newTraining) {newTr ->
                                        checkOrderAndUpdateView(newTr)
                                    }
                                }
                            }
                        }
//                    richTextEdit.getText().let { description ->
//                        funcAfter(inputNameState, description)
//                    }
                        closeModadWindow()
                    }
                }) {
                    Text(if (training?.id != null) "Изменить" else "Добавить")
                }
            }
        }
    }
}
