package global

import androidx.compose.runtime.*
import api.firebase.getFilterBaseVideoFromFirestore
import bdElement.BaseVideo
import bdElement.MyAnswerCommon
import elements.*
import global.QuickStyleProperty.borderBox
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H3
import org.jetbrains.compose.web.dom.Text

object ListVideoForAdd {
    var videosLoaded by mutableStateOf<MyAnswerCommon<BaseVideo>?>(null)
        private set

    private var loadingProcess by mutableStateOf(true)

    val pagination = PageAdapter(12) { limit, skip, silent ->
        if (silent.not()) loadingProcess = true
        if (filterVideo.isEmpty()) {
//        getBaseVideoFromFirestore(limit, skip)
            getFilterBaseVideoFromFirestore(listOf(), listOf(), listOf(), limit, skip)
        } else {
            getFilterBaseVideoFromFirestore(
                filterVideo.sex_id,
                filterVideo.equipment_id,
                filterVideo.muscle_group_id,
                limit,
                skip
            )
        }
    }

    fun setVideosLoaded(load: MyAnswerCommon<BaseVideo>) {
        videosLoaded = load
        load.totalCount?.let { pagination.totalCount = it }
        loadingProcess = false
    }

    @Composable
    fun getViewListMyVideos() {
        LaunchedEffect(findInCommon.value){
            if (findInCommon.value || findInMy.value) pagination.update()
            else findInMy.value = true
        }
        LaunchedEffect(findInMy.value){
            if (findInCommon.value || findInMy.value) pagination.update()
            else findInCommon.value = true
        }
        if (loadingProcess) {
            CenterDiv({ style { marginTop(40.px) } }) { mainLoader() }
        } else {
            Div(
                attrs = {
//            classes(StyleVideoBlock.myVideoBlock) //WtRows.wtRow,
                    style {
                        display(DisplayStyle.Flex)
                        flexWrap(FlexWrap.Wrap)

                        columnGap(10.pt)
                        rowGap(10.pt)
                        paddingLeft(1.pt)
                        marginRight(-9.pt)
                        borderBox()

                        paddingBottom(styleConst.paddingCommon)
                    }
                }
            ) {

                videosLoaded?.mutList?.let {
                    if (it.isEmpty()) {
                        H3(attrs = {
                            classes(styleBlock.commonElement.headerBlock)
                            style {
                                color(rgb(255, 150, 150))
                            }
                        }) {
                            Text("Видео подходящих условиям поиска не найдено, попробуйте сменить фильтры.")
                        }
                    } else {
                        it.forEach { baseVideo ->
                            cardBaseVideo(baseVideo, currentUser.userProfile?.role?.contains("admin") == true) {
                                pagination.update()
                            }
                        }
                    }
                }
            }
        }
        pagination.paginationElement()
    }
}