package elements

import androidx.compose.runtime.Composable
import global.CSSShadow
import global.QuickStyleProperty.myBoxShadow
import global.styleBlock
import global.styleConst
import global.stylePalette
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div

@Composable
fun markerRound(color: CSSColorValue, size: CSSNumeric) {
    Div({
        classes(styleBlock.trainerRoom.ctMarkerOut)
        style {
            borderRadius(50.percent)
            height(size)
            width(size)
            padding(2.px)
            border(1.px, LineStyle.Solid, color)
        }

    }) {
        Div({
            classes(styleBlock.trainerRoom.ctMarkerIn)
            style {
                borderRadius(50.percent)
                backgroundColor(color)
                height(100.percent)
                width(100.percent)
            }

        })
    }
}
@Composable
fun markerRound2(color: CSSColorValue, size: CSSNumeric) {
    Div({
//        classes(styleBlock.trainerRoom.ctMarkerOut)
        style {
            borderRadius(50.percent)
            height(size)
            width(size)
            padding(3.px)
            backgroundColor(Color.white)
            border(1.px, LineStyle.Solid, stylePalette.fontMiddleColor)
        }

    }) {
        Div({
//            classes(styleBlock.trainerRoom.ctMarkerIn)
            style {
                borderRadius(50.percent)
                backgroundColor(color)
                height(100.percent)
                width(100.percent)
            }

        })
    }
}
