package style.White

import global.QuickStyleProperty.myBackgroundGradient
import global.QuickStyleProperty.myBorderGradient
import global.styleConst
import global.stylePalette
import org.jetbrains.compose.web.css.*
import style.AppStylesheet
import style.SNavigation

object StyleNavigationWhite: SNavigation, StyleSheet(AppStylesheet) {

    override val title by style {
        color(stylePalette.black)
    }

    override val lineMenuButton by style {
        backgroundColor(Color.black)
    }

    override val sideNav by style {
        backgroundColor(stylePalette.white)
    }

    override val avatarUserProfile by style {
        borderRadius(50.percent)
    }

    override val textUserProfile by style {
        color(Color.black)
        fontFamily(styleConst.mainFontFamily)
    }

    override val textUserContact by style {
        fontWeight(100)
        fontFamily(styleConst.mainFontFamily)
        color(Color.gray)
    }

    /* The navigation menu links */
    override val buttonSideNav by style {
        myBorderGradient(2.px, Color.white, rgb(236,156,24),rgb(237,123,26))
        color(Color.black)
        borderRadius(20.px)
        hover(self) style {
            myBorderGradient(2.px, Color.lightgray, rgb(236,156,24),rgb(237,123,26)) //            backgroundColor(rgb(41, 41, 41))
        }
    }

    override val buttEnter by style {
        myBackgroundGradient(rgb(236,156,24),rgb(237,123,26))
        color(stylePalette.mainTextColorInv)
        hover(self) style {
            myBackgroundGradient(rgb(216,136,20),rgb(217,103,18))
        }
    }
    override val closebtn by style {
        myBackgroundGradient(rgb(236,156,24),rgb(237,123,26))
        color(Color.white)
        hover(self) style {
            myBackgroundGradient(rgb(216,136,20),rgb(217,103,18))
        }
    }

    override val headerStyle by style {
        color(stylePalette.white)
        backgroundColor(stylePalette.siteHeadersColor)
    }

}
