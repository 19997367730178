package mainPage

import androidx.compose.runtime.Composable
import api.firebase.setCompletedTrainingFirestore
import bdElement.BaseTraining
import elements.CenterDiv
import elements.FlexRowDivParent
import elements.RichTextView
import global.*
import global.QuickStyleProperty.marginWidthAuto
import global.QuickStyleProperty.myTestBorder
import global.QuickStyleProperty.zIndex
import kotlinx.browser.window
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import style.First.StyleClientRoom
import style.First.StyleCommon
import style.First.StyleTrainingRoom
import kotlin.js.Date
import kotlin.math.min

@Composable
fun pageClientSelectedTraining(training: BaseTraining, backFun: () -> Unit) {
    Div({
        style {
            width(100.percent)
        }
    }) {
        H2({ classes(styleBlock.openTraining.nameTraining) }) {
            Text(training.name ?: "")
        }
    }
    Div({
        style {
            width((min(window.innerHeight * 0.45, (window.innerWidth - 44) * 0.95)).px)
            /** 44 это минимальные padding -и на маленьких экранах */
            marginWidthAuto()
        }
    }) {
        RichTextView(training.description ?: "") {
            classes(styleBlock.openTraining.descriptionTraining)
            /*
                            style {
                                padding(10.px)
                                backgroundColor(ColorRes.lightGray)
                            }
            */
        }
        training.assignments.sortedBy { it.order }.mapIndexed { index, baseAssignment -> Pair(index, baseAssignment) }
            .forEach {
//            if (it.first != 0)
                Div({ classes(styleBlock.openTraining.delimiter) })
                Div({
                    classes(styleBlock.openTraining.itemAssignment)
                }) {
                    it.second.name?.let { nameAs ->
                        if (nameAs.isNotEmpty()) H3({ classes(styleBlock.openTraining.nameAssignment) }) {
                            Text(nameAs)
                        }
                    }
                    it.second.description?.let { descAs ->
                        if (descAs.isNotEmpty()) RichTextView(descAs) {
                            classes(styleBlock.openTraining.descriptionAssignment)
                        }
                    }
                    Div({
                        style {
                            borderRadius(10.px)
                            overflow("hidden")
                        }
                    }) {
                        it.second.getFrameVideo(false)
                    }
                }
            }
        FlexRowDivParent({ style { marginTop(10.pt) } }) {
            Button({
                classes(styleBlock.commonElement.buttonBack)
                onClick {
                    backFun()
                }
            }) {
                Text("Назад")
            }
            Div({ style { flex("auto") } })
            if (selectedOpenTraining == training) Button({
                classes(styleBlock.openTraining.buttonDone)
                style {
                    zIndex(185)
                }
                onClick {
                    val today = (Date().getTime() + Date().getTimezoneOffset()).toLong()
                    setCompletedTrainingFirestore(training, today) {
                        training.completed = today
                        selectedOpenTraining = null
                        backFun()
                    }
                }
            }) {
                Text("Выполнено")
            }
        }
    }
}
