package mainPage.modalWindows

import androidx.compose.runtime.Composable
import elements.ModalContent
import elements.buttonWithIcon
import global.closeModadWindow
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.rgb
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.Text
import style.First.StyleTrainingRoom

class ModalDeleteAssignment(val funcDelete: () -> Unit) : ModalContent() {
    @Composable
    override fun drawModalContent() {
        Div {
            H2 {
                Text("Вы действительно хотите удалить это упражнение?")
            }
            buttonWithIcon("Удалить", listOf("fa-solid", "fa-trash-can", StyleTrainingRoom.chevron), {
                style {
                    backgroundColor(rgb(255, 210, 210))
                }
            }) {
                funcDelete()
                closeModadWindow()
            }
        }
    }
}
