package elements.miniElement

import androidx.compose.runtime.Composable
import bdElement.BaseClient
import elements.CenterDiv
import global.QuickStyleProperty.borderBox
import global.QuickStyleProperty.fontSubheader
import global.styleBlock
import global.styleConst
import global.stylePalette
import global.tempAvatarUrl
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun clientHeader(client: BaseClient) {
    Div({
        style {
            marginTop(5.px)
            display(DisplayStyle.Flex)
            marginBottom(styleConst.paddingCommon)
        }
    }) {
        Div({
            style {
                position(Position.Relative)
                flex("auto")
                marginRight(10.px)
//                border(2.px, LineStyle.Solid, rgb(0, 255, 0))
            }
        }) {
            Div({
                style {
                    borderBox()
                    width(100.percent)
                }
            }) {

                P({
//                    classes(styleBlock.commonElement.headerBlock)
                    style {
                        fontSubheader()
                        borderBox()
                        width(100.percent)
                        textAlign("left")
                    }
                }) {
                    Text("Kлиент:")
                }
                P({
//                    classes(styleBlock.commonElement.headerBlock)
                    style {
                        fontSubheader(stylePalette.fontHeaderColor)
                        borderBox()
                        width(100.percent)
                        textAlign("left")
                    }
                }) {
                    Text("${client.name}")
                }
            }
        }
        CenterDiv({
            style {
                flex("initial")
            }
        }) {

            Img(src = client.avatar ?: tempAvatarUrl) {
                classes(styleBlock.trainerRoom.ccAvatar)
                style {
                    width(50.pt)
                }
            }
        }

    }
}
