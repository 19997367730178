package mainPage

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import elements.*
import global.*
import global.QuickStyleProperty.borderBox
import global.QuickStyleProperty.fontSubheader
import global.QuickStyleProperty.myTestBorder
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.jetbrains.compose.web.dom.H2
import style.First.StyleTrainingRoom


private var openArchive by mutableStateOf(false)

val listTabs = listOf(
    MyTabs("tab1") {
        if (openArchive) ListClients.getViewListCloseClients() else ListClients.getViewListOpenClients()
    },
    MyTabs("tab2") {
        PageSpisComplexes(openArchive)
    },
)
val sliderP = SliderPanel(listTabs){
//    console.log("window.onresize set")
    onresizeClientComplexSlider = it
}

@Composable
fun pageSpisClients() {
    FlexColumnDivParent({
        style {
            height(100.percent)
        }
    }, rowGap = 5.pt) {
//        zayavki()
//        if (currentUser.userProfile?.role?.contains("admin") == true) {
            spisClientsAndComplex()
//        } else {
//            oldSpisClients()
//        }

    }
}



@Composable
fun spisClientsAndComplex(){
    FlexRowDivParent(columnGap = 5.pt) {
        CenterDiv({style {
            padding(5.px)
        }}) {
            Img(src = if (openArchive) myIconURL.archiveBlue else myIconURL.archiveGray) {
                style {
                    width(26.px)
                    height(26.px)
                }
                onClick {
                    openArchive = openArchive.not()
                }
            }
/*
            buttonIcon(listOf("fa", "fa-box-archive", StyleTrainingRoom.chevron), attrs = {
                classes(
                    styleBlock.trainerRoom.buttNewTraining,
                    if (openArchive) styleBlock.trainerRoom.buttAccept else styleBlock.trainerRoom.buttResetPasteTrainings
                )
            }, minSize = 25.pt) {
                openArchive = openArchive.not()
            }
*/
        }
        clientsHeader()
        CenterDiv({
            style {
                flex("auto")
                flexGrow(1)
                flexShrink(1)
                position(Position.Relative)
                borderBox()
                borderRadius(10.px)

            }
            onClick {
                sliderP.setSlide(1)
            }
        }) {
            P({
//                classes(styleBlock.commonElement.headerBlock)
                style {
                    padding(5.px)
                    fontSubheader(if (sliderP.slideIndex == 1) stylePalette.myBlue else stylePalette.fontMiddleColor)
//                            marginBottom(10.pt)
                }
            }) {
                Text("Комплексы${if (openArchive) " (А)" else ""}")
            }
            if (sliderP.slideIndex == 1) selectorIndicator()
        }
    }
    Div({
        style {
            flexGrow(1)
            overflowX("hidden")
        }
    }) {
        sliderP.sliderPanel()
    }
}

@Composable
fun selectorIndicator(){
    Div({
        style {
            height(2.px)
            width(100.percent)
            borderRadius(4.px)
            borderBox()
            position(Position.Absolute)
            bottom(0.px)
            backgroundColor(stylePalette.myBlue)//myOrange)
        }
    })
}
@Composable
fun oldSpisClients(){
    FlexRowDivParent(columnGap = 5.pt) {
        CenterDiv {
            buttonIcon(listOf("fa", "fa-box-archive", StyleTrainingRoom.chevron), attrs = {
                classes(
                    styleBlock.trainerRoom.buttNewTraining,
                    if (openArchive) styleBlock.trainerRoom.buttAccept else styleBlock.trainerRoom.buttResetPasteTrainings
                )
            }, minSize = 25.pt) {
                openArchive = openArchive.not()
            }
        }
        clientsHeader()
    }
    if (openArchive) ListClients.getViewListCloseClients() else ListClients.getViewListOpenClients()
}

@Composable
fun clientsHeader() {
    CenterDiv({
        style {
            flex("auto")
            flexGrow(1)
            flexShrink(1)
            borderBox()
            borderRadius(10.px)
            position(Position.Relative)
        }
        onClick {
            sliderP.setSlide(0)
        }
    }) {
        P({
//            classes(styleBlock.commonElement.headerBlock)
            style {
                padding(5.px)
                fontSubheader(if (sliderP.slideIndex == 0) stylePalette.myBlue else stylePalette.fontMiddleColor)
//                marginBottom(10.pt)
            }
        }) {
            Text("Клиенты${if (openArchive) " (А)" else ""}")
        }
//        if (currentUser.userProfile?.role?.contains("admin") == true)
            if (sliderP.slideIndex == 0)  selectorIndicator()
    }
}

