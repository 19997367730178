package style.common

import global.QuickStyleProperty.myBorderGradient
import global.QuickStyleProperty.zIndex
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*
import style.AppStylesheet
import style.AppStylesheet.style
import style.SCommonElement

private fun flexBasis_3_8(cssBuilder: CSSBuilder, columnGap: CSSNumeric) {
    cssBuilder.apply {
        flexBasis((100 / 8.0).percent - columnGap)
        media(mediaMaxWidth(1750.px)) {
            self style {
                flexBasis((100 / 7.0).percent - columnGap)
            }
        }
        media(mediaMaxWidth(1500.px)) {
            self style {
                flexBasis((100 / 6.0).percent - columnGap)
            }
        }
        media(mediaMaxWidth(1250.px)) {
            self style {
                flexBasis((100 / 5.0).percent - columnGap)
            }
        }
        media(mediaMaxWidth(1000.px)) {
            self style {
                flexBasis((100 / 4.0).percent - columnGap)
            }
        }
        media(mediaMaxWidth(750.px)) {
            self style {
                flexBasis((100 / 3.0).percent - columnGap)
            }
        }
    }
}

private fun flexBasis_Custom(cssBuilder: CSSBuilder, min: Int, max: Int, columnGap: CSSNumeric) {
    cssBuilder.apply {
        flexBasis((100.0 / max.toFloat()).percent - columnGap)

        val shag = 700 / min
//        val count = 1920/shag
        for (i in max downTo min) {
            media(mediaMaxWidth(i * shag.px)) {
                self style {
                    flexBasis((100.0 / i.toFloat()).percent - columnGap)
                }
            }
        }
    }
}

object StyleCustomProperties : StyleSheet(AppStylesheet) {


    val hoverOpacity by style {
        opacity(0)
        hover(self) style {
            opacity(1)
        }
    }

    val flexBasis_3_8_5pt by style {
        flexBasis_Custom(this, 3, 8, 5.pt)
    }
    val flexBasis_2_6_5pt by style {
        flexBasis_Custom(this, 2, 6, 5.pt)
    }
    val flexBasis_3_8_10pt by style {
        flexBasis_Custom(this, 3, 8, 10.pt)
    }

    @OptIn(ExperimentalComposeWebApi::class)
    val allAnimation by style {
//        height(30.pt)
//        width(30.pt)
        transitions {
            all {
                duration(0.2.s)
                timingFunction(AnimationTimingFunction.EaseInOut)
            }
        }
    }
}

