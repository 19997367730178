package bdElement

import api.commonPostJsonRequest
import api.firebase.*
import api.parseMyResponse
import global.currentUser
import global.getCurrentDateTimeUTC
import kotlinx.serialization.Serializable

@Serializable
data class BaseComplex(
    var id: String? = null,
    var trainer_id: String? = null,
    var cover: String? = null,
    var name: String? = null,
    var description: String? = null,
    var price: Double? = null,
    var deadline: Int? = null,
    var activate: Boolean? = false,
    var archive: Boolean? = false,
    override var date_create: Long? = getCurrentDateTimeUTC(),
    override var date_update: Long? = null,
): CommonElement()  {

    override val typeElement = TypeElement.Complex

    fun addNewComplexFirestore(
        funcRez: (BaseComplex) -> Unit
    ) {
        addNewCommonElementFirestoreFromTrainer(getBaseElement(this), funcRez)
    }

    fun changeComplexFirestore(
        funcRez: (String) -> Unit
    ) {
        changeCommonElementFirestoreFromTrainer(
            CommonChangesBaseElement(
                TypeElement.Complex.label,
                id ?: "",
                mapOf(
                    "cover" to cover,
                    "name" to (name ?: ""),
                    "description" to (description ?: ""),
                    "price" to (price ?: 0.0),
                    "deadline" to (deadline ?: 0)
                )
            ), funcRez
        )
    }
    fun setActivateComplexFirestore(
        activate: Boolean,
        funcRez: (String) -> Unit
    ) {
        changeCommonElementFirestoreFromTrainer(
            CommonChangesBaseElement(
                TypeElement.Complex.label,
                id ?: "",
                mapOf(
                    "activate" to activate
                )
            ), funcRez
        )
    }

    fun setArchiveComplexFirestore(
        archive: Boolean,
        funcRez: (String) -> Unit
    ) {
        changeCommonElementFirestoreFromTrainer(
            CommonChangesBaseElement(
                TypeElement.Complex.label,
                id ?: "",
                mapOf(
                    "archive" to archive
                )
            ), funcRez
        )
    }

    fun deleteComplexFirestore(funcRez: (String) -> Unit){
        id?.let {
            deleteCommonElementFirestoreFromTrainer(typeElement,it){
                funcRez(it)
            }
        }
    }
}

@Serializable
data class ComplexInfo(
    val complex: BaseComplex,
    val count_trainings: Long,
    val srok: Long,
    val max_date: Long,
    val date_opens: List<Long>,
    val started: Boolean,
    val request: Boolean
){
    companion object {
        fun getComplexInfo(complexId: String, rezFun: (ComplexInfo?)->Unit) {
            commonPostJsonRequest(
                path = "get_complex_info",
                headers = { headers ->
                    headers.append("complexId", complexId)
                    headers.append("clientId", currentUser.userProfile?.id ?: "")
                },
                body = ""
            ){
                parseMyResponse<ComplexInfo>(it).checkStatusOK {
                    rezFun(objectResponse)
                }
            }
        }
    }
}