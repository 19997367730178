package style.First

import global.*
import global.QuickStyleProperty.myBoxShadow
import global.QuickStyleProperty.myTextShadow
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*
import style.AppStylesheet
import style.ColorResWhite
import style.SClientRoom


object StyleClientRoom  : SClientRoom, StyleSheet(AppStylesheet) {

    val buttonBack by style {
        flex("initial")
        fontFamily("Roboto Slab")
        padding(8.px, 8.px, 8.px, 8.px)
        fontSize(15.px)
        property("text-transform", "uppercase");
        backgroundColor(rgb(71, 71, 71))
        color(rgb(255, 255, 255))
        borderWidth(0.px)
        borderRadius(10.px)
        self + hover style {
            backgroundColor(stylePalette.gray)// stylePalette.gray)
        }
    }

    val buttonDone by style {
        flex("initial")
        fontFamily(styleConst.mainFontFamily)
        padding(8.px, 8.px, 8.px, 8.px)
        fontSize(15.px)
        fontWeight(700)
//        property("text-transform", "uppercase");
        backgroundColor(rgb(12,192,61))
        color(rgb(255, 255, 255))
        borderWidth(0.px)
        borderRadius(10.px)
        self + hover style {
            backgroundColor(stylePalette.darkGreen)
        }
    }


    override val itemCardTraining by style {
        border(1.px, LineStyle.Solid, rgb(127, 127, 127))
        borderRadius(10.pt)
        myBoxShadow(CSSShadow(3.px,3.px,3.px,0.px))
//        property(
//            "box-shadow",
//            "3px 3px 1px 0 rgba(0, 0, 0, 0.5), 4px 4px 6px 0 rgba(0, 0, 0, 0.19)"
//        )
        padding(10.px)
        marginBottom(10.pt)
        backgroundColor(rgb(230, 230, 230))
    }
    override val ctIconButton by style {
        fontFamily(styleConst.mainFontFamily)
        color(Color.white)
    }
    override val ctMarkerOut by style {
        myBoxShadow(
            CSSShadow(-3.px, -3.px, 5.px, color = rgba(255, 255, 255, 0.35)),
            CSSShadow(2.px, 2.px, 5.px, color = rgba(0, 0, 0, 0.3))
        )
        backgroundColor(Color.lightgray)
    }
    override val ctMarkerIn by style {
        myBoxShadow(
            CSSShadow(-2.px, -2.px, 5.px, color = rgba(255, 255, 255, 0.35), inner = true),
            CSSShadow(2.px, 2.px, 5.px, color = rgba(0, 0, 0, 0.4), inner = true)
        )
    }

    @OptIn(ExperimentalComposeWebApi::class)
    override val ctIconDoneButton by style {
        transitions {
            all {
                duration(0.2.s)
                timingFunction(AnimationTimingFunction.EaseInOut)
            }
        }
        borderRadius(10.px)
        myTextShadow(
            CSSTextShadow(-2.px, -2.px, 4.px, color = rgba(255, 255, 255, 0.35)),
            CSSTextShadow(2.px, 2.px, 4.px, color = rgba(0, 0, 0, 0.6)),
            CSSTextShadow(0.px, 0.px, 1.px, color = stylePalette.gray)
        )
        self + hover style {
            myTextShadow(
                CSSTextShadow(-2.px, -2.px, 4.px, color = rgba(255, 255, 255, 0.35)),
                CSSTextShadow(2.px, 2.px, 4.px, color = rgba(0, 0, 0, 0.9)),
                CSSTextShadow(0.px, 0.px, 1.px, color = stylePalette.darkGreen)
            )
        }
        self + active style {
            myTextShadow(
                CSSTextShadow(-2.px, -2.px, 4.px, color = rgba(255, 255, 255, 0.35)),
                CSSTextShadow(2.px, 2.px, 4.px, color = rgba(0, 0, 0, 0.9)),
                CSSTextShadow(0.px, 0.px, 1.px, color = stylePalette.black)
            )
            transform {
                scale(0.98)
            }
        }
    }
    override val ctName by style {
        fontFamily(styleConst.mainFontFamily)
        color(Color.white)
    }
    override val ctTime by style {
        fontFamily(styleConst.mainFontFamily)
        color(Color.yellow)
//        color(rgb(235,235,235))
    }
    override val ctBoxMuscleGroup by style {
        fontFamily(styleConst.mainFontFamily)
        padding(6.px)
        borderRadius(6.px)
        fontSize(14.px)
        backgroundColor(rgba(255, 255, 255, 0.3))
        color(stylePalette.white)
    }
    override val buttonStart by style {
//        margin(8.pt)
        fontFamily("Roboto Slab")
        padding(8.px)
        fontSize(16.px)
        backgroundColor(rgb(192, 12, 61))
        color(rgb(255, 255, 255))
        borderRadius(6.px)
        width(100.percent)
        boxSizing("border-box")
        display(DisplayStyle.Block)
        borderWidth(0.px)
        myBoxShadow(
            CSSShadow(-2.px, -2.px, 3.px, color = rgb(255, 255, 255)),
            CSSShadow(2.px, 2.px, 3.px, color = rgba(0, 0, 0, 0.3))
        )
        self + hover style {
            backgroundColor(stylePalette.darkRed)
//            myBoxShadow(
//                CSSShadow(-2.px, -2.px, 5.px, color = rgba(0, 0, 0, 0.5), inner = true),
//                CSSShadow(2.px, 2.px, 5.px, color = rgba(255, 255, 255,0.3), inner = true)
//            )
        }
        self + active style {
            myBoxShadow(
//                CSSShadow(-2.px, -2.px, 5.px, color = rgba(255, 255, 255,0.3), inner = true),
                CSSShadow(0.px, 0.px, 3.px, color = rgba(0, 0, 0, 0.35), inner = true)
            )
        }
    }

}