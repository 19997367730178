package bdElement

import api.firebase.TypeElement
import global.getCurrentDateTimeUTC
import kotlinx.serialization.Serializable

@Serializable
data class UserProfile(
    override var id: String? = null,
    override var email: String? = null,
    override var name: String? = null,
    override var second_name: String? = null,
    override var phone: String? = null,
    override var avatar: String? = null,
    var role: List<String>? = null,
    override var description: String? = null,
    var quiz: Boolean? = null,
    override var status: String? = null,
    var date_registration: Long? = null,
) :CommonUser()

//@Serializable
open class CommonUser(): CommonElement() {
    open var id: String? = null
    open var email: String? = null
    open var name: String? = null
    open var second_name: String? = null
    open var phone: String? = null
    open var avatar: String? = null
    open var description: String? = null
    open var status: String? = null
    override var date_create: Long? = getCurrentDateTimeUTC()
    override var date_update: Long? = null
    override val typeElement: TypeElement = TypeElement.Client
    }
