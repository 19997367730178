package global

import androidx.compose.runtime.*
import api.CustomMyListResponse
import api.firebase.getCommonListFromTrainer
import bdElement.BaseClient
import bdElement.MyAnswerCommon
import elements.CardClient
import elements.CenterDiv
import elements.mainLoader
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H3
import org.jetbrains.compose.web.dom.Text

object ListClients {
    var clientsLoaded by mutableStateOf<MyAnswerCommon<BaseClient>?>(null)
        private set

    var newClient by mutableStateOf<Int>(0)
        private set

    private var loadingProcess by mutableStateOf(true)

    fun newZayavki(): Boolean = clientsLoaded?.rows?.filter { it.status == "new" }?.isNotEmpty() == true

    val pagination = PageAdapter(0) { limit, skip, silent ->
        if (silent.not()) loadingProcess = true
//        getListClients(limit, skip)
        getCommonListFromTrainer("trainer_clients", listOf(), limit, skip) {
            setClientsLoaded(it)
            loadingProcess = false
        }
    }

    private var firstStart: Boolean = true

    @Composable
    fun listenerForWritingCookiesAndFilter() {
        LaunchedEffect(selectedClient) {
            currentUser.userProfile?.id?.let { id_user ->
                selectedClient?.id?.let { id_client ->
                    setCookie("${id_user}_selectedClient", id_client)
                } ?: run {
                    deleteCookie("${id_user}_selectedClient")
                }
            }
        }
    }

    private fun getFromCookie() {
        if (firstStart) {
            currentUser.userProfile?.id?.let { id_user ->
                getCookie("${id_user}_selectedClient")?.let { id_client ->
                    if (id_client.isNotEmpty()) {
                        clientsLoaded?.rows?.find { it.id == id_client }?.let {
                            selectedClient = it
                        }
                    }
                }
            }
            firstStart = false
        }
    }

    /*
        fun setClientsLoaded(load: MyAnswerCommon<BaseClient>) {
            clientsLoaded = load
            load.totalCount?.let { pagination.totalCount = it }
            getFromCookie()
            loadingProcess = false
        }
    */

    private fun setClientsLoaded(load: CustomMyListResponse<BaseClient>) {
        clientsLoaded = MyAnswerCommon(load.objectResponse, load.totalCount)
        newClient = clientsLoaded?.rows?.count { it.status == "new" } ?: 0
        load.totalCount?.let { pagination.totalCount = it }
        getFromCookie()
    }

    @Composable
    fun getViewListNewZayavki() {
        if (loadingProcess) {
            CenterDiv({ style { marginTop(40.px) } }) { mainLoader() }
        } else {
            Div({
                style {
                    marginBottom(10.pt)
                }
            }) {
                clientsLoaded?.rows?.filter { it.status == "new" }
                    ?.mapIndexed { index, baseClient -> Pair(index, baseClient) }?.let {
                        if (it.isEmpty()) {
                            H3(attrs = {
                                classes(styleBlock.commonElement.headerBlock)
                                style {
                                    marginTop(20.pt)
                                    color(Color.gray)
                                }
                            }) {
                                Text("Новых заявок пока нет.")
                            }
                        } else {
                            it.forEach {
//                if (it.first != 0) Div({ classes(styleBlock.trainerRoom.delimiterCardClient) })
                                CardClient(it.second) {
//                    statusLoading.loadingTraining = true
//                    selectedClient = it.second
                                }
                            }
                        }
                    }
            }
        }
    }

    @Composable
    fun getViewListOldZayavki() {
        if (loadingProcess) {
            CenterDiv({ style { marginTop(40.px) } }) { mainLoader() }
        } else {
            Div({
                style {
                    marginBottom(10.pt)
                }
            }) {
                clientsLoaded?.rows?.filter { it.status != "new" }
                    ?.mapIndexed { index, baseClient -> Pair(index, baseClient) }?.let {
                        if (it.isEmpty()) {
                            H3(attrs = {
                                classes(styleBlock.commonElement.headerBlock)
                                style {
                                    marginTop(20.pt)
                                    color(Color.gray)
                                }
                            }) {
                                Text("В вашем архиве пока что ничего нет.")
                            }
                        } else {
                            it.forEach {
//                if (it.first != 0) Div({ classes(styleBlock.trainerRoom.delimiterCardClient) })
                                CardClient(it.second, cardZayvka = true) {
//                    statusLoading.loadingTraining = true
//                    selectedClient = it.second
                                }
                            }
                        }
                    }
            }
        }
    }

    @Composable
    fun getViewListOpenClients() {
        if (loadingProcess) {
            CenterDiv({ style { marginTop(40.px) } }) { mainLoader() }
        } else {
            Div({
                style {
                    overflowX("visible")
                }
            }) {
                clientsLoaded?.rows?.filter { it.status == "open" }
                    ?.let {
                        if (it.isEmpty()) {
                            H3(attrs = {
                                classes(styleBlock.commonElement.headerBlock)
                                style {
                                    color(Color.gray)
                                }
                            }) {
                                Text("У вас пока нет ни одного клиента. Ссылку чтобы пригласить клиента можно найти в разделе \"Профиль\".")
                            }
                        } else {
                            it.mapIndexed { index, baseClient -> Pair(index, baseClient) }?.forEach {
                                if (it.first != 0) Div({ classes(styleBlock.trainerRoom.delimiterCardClient) })
                                CardClient(it.second) {
                                    statusLoading.loadingTraining = true
                                    selectedClient = it.second
                                }
                            }
                        }
                    }
            }
        }
//        pagination.paginationElement()
    }
//        pagination.paginationElement()


    @Composable
    fun getViewListCloseClients() {
        if (loadingProcess) {
            CenterDiv({ style { marginTop(40.px) } }) { mainLoader() }
        } else {
            Div {
                clientsLoaded?.rows?.filter { it.status == "close" }?.let {
                    if (it.isEmpty()) {
                        H3(attrs = {
                            classes(styleBlock.commonElement.headerBlock)
                            style {
                                marginTop(20.pt)
                                color(Color.gray)
                            }
                        }) {
                            Text("В вашем архиве пока что никого нет.")
                        }
                    } else {
                        it.sortedByDescending { it.date_update }
                            ?.mapIndexed { index, baseClient -> Pair(index, baseClient) }?.forEach {
                                if (it.first != 0) Div({ classes(styleBlock.trainerRoom.delimiterCardClient) })
                                CardClient(it.second) {
                                    statusLoading.loadingTraining = true
                                    selectedClient = it.second
                                }
                            }
                    }
                }
            }
        }
//        pagination.paginationElement()
    }

}