package elements

import androidx.compose.runtime.Composable
import bdElement.BaseRequestComplex
import global.*
import global.QuickStyleProperty.borderBox
import global.QuickStyleProperty.zIndex
import mainPage.modalWindows.ModalCancelObject
import mainPage.modalWindows.ModalConfirmObject
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import kotlin.js.Date

@Composable
fun CardRequestComplex(requestComplex: BaseRequestComplex, forTrainer: Boolean = true, onClick: () -> Unit = {}) {
    FlexColumnDivParent({
        style {
            padding(10.px)
        }
    }) {
        FlexRowDivParent({
            style {
                zIndex(if (dropDownElementNow == requestComplex.id.toString()) 150 else 10)
//            maxWidth(100.percent)
//            borderBox()
//            myTestBorder()
            }
        }, columnGap = 10.px) {
            CenterDiv({
                style {
                    flex("initial")
                }
            }) {
                Img(src = requestComplex.client_avatar ?: tempAvatarUrl) {
                    classes(styleBlock.trainerRoom.ccAvatar)
                }
            }
            Div({
                style {
//                width(100.percent)
//                borderBox()
                    flex("auto")
                    overflowX("hidden")
//                    flexDirection(FlexDirection.Column)
                    flexShrink(1)
                    flexGrow(1)
                    fontWeight(800)
                    borderBox()
                    fontFamily(styleConst.mainFontFamily)
                    property("overflow-wrap", "break-word")
                }
            }) {
                Div({
                    style {
                        textAlign("left")
                        width(100.percent)
                    }
                }) {
                    H3({
                        style {
                            width(100.percent)
                        }
                    }) {
                        Text(requestComplex.client_name ?: "")
                    }
                    H4({
                        style {
                            width(100.percent)
                            fontWeight(400)
                        }
                    }) {
                        Text(requestComplex.client_phone ?: "")
                        if (forTrainer) {
                            Br {}
                            Text(requestComplex.client_message ?: "")
                        }
                    }
                    if (forTrainer.not() || requestComplex.status != "new") FlexRowDivParent {
                        Div({
                            style {
                                backgroundColor(
                                    if (requestComplex.status == "new") stylePalette.myBlue
                                    else if (requestComplex.status == "rejected" || requestComplex.status == "canceled") Color.gray
                                    else stylePalette.myGreen
                                )
                                color(stylePalette.mainTextColorInv)
                                fontWeight(400)
//                                borderBox()
//                                width(200.px)
                                borderRadius(15.px)
                                padding(3.px, 15.px)
                            }
                        }) {
                            Text(
                                if (requestComplex.status == "new") "Заявка на рассмотрении"
                                else if (requestComplex.status == "rejected") "Заявка отклонена"
                                else if (requestComplex.status == "canceled") "Заявка отменена"
                                else "Заявка выполнена"
                            )
                            if (requestComplex.status != "new") {
                                Br {}
                                Text(
                                    Date(
                                        (requestComplex.date_update ?: 0) - Date().getTimezoneOffset()
                                    ).toLocaleDateString()
                                )
                            }
                        }
                    }

                }
                Div({
                    style {
                        textAlign("right")
                        width(100.percent)
                    }
                }) {
                    H3({
                        style {
                            width(100.percent)
                        }
                    }) {
                        Text(requestComplex.complex_name ?: "")
                    }
                    H4({
                        style {
                            width(100.percent)
                            fontWeight(400)
                        }
                    }) {
                        Text(requestComplex.complex_price?.toString() ?: "")
                    }
                }
            }
            CenterDiv {
                CutBorderBox(15, 2, attrs = {
                    style {
                        flex("initial")
                        position(Position.Relative)
                    }
                    onClick {
                        onClick()
                    }
                }) {
                    Img(src = requestComplex.complex_cover ?: tempComplexCoverUrl) {
                        classes(styleBlock.trainerRoom.ccCoverComplex)
                        style {
                            width(60.px)
                            height(80.px)
                        }
                    }
                }
            }
        }
        if (requestComplex.status == "new") FlexRowDivParent({
            style {
                justifyContent(JustifyContent.SpaceBetween)
            }
        }) {
            if (forTrainer) Button({
                classes(styleBlock.trainerRoom.fmButton)
                style {
                    borderBox()
//                    flex("auto")
                    flexBasis(40.percent)
                }
                onClick {
                    openModalWindow(ModalCancelObject("Вы действительно хотите отклонить эту заявку?","Отклонить") {
                        requestComplex.reject { ListRequestsComplexNew.pagination.update() }
                    })
                }
            }) {
                Text("Отклонить")
            } else Button({
                classes(styleBlock.trainerRoom.fmButton)
                style {
                    borderBox()
//                    flex("auto")
                    flexBasis(40.percent)
                }
                onClick {
                    openModalWindow(ModalCancelObject("Вы действительно хотите отменить эту заявку?","Отменить") {
                        requestComplex.cancel { ListRequestsComplexNewForClient.pagination.update() }
                    })
                }
            }) {
                Text("Отменить")
            }
            if (forTrainer) Button({
                classes(styleBlock.trainerRoom.fmButton)
                style {
                    borderBox()
//                    flex("auto")
                    flexBasis(40.percent)
                    backgroundColor(stylePalette.myGreen)
                }
                onClick {
                    openModalWindow(ModalConfirmObject("Вы действительно хотите подтвердить эту заявку?","Подтвердить") {
                        requestComplex.confirm { ListRequestsComplexNew.pagination.update() }
                    })
                }
            }) {
                Text("Подтвердить")
            }

        }
    }

}