package api.firebase

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import api.commonUserGetJsonRequest
import api.commonUserPostFDRequest
import api.commonUserPostJsonRequest
import api.parseMyResponse
import apiKey
import appId
import authDomain
import bdElement.BaseTraining
import bdElement.CreatiumRequest
import bdElement.MyAnswerCommon
import bdElement.UserProfile
import global.*
import kotlinx.browser.window
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import messagingSenderId
import org.w3c.dom.EventSource
import org.w3c.fetch.RequestInit
import projectId
import serverAdress
import storageBucket
import kotlin.js.Promise

external fun firebaseAuthInit(
    apiKey: String,
    authDomain: String,
    projectId: String,
    storageBucket: String,
    messagingSenderId: String,
    appId: String,
    measurementId: String,
    authStateChanged: (dynamic) -> Unit
)

external fun loadUiGoogleSignIn(idDiv: String)
external fun sendEmailVerification(urlAfter: String, afterSent: () -> Unit)
external fun userSignOut(afterSignOut: () -> Unit)

class CurrentUser() {
    private var user: dynamic = null
    var userProfile by mutableStateOf<UserProfile?>(null)

    var login by mutableStateOf<Boolean>(false)

    fun setUser(authUser: dynamic) {
        user = authUser
        login = user != null
    }

    fun getIdToken(thenF: (String) -> Unit) {
        if (user != null) {
            val promise: Promise<String> = user.getIdToken() as Promise<String>
            promise.then {
                thenF(it)
            }
        }
    }

    fun getUid(): String? = user?.uid as? String
    fun getDisplayName(): String? = user?.displayName as? String
    fun getEmail(): String? = user?.email as? String
    fun getEmailVerified(): Boolean? = user?.emailVerified as? Boolean
}


fun setStatusUserFirestore(
    status: String,
    funcRez: () -> Unit
) {
    commonUserPostFDRequest(
        path = "set_status_user",
        formData = { formData ->
            formData.append("user_id", currentUser.userProfile?.id ?: "")
            formData.append("status", status)
            formData.append("date_update", getCurrentDateTimeUTC().toString())
        },
    ) { userStr ->
        val response = parseMyResponse<String>(userStr)
        response.status?.let { status ->
            if (status == "OK") funcRez()
        }
    }
}


var additionFunAfterSignIn: (() -> Unit)? = null

fun firebaseStart() {
//    console.log("firebaseStart")
    firebaseAuthInit(
        apiKey,
        authDomain,
        projectId,
        storageBucket,
        messagingSenderId,
        appId,
        measurementId
    ) { user ->
//        console.log("firebaseAuthInit User")
        if (user != "no_user") {
            console.log("User: $user")
            currentUser.setUser(user)
            updateUserProfile() {
                additionFunAfterSignIn?.invoke()
            }
        } else {
            console.log("no User")
            currentUser.setUser(null)
            statusLoading.startNoUserFirebase()
        }
    }
}

private var firstStart = true

fun requestFromTrainer(request: (UserProfile) -> Unit) {
    currentUser.userProfile?.let { profile ->
        if (profile.role?.contains("trainer") == true) {
            request(profile)
        }
    }
}


fun getClientTrainings(clientId: String, limit: Int = 10, skip: Int = 0) {
    currentUser.userProfile?.let { profile ->
        if (profile.role?.contains("trainer") == true) {
            commonUserPostJsonRequest(
                path = "trainings_clients",
                bodyStr = myJson.encodeToString(CreatiumRequest(limit = limit, skip = skip)),
                headers = { headers ->
                    headers.append("trainerId", profile.id ?: "")
                    headers.append("clientId", clientId)
                }) { userStr ->
                ClientTrainings.setTrainingLoaded(Json.decodeFromString<MyAnswerCommon<BaseTraining>>(userStr))
                statusLoading.loadingTraining = false
            }
        }
    }
}


/*
fun getListClients(limit: Int = 10, skip: Int = 0) {
    currentUser.userProfile?.let { profile ->
        if (profile.role?.contains("trainer") == true) {
            commonUserPostJsonRequest(
                path = "trainer_clients",
                bodyStr = myJson.encodeToString(CreatiumRequest(limit = limit, skip = skip)),
                headers = { headers ->
                    headers.append("trainerId", profile.id ?: "")
                }) { userStr ->
                parseMyListResponse<BaseClient>(userStr).checkStatusOK {
                    ListClients.setClientsLoaded(it)
                }
            }
        }
    }
}
*/

fun addNewClient(clientId: String, idTrainer: String, phone: String, rezFun: () -> Unit = {}) {
    commonUserGetJsonRequest("add_new_client", { headers ->
        headers.append("trainerIdNew", idTrainer)
        headers.append("clientId", clientId)
        headers.append("phone", phone)
        if (currentUser.userProfile?.status == "new") headers.append("status", "client")
    }) { respStr ->
        parseMyResponse<String>(respStr).checkStatusOK {
            if (objectResponse == idTrainer) {
                statusLoading.checkZayavkaDone()
//                console.log("Заявка тренеру: есть")
            } else console.log("Заявка тренеру: ошибка")
            rezFun()
        }
    }
}

var eventSource: EventSource? = null
fun startSSElistener(userId: String) {
    //    val eventSource =  EventSource("$serverAdress/sse-endpoint")
    eventSource = EventSource("$serverAdress/sse-endpoint/$userId")

    eventSource?.onmessage = {
        // Обработка полученных данных
//        console.log("Получены данные:", it.data);
        when (CodesSSE.getCode(it.data.toString())) {
            CodesSSE.nothing -> {
                console.log("CodesSSE:", "nothing")
            }

            CodesSSE.trainingsUpdateList -> {
                OpenTrainings.pagination.update(true)
                OldTrainings.pagination.update(true)
            }

            CodesSSE.trainersUpdateList -> {
                ListTrainers.pagination.update(true)
            }

            CodesSSE.clientTrainingsUpdateList -> {
                ClientTrainings.pagination.update(true)
            }

            CodesSSE.clientUpdateList -> {
                ListClients.pagination.update(true)
            }
        }
    }

    eventSource?.onerror = { event ->
        // Обработка ошибок
        console.error("Ошибка соединения:", event.timeStamp);
    }
}

fun updateUserProfile(rezFun: () -> Unit = {}) {
    commonUserGetJsonRequest("get_user_profile", { headers ->
//        console.log("subuser")
//        console.log(statusLoading.params["subuser"])
        statusLoading.trainerProfile?.id?.let { idTrainer ->
            headers.append("trainerIdNew", idTrainer)
        }
        headers.append("subuser", statusLoading.params["subuser"] ?: "")
    }) { userStr ->
//        console.log("subuser2")
//        console.log(statusLoading.params["subuser"])
//        console.log("userStr")
//        console.log(userStr)
        parseMyResponse<UserProfile>(userStr).checkStatusOK {
//            console.log("parseMyResponse<UserProfile>")
//            console.log(objectResponse)

            currentUser.userProfile = objectResponse
            currentUser.userProfile?.let { profile ->

//                console.log("currentUser.userProfile?.let")
                /*
                                statusLoading.trainerProfile?.id?.let { idTrainer ->
                                    console.log("currentUser  11")
                                    profile.id?.let { client_id ->
                                        console.log("currentUser  22")
                                        addNewClient(clientId = client_id, idTrainer = idTrainer)
                                    }
                                }
                */
                rezFun()
                if (firstStart && profile.role?.contains("trainer") == true) {
                    ListClients.pagination.update()
                    ListRequestsComplexNew.pagination.update()
                    ListVideoForAdd.pagination.update()
                    getTagsFromFirestore()
                    profile.id?.let { startSSElistener(it) }
                    firstStart = false
                }
                statusLoading.startFirebase()

            } ?: run {
//                statusLoading.finishLoadSpisTrainersFirebase()
                statusLoading.startNoUserFirebase()
            }
        }
    }
}

fun getFreeTrainings() {
    val options: RequestInit = RequestInit(
        method = "POST",
    )

    Promise.resolve(window.fetch("$serverAdress/free_trainings", options).then { response ->
        response.json()
    }.then {
        val userStr = JSON.stringify(it)
        val response = parseMyResponse<List<BaseTraining>>(userStr)
        response.status?.let { status ->
            if (status == "OK") {
                response.objectResponse?.let {
                    freeGirlTrainings = arrayListOf<BaseTraining>().apply {
                        it.find { it.id == "998f3133-057f-43bd-ab67-e06bd2581411" }?.let { baseTraining ->
                            add(baseTraining.copy(name = "1"))
                        }
                        it.find { it.id == "f823a0b9-5680-4e13-90aa-d651cd605d7a" }?.let { baseTraining ->
                            add(baseTraining.copy(name = "2"))
                        }
                        it.find { it.id == "3b68d7d6-e8fc-4231-9b94-d9ef9c587810" }?.let { baseTraining ->
                            add(baseTraining.copy(name = "3"))
                        }
                    }
                    freeBoyTrainings = arrayListOf<BaseTraining>().apply {
                        it.find { it.id == "8b2f3ae0-c6fa-4aec-9d44-e76054f39bf6" }?.let { baseTraining ->
                            add(baseTraining.copy(name = "1"))
                        }
                        it.find { it.id == "790ffc84-792e-493d-ae8a-876895862c97" }?.let { baseTraining ->
                            add(baseTraining.copy(name = "2"))
                        }
                        it.find { it.id == "a1c44fe4-9fed-4dca-84b6-1dc664e471d2" }?.let { baseTraining ->
                            add(baseTraining.copy(name = "3"))
                        }
                    }
                    /*
                                        OpenTrainings.setTrainingLoaded(
                                            MyAnswerCommon(
                                                it,
                                                response.totalCount
                                            )
                                        )
                    */
                }
            }
        }
    })
}

