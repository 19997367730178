package mainPage.modalWindows

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import bdElement.BaseAssignment
import elements.*
import global.QuickStyleProperty.borderBox
import global.QuickStyleProperty.myFullSize
import global.closeModadWindow
import global.phoneDevice
import global.styleBlock
import global.stylePalette
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import style.First.StyleUserProfile

class ModalEditAssignment(val assignment: BaseAssignment, val funcAfter: (String, String) -> Unit) : ModalContent() {
    val richTextEdit = RichTextEdit(assignment.description ?: "")
    var inputNameState by mutableStateOf(assignment.name ?: "")

    override val closableBack = false
    override val fullscreen: Boolean = phoneDevice

    private var disabledButtSet by mutableStateOf("temp")

    @Composable
    override fun drawModalContent() {
        FlexRowDivParent({
            style {
                backgroundColor(stylePalette.bodyModalColor)
            }
        },columnGap = 0.px) {
            if (fullscreen.not()) FlexInitialDiv({
                style {
//                    flex("initial")
                }
            }) {
                Div({
//                    id("frameVideoAssignment")
                    style {
                        width(180.pt)
//                    height(400.pt)
                    }
                }) {
                    assignment.getFrameVideo()
                }
            }
            FlexColumnDivParent({
                style {
                    flex("auto")
                    flexShrink(1)
                    flexGrow(1)
                    myFullSize()
                    backgroundColor(stylePalette.bodyModalColor)
                    padding(10.px)
                    if (fullscreen) height(window.innerHeight.px) else {
//                        document.getElementById("frameVideoAssignment")?.getBoundingClientRect()?.height
                        height(180.pt / 9.0 * 16.0-5.pt)
                    }
                }
            }) {
                FlexColumnDivParent({ style { flex("auto") } }, rowGap = 0.px) {
                    H3({
                        classes(styleBlock.commonElement.inputTextHeaders)
                        style {
                            marginBottom(2.pt)
                        }
                    }) {
                        Text("Название упражнения")
                    }
                    Input(type = InputType.Text) {
                        style {
                            width(100.percent)
                            borderBox()
                        }
                        classes(styleBlock.commonElement.inputText)
                        value(inputNameState)
                        onInput { event -> inputNameState = event.value }
                    }

                    H3({
                        classes(styleBlock.commonElement.inputTextHeaders)
                        style {
                            marginTop(8.pt)
                            marginBottom(2.pt)
                        }
                    }) {
                        Text("Описание упражнения")
                    }
                    richTextEdit.RichTextEdit()
                }
                FlexRowDivParent {
                    if (fullscreen) Button({
                        classes(styleBlock.commonElement.buttonBack)
                        style {
                            flex("initial")
                        }
                        onClick {
                            closeModadWindow()
                        }
                    }) {
                        Text("Назад")
                    }
                    Button({
                        classes(styleBlock.trainerRoom.fmButton)
                        style {
                            borderBox()
                            flex("auto")
                        }
                        attr(disabledButtSet, "")
                        onClick {
                            disabledButtSet = "disabled"
                            funcAfter(inputNameState, richTextEdit.getText())
                            closeModadWindow()
                        }
                    }) {
                        Text("Изменить")
                    }
                }
            }
        }
    }
}
