package elements

import androidx.compose.runtime.Composable
import global.*
import global.QuickStyleProperty.fontSubheader
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun blockAddAssignment() {

    Div({
        style {
            marginTop(styleConst.paddingCommon)
            property("user-select", "none")
        }
    }) {
        P(attrs = {
            style {
                fontSubheader()
                width(100.percent)
                textAlign("left")
            }
        }) {
            Text("Выберите и добавьте")
        }
        filterSelectedBlock(filterBlock, {
            ListVideoForAdd.pagination.update()
        }, myVideo = false) {
            style {
                paddingBottom(styleConst.paddingCommon)
                marginTop(styleConst.paddingSmall)
            }
        }
        ListVideoForAdd.getViewListMyVideos()
    }
}
