package mainPage

import androidx.compose.runtime.Composable
import elements.CenterDiv
import elements.FlexRowDivParent
import elements.buttonIcon
import global.*
import global.QuickStyleProperty.zIndex
import mainPage.modalWindows.ModalAddEditComplex
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div

@Composable
fun PageSpisComplexes(openArchive: Boolean) {
    Div({ style { position(Position.Relative)
        overflowX("hidden")

    } }) {
        if (openArchive.not() && phoneDevice.not()) CenterDiv({
            style {
                width(100.percent)
                padding(styleConst.paddingCommon, 10.pt)
            }
        }) {
            buttonIcon(listOf("fa", "fa-plus"), {
                classes(styleBlock.trainerRoom.buttNewTraining)
            }, fontSize = 25.pt) {
                openModalWindow(ModalAddEditComplex() {})
            }
        }
        ListComplexes.getViewListComplexes(openArchive)
    }
    if (openArchive.not() && phoneDevice) CenterDiv({
        style {
            position(Position.Sticky)
            bottom(0.px)
            width(100.percent)// - styleConst.paddingCommon * 2)
            paddingTop(styleConst.paddingCommon)
            backgroundColor(Color.white)
            zIndex(13)
        }
    }) {
        buttonIcon(listOf("fa", "fa-plus"), {
            classes(styleBlock.trainerRoom.buttNewTraining)
        }, fontSize = 25.pt) {
            openModalWindow(ModalAddEditComplex() {})
        }
    }
}