package global

//val apiKey: String = "AIzaSyAYKZcQK8Y9tKCHHiqox5hA1wyxEbVEF2E"
//val authDomain: String = "fitconstructor.firebaseapp.com"
//val projectId: String = "fitconstructor"
//val storageBucket: String = "fitconstructor.appspot.com"
//val messagingSenderId: String = "331993032509"
//val appId: String = "1:331993032509:web:fa13f0f0872465767ac754"
val measurementId: String = "G-9J0YFJLTXJ"

//val apiKey: String = "AIzaSyAA6WTCkdFE_dN5pnW5Git1p34VYETG30M"
//val authDomain: String = "flowing-lead-379317.firebaseapp.com"
//val projectId: String = "flowing-lead-379317"
//val storageBucket: String = "flowing-lead-379317.appspot.com"
//val messagingSenderId: String = "156836767555"
//val appId: String = "1:156836767555:web:d6bf7cf3e00079dab931f6"
