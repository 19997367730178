package api.firebase

import api.commonUserPostFDRequest
import api.commonUserPostJsonRequest
import api.parseMyListResponse
import api.parseMyResponse
import bdElement.BaseClient
import bdElement.BaseTraining
import bdElement.CreatiumRequest
import bdElement.MyAnswerCommon
import global.*
import kotlinx.browser.window
import kotlinx.serialization.encodeToString
import org.w3c.fetch.Headers
import org.w3c.fetch.RequestInit
import serverAdress
import kotlin.js.Promise

fun setCompletedTrainingFirestore(
    training: BaseTraining,
    completed: Long,
    funcRez: () -> Unit
) {
    commonUserPostFDRequest(
        path = "set_completed_training",
        formData = { formData ->
            formData.append("trainingId", training.id ?: "")
            formData.append("completed", completed.toString())
        },
    ) { userStr ->
        val response = parseMyResponse<String>(userStr)
        response.status?.let { status ->
            if (status == "OK") funcRez()
        }
    }
}

fun getOpenTrainings(limit: Int = 10, skip: Int = 0) {
    currentUser.userProfile?.let { profile ->
        commonUserPostJsonRequest(
            path = "open_trainings",
            bodyStr = myJson.encodeToString(CreatiumRequest(limit = limit, skip = skip)),
            headers = { headers ->
                headers.append("clientId", profile.id ?: "")
            }) { userStr ->
            val response = parseMyResponse<List<BaseTraining>>(userStr)
            response.status?.let { status ->
                if (status == "OK") {
                    response.objectResponse?.let {
                        OpenTrainings.setTrainingLoaded(
                            MyAnswerCommon(
                                it,
                                response.totalCount
                            )
                        )
                    }
                }
            }
        }
    }
}

fun getCloseTrainings(limit: Int = 10, skip: Int = 0) {
    currentUser.userProfile?.let { profile ->
        commonUserPostJsonRequest(
            path = "close_trainings",
            bodyStr = myJson.encodeToString(CreatiumRequest(limit = limit, skip = skip)),
            headers = { headers ->
                headers.append("clientId", profile.id ?: "")
            }) { userStr ->
            val response = parseMyResponse<List<BaseTraining>>(userStr)
            response.status?.let { status ->
                if (status == "OK") {
                    response.objectResponse?.let {
                        OldTrainings.setTrainingLoaded(
                            MyAnswerCommon(
                                it,
                                response.totalCount
                            )
                        )
                    }
                }
            }
        }
    }
}

fun getTrainerProfileFromEmailOrId(email: String, idt: String, falseFun: (String) -> Unit, okFun: (BaseClient?) -> Unit) {
    val headers = Headers()
    if (email != "") headers.append("email", email)
    if (idt != "") headers.append("idTrainer", idt)
//            headers.append("Content-Type", "application/json")
    headers.append("Accept", "*/*")

    val options: RequestInit = RequestInit(
        method = "POST",
        headers = headers
    )

    Promise.resolve(window.fetch("$serverAdress/get_trainer_profile_from_email", options).then { response ->
        response.json()
    }.then {
        val userStr = JSON.stringify(it)
        val response = parseMyResponse<BaseClient>(userStr)
        response.checkStatusOK(falseFun) {
            console.log("TrainerProfile:")
            console.log(objectResponse)
            okFun(objectResponse)
        }
    })
}


fun getTrainersForThisClient(limit: Int = 10, skip: Int = 0) {
    currentUser.userProfile?.let { profile ->
        commonUserPostJsonRequest(
            path = "get_trainers_for_this_clients",
            bodyStr = myJson.encodeToString(CreatiumRequest(limit = limit, skip = skip)),
            headers = { headers ->
                headers.append("clientId", profile.id ?: "")
            }) { userStr ->
            parseMyListResponse<BaseClient>(userStr).checkStatusOK {
                it.getMyAnswerCommon()?.let { ListTrainers.setTrainersLoaded(it) }
            }
            if (statusLoading.loadedTrainersList.not()) statusLoading.finishLoadSpisTrainersFirebase()
        }
    } ?: run {
        if (statusLoading.loadedTrainersList.not()) statusLoading.finishLoadSpisTrainersFirebase()
    }
}
