package global

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import api.CustomMyListResponse
import api.firebase.getCommonListFromClient
import bdElement.BaseRequestComplex
import bdElement.MyAnswerCommon
import elements.*
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div

object ListRequestsComplexCloseForClient {
    var requestssLoaded by mutableStateOf<MyAnswerCommon<BaseRequestComplex>?>(null)
        private set

    var closeRequest by mutableStateOf<Int>(0)
        private set

    var slider by mutableStateOf<SliderPanel?>(null)

    private fun setRequestsComplexeLoaded(load: CustomMyListResponse<BaseRequestComplex>) {
        requestssLoaded = load.getMyAnswerCommon()
        closeRequest = requestssLoaded?.rows?.count() ?: 0
        load.totalCount?.let { pagination.totalCount = it }
    }

    val pagination = PageAdapter(12) { limit, skip, silent ->
        if (silent.not()) loadingProcess = true
        getCommonListFromClient("get_requests_complex_for_this_clients", listOf("archive" to "true"), limit, skip) {
            setRequestsComplexeLoaded(it)
            loadingProcess = false
        }
    }
    private var loadingProcess by mutableStateOf(true)

    private var firstStart: Boolean = true

    @Composable
    fun listenerForWritingCookies() {
//        LaunchedEffect(selectedClient?.selectedTraining) {
//            currentUser.userProfile?.id?.let { id_user ->
//                selectedClient?.selectedTraining?.id?.let { id_training ->
//                    setCookie("${id_user}_selectedClient_selTraining", id_training)
//                } ?: run {
//                    deleteCookie("${id_user}_selectedClient_selTraining")
//                }
//            }
//        }
    }

    private fun getFromCookie() {
//        if (firstStart){
//            currentUser.userProfile?.id?.let { id_user ->
//                getCookie("${id_user}_selectedClient_selTraining")?.let {id_training ->
//                    if (id_training.isNotEmpty()){
//                        complexesLoaded?.rows?.find{ it.id == id_training}?.let {
//                            selectedClient?.setSelectedTraining(it)
//                        }
//                    }
//                }
//            }
//            firstStart = false
//        }
    }


    @Composable
    fun getViewListRequestsComplex() {
        if (loadingProcess) {
            CenterDiv({ style { marginTop(40.px) } }) { mainLoader() }
        } else {
            requestssLoaded?.mutList?.let { //.filter { if (archive) it.archive == true else it.archive != true }?
                FlexColumnDivParent(rowGap = 0.px) {
                    it.sortedByDescending { it.date_create }
                        .mapIndexed { index, baseComplex -> Pair(index, baseComplex) }.forEach {
                            if (it.first != 0) Div({ classes(styleBlock.trainerRoom.delimiterCardClient) })
                            CardRequestComplex(it.second, forTrainer = false) {
//                                selectedComplex = it.second
                            }
                        }
                }
            }
        }
        pagination.paginationElement()
    }
}