package mainPage

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import api.commonUserPostFDRequest
import api.parseMyResponse
import bdElement.BunnyApiKey
import elements.*
import global.*
import global.QuickStyleProperty.borderBox
import global.QuickStyleProperty.fontSubheader
import global.QuickStyleProperty.myBoxShadow
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.accept
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.asList
import org.w3c.files.File

@Composable
fun pageMyVideo() {
    MyContainerInSection() {
        CenterDiv {
            P ({
                style {
                    width(100.percent)
                    borderBox()
                    textAlign("center")
                    fontSubheader()
                    marginBottom(styleConst.paddingCommon)
                }
            }) {
                Text("Мои видео-упражнения")
            }
        }
        CenterDiv({
            style {
                flex("auto")
                padding(0.pt, 10.pt)
            }
        }) {
            buttonIcon(listOf("fa", "fa-plus"), {
                classes(styleBlock.trainerRoom.buttNewTraining)
            }, fontSize = 25.pt) {
                openModalWindow() {
                    modalLoadMyVideo()
                }
            }
        }
        filterSelectedBlock(filterBlockMyVideo, {
            ListMyVideos.pagination.update()
        }, myVideo = true) {
            style {
                paddingBottom(styleConst.paddingCommon)
                marginTop(styleConst.paddingCommon)
            }
        }
        ListMyVideos.getViewListMyVideos()
    }
}

data class UploadFile(val file: File, val shiftTime: Int) {

    var finish: Boolean by mutableStateOf(false)
    var progress: Float by mutableStateOf(0f)
    var error: String? by mutableStateOf(null)
    val name: String = file.name
}

private var filesUpload by mutableStateOf<List<UploadFile>>(listOf())

@Composable
private fun modalLoadMyVideo() {
    FlexColumnDivParent({
        style {
            alignItems(AlignItems.Center)
        }
    }) {
        CenterDiv({
            style {
//                height(40.px)
                textAlign("center")
//                paddingTop(16.px)
                position(Position.Relative)
                rowGap(0.px)
            }
        }) {
            Input(type = InputType.File) {
//                classes("inputFile")
                attr("multiple", "")
                style {
                    display(DisplayStyle.None)
                    padding(8.px)
                }
                accept("video/*")
                id("filesVideo")
                onChange { e ->
                    e.target.files?.asList()?.mapIndexed { index, file -> UploadFile(file, index) }?.let {
                        filesUpload = filesUpload.toMutableList().apply { addAll(it) }
                        it.forEach { newFile ->
                            if (newFile.file.size.toLong() < 200 * 1024 * 1024) {
                                GlobalScope.launch {
                                    readVideoFile(newFile)
                                }
                            } else newFile.error =
                                "Файл слишком большой (${newFile.file.size.toLong() / 1024 / 1024} Мб). Максимальный размер файла 200 Мб."
                        }
                    }
//                        uploadImage(e.target, 600, 600)
                }
            }
            Label(forId = "filesVideo", attrs = {
                classes(styleBlock.trainerRoom.fmButton)
                style {
                    backgroundColor(stylePalette.myBlue)
//                        position(Position.Relative)
//                    marginBottom(5.px)
                    padding(10.pt, 25.pt)
//                        width(250.px)
                }
            }) {
                Text("Выбрать файлы")
            }
        }
        H4({
            classes(styleBlock.commonElement.headerBlock)
            style {
//                            marginBottom(10.pt)
                maxWidth(400.px)
                color(stylePalette.darkGray)
                fontSize(15.px)
            }
        }) {
            Text("Максимальный размер файла - 200 МБ. Видео обрабатывается на сервере после загрузки, поэтому сразу после загрузки может быть недоступно.")
        }
//            if (uploadImgStart) blankLoaderInOver()

        filesUpload.forEach {
            FlexRowDivParent({
                style {
                    borderRadius(10.px)
                    border(1.px, LineStyle.Solid, stylePalette.myOrange)
                    alignItems(AlignItems.Center)
                    padding(10.px)
                    myBoxShadow(CSSShadow(2.px, 2.px, 0.px, color = stylePalette.myBlue))
                    marginBottom(2.px)
                    marginRight(2.px)
                }
            }) {
                FlexColumnDivParent({
                    style {
                        flex("auto")
                    }
                }, rowGap = 5.px) {
                    H4({
                        classes(styleBlock.commonElement.headerBlock)
                        style {
//                            marginBottom(10.pt)
                            fontSize(15.px)
                        }
                    }) {
                        Text(it.name)
                    }
                    if (it.error != null) H4({
                        classes(styleBlock.commonElement.headerBlock)
                        style {
//                            marginBottom(10.pt)
                            color(stylePalette.myRed)
                            fontSize(15.px)
                        }
                    }) {
                        Text(it.error ?: "")
                    } else Div({
                        style {
                            width(100.percent)
                            borderBox()
                            height(10.px)
                            borderRadius(5.px)
                            border(1.px, LineStyle.Solid, stylePalette.myOrange)
                        }
                    }) {
                        Div({
                            style {
                                width(100.percent * it.progress)
                                borderBox()
                                height(100.percent)
                                borderRadius(5.px)
                                backgroundColor(stylePalette.myOrange)
                            }
                        })
                    }
                }
                CenterDiv({
                    style {
                        height(100.percent)
                        backgroundColor(if (it.error != null) stylePalette.myRed else if (it.progress < 1) stylePalette.myOrange else stylePalette.myGreen)
                        borderRadius(50.percent)
                        minHeight(34.px)
                        minWidth(34.px)
                    }
                }) {
                    if (it.error == null && it.finish.not()) miniLoader()
                    if (it.finish) I({
                        classes("fa-solid", "fa-check")
                        style {
                            padding(5.px)

                            color(Color.white)
                            fontSize(24.px)
                        }
                    })
                    it.error?.let {
                        I({
                            classes("fa-solid", "fa-xmark")
                            style {
                                padding(5.px)

                                color(Color.white)
                                fontSize(24.px)
                            }
                        })
                    }
                }
            }
        }
    }
}

private suspend fun readVideoFile(fileCard: UploadFile) {

//    val formData = FormData()

//    currentUser.getIdToken { token ->
//        formData.append("accessToken", token);
//        formData.append("fileName", fileCard.file.name);
//        formData.append("file", fileCard.file);
    delay(fileCard.shiftTime*500L+fileCard.shiftTime*10L)

    commonUserPostFDRequest(
        path = "new_video",
        formData = { formData ->
            formData.append("fileName", fileCard.file.name)
            formData.append("shiftTime", fileCard.shiftTime.toString())
        },
    ) { userStr ->
        val response = parseMyResponse<BunnyApiKey>(userStr)
        response.status?.let { status ->
            if (status == "OK") response.objectResponse?.let { api ->
                uploadTus(fileCard.file,
                    fileCard.name,
                    api.signature ?: "",
                    api.expire ?: 0L,
                    api.guid ?: "",
                    api.library ?: 0L,
                    onProgress = { progress ->
                        console.log("progress = ${progress}")
                        fileCard.progress = progress.toFloat()
                    }) {
                    fileCard.finish = true
                    ListMyVideos.pagination.update()
                }
            }
        }
    }

    /*
            axiosUploadWithProgress(
                url = "$serverAdress/new_video",
                formData = formData,
                progressFun = { progress ->
                    fileCard.progress = progress.toFloat()
                },
                thenFun = { code ->
    //                console.log("status code: $code")
                    if (code == 200) fileCard.finish = true
                }
            )
    */
//    }
}