package mainPage.modalWindows


import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import api.commonUserPostFDRequest
import api.firebase.updateUserProfile
import elements.*
import global.QuickStyleProperty.borderBox
import global.QuickStyleProperty.myFullSize
import global.closeModadWindow
import global.phoneDevice
import global.styleBlock
import global.stylePalette
import kotlinx.browser.window
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import style.First.StyleUserProfile

class ModalChangeDescription(currentName: String) : ModalContent() {
    private var disabledButtSet by mutableStateOf("temp")
    val richTextEdit = RichTextEdit(currentName)

    override val fullscreen: Boolean = phoneDevice
    override val closableBack = false

    @Composable
    override fun drawModalContent() {
        FlexColumnDivParent({
            style {
                flex("auto")
                flexShrink(1)
                flexGrow(1)
                myFullSize()
                backgroundColor(stylePalette.bodyModalColor)
                padding(10.px)
                if (fullscreen) height(window.innerHeight.px)   else    borderRadius(10.px)
            }
        }) {
            FlexColumnDivParent({ style { flex("auto") } }, rowGap = 0.px) {
                H3({
                    classes(styleBlock.commonElement.inputTextHeaders)
                    style {
                        marginBottom(2.pt)
                    }
                }) {
                    Text("О себе:")
                }
                richTextEdit.RichTextEdit()
            }
            FlexRowDivParent {
                if (fullscreen) Button({
                    classes(styleBlock.commonElement.buttonBack)
                    style {
                        flex("initial")
                    }
                    onClick {
                        closeModadWindow()
                    }
                }) {
                    Text("Назад")
                }
                Button({
                    classes(styleBlock.trainerRoom.fmButton)
                    style {
                        borderBox()
                        flex("auto")
                    }
                    attr(disabledButtSet, "")
                    onClick {
                        disabledButtSet = "disabled"
                        setNewDescription()
                    }
                }) {
                    Text("Изменить описание")
                }
            }
        }
/*
        Div {
            CenterDiv( {
                style {
//                    border(1.px, LineStyle.Solid, rgb(47, 47, 47))
                }
            }) {
                richTextEdit.RichTextEdit()
                Button({
                    style { width(100.percent) }
                    classes(StyleUserProfile.buttSetAvatar)
                    attr(disabledButtSet, "")
                    onClick {
//                        console.log(document.getElementById("editText")?.innerHTML)
                        disabledButtSet = "disabled"
//                        console.log("buttDisable")
                        setNewDescription()
                    }
                }) {
                    Text("Изменить описание")
                }
            }
        }
*/
    }

    fun setNewDescription() {
        richTextEdit.getText().let {description ->
            commonUserPostFDRequest("set_user_field",{ formData ->
                formData.append("fieldName","description")
                formData.append("newValue",description)
            }){
                updateUserProfile()
                closeModadWindow()
                disabledButtSet = "temp"
            }
        }
    }

}

