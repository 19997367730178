package elements

import androidx.compose.runtime.Composable
import bdElement.BaseAssignment
import bdElement.BaseTraining
import global.*
import global.QuickStyleProperty.marginHeightAuto
import mainPage.modalWindows.ModalDeleteAssignment
import mainPage.modalWindows.ModalEditAssignment
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H4
import org.jetbrains.compose.web.dom.I
import org.jetbrains.compose.web.dom.Text
import style.First.StyleTrainingRoom

class DragTraining (val training: BaseTraining): DragCommon<BaseTraining>(training) {
    override val directionAsk: Boolean = false

    override val view: @Composable (BaseTraining, @Composable () -> Unit) -> Unit = @Composable { tr, dragEl ->
        cardTrainingEditor(tr,true,dragEl)
    }

    override val fixOrderInElement: (DragCommon<BaseTraining>) -> Unit = { elem ->
        ComplexTrainings.trainingsLoaded?.mutList?.find { it.id == elem.dragBaseElement.id }?.let { findELem ->
            findELem.order = elem.numberNew
//            elem.dragBaseElement = findELem.copy(order = numberNew)
        }
    }

    override val fixOrdersInBaseAndView: () -> Unit = {
        val dates = ComplexTrainings.trainingsLoaded?.mutList
            ?.sortedByDescending { it.date_open }
            ?.mapIndexed { ind, item -> ind to item.date_open }?.toMap() ?: mapOf()
        ComplexTrainings.trainingsLoaded?.mutList //: List<Pair<String, Pair<Int, Long>>>
            ?.sortedByDescending { it.order }?.run {
                forEachIndexed { index, baseTraining ->
                    baseTraining.date_open = dates[index]
                }
//                ComplexTrainings.trainingsLoaded?.mutList?.sortedByDescending { it.order }?.
                map { DragTraining(it) }.let {
                    ComplexTrainings.listDragTraining = it
                }
            }
//        ComplexTrainings.listDragTraining.sortedByDescending { it.dragBaseElement.order }.forEachIndexed { index, tr ->
//            tr.dragBaseElement = tr.dragBaseElement.copy(date_open = dates[index])
//        }
        ComplexTrainings.fixOrderInBase()
    }
}
