package elements

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import api.firebase.deleteMyVideoFromFirestore
import bdElement.BaseAssignment
import bdElement.BaseVideo
import global.*
import global.QuickStyleProperty.marginWidthAuto
import global.QuickStyleProperty.overflowHidden
import global.QuickStyleProperty.zIndex
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.I
import org.jetbrains.compose.web.dom.Text
import style.First.StyleTrainingRoom
import style.common.StyleCustomProperties

@Composable
fun cardBaseVideo(baseVideo: BaseVideo, editTags: Boolean, deletable: Boolean = false, updateListFun: ()->Unit = {}) {
    var deleteProcess by mutableStateOf(false)

    Div({
        classes(
            styleBlock.trainerRoom.bvCard, StyleCustomProperties.flexBasis_3_8_10pt
        )
        style {
            zIndex(if (dropDownElementNow == baseVideo.id.toString()) 150 else 10)
        }
    }) {
        baseVideo.getPreview() {
            classes(styleBlock.trainerRoom.bvImage)
            style {
                borderRadius(10.pt)
                overflowHidden()
            }
        }

        FlexRowDivParent({
            classes(styleBlock.trainerRoom.bvButtonPanel)
        }) {
            if (currentPage == PageApplication.TrainerRoom && selectedTrainingForEdit != null) {
                CenterDiv({
                    classes(StyleTrainingRoom.chevronButton, styleBlock.trainerRoom.bvIconButton)
                    style {
                        flexBasis(30.percent)
                        fontSize(20.pt)
                        marginWidthAuto()
                        minWidth(30.pt)
                        minHeight(30.pt)
                        if (editListAssignment.listDragAssignment.map { it.assignment.video_id }
                                .contains(baseVideo.id)) color(
                            stylePalette.myGreen
                        )
                    }
                    onClick {
                        selectedTrainingForEdit?.let { baseTraining ->
                            val newAssignment = BaseAssignment(id = getUUID(),
                                order = baseTraining.assignments?.let {
                                    if (it.isNotEmpty()) it.maxBy { it.order ?: 0 }.order?.plus(
                                        1
                                    ) else null
                                } ?: 1,
                                video_id = baseVideo.id,
                                muscle_group_tags = baseVideo.muscle_group_tags_id?.map { tag_id -> tags_muscle_group.value?.rows?.find { it.id == tag_id }?.name ?: "" },
                                bunny_guid = baseVideo.bunny_guid,
                                bunny_library = baseVideo.bunny_library)
                            editListAssignment.addAssignment(newAssignment)
                        }
                    }
                }) {
                    I({
                        classes(
                            "fa", "fa-plus",
                            StyleTrainingRoom.chevron,
                        )
                    })
                }
            }
            if (editTags) CenterDiv({
                classes(StyleTrainingRoom.chevronButton, styleBlock.trainerRoom.bvIconButton)
                style {
                    flexBasis(30.percent)
                    fontSize(20.pt)
                    marginWidthAuto()
                    minWidth(30.pt)
                    minHeight(30.pt)
                }
                onClick {
                    openModalWindow(fullscreen = phoneDevice){
                        tagsBlock.getBlock(baseVideo, funUpdate = {
                            updateListFun()
                        }) {
                            visibleModalWindow.value = false
                            modalContent = null
                        }
                    }
                }
            }) {
                I({
                    classes(
                        "fa", "fa-gears",
                        StyleTrainingRoom.chevron,
                    )
                })
            }
            if (deletable) CenterDiv({
                style {
                    flex("initial")
                    flexBasis(30.percent)
                    fontSize(20.pt)
                    marginWidthAuto()
                }
            }) {
                DropDown({
                    classes(StyleTrainingRoom.chevronButton)
                }, {
                    Div({
                        style {
                            fontSize(20.pt)
                            minWidth(30.pt)
                            minHeight(30.pt)
                            display(DisplayStyle.Flex)
                            alignItems(AlignItems.Center)
                            justifyContent(JustifyContent.Center)
                        }
                    }) { //, minHeight
                        I({
                            classes(
                                "fa", "fa-bars",
                                StyleTrainingRoom.chevron,
                            )
                        })
                    }
                }, right = true, elementId = baseVideo.id.toString()) {
//                        buttonWithIcon("Редактировать", listOf("fa", "fa-gears", StyleTrainingRoom.chevron), {
//                            style {
//                                marginBottom(6.px)
//                            }
//                        }) {
//                            selectedClient?.selectedTraining?.let { training ->
//                                openModalWindow(ModalEditAssignment(assignment) { newName, newDescription ->
//                                    editListAssignment.changeAsignment(assignment, newName, newDescription)
//                                })
//                            }
//                        }
                    buttonWithIcon("Удалить", listOf("fa-solid", "fa-trash-can", StyleTrainingRoom.chevron), {
                        classes(StyleTrainingRoom.redButton)
                    }) {
                        openModalWindow() {
                            if (deleteProcess) {
                                CenterDiv({ style { marginTop(40.px) } }) { mainLoader() }
                            } else Div {
                                H2 {
                                    Text("Вы действительно хотите удалить это видео-упражнение?")
                                }
                                buttonWithIcon("Удалить",
                                    listOf("fa-solid", "fa-trash-can", StyleTrainingRoom.chevron),
                                    {
                                        style {
                                            marginTop(10.px)
                                            backgroundColor(rgb(255, 210, 210))
                                        }
                                    }) {
                                    deleteProcess = true
                                    deleteMyVideoFromFirestore(baseVideo) {
                                        updateListFun()
                                        closeModadWindow()
                                        deleteProcess = false
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}