package api.firebase

import api.*
import bdElement.BaseClient
import bdElement.CreatiumRequest
import bdElement.MyAnswerCommon
import extension.AnySerializer
import global.ListClients
import global.currentUser
import global.myJson
import kotlinx.serialization.*
import kotlinx.serialization.builtins.MapSerializer
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.descriptors.*
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.*

@Serializable
class CommonBaseElement(
    var type: String? = null,
    var obj: String? = null
)

class BaseElement<T : Any>(
    var type: String,
    var obj: T,
    var objStr: String
) {
    companion object {
        inline fun <reified T : Any> createBaseElement(type: String, obj: T) =
            BaseElement(type, obj, myJson.encodeToString(obj))
    }

    fun getCommonBaseElement() = CommonBaseElement(type, objStr)
}

@Serializable
data class CommonChangesBaseElement(
    val type: String,
    val id: String,

    val changes: Map<String, @Serializable(with = AnySerializer::class) Any?>
)

@Serializer(forClass = Map::class)
object MapAnySerializer : KSerializer<Map<String, Any?>> {
    override val descriptor: SerialDescriptor = MapSerializer(String.serializer(), AnySerializer).descriptor

    override fun serialize(encoder: Encoder, value: Map<String, Any?>) {
        val json = JsonObject(value.entries.associate { (key, value) ->
            key to Json.encodeToJsonElement(AnySerializer, value as Any)
        })
        encoder.encodeSerializableValue(JsonObject.serializer(), json)
    }

    override fun deserialize(decoder: Decoder): Map<String, Any?> {
        val json = decoder.decodeSerializableValue(JsonObject.serializer())
        return json.entries.associate { (key, value) ->
            key to when (value) {
                is JsonPrimitive -> value.content
                is JsonObject -> Json.decodeFromJsonElement(AnySerializer, value)
                else -> throw SerializationException("Unsupported JSON element: $value")
            }
        }
    }
}

//@Serializable
//typealias MapAny = Map<String, @Serializable(with = AnySerializer::class) Any?>

enum class TypeElement(
    val label: String,
) {
    Client("BaseClient"),
    Training("BaseTraining"),
    Complex("BaseComplex"),
    RequestComplex("BaseRequestComplex");
}

inline fun <reified T : Any> addNewCommonElementFirestoreFromTrainer(
    element: BaseElement<T>,
    crossinline funcRez: (T) -> Unit
) {
    requestFromTrainer {
        commonUserPostFDRequest(
            path = "add_new_object",
            formData = { formData ->
                formData.append("commonElement", myJson.encodeToString(element.getCommonBaseElement()))
            },
        ) { userStr ->
            val response = parseMyResponse<T>(userStr)
            response.checkStatusOK(falseFun = { error ->
                console.log(error)
            }) {
                objectResponse?.let { newElement ->
                    funcRez(newElement)
                }
            }
        }
    }
}

inline fun <reified T : Any> addNewCommonElementFirestoreFromClient(
    element: BaseElement<T>,
    crossinline funcRez: (T) -> Unit
) {
    commonUserPostFDRequest(
        path = "add_new_object",
        formData = { formData ->
            formData.append("commonElement", myJson.encodeToString(element.getCommonBaseElement()))
        },
    ) { userStr ->
        val response = parseMyResponse<T>(userStr)
        response.checkStatusOK(falseFun = { error ->
            console.log(error)
        }) {
            objectResponse?.let { newElement ->
                funcRez(newElement)
            }
        }
    }
}

inline fun deleteCommonElementFirestoreFromTrainer(
    element_type: TypeElement,
    element_id: String,
    crossinline funcRez: (String) -> Unit
) {
    requestFromTrainer {
        commonUserPostFDRequest(
            path = "delete_object",
            formData = { formData ->
                formData.append("element_type", element_type.label)
                formData.append("element_id", element_id)
            },
        ) { userStr ->
            val response = parseMyResponse<String>(userStr)
            response.checkStatusOK(falseFun = { error ->
                console.log(error)
            }) {
                objectResponse?.let { answer ->
                    funcRez(answer)
                }
            }
        }
    }
}

inline fun changeCommonElementFirestoreFromTrainer(
    element: CommonChangesBaseElement,
    crossinline funcRez: (String) -> Unit
) {
    requestFromTrainer {
        commonUserPostFDRequest(
            path = "change_object",
            formData = { formData ->
                formData.append("commonChangesElement", myJson.encodeToString(element))
            },
        ) { userStr ->
            val response = parseMyResponse<String>(userStr)
            response.checkStatusOK(falseFun = { error ->
                console.log(error)
            }) {
                objectResponse?.let { timeUpdate ->
                    funcRez(timeUpdate)
                }
            }
        }
    }
}

inline fun changeCommonElementFirestoreFromClient(
    element: CommonChangesBaseElement,
    crossinline funcRez: (String) -> Unit
) {
    commonUserPostFDRequest(
        path = "change_object",
        formData = { formData ->
            formData.append("commonChangesElement", myJson.encodeToString(element))
        },
    ) { userStr ->
        val response = parseMyResponse<String>(userStr)
        response.checkStatusOK(falseFun = { error ->
            console.log(error)
        }) {
            objectResponse?.let { timeUpdate ->
                funcRez(timeUpdate)
            }
        }
    }
}

inline fun <reified T : Any> addNewElementFirestoreFromTrainer(
    path: String,
    nameParam: String,
    element: T,
    crossinline funcRez: (T) -> Unit
) {
    requestFromTrainer {
        commonUserPostFDRequest(
            path = path,
            formData = { formData ->
                formData.append(nameParam, myJson.encodeToString(element))
            },
        ) { userStr ->
            val response = parseMyResponse<T>(userStr)
            response.checkStatusOK(falseFun = { error ->
                console.log(error)
            }) {
                objectResponse?.let { newElement ->
                    funcRez(newElement)
                }
            }
        }
    }
}

inline fun <reified T : Any> changeNewElementFirestoreFromTrainer(
    path: String,
    nameParam: String,
    element: T,
    crossinline funcRez: (String) -> Unit
) {
    requestFromTrainer {
        commonUserPostFDRequest(
            path = path,
            formData = { formData ->
                formData.append(nameParam, myJson.encodeToString(element))
            },
        ) { userStr ->
            val response = parseMyResponse<String>(userStr)
            response.checkStatusOK(falseFun = { error ->
                console.log(error)
            }) {
                objectResponse?.let { newElement ->
                    funcRez(newElement)
                }
            }
        }
    }
}

inline fun <reified T : Any> getCommonListFromTrainer(
    path: String,
    headers: List<Pair<String, String>>,
    limit: Int = 10,
    skip: Int = 0,
    crossinline funcRez: (CustomMyListResponse<T>) -> Unit
) {
    requestFromTrainer { profile ->
        commonUserPostJsonRequest(
            path = path,
            bodyStr = myJson.encodeToString(CreatiumRequest(limit = limit, skip = skip)),
            headers = { headersIn ->
                headersIn.append("trainerId", profile.id ?: "")
                headers.forEach {
                    headersIn.append(it.first, it.second)
                }
            }) { userStr ->
            parseMyListResponse<T>(userStr).checkStatusOK {
                funcRez(it)
            }
        }
    }
}

inline fun <reified T : Any> getCommonListFromClient(
    path: String,
    headers: List<Pair<String, String>>,
    limit: Int = 10,
    skip: Int = 0,
    crossinline funcRez: (CustomMyListResponse<T>) -> Unit
) {
    commonUserPostJsonRequest(
        path = path,
        bodyStr = myJson.encodeToString(CreatiumRequest(limit = limit, skip = skip)),
        headers = { headersIn ->
            headersIn.append("clientId", currentUser.userProfile?.id ?: "")
            headers.forEach {
                headersIn.append(it.first, it.second)
            }
        }) { userStr ->
        parseMyListResponse<T>(userStr).checkStatusOK {
            funcRez(it)
        }
    }
}

inline fun commonRequestFromTrainer(
    path: String,
    headers: List<Pair<String, String>>,
    crossinline funcRez: (String?) -> Unit
) {
    requestFromTrainer { profile ->
        commonUserPostFDRequest(
            path = path,
//            bodyStr = myJson.encodeToString(CreatiumRequest(limit = limit, skip = skip)),
            headers = { headersIn ->
                headersIn.append("trainerId", profile.id ?: "")
                headers.forEach {
                    headersIn.append(it.first, it.second)
                }
            },
            formData = {}) { userStr ->
            parseMyResponse<String>(userStr).checkStatusOK {
                funcRez(objectResponse)
            }
        }
    }
}
