package elements.miniElement

import androidx.compose.runtime.Composable
import bdElement.BaseComplex
import global.QuickStyleProperty.borderBox
import global.QuickStyleProperty.fontSubheader
import global.styleConst
import global.stylePalette
import global.tempComplexCoverUrl
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun complexHeader(complex: BaseComplex){
    Div({
        style {
            marginBottom(styleConst.paddingCommon)
            display(DisplayStyle.Flex)
            columnGap(styleConst.paddingCommon)
        }
    }) {
        Div({
            style {
                borderBox()
                flex("auto")
                width(100.percent)
            }
        }) {

            P({
//                    classes(styleBlock.commonElement.headerBlock)
                style {
                    fontSubheader()
                    borderBox()
                    width(100.percent)
                    textAlign("left")
                }
            }) {
                Text("Комплекс:")
            }
            P({
//                    classes(styleBlock.commonElement.headerBlock)
                style {
                    fontSubheader(stylePalette.fontHeaderColor)
                    borderBox()
                    width(100.percent)
                    textAlign("left")
                }
            }) {
                Text("${complex.name}")
            }
        }
//        CutBorderBox(15, 2, attrs = {
        Div({
            style {
                flex("initial")
//                borderRadius(10.px)//styleConst.borderRadiusCommon)
//                overflowHidden()
                flexShrink(0)
                width(45.px)
                height(60.px)

            }
        }) {
            Img(src = complex.cover ?: tempComplexCoverUrl) {
//                classes(styleBlock.trainerRoom.ccCoverComplex)
                style {
                    property("aspect-ratio", "3/4")
                    property("object-fit", "cover")
                    border(1.px, LineStyle.Solid, rgba(180, 180, 180, 0.3))
                    borderRadius(10.px)//styleConst.borderRadiusCommon)
//                    width(60.px)
                    height(60.px)
                }
            }
        }
    }
}