package global

enum class CodesSSE(val code: String) {
    nothing("nothing"),
    clientTrainingsUpdateList("clientTrainingsUpdateList"),
    trainingsUpdateList("trainingsUpdateList"),
    clientUpdateList("clientUpdateList"),
    trainersUpdateList("trainersUpdateList");

    companion object{
        fun getCode(code: String): CodesSSE = CodesSSE.values().find{ it.code == code} ?: nothing
    }
}