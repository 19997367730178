package elements

import androidx.compose.runtime.Composable
import global.styleConst
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.I
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import style.First.StyleTrainingRoom

@Composable
fun buttonWithIcon(
    name: String,
    iconClass: List<String>,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    onclick: () -> Unit
) {
    FlexRowDivParent({
        classes(StyleTrainingRoom.chevronButton)
        style {
            padding(6.px)
            fontSize(20.pt)
//                                    property("white-space", "nowrap")
        }
        attrs?.invoke(this)
        onClick { onclick() }
    }) {
        CenterDiv({
            style {
                width(25.px)
                height(25.px)
            }
        }) {
            I({
                classes(iconClass)
            })
        }
        H2({
            style {
                fontFamily(styleConst.mainFontFamily)
                fontSize(16.pt)
            }
        }) {
            Text(name)
        }
    }

}