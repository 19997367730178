package style.First

import style.AppStylesheet
import global.CSSShadow
import global.QuickStyleProperty.marginWidthAuto
import global.QuickStyleProperty.myBoxShadow
import global.stylePalette
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*
import style.STitlePage

object StyleTitlePage : STitlePage, StyleSheet(AppStylesheet) {

    override val buttonComplex by style {
        marginWidthAuto()
        marginTop(40.px)
//        marginBottom(40.px)
        color(Color.white)
        borderRadius(15.px)
        fontSize(15.px)
        padding(10.px, 20.px)
        fontFamily("TT Norms Pro")
        background("linear-gradient(rgb(30, 30, 30), rgb(30, 30, 30)) padding-box,linear-gradient(to right, rgb(57, 171, 74), rgb(11,117,177)) border-box")
        border(2.px, LineStyle.Solid, Color.transparent)//, "!important")
/*
        self + after style {
//            content: '';
//            position (Position.Absolute);
            top (0.px)
            left (0.px)
            right (0.px)
            bottom (0.px)
            background ("linear-gradient(45deg, #ffd800, #ff5520, #750cf2, #0cbcf2)")
//            zIndex (-2)
//            margin(-50.px)
//            property("backdrop-filter","blur(40px)")
//            filter {
//                blur(40.px)
//            }
        }
*/
    }

    override val buttonComplexGirl by style {
        marginWidthAuto()
        marginTop(40.px)
//        marginBottom(40.px)
        color(Color.white)
        borderRadius(15.px)
        fontSize(15.px)
        padding(10.px, 20.px)
        fontFamily("TT Norms Pro")
        background("linear-gradient(rgb(30, 30, 30), rgb(30, 30, 30)) padding-box,linear-gradient(to right, rgb(178, 22, 28), rgb(241, 164, 23)) border-box")
        border(2.px, LineStyle.Solid, Color.transparent)//, "!important")
    }

    @OptIn(ExperimentalComposeWebApi::class)
    override val trainingButt by style {
        margin(180.px)
        width(70.px)
        height(70.px)
//                    maxWidth(350.px)
        marginWidthAuto()
        borderRadius(50.percent)
        backgroundColor(rgb(50, 50, 50))
        border(1.px, LineStyle.Solid, rgba(90, 90, 90, 0.4))
        myBoxShadow(
            CSSShadow(-15.px, -15.px, 20.px, color = rgba(242, 110, 66, 0.2)),
            CSSShadow(20.px, 20.px, 35.px, color = rgba(0, 0, 0, 0.3)),
            CSSShadow(-5.px, -5.px, 15.px, color = rgba(242, 110, 66, 0.3)),
            CSSShadow(15.px, 15.px, 20.px, color = rgba(0, 0, 0, 0.4))
        )
        hover(self) style {
            backgroundColor(rgb(30, 30, 30))
            myBoxShadow(
                CSSShadow(-15.px, -15.px, 40.px, color = rgba(242, 110, 66, 0.2)),
                CSSShadow(20.px, 20.px, 55.px, color = rgba(0, 0, 0, 0.3)),
                CSSShadow(-5.px, -5.px, 25.px, color = rgba(242, 110, 66, 0.3)),
                CSSShadow(15.px, 15.px, 40.px, color = rgba(0, 0, 0, 0.4))
            )
            transform {
//                translate(-10.px,-10.px)
            }
        }
        self + active style {
            transform {
//                translate(10.px,10.px)
            }
        }
    }
    override val mainHeaders by style {
        color(stylePalette.white)
    }
    override val textInHeaders by style {
        fontWeight(400)
        color(stylePalette.white)
        fontFamily("TT Norms Pro")
    }
    override val mainText by style {
        color(stylePalette.lightGray)
    }
    override val stapsHeaders by style {
    }
    override val checkHeader_1 by style {
        color(rgb(57, 171, 74))
    }
    override val checkHeader_2 by style {
        color(rgb(241, 164, 23))
    }
    override val checkHeader_3 by style {
        color(rgb(178, 22, 28))
    }
    override val trainingHeader by style {
        color(stylePalette.lightGray)
    }
}