package elements

import androidx.compose.runtime.*
import global.QuickStyleProperty.borderBox
import global.QuickStyleProperty.myTestBorder
import global.QuickStyleProperty.zIndex
import global.selectableNone
import global.styleConst
import global.stylePalette
import kotlinx.browser.document
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.I
import org.jetbrains.compose.web.dom.Text
import style.First.StyleTrainingRoom

class SliderButton(val text: String, val color: CSSColorValue, private val widthSlider: CSSNumeric = 100.percent) {

    val widthButtonPx = 60
    val heightButtonPx = 40

    var finish by mutableStateOf(false)
    private var clickNotYet by mutableStateOf(true)
    private var dragInit by mutableStateOf(false)
    private var moveNowX by mutableStateOf(false)
    private var moveNowY by mutableStateOf(false)
    private var translateAnimX by mutableStateOf(0.0)
    private var translateIndex by mutableStateOf(0.0)

    private var Xi = 0
    private var Yi = 0

    private var Xt = 0
    private var Yt = 0

    private var Xf = 0
    private var Yf = 0

    fun dragInitFun(event: MyToucheEvent) {
//        event.preventDefault()
        dragInit = true
        selectableNone = true
        Xi = event.clientX
        Yi = event.clientY
//        translateAnimX = paddingStart
    }

    fun drogEnd() {
        dragInit = false

        if (moveNowX) {
            val slidePathPx: Int = (document.getElementById("slider_list")?.clientWidth ?: 150) - widthButtonPx
            val delta: Double = (slidePathPx - translateAnimX) / slidePathPx
            if (delta < 0.05) {
                /** Finish */
                translateAnimX = slidePathPx.toDouble()
                translateIndex = 1.0
                finish = true

            } else {
                translateAnimX = 0.0
                translateIndex = 0.0
            }

        }

        moveNowX = false
        moveNowY = false
        Xi = 0
        Yi = 0
    }


    fun dragMove(event: MyToucheEvent) {

        Xf = event.clientX
        Yf = event.clientY

        Xt = Xf - Xi
        Yt = Yf - Yi

        if ((Xt > 10 || Xt < -10) && moveNowY.not() && moveNowX.not()) moveNowX = true
        if ((Yt > 10 || Yt < -10) && moveNowY.not() && moveNowX.not()) moveNowY = true

        if (moveNowX && finish.not()) {
            event.preventDefault()
            val slidePathPx: Int = (document.getElementById("slider_list")?.clientWidth ?: 150) - widthButtonPx
            translateAnimX = if (Xt > slidePathPx) slidePathPx.toDouble()
            else if (Xt < 0) 0.0
            else Xt.toDouble()
            translateIndex = 1.0 - (slidePathPx - translateAnimX) / slidePathPx.toDouble()
        }
    }

    @OptIn(ExperimentalComposeWebApi::class)
    @Composable
    fun sliderButton(onClick: ()->Unit) {
        LaunchedEffect(finish){
            if (finish && clickNotYet){
                clickNotYet = false
                onClick()
            }
        }
        Div({
            id("slider_list")
            style {
//                borderBox()
                width(widthSlider)
                height(heightButtonPx.px)
                position(Position.Relative)
            }
        }) {
            Div({
                style {
                    borderBox()
                    width(100.percent)
                    height(100.percent)
                    borderRadius(10.px)
                    myTestBorder(Color.gray)
                    backgroundColor(color)
                    opacity(0.2 + 0.8 * translateIndex)
                }
            }) { }
            CenterDiv({style {
                position(Position.Absolute)
                width(100.percent)
                height(100.percent)
                borderBox()
                top(0.px)
                left(0.px)
                color(Color.white)
                fontFamily(styleConst.mainFontFamily)
                fontSize(16.pt)
                zIndex(10)
                paddingLeft(widthButtonPx.px)
            }}) {
                Text(text)
            }
            CenterDiv({
                id("slider_track")
                style {
//                    myTestBorder(Color.green)
//                    height(175.px)
                    zIndex(20)
                    borderBox()
                    width(widthButtonPx.px)
                    height(100.percent)
//                    overflow("hidden")
                    position(Position.Absolute)
                    top(0.px)
                    left(0.px)
                    borderRadius(10.px)
                    color(Color.white)
                    fontWeight(800)
                    fontSize(16.pt)
                    backgroundColor(stylePalette.myBlue)
//                    zIndex(if (dragInit || dropDownElementNow == assignment.id.toString()) 150 else 10)
                    transform {
                        translate(translateAnimX.px, 0.px)
                    }
                    transitions {
                        all {
                            duration(if (dragInit) 0.001.s else 0.2.s)
                            timingFunction(AnimationTimingFunction.EaseInOut)
                        }
                    }
                }
                onMouseDown {
                    dragInitFun(MyToucheEvent(it))
                    document.onmousemove = { event -> dragMove(MyToucheEvent(event)) }
                    document.onmouseup = {
                        selectableNone = false
                        document.onmousemove = null
                        document.onmouseup = null
                        drogEnd()
                        null
                    }
                }
                onTouchStart {
                    dragInitFun(MyToucheEvent(it))
                }
                if (dragInit) onTouchMove { event ->
                    dragMove(MyToucheEvent(event))
                }
                onTouchEnd {
                    selectableNone = false
                    drogEnd()
                }
            }) {
                I({
                    classes(
                        "fa-solid", "fa-arrow-right",
                        StyleTrainingRoom.chevron,
                    )
                })
            }
        }
    }
}