package mainPage.modalWindows

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import elements.CenterDiv
import elements.ModalContent
import elements.SliderButton
import global.closeModadWindow
import global.styleBlock
import global.stylePalette
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.Text

open class ModalSliderConfirm(
    val textMessage: String,
    val textButton: String,
    val colorSlider: CSSColorValue,
    private val widthSlider: CSSNumeric = 300.px,
    val funcConfirm: () -> Unit
) : ModalContent() {
    var inputNameState by mutableStateOf("")
    var disableButton by mutableStateOf(true)

    @Composable
    override fun drawModalContent() {
        Div({ style { maxWidth(350.px) } }) {
            H2 {
                Text(textMessage)
            }
            CenterDiv({
                style {
                    paddingTop(10.pt)
                }
            }) {
                SliderButton(textButton, colorSlider, widthSlider).sliderButton {
                    funcConfirm()
                    closeModadWindow()
                }
            }
            CenterDiv({
                style {
                    paddingTop(10.pt)
                }
            }) {
                Div(attrs = {
                    classes(styleBlock.commonElement.buttonBack)
                    onClick {
                        closeModadWindow()
                    }
                }) {
                    Text("Отмена")
                }
            }
        }
    }
}

class ModalDeleteObject(val nameDeleteObj: String, val funcDelete: () -> Unit)
    : ModalSliderConfirm("Вы действительно хотите удалить ${nameDeleteObj}?", "Удалить", stylePalette.myRed,300.px,funcDelete)

class ModalCancelObject(textMessage: String, textButton: String, funcDecline: () -> Unit)
    : ModalSliderConfirm(textMessage, "Отменить", stylePalette.myRed,300.px,funcDecline)

class ModalConfirmObject(textMessage: String, textButton: String, funcConfirm: () -> Unit)
    : ModalSliderConfirm(textMessage, textButton, stylePalette.myGreen,300.px,funcConfirm)

