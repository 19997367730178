package bdElement

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import kotlinx.serialization.Serializable

@Serializable
data class CreatiumColumn(
    val id: String? = "",
    val name: String? = "",
    val type: String? = ""
)

@Serializable
data class MyAnswerCommon<T>(
    val rows: List<T>? = null,
    val totalCount: Int? = null
) {
    var mutList by mutableStateOf(rows)

    fun <R : Comparable<R>> newElem(newElem: T, desc: Boolean = true, sortBy: (T)->R?){
        val tempList = mutList?.toMutableList()?.apply {
            add(newElem)
            if (desc) sortByDescending(sortBy)
            else sortBy(sortBy)
        }
        tempList?.indexOf(newElem)?.let {
            if (it<tempList.count()){
                mutList = tempList.subList(0,tempList.count())
            }
        }
    }

    fun <R : Comparable<R>> updateElem(updateElem: T, sortBy: (T)->R?, desc: Boolean = true, predicate: (T)->Boolean){
        mutList = mutList?.toMutableList()?.apply {
            indexOfFirst(predicate).let {
                if (it > -1) set(it,updateElem)
            }
            if (desc) sortByDescending(sortBy)
            else sortBy(sortBy)
        }
    }

    fun deleteElem(deleteElem: T){
        mutList = mutList?.toMutableList()?.apply {
            remove(deleteElem)
        }
    }
}

