package api.firebase

import api.commonUserPostFDRequest
import api.parseMyResponse
import bdElement.BaseAssignment
import bdElement.BaseClient
import bdElement.BaseTraining
import global.*
import global.getCurrentDateUTC
import kotlinx.serialization.encodeToString

fun setStatusClientFirestore(
    baseClient: BaseClient,
    status: String,
    funcRez: () -> Unit
) {
    commonUserPostFDRequest(
        path = "set_status_client",
        formData = { formData ->
            formData.append("zayavka_id", baseClient.zayavka_id ?: "")
            formData.append("status", status)
            formData.append("date_update", getCurrentDateTimeUTC().toString())
        },
    ) { userStr ->
        val response = parseMyResponse<String>(userStr)
        response.status?.let { status ->
            if (status == "OK") funcRez()
        }
    }
}



fun addNewTrainingFirestore(
    training: BaseTraining,
    funcRez: (BaseTraining) -> Unit
) {
    addNewElementFirestoreFromTrainer("add_new_training","training",training,funcRez)
/*
    requestFromTrainer {
        commonUserPostFDRequest(
            path = "add_new_training",
            formData = { formData ->
                formData.append("training", myJson.encodeToString(training))
            },
        ) { userStr ->
            val response = parseMyResponse<BaseTraining>(userStr)
            response.objectResponse?.let { newTraining ->
                funcRez(newTraining)
//                newTraining.client_id?.let {  getClientTrainings(it) }
            }
        }
    }
*/
}

fun changeTrainingFirestore(
    training: BaseTraining,
    funcRez: (String) -> Unit
) {
    changeNewElementFirestoreFromTrainer("change_training","training",training,funcRez)
/*
    requestFromTrainer {
        commonUserPostFDRequest(
            path = "change_training",
            formData = { formData ->
                formData.append("training", myJson.encodeToString(training))
            },
        ) { userStr ->
            val response = parseMyResponse<String>(userStr)
            if (response.status == "OK") {
                console.log("updateTime: ${response.objectResponse}")
//                selectedClient?.id?.let {  getClientTrainings(it) }
                funcRez()
            } else {
                console.log("status: ${response.status}")
            }
        }
    }
*/
}

fun deleteTrainingFirestore(
    training: BaseTraining,
    funcRez: () -> Unit
) {
    requestFromTrainer {
        commonUserPostFDRequest(
            path = "delete_training",
            formData = { formData ->
                formData.append("trainingId", training.id ?: "")
            },
        ) { userStr ->
            val response = parseMyResponse<String>(userStr)
            response.status?.let { status ->
                if (status == "OK") {
                    funcRez()
                    selectedClient?.id?.let {
                        ClientTrainings.pagination.update()
//                        getClientTrainings(it)
                    }
                }
            }
        }
    }
}

fun pasteTrainingsFirestore(
    trainings: List<BaseTraining>,
    funcRez: (List<BaseTraining>) -> Unit
) {
    requestFromTrainer {
        commonUserPostFDRequest(
            path = "paste_trainings",
            formData = { formData ->
                formData.append("trainings",  myJson.encodeToString(trainings))
            },
        ) { userStr ->
            val response = parseMyResponse<List<BaseTraining>>(userStr)
            response.status?.let { status ->
                if (status == "OK") {
                    response.objectResponse?.let{funcRez(it) }
                }
            }
        }
    }
}

fun getAssignmentsForTrainingsFirestore(
    training: BaseTraining,
    funcRez: (List<BaseAssignment>) -> Unit
) {
    requestFromTrainer {
        commonUserPostFDRequest(
            path = "get_assignments_training",
            formData = { formData ->
                formData.append("trainingId",  training.id ?: "")
            },
        ) { userStr ->
            val response = parseMyResponse<List<BaseAssignment>>(userStr)
            response.status?.let { status ->
                if (status == "OK") funcRez(response.objectResponse ?: listOf())
            }
        }
    }
}

fun setAssignmentsForTrainingsFirestore(
    training: BaseTraining,
    assignments: List<BaseAssignment>,
    funcRez: () -> Unit
) {
    requestFromTrainer {
        commonUserPostFDRequest(
            path = "set_assignments_training",
            formData = { formData ->
                formData.append("trainingId",  training.id ?: "")
                formData.append("assignments",  myJson.encodeToString(assignments))
            },
        ) { userStr ->
            val response = parseMyResponse<String>(userStr)
            response.status?.let { status ->
                if (status == "OK") funcRez()
            }
        }
    }
}

fun setActivateTrainingFirestore(
    training: BaseTraining,
    activate: Boolean,
    funcRez: () -> Unit
) {
    requestFromTrainer {
        commonUserPostFDRequest(
            path = "set_activate_training",
            formData = { formData ->
                formData.append("trainingId", training.id ?: "")
                formData.append("activate", activate.toString())
            },
        ) { userStr ->
            val response = parseMyResponse<String>(userStr)
            response.status?.let { status ->
                if (status == "OK") funcRez()
            }
        }
    }
}

fun setStatusTrainingFirestore(
    training: BaseTraining,
    status: String,
    funcRez: () -> Unit
) {
    requestFromTrainer {
        commonUserPostFDRequest(
            path = "set_status_training",
            formData = { formData ->
                formData.append("trainingId", training.id ?: "")
                formData.append("status", status)
            },
        ) { userStr ->
            val response = parseMyResponse<String>(userStr)
            response.status?.let { status ->
                if (status == "OK") funcRez()
            }
        }
    }
}
