package mainPage

import androidx.compose.runtime.Composable
import bdElement.BaseTraining
import elements.CenterDiv
import elements.CutBorderBox
import elements.FlexRowDivParent
import elements.blockAddAssignment
import elements.miniElement.clientHeader
import elements.miniElement.complexHeader
import global.*
import global.QuickStyleProperty.borderBox
import global.QuickStyleProperty.fontSubheader
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*


@Composable
fun pageOpenEditTraining(training: BaseTraining) {
    if (phoneDevice.not()) {
        selectedClient?.let { client ->
            clientHeader(client)
        } ?: selectedComplex?.let { complex ->
            complexHeader(complex)
        }
        FlexRowDivParent ({
            style {
                borderBox()
                width(100.percent)
                columnGap(styleConst.paddingCommon)
                marginBottom(styleConst.paddingCommon)
            }
        }) {
            P({
                style {
                    fontSubheader()
                    borderBox()
                    textAlign("left")
                }
            }) {
                Text("Тренировка:")
            }
            P({
                style {
                    fontSubheader(stylePalette.fontHeaderColor)
                    borderBox()
                    textAlign("left")
                }
            }) {
                Text(training.name ?: "")
            }
        }
    }
    training.assignments?.let {
        editListAssignment.getListAssignment()
        blockAddAssignment()
    }
}


