package style.White

import global.CSSShadow
import global.QuickStyleProperty.marginWidthAuto
import global.QuickStyleProperty.myBorderGradient
import global.QuickStyleProperty.myBoxShadow
import global.styleConst
import global.stylePalette
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*
import style.AppStylesheet
import style.STitlePage

object StylePageTitleWhite : STitlePage, StyleSheet(AppStylesheet) {

    override val buttonComplex by style {
        color(Color.black)
        fontFamily(styleConst.mainFontFamily)
        myBorderGradient(2.px, Color.white,rgb(57, 171, 74), rgb(11,117,177))
        hover(self) style {
            myBorderGradient(2.px, Color.lightgray,rgb(57, 171, 74), rgb(11,117,177))
        }
    }

    override val buttonComplexGirl by style {
        color(Color.black)
        fontFamily(styleConst.mainFontFamily)
        myBorderGradient(2.px, Color.white,rgb(178, 22, 28), rgb(241, 164, 23))
        hover(self) style {
            myBorderGradient(2.px, Color.lightgray,rgb(178, 22, 28), rgb(241, 164, 23))
        }
    }

    override val mainHeaders by style {
        color(stylePalette.headersTextColor)
    }
    override val textInHeaders by style {
        fontWeight(400)
        color(stylePalette.textColor_1)
    }
    override val mainText by style {
        color(stylePalette.gray)
    }
    override val stapsHeaders by style {
        fontFamily(styleConst.mainFontFamily)
        fontWeight(600)
        color(stylePalette.myRed)
    }
    override val checkHeader_1 by style {
        color(stylePalette.myGreen)
    }
    override val checkHeader_2 by style {
        color(stylePalette.myOrange)
    }
    override val checkHeader_3 by style {
        color(stylePalette.myRed)
    }

    override val trainingHeader by style {
        color(Color.black)
    }

    @OptIn(ExperimentalComposeWebApi::class)
    override val trainingButt by style {
        margin(30.px)
        width(70.px)
        height(70.px)
//                    maxWidth(350.px)
        marginWidthAuto()
        borderRadius(50.percent)

        myBorderGradient(2.px, Color.white,rgb(178, 22, 28), rgb(241, 164, 23))
        hover(self) style {
            myBorderGradient(2.px, Color.lightgray,rgb(178, 22, 28), rgb(241, 164, 23))
        }

//        backgroundColor(Color.transparent)
/*
        border(2.px, LineStyle.Solid, Color("#f1a417"))//rgba(90, 90, 90, 0.4))
        myBoxShadow(
            CSSShadow(-15.px, -15.px, 20.px, color = rgba(242, 110, 66, 0.2)),
            CSSShadow(20.px, 20.px, 35.px, color = rgba(0, 0, 0, 0.3)),
            CSSShadow(-5.px, -5.px, 15.px, color = rgba(242, 110, 66, 0.3)),
            CSSShadow(15.px, 15.px, 20.px, color = rgba(0, 0, 0, 0.4))
        )
        hover(self) style {
            backgroundColor(rgb(30, 30, 30))
            myBoxShadow(
                CSSShadow(-15.px, -15.px, 40.px, color = rgba(242, 110, 66, 0.2)),
                CSSShadow(20.px, 20.px, 55.px, color = rgba(0, 0, 0, 0.3)),
                CSSShadow(-5.px, -5.px, 25.px, color = rgba(242, 110, 66, 0.3)),
                CSSShadow(15.px, 15.px, 40.px, color = rgba(0, 0, 0, 0.4))
            )
            transform {
//                translate(-10.px,-10.px)
            }
        }
        self + active style {
            transform {
//                translate(10.px,10.px)
            }
        }
*/
    }
}