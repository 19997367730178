package mainPage


import androidx.compose.runtime.*
import api.firebase.setStatusUserFirestore
import api.firebase.updateUserProfile
import api.firebase.userSignOut
import style.Samples.WtSections
import elements.*
import global.*
import global.QuickStyleProperty.borderBox
import global.QuickStyleProperty.marginWidthAuto
import global.QuickStyleProperty.zIndex
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.delay
import mainPage.modalWindows.ModalChangeAvatar
import mainPage.modalWindows.ModalChangeDescription
import mainPage.modalWindows.ModalChangeName
import mainPage.modalWindows.ModalChangePhone
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLTextAreaElement
import org.w3c.xhr.FormData
import style.First.StyleCommon
import style.First.StyleSideNav
import style.First.StyleUserProfile
import style.common.StyleNavigationCommon
import style.common.StylePageTitleCommon

external fun axiosUploadWithProgress(
    url: String,
    formData: FormData,
    progressFun: (Double) -> Unit,
    thenFun: (Int) -> Unit
);


private var copyAlarm by mutableStateOf(false)
private var copyAlarm2 by mutableStateOf(false)

@OptIn(ExperimentalComposeWebApi::class)
@Composable
fun pageUserProfile() {
    MyContainerInSection {
        Div({
            style {
                marginWidthAuto()
                maxWidth(700.px)
                padding(10.pt)
            }
        }) {
            currentUser.userProfile?.let { userProf ->
                CenterDiv({
                    style {
                        width(100.percent)
                        padding(20.pt)
                        borderBox()
                    }
                }) {
                    Div({
                        style {
                            width(170.pt)
                        }
                    }) {
                        Img(src = userProf.avatar ?: tempAvatarUrl) {
                            classes(styleBlock.userProfile.avatarImg)
                            style {
                                width(170.pt)
                            }
                        }
                    }
                }
                CenterDiv({
                    style {
                        margin(10.px)
                        marginBottom(50.pt)
                    }
                }) {
                    Button({
                        classes(styleBlock.userProfile.buttonChangeAvatar)
                        onClick {
                            modalContent = ModalChangeAvatar()
                            visibleModalWindow.value = true
                        }
                    }) {
                        Text("Загрузить новое фото")
                    }
                }

                propertyUserProfileItem(ModalChangeName(userProf.name ?: "")) {
//                    CenterDiv {
                    H3({ classes(styleBlock.userProfile.textProperties) }) { //, StyleCommon.positionCenterVChild
                        Text(userProf.name ?: "")
                    }
//                    }
                }
                Div({ classes(styleBlock.userProfile.delimiter) })
                /*
                            propertyUserProfileItem("Изменить почту", ModalDefault()) {
                                textPropertyUserProfileItem(userProf.email ?: "")
                            }
                */
                propertyUserProfileItem(ModalChangePhone(userProf.phone ?: "")) {
//                    CenterDiv {
                    H3({ classes(styleBlock.userProfile.textProperties) }) { //, StyleCommon.positionCenterVChild
                        Text("Тел: ${userProf.phone ?: ""}")
                    }
//                    }
                }
                Div({ classes(styleBlock.userProfile.delimiter) })

                propertyUserProfileItem(ModalChangeDescription(userProf.description ?: "")) {
                    Div({
                        style {
                            width(100.percent)
//                        position(Position.Relative)
                        }
                    }) {
                        H2({
                            classes(styleBlock.userProfile.textProperties)
                            style {
                                color(stylePalette.myBlue)
                            }
                        }) {
                            Text("О себе:")
                        }
                        RichTextView(currentUser.userProfile?.description ?: "", attrs = {
                            classes(styleBlock.commonElement.rtView)
                            style {
//                        myTestBorder()
//                        maxWidth(400.px)

                            }
                        })
                    }
                }
                currentUser.userProfile?.id?.let { id_curr_trainer ->
                    if (currentUser.userProfile?.status == "trainer") {
                        Div({ classes(styleBlock.userProfile.delimiter) })
                        Div() {
                            FlexRowDivParent(
                                attrs = {
//                classes(StyleUserProfile.rowSetting)
                                    style {
//                    padding(10.px)
                                        position(Position.Relative)
                                    }
                                }
                            ) {
                                CenterDiv({
                                    style {
                                        flex("auto")
                                        flexDirection(FlexDirection.Column)
                                        textAlign("left")
                                    }
                                }) {
                                    H3({
                                        style {
                                            borderBox()
                                            fontWeight(200)
                                            width(100.percent)
                                        }
                                    }) {
                                        Text("Ссылка по которой клиенты смогут отправить вам заявку, чтобы тренироваться у вас.")
                                    }
                                    H4({
                                        style {
                                            borderBox()
                                            width(100.percent)
                                            fontWeight(200)
                                            color(stylePalette.myBlue)
                                        }
                                    }) {
                                        Text("mastergym.online?idt=$id_curr_trainer")
                                    }
                                }
                                Div {

                                    CenterDiv({
                                        classes(
                                            "dropdown-content",
                                            StyleCommon.noShow
                                        ) //if (openDropDown) StyleCommon.show else StyleCommon.noShow,
                                        style {
                                            position(Position.Absolute)
                                            top(-30.pt)
                                            display(DisplayStyle.None)
                                            right(0.px)
                                            if (copyAlarm) {
                                                display(DisplayStyle.Block)
                                                opacity(0)
                                            } else {
                                                display(DisplayStyle.None)
                                                opacity(0)
                                            }
                                            if (copyAlarm2) {
                                                opacity(1)
                                            } else {
                                                opacity(0)
                                            }
                                            transitions {
                                                all {
                                                    duration(0.5.s)
                                                    timingFunction(AnimationTimingFunction.EaseInOut)
                                                }
                                            }
                                            property("z-index", "699")
                                        }
                                    }) {
                                        Div({
                                            style {
                                                if (copyAlarm) {
                                                    display(DisplayStyle.Block)
//                                                    opacity(0)
                                                } else {
                                                    display(DisplayStyle.None)
//                                                    opacity(0)
                                                }
                                                position(Position.Relative)
                                                zIndex(799)
                                                backgroundColor(stylePalette.myBlue)
                                                color(stylePalette.mainTextColorInv)
                                                height(100.percent)
                                                borderRadius(10.px)
                                                padding(5.px, 20.px)
                                            }
                                        }) {
                                            Text("Ссылка скопирована")
                                        }
                                    }
                                    buttonIcon(listOf("fa-solid", "fa-copy"), {
                                        classes(styleBlock.userProfile.buttonIcon)
                                        style {
                                            height(100.percent)
                                            borderBox()
                                        }
                                    }, fontSize = 20.pt) {
//                                (document.getElementById("linkForCopy") as HTMLTextAreaElement).select()
//                                text.value = "Testovyi text dlya copirovaniya"
//                                text.select()

//                                document.getSelection().addRange(document.createRange().selectNode((document.getElementById("linkForCopy") as HTMLDivElement)))
                                        val copyTextarea = document.createElement("textarea") as HTMLTextAreaElement
                                        copyTextarea.style.position = "fixed";
                                        copyTextarea.style.opacity = "0";
                                        copyTextarea.style.height = "0px";
                                        copyTextarea.style.width = "0px";
                                        copyTextarea.textContent = "https://mastergym.online?idt=$id_curr_trainer"

                                        document.body?.appendChild(copyTextarea);
                                        copyTextarea.select();
                                        document.execCommand("copy")//, value = "asd 666 asdfasdf");
                                        copyTextarea.style.display = "none";
                                        copyAlarm = true
//                                modalContent = modal
//                                visibleModalWindow.value = true
                                    }
                                }
                            }
                        }

                    }
                }
                LaunchedEffect(copyAlarm) {
                    if (copyAlarm) {
                        copyAlarm2 = true
                        delay(1000)
                        copyAlarm2 = false
                        delay(550)
                        copyAlarm = false
                    }
                }
                Div({ classes(styleBlock.userProfile.delimiter) })

                CenterDiv({
                    style {
                        marginTop(20.px)
                        width(100.percent)
                        color(stylePalette.mainTextColor)
                        flexDirection(FlexDirection.Column)
                        flexGrow(0)
                        if (window.innerWidth > 600) textAlign("center")
                    }
                }) {
                    H3({
                        style {
                            fontWeight(200)
                        }
                    }) {
                        Text("Хотите сменить статус?")
                    }
                    FlexRowDivParent({
                        style {
                            margin(20.px, 5.percent)
                        }
                    }, flexWrap = FlexWrap.Nowrap, rowGap = 10.percent) {
                        if (currentUser?.userProfile?.status == "trainer") Button({
                            classes(StylePageTitleCommon.buttonComplexGirl, styleBlock.pageTitle.buttonComplexGirl)
                            style {
                                marginTop(0.px)
                            }
                            onClick {
                                setStatusUserFirestore("client") {
                                    updateUserProfile()
                                }
                            }
                        }) {
                            Text("Да, я клиент")
                        }
                        else Button({
                            classes(StylePageTitleCommon.buttonComplex, styleBlock.pageTitle.buttonComplex)
                            style {
                                marginTop(0.px)
                            }
                            onClick {
                                setStatusUserFirestore("trainer") {
                                    updateUserProfile()
                                }
                            }
                        }) {
                            Text("Да, я тренер")
                        }
                    }
                    if (currentUser.login) {
//                        Div({
//                            classes(StyleNavigationCommon.closebtn)
//                        }) {
                            Button({
                                classes(styleBlock.navigation.buttonSideNav, StyleNavigationCommon.buttonSideNav)
                                classes(styleBlock.navigation.closebtn)
                                style {
                                    margin(styleConst.paddingCommon,0.px)
                                }
                                onClick {
                                    userSignOut {}
                                    currentUser.userProfile = null
                                    currentPage = PageApplication.TitlePage
//                                    if (window.innerWidth < 700.pt.value) widthSideNavPx = 0.pt
                                }
                            }) {
                                Text("Выйти")
                            }
//                        }
                    }
                }


                /*
                            propertyUserProfileItem("Изменить пароль", ModalDefault()) {
                                textPropertyUserProfileItem("Пароль: ***")
                            }
                */
            }
        }
    }
}

@Composable
fun propertyUserProfileItem(
    modal: ModalContent,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    content: @Composable () -> Unit
) {
    Div(attrs) {
        FlexRowDivParent(
            attrs = {
//                classes(StyleUserProfile.rowSetting)
                style {
//                    padding(10.px)
                }
            }
        ) {
            CenterDiv({
                style {
                    flex("auto")
                }
            }) {
                content()
            }
            buttonIcon(listOf("fa-solid", "fa-gears"), {
                classes(styleBlock.userProfile.buttonIcon)
            }, fontSize = 20.pt) {
                modalContent = modal
                visibleModalWindow.value = true
            }
        }
    }
}


